import { getRoot, Instance, SnapshotOut, types } from "mobx-state-tree";
import {
  ContactData,
  ContactList,
  ContactUpdatedData,
  Event,
  EventNames,
  QueryOnlineData,
  ContactDeletedData,
  MessageStatus,
  MessageTypes,
} from "../../proto/nbchat-proto";
import { Contact, ContactModel } from "../contact/contact";
import { withEnvironment } from "../extensions/with-environment";
import { NotifyType } from "../../types/message";
import { RootStoreModel } from "..";

type ValueOf<T> = T[keyof T];

type TypeLetterListData = {
  letter: string;
  data: string[];
};

function makeLetterListData<T extends Record<string, any>>(
  filter: (d: T) => boolean,
  data: T[],
  key: keyof T
): TypeLetterListData[] {
  const dst = {} as Record<ValueOf<T>, T[]>;
  Array.from(data)
    .filter(filter)
    .forEach((d: T) => {
      const v = d[key];
      dst[v] = dst[v] || [];
      dst[v].push(d);
    });

  const lst = Object.keys(dst).reduce((acc, letter) => {
    if (dst[letter].length) acc.push({ letter, data: dst[letter] });
    return acc;
  }, [] as TypeLetterListData[]);

  return lst;
}

/**
 * Model description here for TypeScript hints.
 */
export const ContactStoreModel = types
  .model("ContactStore")
  .props({
    contacts: types.optional(types.map(ContactModel), {}),
  })
  .extend(withEnvironment)
  .views((self) => {
    function _contactNameDefaultFilter(filter: RegExp, contact: Contact) {
      return (
        filter.test(contact.user.username) ||
        filter.test(contact.user.name) ||
        filter.test(contact.name)
      );
    }
    function size() {
      return self.contacts.size;
    }
    function byName() {
      const data = Array.from(self.contacts.values());
      return makeLetterListData<(typeof data)[0]>(
        () => true,
        data,
        "name_letter"
      );
    }
    function byNameWithFilter(filter: RegExp) {
      const filterFunc = (contact: Contact) => {
        return _contactNameDefaultFilter(filter, contact);
      };
      const data = Array.from(self.contacts.values());
      return makeLetterListData<(typeof data)[0]>(
        filterFunc,
        data,
        "name_letter"
      );
    }
    function byNameWithFilterAndExcludeIds(
      filter: RegExp,
      excludeIds: number[]
    ) {
      const filterFunc = (contact: Contact) => {
        return (
          !excludeIds.includes(contact.user_id) &&
          _contactNameDefaultFilter(filter, contact)
        );
      };
      const data = Array.from(self.contacts.values());
      return makeLetterListData<(typeof data)[0]>(
        filterFunc,
        data,
        "name_letter"
      );
    }
    function byNameWithFilterAndIncludeIds(
      filter: RegExp,
      includeIds: number[]
    ) {
      const filterFunc = (contact: Contact) => {
        return (
          includeIds.includes(contact.user_id) &&
          _contactNameDefaultFilter(filter, contact)
        );
      };
      const data = Array.from(self.contacts.values());
      return makeLetterListData<(typeof data)[0]>(
        filterFunc,
        data,
        "name_letter"
      );
    }
    function byUserId(id: number) {
      return Array.from(self.contacts.values()).find((x) => x.user.id === id);
    }
    // 根据用户id来显示名称
    function getDisplayName(user_id: number) {
      const contact = byUserId(user_id);
      if (contact) {
        return contact.displayName;
      }
      const { userStore } = getRoot<Instance<typeof RootStoreModel>>(self);
      const user = userStore.users.get(user_id);
      if (user) {
        return user.name;
      }
    }
    return {
      get size() {
        return size();
      },
      get byName() {
        return byName();
      },
      byNameWithFilter,
      byNameWithFilterAndExcludeIds,
      byNameWithFilterAndIncludeIds,
      byUserId,
      getDisplayName,
    };
  })
  .actions((self) => {
    function clear() {
      self.contacts.clear();
    }
    function addContact({ user, ...contact }: ContactData) {
      const { userStore } = getRoot<Instance<typeof RootStoreModel>>(self);
      const u = userStore.addUser(user);
      return self.contacts.put({ ...contact, user: u.id });
    }
    // event-store
    function onContactCreated({
      data: { user, ...contact },
    }: Event<ContactData>) {
      const { chatStore } = getRoot<Instance<typeof RootStoreModel>>(self);
      const prevContact = self.contacts.get(contact.id.toString());
      const currentContact = addContact({
        user,
        ...contact,
      });
      currentContact.setDeleted(prevContact ? prevContact.deleted : true);
      const chat = chatStore.newPrivateChat({
        receiver_id: currentContact.user.id,
        name: currentContact.displayName,
      });
      chat.newMessage({
        content: "已加为好友",
        type: MessageTypes.TEXT,
        status: MessageStatus.READ,
        notify_type: NotifyType.FRIEND_ADDED_BUT_NOT_APPROVED,
      });
      chat.setDeleted(false);
    }
    function fetchOnline() {
      if (self.contacts.size > 0) {
        const evt = new Event<QueryOnlineData>(EventNames.QUERY_ONLINE, {});
        const { eventStore } = getRoot<Instance<typeof RootStoreModel>>(self);
        eventStore.publish(evt);
      }
    }
    // event-store
    function onContactList({ data: { items } }: Event<ContactList>) {
      items.forEach((data) => addContact(data));
    }
    // event-store
    function onContactUpdated({
      data: { id, ...values },
    }: Event<ContactUpdatedData>) {
      const contact = self.contacts.get(id.toString()); // 根据ID获取需要更新资料的用户
      contact.update(values);
      return contact;
    }
    // event-store
    function onContactDeleted({
      data: { id, owner_id, user_id },
    }: Event<ContactDeletedData>) {
      const {
        userStore: { currentUser },
        chatStore,
      } = getRoot<Instance<typeof RootStoreModel>>(self);
      const isSelf = currentUser.id === owner_id; // 是否自己主動刪除
      if (isSelf) {
        self.contacts.delete(id.toString());
        const chat = chatStore.chats.get(user_id);
        chatStore.setCurrent(undefined);
        chat && chatStore.deletePrivateChat(user_id);
      } else {
        const targetContact = self.byUserId(owner_id);
        targetContact && targetContact.setStatus(0);
      }
    }
    return {
      clear,
      onContactCreated,
      addContact,
      fetchOnline,
      onContactList,
      onContactUpdated,
      onContactDeleted,
    };
  });

type ContactStoreType = Instance<typeof ContactStoreModel>;
export interface ContactStore extends ContactStoreType {}
type ContactStoreSnapshotType = SnapshotOut<typeof ContactStoreModel>;
export interface ContactStoreSnapshot extends ContactStoreSnapshotType {}
export const createContactStoreDefaultModel = () =>
  types.optional(ContactStoreModel, {});
