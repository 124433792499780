import { useMemo } from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import routes from "../../../routes";
import { useStores } from "../../../../models";
export const PanelWidth = 300;
const PanelWrap = styled.div`
  position: relative;
  min-width: ${PanelWidth}px;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.inputBackground};
  border-width: 0px;
  border-right-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.border};
`;

const ContentWrap = styled.div`
  height: 100%;
  overflow: scroll;
`;

const PanelContent = ({ panel, setPanel }) => {
  const Component = panel.Component;
  return (
    <ContentWrap>
      <Component setPanel={setPanel} />
    </ContentWrap>
  );
};

const LeftPanel = () => {
  const rootStore = useStores();
  const { panel, setPanel, isMobile } = rootStore;

  const PanelComponents = routes.filter((route) =>
    isMobile ? true : route.routerName === "app" && route.isLeftPanel === true
  );

  const panelObj = useMemo(() => {
    return PanelComponents.find(({ key }) => key === panel);
  }, [PanelComponents, panel]);

  return (
    <PanelWrap>
      <PanelContent panel={panelObj} setPanel={setPanel} />
    </PanelWrap>
  );
};

export default observer(LeftPanel);
