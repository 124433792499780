/** @format */

import { observer } from "mobx-react-lite";
import React, {
  useRef,
  useState,
  useEffect,
  useContext,
  createContext,
} from "react";
import { SpinLoading } from "antd-mobile";
import styled from "styled-components";
import { PageProps } from "../types";
import { useStores } from "../../models";
import useGlobalTheme from "../../hooks/useGlobalTheme";
import useNavigation from "../../hooks/useNavigation";
import ErrorText from "../../widgets/ErrorText";
import { validate, isEmpty, rules } from "../../refactor-utils/validator";
import PageWrap from "../components/PageWrap";
import NormalInput from "../../components/NormalInput";
import NormalButton from "../../components/NormalButton";
import { ToastSuccess, ToastError } from "../../components/Toast";
const loginLogo = require("../../assets/images/login-logo.png");

const Page = styled.div<{ isMobile: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.secondary};
  background-color: ${({ theme, isMobile }) =>
    isMobile ? theme.colors.primary : theme.colors.secondary};
`;

const FormWrap = styled.div<{ isMobile: boolean }>`
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  width: ${({ isMobile }) => `${isMobile ? 100 : 60}%`};
  max-width: 500px;
  min-width: 320px;
  min-height: 400px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
`;

const TopArea = styled.div`
  position: relative;
  width: 100%;
  height: 160px;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: ${({ theme }) => theme.radii[3]};
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
`;

const Form = styled.div`
  overflow-y: scroll;
  display: flex;
  width: 100%;
  height: 360px;
  flex-direction: column;
  padding: ${({ theme }) => theme.space[4]};
  border: 1px solid ${({ theme }) => theme.colors.border};
  background-color: ${({ theme }) => theme.colors.blockBackground};
  border-radius: ${({ theme }) => theme.radii[3]};
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
`;

const LoginLogo = styled.img`
  position: absolute;
  z-index: 2;
  top: ${({ theme }) => theme.space[2]};
  right: ${({ theme }) => theme.space[2]};
  width: 120px;
  height: 120px;
`;

const MainTitle = styled.h1`
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.blockBackground};
  margin-top: ${({ theme }) => theme.space[4]};
  margin-left: ${({ theme }) => theme.space[4]};
  margin-bottom: ${({ theme }) => theme.space[2]};
`;

const Title = styled.h1`
  font-size: 1.3rem;
  margin: 0px;
  margin-bottom: ${({ theme }) => theme.space[2]};
`;

const ButtonArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 10px;
`;

const Text = styled.span<{ color?: string; fontSize?: string }>`
  color: ${({ color, theme }) => color || theme.colors.text};
  font-size: ${({ fontSize }) => fontSize || "0.8rem"};
`;

const VerifyText = styled.div`
  color: ${({ theme }) => theme.colors.text};
  margin-bottom: 10px;
`;

const EmailText = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 10px;
`;

const CodeAlertTextWrap = styled.div`
  display: flex;
  flex-direction: row;
`;

const CodeCountDownText = styled.div`
  color: ${({ theme }) => theme.colors.danger};
`;

const InputWrap = styled.div`
  position: relative;
`;

const ResendButton = styled.div`
  position: absolute;
  right: 0px;
  bottom: 7px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const ResendText = styled.div`
  color: ${({ theme }) => theme.colors.primary};
`;

type ForgetPasswordBase = {
  step: number;
  setStep: (step: number) => void;
  username: string;
  setUsername: (username: string) => void;
  email: string;
  setEmail: (email: string) => void;
};

const ForgetPasswordBaseContext = createContext<ForgetPasswordBase>({
  step: 1,
  setStep: (step: number) => {
    console.log("setStep is not ready yet", step);
  },
  username: "",
  setUsername: (username: string) => {
    console.log("setUsername is not ready yet", username);
  },
  email: "",
  setEmail: (email: string) => {
    console.log("setEmail is not ready yet", email);
  },
});

const useForgetPasswordBaseContext = () =>
  useContext(ForgetPasswordBaseContext);

interface Props extends PageProps {}

const Step1: React.FC = () => {
  const theme = useGlobalTheme();
  const { navigate } = useNavigation();
  const { username, setUsername, email, setEmail, setStep } =
    useForgetPasswordBaseContext();
  const { apiStore } = useStores();
  const [formErr, setFormErr] = useState(false);
  const [loading, setLoading] = useState(false);
  async function onForgetPasswword() {
    setLoading(true);
    try {
      await apiStore.forgetPassword({ username, email });
      setStep(2);
    } catch (e) {
      ToastError(e.message);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    setFormErr(
      !isEmpty(
        validate(
          { username: rules.username, email: rules.email },
          { username, email }
        )
      )
    );
  }, [username, email]);
  return (
    <Form>
      <Title>忘记密码</Title>
      <NormalInput
        label="账号"
        placeholder="请输入注册过之账号"
        value={username}
        validateRule={rules.username}
        onChange={(e) => {
          setUsername(e.target.value);
        }}
      />
      <NormalInput
        label="电子邮箱"
        placeholder="请输入注册过之电子邮箱"
        value={email}
        validateRule={rules.email}
        onChange={(e) => {
          setEmail(e.target.value);
        }}
      />
      <ButtonArea>
        <NormalButton disabled={formErr || loading} onClick={onForgetPasswword}>
          下一步
        </NormalButton>
      </ButtonArea>
      <ButtonArea>
        <Text
          style={{ cursor: "pointer" }}
          color={theme.colors.primary}
          onClick={() => {
            navigate("Login");
          }}
        >
          返回登录
        </Text>
      </ButtonArea>
    </Form>
  );
};

const Step2: React.FC = () => {
  const countDownSec = 300;
  const countDownIntervalRef = useRef<any>();
  const theme = useGlobalTheme();
  const { navigate } = useNavigation();
  const { username, email } = useForgetPasswordBaseContext();
  const { apiStore } = useStores();
  const [countDown, setCountDown] = useState(countDownSec);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordErrorText, setConfirmPasswordErrorText] = useState("");
  const [code, setCode] = useState("");
  const [codeErrorText, setCodeErrorText] = useState("");
  const [formErr, setFormErr] = useState(false);
  const [loading, setLoading] = useState(false);
  async function onResetPassword() {
    try {
      setLoading(true);
      await apiStore.resetPassword({ username, password, code });
      ToastSuccess("重设密码成功, 请使用新密码登录");
      navigate("Login");
    } catch (e) {
      ToastError(e.message);
    } finally {
      setLoading(false);
    }
  }
  async function onResendCode() {
    try {
      setLoading(true);
      await apiStore.forgetPassword({ username, email });
      setCountDown(countDownSec);
    } catch (e) {
      ToastError(e.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    setFormErr(
      !!codeErrorText ||
        !!confirmPasswordErrorText ||
        !isEmpty(
          validate(
            { password: rules.password, code: rules.code },
            { password, code }
          )
        )
    );
  }, [codeErrorText, confirmPasswordErrorText, password, code]);

  useEffect(() => {
    countDownIntervalRef.current = setInterval(() => {
      if (countDown > 0) {
        setCountDown(countDown - 1);
      }
    }, 1000);
    return () => {
      clearInterval(countDownIntervalRef.current);
    };
  }, [countDown]);

  useEffect(() => {
    setCodeErrorText(countDown === 0 ? "验证码过期" : "");
  }, [countDown]);

  useEffect(() => {
    setConfirmPasswordErrorText(
      password !== confirmPassword ? "与新密码不一致" : ""
    );
  }, [password, confirmPassword]);
  return (
    <Form>
      <Title>忘记密码</Title>
      <VerifyText>验证码已发送至</VerifyText>
      <EmailText>{email}</EmailText>
      <CodeAlertTextWrap>
        <VerifyText>请于5分钟内查收信件并输入验证码</VerifyText>
        <CodeCountDownText>({countDown})</CodeCountDownText>
      </CodeAlertTextWrap>
      <InputWrap>
        <NormalInput
          label="验证码"
          placeholder="请输入电子邮箱验证码"
          value={code}
          validateRule={rules.code}
          onChange={(e) => {
            setCode(e.target.value);
          }}
        />
        <ErrorText visible={!!codeErrorText}>{codeErrorText}</ErrorText>
        <ResendButton onClick={onResendCode}>
          {loading ? (
            <SpinLoading
              color={theme.colors.primary}
              style={{ "--size": "18px" }}
            />
          ) : (
            <ResendText>重新发送</ResendText>
          )}
        </ResendButton>
      </InputWrap>
      <NormalInput
        label="新密码"
        placeholder="请输入新密码"
        value={password}
        validateRule={rules.password}
        onChange={(e) => {
          setPassword(e.target.value);
        }}
      />
      <InputWrap>
        <NormalInput
          label="再次确认密码"
          placeholder="请输入相同密码"
          value={confirmPassword}
          onChange={(e) => {
            setConfirmPassword(e.target.value);
          }}
        />
        <div style={{ position: 'absolute', bottom: '9px' }}>
          <ErrorText visible={!!confirmPasswordErrorText}>
            {confirmPasswordErrorText}
          </ErrorText>
        </div>
      </InputWrap>
      <ButtonArea>
        <NormalButton disabled={formErr || loading} onClick={onResetPassword}>
          设定新密码完成
        </NormalButton>
      </ButtonArea>
      <ButtonArea>
        <Text
          style={{ cursor: "pointer" }}
          color={theme.colors.primary}
          onClick={() => {
            navigate("Login");
          }}
        >
          返回登录
        </Text>
      </ButtonArea>
    </Form>
  );
};

const steps = {
  1: Step1,
  2: Step2,
};

const ForgetPassword: React.FC<Props> = observer(() => {
  const { isMobile, configStore } = useStores();
  const [step, setStep] = useState(1);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const CurrentStep = steps[step];

  return (
    <ForgetPasswordBaseContext.Provider
      value={{ step, setStep, username, setUsername, email, setEmail }}
    >
      <PageWrap>
        <Page isMobile={isMobile}>
          <FormWrap isMobile={isMobile}>
            <TopArea>
              <MainTitle>欢迎來到，</MainTitle>
              <MainTitle>{configStore.appName}IM</MainTitle>
              <LoginLogo src={loginLogo} />
            </TopArea>
            <CurrentStep />
          </FormWrap>
        </Page>
      </PageWrap>
    </ForgetPasswordBaseContext.Provider>
  );
});
export default ForgetPassword;
