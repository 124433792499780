import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { ChatPerms } from "../../proto/nbchat-proto"
import { UserModel } from "../user"

/**
 * 聊天室成员
 */
export const ChatMemberModel = types
  .model("ChatMember")
  .props({
    user_id: types.identifierNumber,
    chat_id: types.maybe(types.number),
    id: types.maybe(types.number),
    perm: types.optional(types.number, ChatPerms.DEFAULT),
    user: types.reference(UserModel),
    // options: types.
  })
  .views((self) => ({}))
  .actions((self) => ({}))

type ChatMemberType = Instance<typeof ChatMemberModel>
export interface ChatMember extends ChatMemberType {}
type ChatMemberSnapshotType = SnapshotOut<typeof ChatMemberModel>
export interface ChatMemberSnapshot extends ChatMemberSnapshotType {}
export const createChatMemberDefaultModel = () => types.optional(ChatMemberModel, {} as any)
