import { useState, useCallback } from "react"
import { validateSingle, ValidationRule } from "../utils"

const useValidateInput = (validRule: ValidationRule) => {
  const [error, setError] = useState(false)
  const validate = useCallback(
    (value: any) => {
      setError(validateSingle(validRule, value))
    },
    [validRule],
  )
  return { error, validate }
}

export default useValidateInput
