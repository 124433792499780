/** @format */

import React, { useRef, useState, useMemo, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import useNavigation from '../../hooks/useNavigation';
import PageWrap from '../components/PageWrap';
import PageHeader, { headerHeight } from '../components/PageHeader';
import NormalInput from '../../components/NormalInput';
import { ToastSuccess } from '../../components/Toast';
import { useStores } from '../../models';
import {
  Event,
  EventNames,
  ContactUpdateData,
  FLAG_ACK,
} from '../../proto/nbchat-proto';
import { rules, isEmpty, validate } from '../../refactor-utils/validator';

const ScrollArea = styled.div`
  height: calc(100% - ${headerHeight});
  overflow: scroll;
  padding: ${({ theme }) => theme.space[4]};
  background-color: ${({ theme }) => theme.colors.blockBackground};
`;

const SubmitText = styled.span<{ canSubmit: boolean }>`
  cursor: pointer;
  color: ${({ canSubmit, theme }) =>
    canSubmit ? theme.colors.primary : theme.colors.inputPlaceholderColor};
`;

const SubTitle = styled.div`
  font-size: 0.8rem;
  color: ${({ theme }) => theme.colors.inputPlaceholderColor};
  margin-bottom: ${({ theme }) => theme.space[4]};
  text-align: center;
`;

const UpdateContact: React.FC = () => {
  const { goBack, params } = useNavigation();
  const { user_id } = params;
  const { contactStore, eventStore } = useStores();
  const contact = contactStore.byUserId(Number(user_id));
  const oldDisplayname = useRef(contact.name || '');
  const oldNote = useRef(contact.note || '');
  const [displayname, setDisplayname] = useState(contact.name || '');
  const [note, setNote] = useState(contact.note || '');

  const onSubmit = useCallback(() => {
    const evt = new Event<ContactUpdateData>(EventNames.CONTACT_UPDATE, {
      username: contact.username,
      name: displayname,
      note,
    });
    eventStore.publish(evt, { flags: FLAG_ACK });
    ToastSuccess('修改成功!');
    goBack(-1);
  }, [contact.username, displayname, note, goBack, eventStore]);

  const disabledFunc = useCallback(
    ({ displayname, note }) => {
      return (
        (displayname === oldDisplayname.current && note === oldNote.current) ||
        !isEmpty(
          validate(
            { name: rules.contact_displayname, note: rules.contact_note },
            { name: displayname, note }
          )
        )
      );
    },
    [oldDisplayname, oldNote]
  );

  const canSubmit = useMemo(
    () => !disabledFunc({ displayname, note }),
    [disabledFunc, displayname, note]
  );

  return (
    <PageWrap>
      <PageHeader title={'设置备注'}>
        <SubmitText
          canSubmit={canSubmit}
          onClick={canSubmit ? onSubmit : undefined}
        >
          确定
        </SubmitText>
      </PageHeader>

      <ScrollArea>
        <SubTitle>修改群聊名称后，将在群内通知其他成员。</SubTitle>
        <NormalInput
          label="备注"
          placeholder="请输备注"
          value={displayname}
          onChange={(e) => setDisplayname(e.target.value)}
          validateRule={rules.contact_displayname}
        ></NormalInput>
        <NormalInput
          label="描述"
          placeholder="请输入描述"
          value={note}
          onChange={(e) => setNote(e.target.value)}
          validateRule={rules.contact_note}
        ></NormalInput>
      </ScrollArea>
    </PageWrap>
  );
};

export default observer(UpdateContact);
