import { autorun } from "mobx";
import { getRoot, Instance, SnapshotOut, types } from "mobx-state-tree";
import { Event, EventNames } from "../../proto/nbchat-proto";
import {
  debugProto,
  deserialize,
  Proto,
  ProtoData,
  serialize,
} from "../../types/proto";
import { debug } from "../../utils/print";
import { RootStoreModel } from "..";

const FLAG_ACK = 1;
const FLAG_REPLY = 2;
const FLAG_ACKED = 4;
// const FLAG_REPLIED = 8
const ACK_TIMEOUT = 10000;
const REQUEST_TIMEOUT = 5000;
const DELAY = 10; // 5ms 延迟，避免短期内消息风暴

type ReplyQ = {
  [cid: string]: {
    resolve: (data) => void;
    reject: (error: Error) => void;
  };
};

type AckQ = {
  [cid: string]: () => void;
};

/**
 * 通道管理
 */
export const ChannelStoreModel = types
  .model("ChannelStore")
  .props({
    sendq: types.optional(types.array(types.frozen<ProtoData>()), []),
    recvq: types.optional(types.array(types.frozen<ProtoData>()), []),
  })
  .volatile((self): { ackq: AckQ; replyq: ReplyQ } => ({
    ackq: {},
    replyq: {},
  }))
  .views((self) => ({}))
  .actions((self) => ({
    // 处理请求协议确认机制
    onAck: (data: Proto) => {
      const f = self.ackq[data.id];
      if (f) {
        f();
        delete self.ackq[data.id];
      }
    },

    setSendQ: (data: ProtoData[]) => {
      self.sendq.replace(data);
    },

    clearRecvQ: () => {
      self.recvq.clear();
    },

    clearSendQ: () => {
      self.sendq.clear();
    },

    setRecvQ: (data: ProtoData[]) => {
      self.recvq.replace(data);
    },

    removeMessageNewFromSendqByMessageID: (m_id: string) => {
      const sendq = self.sendq.filter((x: ProtoData) => {
        if (x[1] === EventNames.MESSAGE_NEW) {
          const { message_id } = x[2];
          return m_id !== message_id;
        }
        return true;
      });
      self.sendq.replace(sendq);
    },

    // 处理响应
    onReply: (msg: Proto) => {
      const cid = msg.id;
      const data = msg.data;
      const f = self.replyq[cid];
      if (f) {
        debugProto(msg, "<<<");
        const { error } = data || {};
        if (error) {
          f.reject(new Error(error.message));
        } else {
          f.resolve(data);
        }
        delete self.replyq[cid];
      }
    },

    // 处理来自服务器的心跳协议
    onHeartBeat: (data: Proto) => {},

    // send proto
    send: (
      msg: Proto,
      options?: {
        onData?: (data) => void;
        onError?: (error: Error) => void;
        immediately?: boolean;
      }
    ) => {
      if (!msg.name) {
        // FIXME: 首次启动会混进来一个空消息
        debug("empty msg: ", msg);
        return;
      }
      const { websocketStore } = getRoot<Instance<typeof RootStoreModel>>(self);
      const { onData, onError, immediately } = options || {};
      if (immediately) {
        websocketStore.send(serialize(msg.unstructure()));
      } else {
        self.sendq.push(msg.unstructure());
      }

      // request / reply
      if (msg.flags & FLAG_REPLY && onData) {
        self.replyq[msg.id] = createReplyCallback(msg.id, onData, onError);
      }
    },
  }))
  .actions((self) => {
    const { websocketStore, eventStore } =
      getRoot<Instance<typeof RootStoreModel>>(self);

    // on data received from the server
    const ondata = (data: Uint8Array) => {
      const proto = deserialize(data);
      debugProto(proto, "<<<");

      if (proto.flags & FLAG_ACKED) {
        self.onAck(proto);
        return;
      }

      if (proto.flags & FLAG_REPLY) {
        self.onReply(proto);
        return;
      }

      if (proto.flags & FLAG_ACK) {
        const p = new Proto(proto.name, null, FLAG_ACKED, proto.id, proto.v);
        websocketStore.send(p.serialize());
      }

      // FIXME 需要注意短时间内消息过多的问题
      const evt = new Event(proto.name, proto.data, false);
      eventStore.onEvent(evt);
    };

    const createAckCallback = (id: number, x: ProtoData) => {
      const t = setTimeout(() => {
        delete self.ackq[id];
      }, ACK_TIMEOUT);
      return () => {
        clearTimeout(t);
        const { messageStore } = getRoot<Instance<typeof RootStoreModel>>(self);
        if (x[1] === EventNames.MESSAGE_NEW) {
          const { message_id } = x[2];
          clearTimeout(messageStore.sendMessageTimeoutMessageIdMap[message_id]);
        }
        self.setSendQ(self.sendq.filter((x) => x[0][2] !== id));
      };
    };

    const flushSendQ = () => {
      // TODO: 批处理消息，避免惊群效应
      // 当 sendq 的长度或者 socket 的状态发生变化时候
      // 自动把当前队列里的消息投递出去

      debug(
        `[FlushSendQ] length: ${self.sendq.length}, sock ready: ${websocketStore.ready}, online: ${websocketStore.online}`
      );
      if (
        self.sendq.length === 0 ||
        !websocketStore.ready ||
        !websocketStore.online
      )
        return;

      const sendq = self.sendq.filter((x) => {
        const [, flags, id] = x[0];
        const ack = flags & FLAG_ACK;
        if (ack) {
          // 回执队列中没有，表示该发送任务是新的或者是需要重发的
          // 创建回执任务
          if (self.ackq[id] === undefined) {
            self.ackq[id] = createAckCallback(id, x);
          } else {
            // 还在等待回执, 跳过该发送任务
            return true;
          }
        }
        debugProto({ id, flags, name: x[1], data: x[2] }, ">>>");
        websocketStore.send(serialize(x));

        // 移除不需要等待回执的任务
        return ack;
      });

      if (sendq.length !== self.sendq.length) {
        self.setSendQ(sendq);
      }
    };

    // const flushRecvQ = () => {
    //   // 消息处理设计应该考虑幂等性原则
    //   debug(`[FlushRecvQ] length: ${self.recvq.length}`)
    //   if (self.recvq.length == 0) return
    //   self.recvq.forEach((x) => {
    //     const [, name, data] = x
    //     const evt = new Event(name, data, false)
    //     eventStore.onEvent(evt)
    //   })
    //   self.clearRecvQ()
    // }

    let disposers: any[] = [];

    const afterCreate = () => {
      // TODO autorun, delay
      disposers = [autorun(flushSendQ, { delay: DELAY })];
    };

    const beforeDestory = () => {
      disposers.forEach((f) => f());
    };
    return { ondata, afterCreate, beforeDestory };
  });

function createReplyCallback(
  id: number,
  onData: (data) => void,
  onError?: (err: Error) => void,
  ts: number = REQUEST_TIMEOUT
) {
  const t = setTimeout(() => {
    reject(new Error(`[${id}]:request timed out after ${ts} ms`));
  }, ts);

  const resolve = (event) => {
    clearTimeout(t);
    onData(event);
  };
  const reject = (error: Error) => {
    clearTimeout(t);
    onError && onError(error);
  };
  return { resolve, reject };
}

type ChannelStoreType = Instance<typeof ChannelStoreModel>;
export interface ChannelStore extends ChannelStoreType {}
type ChannelStoreSnapshotType = SnapshotOut<typeof ChannelStoreModel>;
export interface ChannelStoreSnapshot extends ChannelStoreSnapshotType {}
export const createChannelStoreDefaultModel = () =>
  types.optional(ChannelStoreModel, {});
