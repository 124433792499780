/** @format */

import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import useGlobalTheme from '../../hooks/useGlobalTheme';
import useNavigation from '../../hooks/useNavigation';
import { useStores } from '../../models';
import PageWrap from '../components/PageWrap';
import PageHeader from '../components/PageHeader';
import NormalInput from '../../components/NormalInput';
import Icon from '../../components/Icon';
import QrcodeDisplayer from '../../components/QrcodeDisplayer';
import { ToastError } from '../../components/Toast';

const SearchBar = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.blockBackground};
  padding: ${({ theme }) => theme.space[2]} ${({ theme }) => theme.space[3]};
`;

const SearchInputWrap = styled.div`
  padding-right: ${({ theme }) => theme.space[2]};
  flex-grow: 1;
`;

const SearchInput = styled(NormalInput)``;

const SearchButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px ${({ theme }) => theme.space[3]};
  cursor: pointer;
`;

const AddContact: React.FC = () => {
  const theme = useGlobalTheme();
  const { navigate } = useNavigation();
  const { userStore } = useStores();
  const { currentUser } = userStore;

  const [username, setUsername] = useState('');
  const [, setSearchFocus] = useState(false);

  const checkCurrentMode = () => {
    setSearchFocus(!!username);
  };

  const handleAddContact = () => {
    if (username === currentUser.username) {
      ToastError('无法添加自己为好友');
      return;
    }
    const onData = (data) => {
      userStore.addUser(data)
      navigate('User', { user_id: data.id });
    };
    const onError = ({ message }) => ToastError(message);
    userStore.publicSearchUser(username, onData, onError);
  };

  return (
    <PageWrap>
      <PageHeader title="添加好友"></PageHeader>
      <SearchBar>
        <SearchInputWrap>
          <SearchInput
            onFocus={() => {
              setSearchFocus(true);
            }}
            onBlur={checkCurrentMode}
            placeholder="输入用户名/手机号"
            value={username}
            onChange={(e) => {
              setUsername(e.target.value);
            }}
          />
        </SearchInputWrap>
        <SearchButtonWrap onClick={handleAddContact}>
          <Icon
            type="mdi"
            name="accountSearch"
            color={theme.colors.primary}
            size={1.2}
          />
        </SearchButtonWrap>
      </SearchBar>
      <QrcodeDisplayer
        avatar={currentUser.avatar}
        gender={currentUser.gender}
        qrcode={currentUser.qrcode}
        name={currentUser.name}
        description="扫描二维码 加我为好友"
      />
    </PageWrap>
  );
};

export default observer(AddContact);
