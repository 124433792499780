/** @format */

import { useEffect, useRef, useState } from "react";
import { ReactMediaRecorder } from "react-media-recorder";
import styled from "styled-components";
import { Modal } from "antd-mobile";
import useGlobalTheme from "../../../hooks/useGlobalTheme";
import Icon from "../../../components/Icon";
import { ToastError } from "../../../components/Toast";
import { makeID, msToTime } from "../../../utils";
import { MessageTypes } from "../../../proto/nbchat-proto";
import { useStores } from "../../../models";
import Emoji from "./emoji";
import { Photo } from "../../../types/photo";

const Wrap = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  gap: 10px;
  top: -38px;
  width: 100%;
  padding: 10px 20px;
  border-width: 0px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.border};
  border-top-width: 1px;
  background-color: ${({ theme }) => theme.colors.blockBackground};
`;

const RecordingRedDot = styled.div`
  background-color: ${({ theme }) => theme.colors.danger};
  width: 8px;
  height: 8px;
  border-radius: 100%;
`;

interface MediaUploadPayload {
  file?: File;
  filename: string;
  type: string;
  path: string;
  size: number;
}

const AudioRecord = ({
  status,
  startRecording,
  stopRecording,
  mediaBlobUrl,
  handleUploadAudio,
}) => {
  const theme = useGlobalTheme();
  const recordRef = useRef<number>();
  const [recordTime, setRecordTime] = useState(0);
  const [fireUpload, setFireUpload] = useState(false);

  const isRecording = status === "recording";

  const recordDotColor = isRecording
    ? theme.colors.primary
    : theme.colors.border;

  const handleStartRecordAudio = (start) => {
    const startCountRecordTime = () => {
      let startTime = Date.now();
      const cb = () => {
        setRecordTime(Date.now() - startTime);
        recordRef.current = window.requestAnimationFrame(cb);
      };
      recordRef.current = window.requestAnimationFrame(cb);
    };
    startCountRecordTime();
    start();
  };

  const handleStopRecordAudio = (stop) => {
    const stopCountRecordTime = () => {
      window.cancelAnimationFrame(recordRef.current);
      setTimeout(() => setFireUpload(true), 500);
    };
    stopCountRecordTime();
    stop();
  };

  const onClick = isRecording
    ? () => {
        handleStopRecordAudio(stopRecording);
      }
    : () => {
        handleStartRecordAudio(startRecording);
      };

  useEffect(() => {
    if (fireUpload && mediaBlobUrl) {
      handleUploadAudio(mediaBlobUrl, recordTime);
      setRecordTime(0);
      setFireUpload(false);
    }
  }, [handleUploadAudio, fireUpload, mediaBlobUrl, recordTime]);

  return (
    <>
      <Icon
        type="antd"
        name="audio"
        onClick={onClick}
        size={1}
        color={recordDotColor}
      />
      {isRecording && <RecordingRedDot />}
      {isRecording && msToTime(recordTime)}
    </>
  );
};
const EmojiControl = ({ addNewContentToInput }) => {
  const [emojiModalVisible, setEmojiModalVisible] = useState(false);
  const handleVisible = () => setEmojiModalVisible(!emojiModalVisible);
  const handleSelect = (emoji) => {
    handleVisible();
    addNewContentToInput(emoji);
  };
  return (
    <>
      <Icon type="antd" name="smile" onClick={handleVisible} size={1} />
      <Modal
        visible={emojiModalVisible}
        closeOnMaskClick={true}
        onClose={handleVisible}
        bodyStyle={{ padding: "0px" }}
        content={<Emoji onSelect={handleSelect} />}
      />
    </>
  );
};

const ToolsBar = ({ chat, _send, addNewContentToInput }) => {
  const {
    userStore: { currentUser },
    chatStore: { isReplyBarVisible, rightClickMenuMessageId },
  } = useStores();
  const handleUploadImages = () => {
    const uploader = document.createElement("input");
    uploader.type = "file";
    uploader.accept = ".png,.jpg,.jpeg";
    uploader.multiple = true;
    uploader.onchange = async (e) => {
      try {
        const files = (e.target as HTMLInputElement).files;
        const arrayFiles = Array.from(files);
        if (arrayFiles.length > 9) throw new Error("最多选择9张图片!");
        const promiseArr = arrayFiles.map((file) => {
          return new Promise<Photo>((resolve) => {
            const reader = new FileReader();
            reader.onload = (readerEvent) => {
              resolve({
                filename: file.name,
                type: file.type,
                path: readerEvent.target.result as string,
                size: file.size,
              });
            };
            reader.readAsDataURL(file);
          });
        });
        const photos: Photo[] = await Promise.all(promiseArr);
        document.body.removeChild(uploader);
        if (photos.length) {
          const message = chat.newMessage({
            content: "",
            type: MessageTypes.IMAGES,
            attach: {
              imgs: photos,
            },
            reply_msg_id:
              isReplyBarVisible && rightClickMenuMessageId
                ? rightClickMenuMessageId
                : null,
          });
          _send(message)
        }
      } catch (e) {
        ToastError(e.message);
      }
    };
    document.body.appendChild(uploader);
    uploader.click();
  };
  const handleUploadVideo = () => {
    const uploader = document.createElement("input");
    uploader.type = "file";
    uploader.accept = ".mp4";
    uploader.multiple = false;
    uploader.onchange = async (e) => {
      try {
        const files = (e.target as HTMLInputElement).files;
        const arrayFiles = Array.from(files);
        const promiseArr = arrayFiles.map((file) => {
          return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = (readerEvent) => {
              resolve({
                file: file,
                filename: file.name,
                type: file.type,
                path: readerEvent.target.result,
                size: file.size,
              });
            };
            reader.readAsDataURL(file);
          });
        });
        const videos = await Promise.all(promiseArr);
        const video = videos[0] as MediaUploadPayload;
        document.body.removeChild(uploader);
        if (videos.length) {
          const message = chat.newMessage({
            type: MessageTypes.VIDEO,
            content: "",
            attach_path: video.path,
            attach: {
              filename: video.filename,
              type: video.type,
              size: video.size,
            },
            reply_msg_id:
              isReplyBarVisible && rightClickMenuMessageId
                ? rightClickMenuMessageId
                : null,
          });
          _send(message);
        }
      } catch (e) {
        ToastError(e.message);
      }
    };
    document.body.appendChild(uploader);
    uploader.click();
  };
  const handleUploadAudio = (mediaBlobUrl, duration) => {
    const message = chat.newMessage({
      type: MessageTypes.VOICE,
      content: "",
      attach_path: mediaBlobUrl,
      attach: {
        filename: `${Date.now()}-${currentUser.id}-${makeID(5)}.wav`,
        type: "audio/wav",
        length: duration,
      },
      reply_msg_id:
        isReplyBarVisible && rightClickMenuMessageId
          ? rightClickMenuMessageId
          : null,
    });
    _send(message);
  };
  const handleUploadFile = () => {
    const uploader = document.createElement("input");
    uploader.type = "file";
    uploader.accept = "*";
    uploader.multiple = false;
    uploader.onchange = async (e) => {
      try {
        const files = (e.target as HTMLInputElement).files;
        const arrayFiles = Array.from(files);
        const promiseArr = arrayFiles.map((file) => {
          return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = (readerEvent) => {
              resolve({
                file: file,
                filename: file.name,
                type: file.type,
                path: readerEvent.target.result,
                size: file.size,
              });
            };
            reader.readAsDataURL(file);
          });
        });
        const pFiles = await Promise.all(promiseArr);
        const pFile = pFiles[0] as MediaUploadPayload;
        document.body.removeChild(uploader);
        if (pFiles.length) {
          const message = chat.newMessage({
            type: MessageTypes.FILE,
            content: "",
            attach_path: pFile.path,
            attach: {
              filename: pFile.filename,
              type: pFile.type,
              size: pFile.size,
            },
            reply_msg_id:
              isReplyBarVisible && rightClickMenuMessageId
                ? rightClickMenuMessageId
                : null,
          });
          _send(message);
        }
      } catch (e) {
        ToastError(e.message);
      }
    };
    document.body.appendChild(uploader);
    uploader.click();
  };
  return (
    <Wrap>
      <Icon type="antd" name="picture" onClick={handleUploadImages} size={1} />
      <Icon
        type="antd"
        name="videoCamera"
        onClick={handleUploadVideo}
        size={1}
      />
      <EmojiControl addNewContentToInput={addNewContentToInput} />
      <Icon type="mdi" name="file" onClick={handleUploadFile} size={0.7} />
      <ReactMediaRecorder
        audio
        blobPropertyBag={{ type: "audio/wav" }}
        mediaRecorderOptions={{ mimeType: "audio/wav" }}
        render={(props) => (
          <AudioRecord {...props} handleUploadAudio={handleUploadAudio} />
        )}
      />
    </Wrap>
  );
};

export default ToolsBar;
