const { CDN_URL } = require("../config/env")

// 导出全路径url
export const staticUrl = (path: string) => {
  if (/^http(s):\/\//.test(path)) {
    return path
  } else if (path) {
    return path.startsWith(CDN_URL) ? path : CDN_URL + "/" + path
  } else {
    return ""
  }
}

export const getExt = (path: string) => {
  const l = path.split(".")
  return l[l.length - 1]
}

export const getMediaPath = ({
  attach_path,
  content,
  url,
}: {
  attach_path?: string
  content?: string
  url?: string
}) => {
  return attach_path && !attach_path.startsWith('blob') ? attach_path : url ? staticUrl(url) : staticUrl(content as string)
}

const re = new RegExp(/^(?<scheme>\w+):\/\/(?<path>\w+)\?(?<query>.*)/)

export const parseScheme = (
  uri: string,
): {
  scheme: string
  path: string
  data?: {
    [key: string]: string
  }
} => {
  const m: any = uri.match(re)
  if (m) {
    return {
      scheme: m.groups.scheme,
      path: m.groups.path,
      data: m.groups.query
        .split("&")
        .map((i) => [i.substring(0, i.indexOf("=")), i.substring(i.indexOf("=") + 1)])
        .reduce((arr, curr) => {
          arr[curr[0]] = curr[1]
          return arr
        }, {}),
    }
  }
  return {
    scheme: "",
    path: "",
    data: {},
  }
}
