import styled from "styled-components";
import { staticUrl } from "../utils";

const poster = require("../assets/images/poster.jpg");

const StyledImg = styled.img``;

const Image = ({ src, ...rest }) => {
  src = staticUrl(src) || poster;
  return <StyledImg src={src} {...rest} />;
};

export default Image;
