/** @format */

import { PageProps } from '../types';
import React, { useState, useMemo, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { Dialog } from 'antd-mobile';
import useNavigation from '../../hooks/useNavigation';
import PageWrap from '../components/PageWrap';
import PageHeader, { headerHeight } from '../components/PageHeader';
import { footerHeight } from '../components/PageFooter';
import PagePanelFooter from '../components/PagePanelFooter';
import Avatar from '../../components/Avatar';
import NormalInput from '../../components/NormalInput';
import { ToastSuccess } from '../../components/Toast';
import { default as ContactListComponent } from '../../components/ContactList';
import { useStores } from '../../models';

const ChoosePanelWrap = styled.div`
  background-color: ${({ theme }) => theme.colors.blockBackground};
  padding: ${({ theme }) => theme.space[2]};
`;

const SelectedListWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow: scroll;
  margin: ${({ theme }) => theme.space[2]};
`;

const SelectedListItemWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${({ theme }) => theme.space[2]};
  cursor: pointer;
`;

const AvatarName = styled.span`
  font-size: 0.8rem;
  color: ${({ theme }) => theme.colors.secondaryText};
  margin-top: ${({ theme }) => theme.space[2]};
`;

const ScrollArea = styled.div`
  height: calc(100% - ${footerHeight} - ${headerHeight});
  overflow: scroll;
`;

const SubmitText = styled.span<{ canSubmit: boolean }>`
  color: ${({ canSubmit, theme }) =>
    canSubmit ? theme.colors.primary : theme.colors.inputPlaceholderColor};
`;

interface Props extends PageProps {}

const AddGroupMember: React.FC<Props> = () => {
  const { goBack, navigate, params } = useNavigation();
  const { chat_id } = params;
  const { chatStore, contactStore, userStore } = useStores();
  const { chats } = chatStore;
  const currentChat = chats.get(chat_id);

  const [search, setSearch] = useState('');

  const [selecteds, setSelecteds] = useState([]);

  const canSubmit = useMemo(() => selecteds.length > 0, [selecteds]);

  const joinedMemberUserIDs = currentChat.members.map(
    (member) => member.user_id
  );

  const sections = [
    ...contactStore.byNameWithFilterAndExcludeIds(
      new RegExp(search, 'i'),
      joinedMemberUserIDs
    ),
  ];

  const selectedUsers = selecteds.map((s) => {
    const user = userStore.users.get(s);
    return user;
  });

  const onSubmit = () => {
    Dialog.confirm({
      content: '确定要加入?',
      onConfirm: () => {
        currentChat.publicAddGroupChatMember(selecteds);
        ToastSuccess('加入成功!');
        goBack(-1);
      },
    });
  };

  const onItemPress = useCallback(
    ({ user }) => {
      const { id } = user;
      if (selecteds.find((x) => x === id)) {
        setSelecteds(selecteds.filter((x) => x !== id));
      } else {
        setSelecteds([...selecteds, id]);
      }
    },
    [selecteds]
  );

  return (
    <PageWrap>
      <PageHeader title="加入联系人" isBackButton={true} isPlusButton={false}>
        <SubmitText
          canSubmit={canSubmit}
          onClick={canSubmit ? onSubmit : undefined}
        >
          确定({selecteds.length})
        </SubmitText>
      </PageHeader>
      <ChoosePanelWrap>
        <NormalInput
          placeholder="请输入用户名"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        ></NormalInput>
        <div>
          <SelectedListWrap>
            {selectedUsers.map((user, index) => {
              const goToUser = () => {
                navigate('User', { user_id: user.id });
              };
              return (
                <SelectedListItemWrap key={index} onClick={goToUser}>
                  <Avatar avatar={user.avatar} name={user.name} />
                  <AvatarName>{user.name}</AvatarName>
                </SelectedListItemWrap>
              );
            })}
          </SelectedListWrap>
        </div>
      </ChoosePanelWrap>
      <ScrollArea>
        <ContactListComponent
          selecteds={selecteds}
          data={sections}
          onItemPress={onItemPress}
        />
      </ScrollArea>
      <PagePanelFooter />
    </PageWrap>
  );
};

export default observer(AddGroupMember);
