import React from "react";
import styled from "styled-components";
import useGlobalTheme from "../../hooks/useGlobalTheme";
import Icon from "../Icon";

const CheckBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

const CheckBoxLabel = styled.span`
  margin-right: 5px;
`;

interface Props {
  checked: boolean;
  value: any;
  label?: string;
  onPress?: (v: Props["value"]) => void;
}

const CheckBox: React.FC<Props> = ({ checked, value, label, onPress }) => {
  const theme = useGlobalTheme();
  return (
    <CheckBoxWrapper onClick={() => onPress?.(value)}>
      {!!label && <CheckBoxLabel>{label}</CheckBoxLabel>}
      {checked ? (
        <Icon type="mdi" name="check" color={theme.colors.primary} size={1} />
      ) : (
        <Icon type="mdi" name="checko" color={theme.colors.border} size={1} />
      )}
    </CheckBoxWrapper>
  );
};

export default CheckBox;
