import { MessageStatus } from "../proto/nbchat-proto"

const text: { [key in MessageStatus]: string } = {
  [MessageStatus.SENDING]: "发送中",
  [MessageStatus.SENT]: "已发送",
  [MessageStatus.DELIVERED]: "未读", // 已接收未读
  [MessageStatus.READ]: "已读",
  [MessageStatus.FAILED]: "失败",
  [MessageStatus.UPLOADING]: "上传中",
  [MessageStatus.DOWNLOADING]: "下载中",
}

export const messageStatusText = (s: MessageStatus) => {
  return text[s]
}

export enum NotifyType {
  CONTACT_CREATED = 1,
  CONTACT_DELETED = 2,
  GROUP_TITLE_UPDATE = 3,
  CHAT_CREATED = 4,
  CHAT_MEMBER_ADDED = 5,
  CHAT_MEMBER_REMOVED = 6,
  CHAT_MEMBER_LEFT = 7,
  CONTACT_CREATED_BUT_NOT_APPROVED = 8,
  MESSAGE_CREATED_BUT_NOT_APPROVED = 9,
  FRIEND_ADDED_BUT_NOT_APPROVED = 10,
  CHAT_BURNING_TIMER_SWITCHED = 11,
}
