import { getEnv, Instance, SnapshotOut, types } from "mobx-state-tree"
const config = require("../../config/env")

/**
 * ConfigStore 用于存储一些系统配置
 */
export const ConfigStoreModel = types
  .model("ConfigStore")
  .props({
    ws: types.optional(types.string, config.WS_URL),
    api: types.optional(types.string, config.API_URL),
    cdn: types.optional(types.string, config.CDN_URL),
    appName: types.optional(types.string, config.APP_NAME),
    redirectURL: types.optional(types.string, config.REDIRECT_URL),
    appKey: types.optional(types.string, config.APP_KEY),
  })
  .views((self) => ({
    get upload_url() {
      return self.api + "/upload/file"
    },
  }))
  .actions((self) => ({
    setWS(ws: string) {
      self.ws = ws
    },
    setAPI(url: string) {
      self.api = url
      const { api } = getEnv(self)
      api.config.update({ url })
    },
    setCDN(url: string) {
      self.cdn = url
    },
    update(data: Partial<ConfigStore>) {
      Object.keys(data).forEach((key) => {
        self[key] = data[key]
      })
    },
  }))

type ConfigStoreType = Instance<typeof ConfigStoreModel>
export interface ConfigStore extends ConfigStoreType {}
type ConfigStoreSnapshotType = SnapshotOut<typeof ConfigStoreModel>
export interface ConfigStoreSnapshot extends ConfigStoreSnapshotType {}
export const createConfigStoreDefaultModel = () => types.optional(ConfigStoreModel, {})
