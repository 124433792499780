import styled from "styled-components";
import useGlobalTheme from "../../hooks/useGlobalTheme";
import Dialog from "../../widgets/Dialog";
import { useStores } from "../../models";

const Box = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  height: 100%;
`;

const ContentArea = styled.div`
  overflow: scroll;
  padding: 15px;
  flex: 1;
  background-color: ${({ theme }) => theme.colors.blockBackground};
`;

const Description = styled.div<{ color: string }>`
  color: ${({ color }) => color};
  background-color: ${({ theme }) => theme.colors.blockBackground};
  padding-bottom: 20px;
`;

const Contents = () => {
  const theme = useGlobalTheme();
  const { configStore } = useStores();
  return (
    <Box>
      <ContentArea>
        <Description color={theme.colors.secondaryText}>
          非常欢迎您使用「{configStore.appName}IM
          」，为了让您能够安心的使用各项服务与资讯，请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款，以及开通或使用某项服务的单独协议。
          若您同意注册，即视为您已阅读并同意上述协议的约束。
        </Description>
        <Description color={theme.colors.secondaryText}>
          一、协议的范围
        </Description>
        <Description color={theme.colors.secondaryText}>
          1.1 本协议是您与本网站之间关于用户使用{configStore.appName}
          IM所订立的协议。
        </Description>
        <Description color={theme.colors.secondaryText}>
          1.2 您在使用{configStore.appName}
          IM某一特定服务时，该服务可能会另有单独的协议、相关业务规则等（以下统称为“单独协议”）。上述内容一经正式发布，即为本协议不可分割的组成部分，您同样应当遵守。您对前述任何业务规则、单独协议的接受，即视为您对本协议全部的接受。
        </Description>
        <Description color={theme.colors.secondaryText}>
          二、帐号与密码安全
        </Description>
        <Description color={theme.colors.secondaryText}>
          2.1 您在使用{configStore.appName}
          IM服务时务必注册一个帐号。如果您没有账号，则无法使用
          {configStore.appName}IM提供的服务。
        </Description>
        <Description color={theme.colors.secondaryText}>
          2.2 {configStore.appName}
          IM特别提醒您应妥善保管您的帐号和密码。因您保管不善可能导致遭受盗号或密码失窃，责任由您自行承担。 
        </Description>
        <Description color={theme.colors.secondaryText}>
          三、按现状提供服务
        </Description>
        <Description color={theme.colors.secondaryText}>
          3.1 您理解并同意，{configStore.appName}
          IM的服务是按照现有技术和条件所能达到的现状提供的。
          {configStore.appName}
          IM会尽最大努力向您提供服务，确保服务的连贯性和安全性；但
          {configStore.appName}
          IM不能随时预见和防范法律、技术以及其他风险，包括但不限于不可抗力、病毒、木马、黑客攻击、系统不稳定、第三方服务瑕疵、政府行为等原因可能导致的服务中断、数据丢失以及其他的损失和风险。
        </Description>
        <Description color={theme.colors.secondaryText}>
          四、自备设备
        </Description>
        <Description color={theme.colors.secondaryText}>
          4.1 您应当理解，您使用{configStore.appName}
          IM的服务需自行准备与相关服务有关的终端设备（如电脑、手机等装置），并承担所需的费用（如电话费、上网费等费用）。
        </Description>
        <Description color={theme.colors.secondaryText}>
          4.2 您理解并同意，您使用本服务时会耗用您的终端设备和带宽等资源。
        </Description>
        <Description color={theme.colors.secondaryText}>
          五、知识产权声明
        </Description>
        <Description color={theme.colors.secondaryText}>
          5.1 {configStore.appName}
          IM在本服务中提供的内容（包括但不限于网页、文字、图片、音频、视频、图表等）的知识产权归
          {configStore.appName}
          IM所有，用户在使用本服务中所产生的内容的知识产权归用户或相关权利人所有。
        </Description>
        <Description color={theme.colors.secondaryText}>
          5.2 除另有特别声明外，{configStore.appName}
          IM提供本服务时所依托软件的著作权、专利权及其他知识产权均归
          {configStore.appName}IM所有。
        </Description>
        <Description color={theme.colors.secondaryText}>
          5.3 {configStore.appName}IM在本服务中所使用的“{configStore.appName}
          IM”等商业标识，其著作权或商标权归{configStore.appName}IM所有。
        </Description>
        <Description color={theme.colors.secondaryText}>
          5.4 上述及其他任何本服务包含的内容的知识产权均受到法律保护，未经
          {configStore.appName}
          IM、用户或相关权利人书面许可，任何人不得以任何形式进行使用或创造相关衍生作品。
        </Description>
        <Description color={theme.colors.secondaryText}>
          六、遵守当地法律监管
        </Description>
        <Description color={theme.colors.secondaryText}>
          6.1
          您在使用本服务过程中应当遵守当地相关的法律法规，并尊重当地的道德和风俗习惯。如果您的行为违反了当地法律法规或道德风俗，您应当为此独立承担责任。
        </Description>
        <Description color={theme.colors.secondaryText}>
          6.2 您应避免因使用本服务而使{configStore.appName}
          IM卷入政治和公共事件，否则
          {configStore.appName}IM有权暂停或终止对您的服务。
        </Description>
        <Description color={theme.colors.secondaryText}>
          七、不可抗力免责
        </Description>
        <Description color={theme.colors.secondaryText}>
          7.1
          您理解并同意，在使用本服务的过程中，可能会遇到不可抗力等风险因素，使本服务发生中断。不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、瘟疫流行和风暴等以及社会事件如战争、动乱、政府行为等。出现上述情况时，
          {configStore.appName}
          IM将努力在第一时间与相关单位配合，及时进行修复，但是由此给您造成的损失
          {configStore.appName}IM在法律允许的范围内免责。
        </Description>
        <Description color={theme.colors.secondaryText}>
          八、协议的生效与变更
        </Description>
        <Description color={theme.colors.secondaryText}>
          8.1 您使用
          {configStore.appName}IM的服务即视为您已阅读本协议并接受本协议的约束。
        </Description>
        <Description color={theme.colors.secondaryText}>
          8.2 {configStore.appName}
          IM有权在必要时修改本协议条款。您可以在相关服务页面查阅最新版本的协议条款。
        </Description>
        <Description color={theme.colors.secondaryText}>
          8.3 本协议条款变更后，如果您继续使用{configStore.appName}
          IM提供的软件或服务，即视为您已接受修改后的协议。如果您不接受修改后的协议，应当停止使用
          {configStore.appName}IM斯提供的软件或服务。
        </Description>
        <Description color={theme.colors.secondaryText}>
          九、服务的变更、中断、终止
        </Description>
        <Description color={theme.colors.secondaryText}>
          9.1 {configStore.appName}
          IM可能会对服务内容进行变更，也可能会中断、中止或终止服务。
        </Description>
        <Description color={theme.colors.secondaryText}>
          9.2 您理解并同意，{configStore.appName}IM有权自主决定经营策略。在
          {configStore.appName}
          IM发生合并、分立、收购、资产转让时，{configStore.appName}
          IM可向第三方转让本服务下相关资产；{configStore.appName}
          IM也可在单方通知您后，将本协议下部分或全部服务转交由第三方运营或履行。具体受让主体以
          {configStore.appName}IM通知的为准。
        </Description>
        <Description color={theme.colors.secondaryText}>
          9.3 如发生下列任何一种情形，{configStore.appName}
          IM有权不经通知而中断或终止向您提供的服务：（1）根据法律规定您应提交真实信息，而您提供的个人资料不真实、或与注册时信息不一致又未能提供合理证明；（2）您违反相关法律法规或本协议的约定；（3）按照法律规定或主管部门的要求；（4）出于安全的原因或其他必要的情形。
        </Description>
        <Description color={theme.colors.secondaryText}>
          {configStore.appName}IM最后修订日期: 2023-05-25
        </Description>
      </ContentArea>
    </Box>
  );
};

const ServiceContractDialog = ({ visible, setVisible, setAgree }) => {
  const { configStore } = useStores();
  const onClose = () => {
    setVisible(false);
  };
  const onCancelButtonClick = () => {
    setAgree(false);
    onClose();
  };
  const onConfirmButtonClick = () => {
    setAgree(true);
    onClose();
  };
  return (
    <Dialog
      title={`${configStore.appName}IM -服务协议`}
      visible={visible}
      Contents={Contents}
      onClose={() => setVisible(false)}
      onCancelButtonClick={onCancelButtonClick}
      onConfirmButtonClick={onConfirmButtonClick}
      buttonShow={false}
    />
  );
};

export default ServiceContractDialog;
