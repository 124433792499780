import { useEffect } from "react";
import { Mask, SpinLoading, Dialog } from "antd-mobile";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { Routes, Route } from "react-router-dom";
import routes from "../routes";
import LeftPanel from "./components/LeftPanel";
import { SocketStates, useStores } from "../../models";
import { initDB } from "../../services/dexie";
import useNavigation from "../../hooks/useNavigation";

const AppRoutes = routes.filter(
  (route) => route.routerName === "app" || route.routerName === "all"
);

const AppRouterWrap = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

const Center = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AppRouter = () => {
  const { navigate } = useNavigation();
  const rootStore = useStores();
  const {
    websocketStore,
    authStore: { userID, publicUpdateClientID, haveBeenPassEmailCheck, setHaveBeenPassEmailCheck },
    blackListStore,
    isUserStateLoaded,
    isMobile,
    authStore,
    syncFinished,
    userStore: { currentUser },
  } = rootStore;

  useEffect(() => {
    async function startDB() {
      await initDB()
    }
    startDB()
  }, [])

  useEffect(() => {
    async function loadUserState() {
      await rootStore.load();
    }
    loadUserState();
  }, [rootStore, userID]);

  useEffect(() => {
    if (isUserStateLoaded) {
      websocketStore.connect();
    }
    return () => {
      if (isUserStateLoaded) {
        websocketStore.close()
      }
    }
  }, [isUserStateLoaded, websocketStore]);

  useEffect(() => {
    async function checkToken() {
      try {
        await authStore.httpPing();
        websocketStore.setState(SocketStates.DISCONNECTED);
      } catch (e) {
        websocketStore.setState(SocketStates.CLOSED);
        websocketStore.kick(e.message || "网路异常请重新登录");
      }
    }
    if (websocketStore.hasError) {
      checkToken();
    }
  }, [websocketStore, websocketStore.hasError, authStore]);

  useEffect(() => {
    if (isUserStateLoaded && websocketStore.ready) {
      blackListStore.publicQueryBlacklistList(userID);
    }
  }, [isUserStateLoaded, websocketStore.ready, userID, blackListStore]);

  useEffect(() => {
    if (isUserStateLoaded && websocketStore.ready && authStore.clientId) {
      publicUpdateClientID();
    }
  }, [
    isUserStateLoaded,
    websocketStore.ready,
    authStore.clientId,
    publicUpdateClientID,
  ]);

  useEffect(() => {
    if (syncFinished && !currentUser.email && !haveBeenPassEmailCheck) {
      setHaveBeenPassEmailCheck(true)
      Dialog.confirm({
        title: '是否要完善电子邮箱资讯?',
        content: `忘记密码可透过电子邮箱重置`,
        onConfirm: () => navigate('UpdateCurrentUserEmail'),
        confirmText: '前往修改',
        cancelText: '之后再说'
      });
    }
  }, [navigate, syncFinished, currentUser, haveBeenPassEmailCheck, setHaveBeenPassEmailCheck])

  if (!isUserStateLoaded) return null;

  return (
    <AppRouterWrap>
      <Mask visible={!syncFinished}>
        <Center>
          <SpinLoading></SpinLoading>
        </Center>
      </Mask>
      {!isMobile && <LeftPanel />}
      <Routes>
        {AppRoutes.map(({ key, path, Component }) => {
          return <Route key={key} path={path} element={<Component />} />;
        })}
      </Routes>
    </AppRouterWrap>
  );
};

export default observer(AppRouter);
