import { autorun } from "mobx";
import {
  flow,
  getRoot,
  Instance,
  SnapshotOut,
  types,
  detach,
} from "mobx-state-tree";
import {
  Event,
  EventNames,
  FLAG_ACK,
  MessageBatchStatusUpdatedData,
  MessageData,
  MessageStatus,
  MessageStatusUpdatedData,
  MessageBatchNewData,
  MessageDeleteData,
  MessageTypes,
  MessageBatchReadedData,
  MessageBatchDeleteData,
} from "../../proto/nbchat-proto";
import { NotifyType } from "../../types/message";
import { newID, genPrivacyChatID } from "../../utils/id";
import { withEnvironment } from "../extensions/with-environment";
import { Message, MessageModel } from "../message/message";
import { RootStoreModel } from "..";
import {
  updateMessage,
  insertMessage,
  deleteMessage,
  queryMessageByMessageID,
} from "../../services/dexie";

/**
 * 消息存储
 */
export const MessageStoreModel = types
  .model("MessageStore")
  .props({
    messages: types.optional(types.map(MessageModel), {}),
  })
  .volatile(() => ({
    sendMessageTimeoutMessageIdMap: {},
  }))
  .extend(withEnvironment)
  .views((self) => ({
    get unread() {
      return Array.from(self.messages.values()).filter(
        (message) =>
          !message.byMe && !message.isReadedByMe && !message.notify_type
      ).length;
    },
  }))
  .actions((self) => {
    function clear() {
      self.messages.clear();
    }
    function ensureMessageData(message: Partial<MessageData>) {
      return {
        ...message,
        mention_ids: message.mention_ids || [],
        read_ids: message.read_ids || [],
      };
    }
    // 將後端格式轉為前端格式
    function messageDataToMessage(message: Partial<MessageData>) {
      message = ensureMessageData(message);
      const { sender_id, receiver_id, ...rest } = message;
      const cM = {
        ...rest,
        receiver: receiver_id || null,
        sender: sender_id || null,
      };
      return cM;
    }
    function preserveLocalAttachData(message: Partial<MessageData>) {
      const localMessage = self.messages.get(message.message_id);
      if (!localMessage) return message;
      const cM = {
        ...localMessage,
        ...message,
        attach_path: localMessage.attach_path,
      };
      if (message.type === MessageTypes.VIDEO) {
        cM.attach = {
          ...localMessage.attach,
          ...message.attach,
        };
      }
      if (message.type === MessageTypes.IMAGES) {
        cM.attach = {
          ...localMessage.attach,
          ...message.attach,
          imgs: localMessage.attach.imgs.map((img, i) => {
            return {
              ...img,
              ...message.attach.imgs[i],
            };
          }),
        };
      }
      return cM;
    }
    const getMessage = (message_id: string) => {
      return self.messages.get(message_id.toString());
    };
    const putMessage = (data: Partial<MessageData>) => {
      const adaptedMessage = messageDataToMessage(data);
      const preservedLocalDataMessage = preserveLocalAttachData(adaptedMessage);
      return self.messages.put(preservedLocalDataMessage);
    };
    const addMessage = (message: Partial<MessageData>) => {
      const now = Date.now();
      return putMessage({
        message_id: message.message_id || newID(),
        created: now,
        updated: now,
        ...message,
      });
    };
    const getMessageInDB = flow(function* (message_id: string) {
      const result = yield queryMessageByMessageID(message_id);
      return result;
    });
    const hasMessageInDB = flow(function* (message_id: string) {
      const result = yield queryMessageByMessageID(message_id);
      return !!result;
    });
    const putMessageInDB = flow(function* (data: Partial<MessageData>) {
      data = ensureMessageData(data);
      yield insertMessage({ cM: data });
      return data;
    });
    const onHistoryMessageCreated = (data: MessageData) => {
      messageCreatedHandle(data, true);
    };
    const onLastMessageCreated = (data: MessageData) => {
      messageCreatedHandle(data, true);
    };
    const onMessageCreated = ({ data }: Event<MessageData>) => {
      messageCreatedHandle(data, false);
    };
    const messageCreatedHandle = flow(function* (
      data: MessageData & { notify_type?: NotifyType },
      prepend?: boolean
    ) {
      // put message to memory and database
      if (!data.message_id || !data.sender_id) {
        console.warn("bad data here!", data);
        return;
      }
      const { chatStore, contactStore, userStore } =
        getRoot<Instance<typeof RootStoreModel>>(self);
      const { currentUser } = userStore;
      const other_id =
        currentUser.id === data.sender_id ? data.receiver_id : data.sender_id;
      if (!data.chat_cid) {
        data.chat_cid = genPrivacyChatID(currentUser.id, other_id).chat_id;
      }
      const message = putMessage(data);
      if (!data.notify_type) putMessageInDB(data);

      // if message's chat not exist, we will create that chat here.
      const chat_id = message.messageChatID;
      let chat = chatStore.getChat(chat_id);

      // group chat must be exist after onChatList event received, so we create contact chat only.
      if (!chat) {
        chat = chatStore.createChatByMessageData(data);
        if (!chat.isGroup) {
          const contact = contactStore.byUserId(other_id);
          if (!contact) {
            chat.newMessage({
              content:
                "他(她)还不是你的朋友。你可以新增他为好友，来保持最优良的聊天品质。",
              type: MessageTypes.TEXT,
              status: MessageStatus.READ,
              notify_type: NotifyType.MESSAGE_CREATED_BUT_NOT_APPROVED,
            });
          } else if (!contact.status) {
            chat.newMessage({
              content:
                "你还不是他(她)的朋友。请通知对方加你为好友，来保持最优良的聊天品质。",
              type: MessageTypes.TEXT,
              status: MessageStatus.READ,
              notify_type: NotifyType.CONTACT_CREATED_BUT_NOT_APPROVED,
            });
          }
        }
      }

      // insert message to chat render list.
      if (chat) chat.onMessageCreated(message, prepend);

      // play sound
      if (message.sender.id !== currentUser.id) {
      }
      // if message has reply message, we will find it from database and insert it to memory, to make sure we can render it.
      if (message.reply_msg_id) {
        const replyMessageData = yield getMessageInDB(message.reply_msg_id);
        if (replyMessageData) putMessage(replyMessageData);
      }
    });
    function onMessageSearched(data: MessageData) {}
    // 消息状态变化
    function onMessageStatusUpdated({
      data: { message_id, status, id },
    }: Event<MessageStatusUpdatedData>) {
      const cM = self.messages.get(message_id);
      if (cM) {
        cM.update({ id, status });
      }
      updateMessage({ cM: { message_id, id, status } });
    }
    function onMessageBatchStatusUpdated({
      data: { items },
    }: Event<MessageBatchStatusUpdatedData>) {
      items.forEach(({ message_id, id, status }) => {
        const cM = self.messages.get(message_id);
        if (cM) {
          cM.update({ id, status });
        }
        updateMessage({ cM: { message_id, id, status } });
      });
    }
    function onMessageBatchReaded({
      data: { items },
    }: Event<MessageBatchReadedData>) {
      items.forEach(({ message_id, id, read_ids }) => {
        const cM = self.messages.get(message_id);
        if (cM) {
          cM.update({ id, read_ids });
        }
        updateMessage({ cM: { message_id, id, read_ids } });
      });
    }
    function getChatByMessageID(messageID: string) {
      const { chatStore } = getRoot<Instance<typeof RootStoreModel>>(self);
      const message = self.messages.get(messageID);
      const chat_id = message.messageChatID;
      return chatStore.getChat(chat_id);
    }
    function handleMessageDeleted({
      message_id,
      id,
    }: {
      message_id: string;
      id: number;
    }) {
      const cM = self.messages.get(message_id);
      if (cM) {
        const chat = getChatByMessageID(message_id);
        chat.onMessageDeleted({ message_id });
        detach(cM);
        self.messages.delete(message_id);
      }
      deleteMessage({ cM: { message_id, id } });
    }
    function onMessageDeleted({
      data: { message_id, id },
    }: Event<MessageDeleteData>) {
      handleMessageDeleted({ message_id, id });
    }
    function onMessageBatchDeleted({
      data: { items },
    }: Event<MessageBatchDeleteData>) {
      items.forEach(({ message_id, id }) => {
        handleMessageDeleted({ message_id, id });
      });
    }
    function formatMessageToMessageData(message: Message) {
      return {
        message_id: message.message_id,
        content: message.content,
        sender_id: message.sender.id,
        receiver_id: message.receiver ? message.receiver.id : null,
        status: message.status,
        type: message.type,
        chat_id: message.chat_id,
        chat_cid: message.chat_cid,
        attach: message.attach,
        created: message.created.getTime(),
        updated: message.updated.getTime(),
        mention_ids: message.mention_ids,
        reply_msg_id: message.reply_msg_id,
        forward_msg_id: message.forward_msg_id,
        forward_user_id: message.forward_user_id,
        forward_name: message.forward_name,
        burning_timer: message.burning_timer || 0,
      };
    }
    function sendBatchMessage(messages: Message) {
      const { eventStore } = getRoot<Instance<typeof RootStoreModel>>(self);
      const evt = new Event<MessageBatchNewData>(EventNames.MESSAGE_BATCH_NEW, {
        items: messages.map((message) => formatMessageToMessageData(message)),
      });
      eventStore.publish(evt, { flags: FLAG_ACK });
    }
    function senddeleteMessageByMessageID(message: Message, isLocal = false) {
      const { eventStore } = getRoot<Instance<typeof RootStoreModel>>(self);
      if (!isLocal) {
        const evt = new Event<MessageDeleteData>(EventNames.MESSAGE_DELETE, {
          id: message.id,
          message_id: message.message_id,
        });
        eventStore.publish(evt, { flags: FLAG_ACK });
      }
      handleMessageDeleted({ message_id: message.message_id, id: message.id });
    }
    function sendImagesMessage(
      message: Message,
      successCallback?: (msg: Message) => void,
      errorCallback?: (e: Error) => void
    ) {
      message
        .uploadMultipleImages()
        .then(() => {
          const m = {
            ...message,
            attach: {
              imgs: message.attach.imgs.map(({ path, ...rest }) => {
                return rest;
              }),
            },
          };
          sendMessage(m);
          successCallback && successCallback(m);
        })
        .catch((e) => {
          errorCallback && errorCallback(e);
        });
    }
    function sendImageMessage(
      message: Message,
      successCallback?: (msg: Message) => void,
      errorCallback?: (e: Error) => void
    ) {
      message
        .upload()
        .then(() => {
          sendMessage(message);
          successCallback && successCallback(message);
        })
        .catch((e) => {
          errorCallback && errorCallback(e);
        });
    }
    function sendVoiceMessage(
      message: Message,
      successCallback?: (msg: Message) => void,
      errorCallback?: (e: Error) => void
    ) {
      message
        .upload()
        .then(() => {
          sendMessage(message);
          successCallback && successCallback(message);
        })
        .catch((e) => {
          errorCallback && errorCallback(e);
        });
    }
    function sendVideoMessage(
      message: Message,
      successCallback?: (msg: Message) => void,
      errorCallback?: (e: Error) => void
    ) {
      message
        .upload()
        .then(() => {
          sendMessage(message);
          successCallback && successCallback(message);
        })
        .catch((e) => {
          errorCallback && errorCallback(e);
        });
    }
    function sendFileMessage(
      message: Message,
      successCallback?: (msg: Message) => void,
      errorCallback?: (e: Error) => void
    ) {
      message
        .upload()
        .then(() => {
          sendMessage(message);
          successCallback && successCallback(message);
        })
        .catch((e) => {
          errorCallback && errorCallback(e);
        });
    }
    function sendTextMessage(
      message: Message,
      successCallback?: (msg: Message) => void,
      errorCallback?: (e: Error) => void
    ) {
      new Promise((resolve) => {
        sendMessage(message);
        resolve(message);
      })
        .then((m: Message) => {
          successCallback && successCallback(m);
        })
        .catch((e) => {
          errorCallback && errorCallback(e);
        });
    }
    function sendMessageByMessageType(
      message: Message,
      successCallback?: (msg: Message) => void,
      errorCallback?: (err: Error) => void
    ) {
      switch (message.type) {
        case MessageTypes.TEXT:
          sendTextMessage(message, successCallback, errorCallback);
          break;
        case MessageTypes.VOICE:
          sendVoiceMessage(message, successCallback, errorCallback);
          break;
        case MessageTypes.IMAGE:
          sendImageMessage(message, successCallback, errorCallback);
          break;
        case MessageTypes.VIDEO:
          sendVideoMessage(message, successCallback, errorCallback);
          break;
        case MessageTypes.FILE:
          sendFileMessage(message, successCallback, errorCallback);
          break;
        case MessageTypes.IMAGES:
          sendImagesMessage(message, successCallback, errorCallback);
          break;
        default:
          break;
      }
    }
    // 发送消息
    function sendMessage(message: Message) {
      const { eventStore, channelStore } =
        getRoot<Instance<typeof RootStoreModel>>(self);
      self.sendMessageTimeoutMessageIdMap[message.message_id] = setTimeout(
        () => {
          message.setStatus(MessageStatus.FAILED);
          channelStore.removeMessageNewFromSendqByMessageID(message.message_id);
        },
        10000
      );
      const evt = new Event<MessageData>(
        EventNames.MESSAGE_NEW,
        formatMessageToMessageData(message)
      );
      eventStore.publish(evt, { flags: FLAG_ACK });
    }
    function remove(message_id: string) {
      self.messages.delete(message_id);
    }
    let disposer;
    const afterCreate = () => {
      disposer = autorun(() => {
        window.postMessage(
          JSON.stringify({
            type: "set_message_unread_count",
            count: self.unread || 0,
          })
        );
      });
    };
    const beforeDestory = () => {
      disposer();
    };
    return {
      afterCreate,
      beforeDestory,
      addMessage,
      clear,
      remove,
      onMessageStatusUpdated,
      onMessageBatchStatusUpdated,
      onMessageBatchReaded,
      onMessageDeleted,
      formatMessageToMessageData,
      sendBatchMessage,
      sendMessageByMessageType,
      sendMessage,
      senddeleteMessageByMessageID,
      onHistoryMessageCreated,
      onLastMessageCreated,
      onMessageCreated,
      onMessageSearched,
      handleMessageDeleted,
      getMessage,
      hasMessageInDB,
      putMessageInDB,
      onMessageBatchDeleted,
    };
  });

type MessageStoreType = Instance<typeof MessageStoreModel>;
export interface MessageStore extends MessageStoreType {}
type MessageStoreSnapshotType = SnapshotOut<typeof MessageStoreModel>;
export interface MessageStoreSnapshot extends MessageStoreSnapshotType {}
export const createMessageStoreDefaultModel = () =>
  types.optional(MessageStoreModel, {});
