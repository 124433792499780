import React from "react";
import styled from "styled-components";

const BadgeWrap = styled.div<Props>`
  position: absolute;
  z-index: 1;
  top: ${({ top }) => top};
  right: ${({ right }) => right};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.2rem;
  min-width: 1.2rem;
  min-height: 1.2rem;
  border-radius: 1.2rem;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.danger};
`;

const BadgeText = styled.span`
  line-height: 12px;
  font-size: 0.6rem;
  color: ${({ theme }) => theme.colors.blockBackground};
`;

const Local = {
  BadgeWrap: BadgeWrap,
  BadgeText: BadgeText,
};

interface Props {
  value?: number;
  right?: string;
  top?: string;
}

const OvalBadge: React.FC<Props> = ({ value, top, right }) => {
  if (value <= 0) return null;
  return (
    <Local.BadgeWrap top={top} right={right}>
      <Local.BadgeText>{value}</Local.BadgeText>
    </Local.BadgeWrap>
  );
};

OvalBadge.defaultProps = {
  value: 0,
  right: "0px",
  top: "0px",
};

export default OvalBadge;
