import { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { BrowserRouter } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";
import theme from "./theme";
import AuthRouter from "./routers/AuthRouter";
import AppRouter from "./routers/AppRouter";
import {
  RootStore,
  RootStoreProvider,
  setupRootStore,
  useStores,
} from "./models";

const AppWrap = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.inputBackground};
`;

const CheckPermissionRouter = observer(() => {
  const { authStore } = useStores();
  return authStore.hasToken ? <AppRouter /> : <AuthRouter />;
});

const ResponsibleRouter = observer(() => {
  const rootStore = useStores();
  useEffect(() => {
    window.addEventListener("resize", rootStore.detectMobile);
    return () => {
      window.removeEventListener("resize", rootStore.detectMobile);
    };
  }, [rootStore]);
  return <CheckPermissionRouter />;
});


function App() {
  const [rootStore, setRootStore] = useState<RootStore | undefined>(undefined);

  useEffect(() => {
    (async () => {
      const store = await setupRootStore();
      setRootStore(store);
    })();
  }, []);

  if (!rootStore) return null;

  return (
    <BrowserRouter>
      <RootStoreProvider value={rootStore as any}>
        <ThemeProvider theme={theme}>
          <AppWrap>
            <ResponsibleRouter />
          </AppWrap>
        </ThemeProvider>
      </RootStoreProvider>
    </BrowserRouter>
  );
}

export default App;
