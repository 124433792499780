import React from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import useNavigation from "../../hooks/useNavigation";
import PageWrap from "../components/PageWrap";
import PageHeader, { headerHeight } from "../components/PageHeader";
import { footerHeight } from "../components/PageFooter";
import PagePanelFooter from "../components/PagePanelFooter";
import Accordion from "../../components/Accordion";
import UserProfile from "../../components/UserProfile";
import { useStores } from "../../models";

const ScrollArea = styled.div`
  height: calc(100% - ${footerHeight} - ${headerHeight});
  overflow: scroll;
`;

const Me: React.FC = () => {
  const { navigate } = useNavigation();
  const {
    userStore: { currentUser },
  } = useStores();

  return (
    <PageWrap>
      <PageHeader title="我" isBackButton={false} />
      <ScrollArea>
        <UserProfile user={currentUser} onItemPress={() => navigate("BasicInfo")} />
        <Accordion
          iconProps={{ type: "mdi", name: "lock", size: 0.8 }}
          title="隐私安全"
          onItemPress={() => navigate("PrivacyAndSafety")}
        />
        <Accordion
          iconProps={{ type: "ion", name: "cog", size: 1.2 }}
          title="设置"
          onItemPress={() => navigate("Setting")}
        />
      </ScrollArea>
      <PagePanelFooter />
    </PageWrap>
  );
};

export default observer(Me);
