import { onSnapshot } from "mobx-state-tree"
import * as storage from "../../utils/storage"
import { RootStore, RootStoreModel } from "./root-store"

export let rootStoreInstance: RootStore
/**
 * The key we'll be saving our state as within async storage.
 */
export const APP_LOCAL_PERSIST_CONFIG = "APP_LOCAL_PERSIST_CONFIGV4"

/**
 * Setup the root state.
 */
export async function setupRootStore() {
  let appLocalPersistConfig: any = {}
  let data: any = {}
  
  try {
    appLocalPersistConfig = (await storage.load(APP_LOCAL_PERSIST_CONFIG)) || {}
    data.authStore = appLocalPersistConfig
  } catch (e) {
    // TODO: 如果出错，保留登录信息，清除其它数据
    // 清除数据，重新登录使用
    await storage.remove(APP_LOCAL_PERSIST_CONFIG)
    data = {}
    // but please inform us what happened
  }
  rootStoreInstance = RootStoreModel.create(data)
  const onSaveAuth = (snapshot) => {
    storage.save(APP_LOCAL_PERSIST_CONFIG, snapshot)
  }
  onSnapshot(rootStoreInstance.authStore, onSaveAuth)

  return rootStoreInstance
}
