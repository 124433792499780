import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { UserModel } from "../user/user"

/**
 * Model description here for TypeScript hints.
 */
export const ContactModel = types
  .model("Contact")
  .props({
    id: types.identifierNumber,
    user_id: types.number,
    owner_id: types.number,
    name: types.maybeNull(types.string),
    username: types.string,
    name_letter: types.string,
    user: types.reference(UserModel),
    status: types.maybe(types.number),
    updated: types.maybe(types.number),
    note: types.maybeNull(types.string),
    deleted: types.maybe(types.boolean),
    is_mute: types.maybeNull(types.boolean),
  })
  .views((self) => ({
    get displayName() {
      return self.name || self.user.name
    },
  }))
  .actions((self) => ({
    setName(name: string) {
      self.name = name
      // publish change name event
    },
    update(values) {
      for (const key in values) {
        self[key] = values[key]
      }
    },
    setDeleted(b: boolean) {
      self.deleted = b
    },
    setStatus(v: number) {
      self.status = v;
    },
    setIsMute(v: boolean) {
      self.is_mute = v;
    }
  }))

type ContactType = Instance<typeof ContactModel>
export interface Contact extends ContactType {}
type ContactSnapshotType = SnapshotOut<typeof ContactModel>
export interface ContactSnapshot extends ContactSnapshotType {}
export const createContactDefaultModel = () => types.optional(ContactModel, {})
