import CryptoJS from "crypto-js"
import { nanoid } from "nanoid"
import { loadString, saveString } from "../storage"

const S = "s_e_e_d"

const getS = async () => {
  let s = await loadString(S)
  if (!s) {
    s = nanoid()
    await saveString(S, s)
  }
  return s
}

export const encrypt = async ({ value }) => {
  return CryptoJS.AES.encrypt(value, await getS()).toString()
}

export const decrypt = async ({ encryptValue }) => {
  return CryptoJS.AES.decrypt(encryptValue, await getS()).toString(CryptoJS.enc.Utf8)
}
