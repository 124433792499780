import styled from "styled-components";

const Text = styled.span`
  color: ${({ theme }) => theme.colors.text};
`;

const TextMessage: React.FC<{ formatedContent: string }> = ({ formatedContent }) => {
  return <Text>{formatedContent}</Text>;
};

export default TextMessage;
