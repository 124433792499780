import { useEffect } from "react";
import styled from "styled-components";
import useNavigation from "../../hooks/useNavigation";
import PageWrap from "../components/PageWrap";
import { useStores } from "../../models";

const Page = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled.span`
  font-size: 1.2rem;
  font-weight: bold;
`;

const Welcome = () => {
  const { navigate } = useNavigation();
  const { isMobile, configStore } = useStores();

  useEffect(() => {
    if (isMobile) {
      navigate("ChatList", {}, { replace: true });
    }
  }, [isMobile, navigate]);

  return (
    <PageWrap>
      <Page>
        <Title>您好，欢迎来到{configStore.appName}IM</Title>
      </Page>
    </PageWrap>
  );
};

export default Welcome;
