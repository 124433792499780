import React, { useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import useGlobalTheme from "../../../../../hooks/useGlobalTheme";
import Overlay from "../../../../Overlay";
import Icon from "../../../../Icon";
import { getMediaPath } from "../../../../../utils";
import { Message } from "../../../../../models";

const poster = require("../../../../../assets/images/poster.jpg");

const w = window.innerWidth * 0.1;

const Popup = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1000;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FloatToolBar = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 1001;
  width: 50px;
  background-color: white;
  border-bottom-left-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: bolder;
  padding-bottom: 10px;
`;

const FloatToolBarButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const VideoWrap = styled.div`
  position: relative;
  width: 30vh;
  margin: ${({ theme }) => theme.space[2]} ${({ theme }) => theme.space[1]};
`;

const PlayLabel = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ProgressBarWrap = styled.div`
  position: absolute;
  top: calc(50% - ${w / 2}px);
  left: calc(50% - ${w / 2}px);
  width: ${w}px;
  height: ${w}px;
`;

const Image = styled.div`
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`;

const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

interface Props {
  message: Message;
}

const VideoMessage: React.FC<Props> = ({ message }) => {
  const ref = useRef<HTMLVideoElement>();
  const theme = useGlobalTheme();
  const [popupData, setPopupData] = useState({
    src: "",
    visible: false,
  });

  const handleVideoClick = (src) => {
    setPopupData({ src, visible: true });
  };

  const src = getMediaPath(message);

  return (
    <>
      <VideoWrap key={src}>
        {message.uploading ? (
          <Image style={{ backgroundImage: `url('${poster}')` }} />
        ) : (
          <Video src={src + "#t=1"} preload="metadata"></Video>
        )}
        {message.uploading ? (
          <ProgressBarWrap>
            <CircularProgressbar
              styles={buildStyles({
                strokeLinecap: "round",
                textSize: "1rem",
                textColor: "transparent",
                trailColor: theme.colors.blockBackground,
                backgroundColor: theme.colors.primary,
                pathColor: theme.colors.primary,
              })}
              value={message.progress}
              maxValue={1}
              text={(message.progress * 100).toFixed(0)}
            />
          </ProgressBarWrap>
        ) : (
          <PlayLabel onClick={() => handleVideoClick(src)}>
            <Icon type="antd" name="play" color="white" size={2} />
          </PlayLabel>
        )}
      </VideoWrap>
      {popupData.visible && (
        <Popup>
          <FloatToolBar>
            <div
              onClick={async () => {
                const outsideRes = await fetch(popupData.src);
                const blob = await outsideRes.blob();
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.download = `${Date.now()}.mp4`;
                link.click();
              }}
              style={{ textDecoration: "none" }}
            >
              <FloatToolBarButton>
                <Icon type="mdi" name="download" size={2} />
                下载
              </FloatToolBarButton>
            </div>
          </FloatToolBar>
          <video
            ref={ref}
            controls={true}
            autoPlay={true}
            src={popupData.src}
            style={{
              zIndex: 1000,
              width: "70%",
              maxWidth: "450px",
              height: "auto",
              objectFit: "cover",
            }}
          />
          <Overlay
            onClick={() => {
              if (ref.current) {
                ref.current.pause();
              }
              setPopupData({ src: "", visible: false });
            }}
          />
        </Popup>
      )}
    </>
  );
};
export default observer(VideoMessage);
