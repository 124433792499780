import React, { useRef, useCallback, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import SwipeableViews from "react-swipeable-views";
import useGlobalTheme from "../../../hooks/useGlobalTheme";
import MessageTime from "../../MessageTime";
import MessageStatus from "../../MessageStatus";
import MessagePreview from "../../MessagePreview";
import OvalBadge from "../../../components/OvalBadge";
import Icon from "../../../components/Icon";
import Avatar from "../../../components/Avatar";
import CheckBox from "../../CheckBox";
import { useStores, Chat } from "../../../models";

const ChatListItemWrap = styled.div`
  position: relative;
  display: flex;
  background-color: ${({ theme }) => theme.colors.blockBackground};
  padding: ${({ theme }) => theme.space[2]} ${({ theme }) => theme.space[2]} }
  border: 0px solid ${({ theme }) => theme.colors.border};
  border-bottom-width: 1px;
  cursor: pointer;
`;

const CheckWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: ${({ theme }) => theme.space[3]};
`;

const Main = styled.div`
  margin-left: 5px;
  width: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
`;

const Title = styled.div`
  font-size: 0.8rem;
  display: flex;
`;

const RightWrap = styled.div`
  display: flex;
  margin-left: auto;
`;
interface Props {
  checked?: boolean;
  item: Chat;
  onItemPress?: (item: Chat) => void;
  onRightPress?: (
    e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>,
    item: Chat | undefined
  ) => void;
}

const ChatListItem: React.FC<Props> = ({
  checked,
  item,
  onItemPress,
  onRightPress,
}) => {
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const theme = useGlobalTheme();
  const { isMobile, contactStore, chatStore } = useStores();
  const [scrollIndex, setScrollIndex] = useState(0);

  const contact = item.receiver?.id
    ? contactStore.byUserId(item.receiver.id)
    : null;
  const count = item.isGroup ? item.memebersCount : 0;

  const isMute = (() => {
    if (contact) {
      return contact.is_mute;
    } else {
      return item.is_mute;
    }
  })();

  const onContextMenu = useCallback(
    (delay, e) => {
      e.preventDefault();
      timeoutRef.current = setTimeout(() => {
        onRightPress?.(e, item);
      }, delay);
    },
    [onRightPress, item]
  );

  const actionDelete = useCallback(
    (chat) => {
      chatStore.remove(chat);
    },
    [chatStore]
  );

  const actionPinOff = useCallback((chat) => {
    chat.clearPinTime();
  }, []);

  const actionPinToTop = useCallback((chat) => {
    chat.setPinTime();
  }, []);

  const actionMute = useCallback((chat) => {
    chat.publicToggleMuteChat(true);
  }, []);

  const actionUnmute = useCallback((chat) => {
    chat.publicToggleMuteChat(false);
  }, []);

  const swipeActionButtonConfigs = useMemo(
    () => [
      {
        visible: () => true,
        icon: "trashCan",
        color: theme.colors.danger,
        text: "删除会话",
        onPress: actionDelete,
      },
      {
        visible: (chat: Chat) => {
          return !!chat.pinTime;
        },
        icon: "pinOff",
        color: theme.colors.success,
        text: "取消置顶",
        onPress: actionPinOff,
      },
      {
        visible: (chat: Chat) => !chat.pinTime,
        icon: "pin",
        color: theme.colors.success,
        text: "置顶",
        onPress: actionPinToTop,
      },
      {
        visible: (chat: Chat) => {
          if (chat.isGroup) {
            return !chat.is_mute;
          }
          const contact = contactStore.byUserId(chat?.receiver.id);
          if (contact) {
            return !contact?.is_mute;
          } else {
            return false;
          }
        },
        icon: "bellOff",
        color: theme.colors.warning,
        text: "静音",
        onPress: actionMute,
      },
      {
        visible: (chat: Chat) => {
          if (chat.isGroup) {
            return chat.is_mute;
          }
          const contact = contactStore.byUserId(chat?.receiver.id);
          if (contact) {
            return contact?.is_mute;
          } else {
            return false;
          }
        },
        icon: "bell",
        color: theme.colors.warning,
        text: "取消静音",
        onPress: actionUnmute,
      },
    ],
    [
      theme,
      actionDelete,
      actionPinOff,
      actionPinToTop,
      actionMute,
      actionUnmute,
      contactStore,
    ]
  );

  const actions = useMemo(() => {
    return swipeActionButtonConfigs.filter((config) => config.visible(item));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swipeActionButtonConfigs, scrollIndex, item]);

  return (
    <SwipeableViews index={scrollIndex} onChangeIndex={setScrollIndex}>
      <ChatListItemWrap
        onClick={() => onItemPress(item)}
        onContextMenu={!isMobile ? (e) => onContextMenu(0, e) : undefined}
      >
        {checked !== void 0 && (
          <CheckWrap>
            <CheckBox checked={checked} value={item.chat_id} />
          </CheckWrap>
        )}
        <Avatar avatar={item.displayImage} name={item.title} />
        <Main>
          <Row>
            {item.isGroup && <Icon type="mdi" name="group" size={0.7} />}
            <Title>
              {item.displayTitle}
              {count > 0 && `(${count})`}
              {isMute && (
                <Icon
                  type="mdi"
                  name="bellOff"
                  size={0.65}
                  color={theme.colors.warning}
                ></Icon>
              )}
            </Title>
            <RightWrap>
              {item.lastMessage && <MessageStatus message={item.lastMessage} />}
              {item.lastMessage && (
                <MessageTime time={item.lastMessage.created} />
              )}
              {item.pinTime && (
                <Icon
                  type="mdi"
                  name="pin"
                  size={0.7}
                  color={theme.colors.success}
                ></Icon>
              )}
              <OvalBadge
                value={item.messageUnreadCount}
                top="34px"
                right="24px"
              />
            </RightWrap>
          </Row>
          <MessagePreview
            draft={item.draft}
            message={item.lastMessage}
            isGroup={item.isGroup}
            isUnread={item.messageUnreadCount !== 0}
          />
        </Main>
      </ChatListItemWrap>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        {actions.map((config, index) => {
          return (
            <div
              key={`${config.icon}${index}`}
              onClick={() => {
                config.onPress(item);
                setScrollIndex(0);
              }}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: config.color,
                flex: 1 / actions.length,
                color: "white",
                height: "100%",
              }}
            >
              <Icon
                type="mdi"
                name={config.icon}
                size={0.7}
                color={"white"}
              ></Icon>
              {config.text}
            </div>
          );
        })}
      </div>
    </SwipeableViews>
  );
};

export default observer(ChatListItem);
