import MDIIcon from "../../widgets/MDIIcon";
import IonIcon, { Props as IonIconProps } from "../../widgets/IonIcon";
import FITIcon from "../../widgets/FitIcon";
import AntdIcon from "../../widgets/AntdIcon";
import IyesIcon from "../../widgets/IyesIcon";

export interface Props {
  type: "mdi" | "ion" | "fit" | "antd" | "iyes";
  name: string | IonIconProps["name"];
  size?: number;
  [key: string]: any;
}

const Icon: React.FC<Props> = ({ type, name, size, ...rest }) => {
  switch (type) {
    case "mdi":
      return <MDIIcon name={name} size={size} {...rest}></MDIIcon>;
    case "ion":
      return (
        <IonIcon
          name={name as IonIconProps["name"]}
          size={size}
          {...rest}
        ></IonIcon>
      );
    case "fit":
      return <FITIcon name={name} size={size} {...rest}></FITIcon>;
    case "antd":
      return <AntdIcon name={name} size={size} {...rest}></AntdIcon>;
    case "iyes":
      return <IyesIcon name={name} size={size} {...rest}></IyesIcon>;
    default:
      return null;
  }
};

export default Icon;
