import { ISimpleType, types as t } from "mobx-state-tree"

export function numEnumeration<T>(name: string | number[], options?: any): ISimpleType<T> {
  const realOptions: number[] = typeof name === "string" ? options! : name
  const type = t.union(...realOptions.map((option) => t.literal(option)))
  if (typeof name === "string") {
    type.name = name
  }
  return type
}
