import { MessagePreviewImageProps } from "../types"
import React from "react"
import PreviewWrap from "./preview-wrap"
import PreviewText from "./preview-text"

const MessageDraft: React.FC<MessagePreviewImageProps> = ({ forwardPrependMsg }) => {
  return (
    <PreviewWrap>
      <PreviewText>{`${forwardPrependMsg}[图片]`}</PreviewText>
    </PreviewWrap>
  )
}
export default MessageDraft
