/** @format */

import React, { useState, useMemo, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { Dialog } from 'antd-mobile';
import useNavigation from '../../hooks/useNavigation';
import PageWrap from '../components/PageWrap';
import PageHeader, { headerHeight } from '../components/PageHeader';
import CheckBoxGroup from '../../components/CheckBoxGroup';
import { ToastSuccess } from '../../components/Toast';
import { useStores } from '../../models';
import { UserGender } from '../../proto/nbchat-proto';

const ScrollArea = styled.div`
  height: calc(100% - ${headerHeight});
  overflow: scroll;
  padding: ${({ theme }) => theme.space[4]};
  background-color: ${({ theme }) => theme.colors.blockBackground};
`;

const SubmitText = styled.span<{ canSubmit: boolean }>`
  cursor: pointer;
  color: ${({ canSubmit, theme }) =>
    canSubmit ? theme.colors.primary : theme.colors.inputPlaceholderColor};
`;

const options = [
  {
    label: '男',
    value: UserGender.MALE,
  },
  {
    label: '女',
    value: UserGender.FEMALE,
  },
];

const UpdateCurrentUserDisplayName: React.FC = () => {
  const { goBack } = useNavigation();
  const {
    userStore,
    userStore: { currentUser },
  } = useStores();
  const [gender, setGender] = useState(currentUser.gender);

  const onSubmit = useCallback(() => {
    Dialog.confirm({
      content: '确定要修改?',
      onConfirm: () => {
        userStore.publicCurrentUserUpdate({ gender });
        ToastSuccess('修改成功!');
        goBack(-1);
      },
    });
  }, [userStore, gender, goBack]);

  const disabledFunc = useCallback(
    ({ gender }) => {
      return gender === currentUser.gender;
    },
    [currentUser.gender]
  );

  const canSubmit = useMemo(
    () => !disabledFunc({ gender }),
    [disabledFunc, gender]
  );

  return (
    <PageWrap>
      <PageHeader title="修改性别" isBackButton={true}>
        <SubmitText
          canSubmit={canSubmit}
          onClick={canSubmit ? onSubmit : undefined}
        >
          确定
        </SubmitText>
      </PageHeader>
      <ScrollArea>
        <CheckBoxGroup
          label="性别"
          options={options}
          value={gender}
          setValue={setGender}
        ></CheckBoxGroup>
      </ScrollArea>
    </PageWrap>
  );
};

export default observer(UpdateCurrentUserDisplayName);
