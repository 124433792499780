import { flow, getRoot, Instance, SnapshotOut, types } from "mobx-state-tree"
import {
  APIService,
  DownloadParams,
  LoginParams,
  RegisterParams,
  UploadParams,
  UploadClientParams,
  UploadToS3StorageParams,
  CheckVersionParams,
  UploadClientResult,
  PingParams,
  ForgetPasswordParams,
  ResetPasswordParams,
} from "../../services/api"
import { RootStoreModel } from ".."

/**
 * 管理API请求
 */
export const APIStoreModel = types
  .model("ApiStore")
  .props({})
  .actions((self) => {
    const { configStore } = getRoot<Instance<typeof RootStoreModel>>(self)

    // http client
    const api = new APIService(configStore.api)

    // 注册账号
    const register = flow(function* (data: RegisterParams) {
      return yield api.register(data)
    })

    // 登录账号
    const login = flow(function* (data: LoginParams) {
      return yield api.login(data)
    })

    // 忘记密码
    const forgetPassword = flow(function* (data: ForgetPasswordParams) {
      const params = { ...data }
      return yield api.forgetPassword(params)
    })

    // 重置密码
    const resetPassword = flow(function* (data: ResetPasswordParams) {
      const params = { ...data }
      return yield api.resetPassword(params)
    })

    // 上传, 状态独立管理，因为可能会存在多任务同时执行的情况
    const upload = flow(function* (data: UploadParams) {
      return yield api.upload(data)
    })

    const uploadClient = flow(function* (data: UploadClientParams) {
      return yield api.uploadClient(data)
    })

    // 登录账号
    const ping = flow(function* (data: PingParams) {
      return yield api.ping(data)
    })

    const uploadToS3Storage = flow(function* (
      s3url: UploadClientResult["url"],
      formData: UploadToS3StorageParams,
      onProgressUpdate: (progress: number) => void,
    ) {
      return yield api.uploadToS3Storage(s3url, formData, onProgressUpdate)
    })

    // 下载
    const download = flow(function* (data: DownloadParams) {
      return yield api.download(data)
    })

    const checkVersion = flow(function* (data: CheckVersionParams) {
      return yield api.checkVersion(data)
    })

    return { register, login, forgetPassword, resetPassword, upload, uploadClient, ping, uploadToS3Storage, download, checkVersion }
  })

type ApiStoreType = Instance<typeof APIStoreModel>
export interface ApiStore extends ApiStoreType {}
type ApiStoreSnapshotType = SnapshotOut<typeof APIStoreModel>
export interface ApiStoreSnapshot extends ApiStoreSnapshotType {}
export const createApiStoreDefaultModel = () => types.optional(APIStoreModel, {})
