import { MessagePreviewVideoProps } from "../types"
import React from "react"
import PreviewWrap from "./preview-wrap"
import PreviewText from "./preview-text"

const MessageVideo: React.FC<MessagePreviewVideoProps> = ({ forwardPrependMsg }) => {
  return (
    <PreviewWrap>
      <PreviewText>{`${forwardPrependMsg}[影片]`}</PreviewText>
    </PreviewWrap>
  )
}
export default MessageVideo
