export const pad0 = (t: number) => {
  return t < 10 ? "0" + t.toString() : t.toString()
}

export const formatOnlineDate = (date: Date | any) => {
  if (!date) return ""

  const month = date.getMonth() + 1
  const day = date.getDate()
  const hour = date.getHours()
  const min = date.getMinutes()
  //   const sec = date.getSeconds()

  const now: any = new Date()
  const t1: any = new Date(now.getFullYear(), now.getMonth(), now.getDate())
  const t2: any = new Date(date.getFullYear(), date.getMonth(), date.getDate())

  const diffDays = (t1 - t2) / (1000 * 60 * 60 * 24)
  const diffTime = now - date

  switch (diffDays) {
    case 0:
      // 15 分钟前
      if (diffTime <= 1000 * 60 * 15) {
        return "刚刚在线"
      }
      return "今天 " + pad0(hour) + ":" + pad0(min)
    case 1:
      return "昨天 " + pad0(hour) + ":" + pad0(min)
    default:
      return pad0(month) + "-" + pad0(day) + " " + pad0(hour) + ":" + pad0(min)
  }
}
