import { MessagePreviewVoiceProps } from "../types"
import React from "react"
import PreviewWrap from "./preview-wrap"
import PreviewText from "./preview-text"

const MessagePreviewVoice: React.FC<MessagePreviewVoiceProps> = ({ forwardPrependMsg }) => {
  return (
    <PreviewWrap>
      <PreviewText>{`${forwardPrependMsg}[语音]`}</PreviewText>
    </PreviewWrap>
  )
}
export default MessagePreviewVoice
