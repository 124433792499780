import React from "react";
import { observer } from "mobx-react-lite";
import useNavigation from "../../hooks/useNavigation";
import PageWrap from "../components/PageWrap";
import PageHeader from "../components/PageHeader";
import Accordion from "../../components/Accordion";

const Setting: React.FC = () => {
  const { navigate } = useNavigation();
  return (
    <PageWrap>
      <PageHeader title={"隐私安全"}></PageHeader>
      <Accordion title="黑名单" onItemPress={() => navigate('BlackList')} />
    </PageWrap>
  );
};

export default observer(Setting);
