import { MessagePreviewFileProps } from "../types"
import React from "react"
import PreviewWrap from "./preview-wrap"
import PreviewText from "./preview-text"

const MessagePreviewFile: React.FC<MessagePreviewFileProps> = ({ forwardPrependMsg }) => {
  return (
    <PreviewWrap>
      <PreviewText>{`${forwardPrependMsg}[文件]`}</PreviewText>
    </PreviewWrap>
  )
}
export default MessagePreviewFile
