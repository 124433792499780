import { nanoid } from "nanoid"
import md5 from "js-md5"

export const newID = (): string => nanoid()

export const genPrivacyChatID = (id1, id2) => {
  const head = id1 > id2 ? id2 : id1;
  const tail = id1 > id2 ? id1 : id2;
  const title = `${head}:${tail}`
  const chat_id = md5(title)
  return { title, chat_id }
}