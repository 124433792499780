import { User } from "../../models/user/user";

export type HTTP_METHOD = "GET" | "POST" | "PUT" | "DELETE";

// 认证结果
export interface AuthResult {
  token: number;
  user: User;
}

// 登录参数
export interface LoginParams {
  username: string;
  password: string;
}

export interface ForgetPasswordParams {
  username: string
  email: string
}

export interface ResetPasswordParams {
  username: string
  password: string
  code: string
}

// 注册参数
export interface RegisterParams {
  name: string;
  password: string;
  username?: string; // 登录名可以不用填写，服务端会自动生成
  phone?: string;
  email?: string;
}

export interface UploadResult {
  uri: string;
  size: string;
  filename: string;
}

export interface UploadParams {
  formData: FormData;
  onProgressUpdate?: (n: number) => void;
}

export interface UploadClientResult {
  storage: string;
  url: string;
  payload?: any;
}

export interface UploadClientParams {
  filename: string;
  onProgressUpdate?: (n: number) => void;
}

export interface UploadToS3StorageResult {}

export type UploadToS3StorageParams = FormData;

export interface DownloadParams {
  url: string;
  onProgressUpdate?: (n: number) => void;
  method?: HTTP_METHOD;
}

export enum UpdateTypes {
  NO_UPDATE = -1,
  SOFT_UPDATE = 0,
  FORCE_UPDATE = 1,
}

type PlatformOSType = "ios" | "android";

export interface CheckVersionParams {
  version: string;
  device: PlatformOSType;
}

export interface CheckVersionResult {
  code: 0 | 1;
  data: {
    id: number;
    version: string;
    type: UpdateTypes;
    device: PlatformOSType;
    updated: string;
    created: string;
    description: string;
    install_url: string;
  };
}

// 登出参数
export interface LogoutParams {
  token: string;
}

// 登出参数
export interface LogoutResult {
  code: number;
}


export interface PingParams {
  token: string;
}

// 确认token是否有效结果
export interface PingResult {
  ip: string;
  message: string;
}
