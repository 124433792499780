import React, { useRef } from "react";
import { observer } from "mobx-react-lite";
import { Dialog } from "antd-mobile";
import styled from "styled-components";
import { useStores } from "../../../../models";
import useNavigation from "../../../../hooks/useNavigation";
import Avatar from "../../../Avatar";
import MessageTime from "../../../MessageTime";
import MessageStatus from "../../../MessageStatus";
import Content from "./contents";
import { Message } from "../../../../models";
import { MessageStatus as Status } from "../../../../proto/nbchat-proto";

const Wrap = styled.div<{ byMe: boolean }>`
  padding: ${({ theme }) => `${theme.space[1]} ${theme.space[3]}`};
  margin: 2px 0px;
  max-width: 80%;
  display: flex;
  justify-content: ${({ byMe }) => (byMe ? "flex-end" : "flex-start")};
  margin-left: ${({ byMe }) => (byMe ? "auto" : "unset")};
  margin-right: ${({ byMe }) => (byMe ? "unset" : "auto")};
`;

const AvatarWrap = styled.div<{ byMe: boolean }>`
  align-items: flex-start;
  margin-left: ${({ byMe }) => (byMe ? "5px" : "0px")};
  margin-right: ${({ byMe }) => (byMe ? "0px" : "5px")};
  cursor: ${({ byMe }) => (byMe ? "unset" : "pointer")};
`;

const Column = styled.div<{ byMe: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: ${({ byMe }) => (byMe ? "flex-end" : "flex-start")};
`;

const Row = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const Main = styled.div<{ byMe: boolean }>`
  background-color: ${({ theme, byMe }) => byMe ? theme.colors.secondary : theme.colors.blockBackground};
  padding: 14px 8px;
  margin-right: 5px;
  border-radius: 14px;
  border-top-right-radius: ${({ byMe }) => (byMe ? "0px" : "14px")};
  border-top-left-radius: ${({ byMe }) => (byMe ? "14px" : "0px")};
  margin-bottom: ${({ theme }) => theme.space[2]};
`;


const ReplyBarBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row
  justify-content: space-between;
  border-width: 0px;
  border-left-width: 2px;
  border-style: solid;
  padding-left: 8px;
  border-color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 6px;
`;

const ReplyBarBoxTitle = styled.span`
  color: ${({ theme }) => theme.colors.primary};
`;

const ReplayBarBoxLeftWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ForwardMessageText = styled.span`
  color: ${({ theme }) => theme.colors.secondaryText};
`;

const ForwardMessageName = styled.span`
  color: ${({ theme }) => theme.colors.primary};
`;

const Name = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 0.8rem;
  color: ${({ theme }) => theme.colors.primary};
`;

const ResendText = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 0.7rem;
  margin-right: 5px;
  cursor: pointer;
`

const MessageCommon: React.FC<{ message: Message }> = ({ message }) => {
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const { messageStore, chatStore, isMobile } = useStores();
  const { navigate } = useNavigation();
  const isGroup = !!message.chat_id;
  const byMe = message.byMe;
  const replyMsg = message.replyMsg;
  const user = message.sender;
  const onContextMenu = (delay, e) => {
    e.preventDefault();
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      chatStore.setRightClickMenuVisible(true);
      chatStore.setRightClickMenuMessageId(message.message_id);
    }, delay);
  };
  const onCancleContextMenu = (e) => {
    clearTimeout(timeoutRef.current);
  };
  const resend = () => {
    message.setStatus(Status.SENDING);
    messageStore.sendMessageByMessageType(message);
  }
  const promptResend = () => {
    Dialog.confirm({
      content: '确定要重发消息?',
      onConfirm: resend,
    });
  }
  return (
    <Wrap
      className={message.message_id}
      byMe={byMe}
      onContextMenu={!isMobile ? (e) => onContextMenu(0, e) : undefined}
      onTouchStart={isMobile ? (e) => onContextMenu(1000, e) : undefined}
      onTouchEnd={isMobile ? onCancleContextMenu : undefined}
      onTouchCancel={isMobile ? onCancleContextMenu : undefined}
    >
      {!byMe && (
        <AvatarWrap
          byMe={byMe}
          onClick={() => navigate("User", { user_id: user.id })}
        >
          <Avatar name={user.name} avatar={user.avatar} />
        </AvatarWrap>
      )}
      <Column byMe={byMe}>
        <Main byMe={byMe}>
          {message.forward_msg_id && (
            <ForwardMessageText>
              转发消息來自:{" "}
              <ForwardMessageName>{message.forward_name}</ForwardMessageName>
            </ForwardMessageText>
          )}
          {isGroup && !byMe && <Name>{user.name}</Name>}
          {message.reply_msg_id && replyMsg && (
            <ReplyBarBox>
              <ReplayBarBoxLeftWrap>
                <ReplyBarBoxTitle>{replyMsg.sender.name}</ReplyBarBoxTitle>
                <Content message={replyMsg} />
              </ReplayBarBoxLeftWrap>
            </ReplyBarBox>
          )}
          {message.reply_msg_id && !replyMsg && (
            <ReplyBarBox>
              <ReplayBarBoxLeftWrap>
                <ReplyBarBoxTitle>回复讯息不存在</ReplyBarBoxTitle>
              </ReplayBarBoxLeftWrap>
            </ReplyBarBox>
          )}
          <Content message={message} />
        </Main>
        <Row>
          {
            message.status === Status.FAILED && (
              <ResendText onClick={promptResend}>
                点击重发
              </ResendText>
            )
          }
          <MessageTime time={message.created} />
          <MessageStatus message={message} />
        </Row>
      </Column>
      {byMe && (
        <AvatarWrap byMe={byMe}>
          <div>
            <Avatar name={user.name} avatar={user.avatar} />
          </div>
        </AvatarWrap>
      )}
    </Wrap>
  );
};
export default observer(MessageCommon);
