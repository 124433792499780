/** @format */

import React, { useState, useMemo, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { Dialog } from 'antd-mobile';
import useNavigation from '../../hooks/useNavigation';
import PageWrap from '../components/PageWrap';
import PageHeader, { headerHeight } from '../components/PageHeader';
import NormalInput from '../../components/NormalInput';
import { ToastSuccess } from '../../components/Toast';
import { rules, validate, isEmpty } from '../../refactor-utils/validator';
import { useStores } from '../../models';

const ScrollArea = styled.div`
  height: calc(100% - ${headerHeight});
  overflow: scroll;
  padding: ${({ theme }) => theme.space[4]};
  background-color: ${({ theme }) => theme.colors.blockBackground};
`;

const SubmitText = styled.span<{ canSubmit: boolean }>`
  cursor: pointer;
  color: ${({ canSubmit, theme }) =>
    canSubmit ? theme.colors.primary : theme.colors.inputPlaceholderColor};
`;

const UpdateCurrentUserEmail: React.FC = () => {
  const { goBack } = useNavigation();
  const {
    userStore,
    userStore: { currentUser },
  } = useStores();
  const [email, setEmail] = useState(currentUser.email);

  const onSubmit = useCallback(() => {
    Dialog.confirm({
      content: '确定要修改?',
      onConfirm: () => {
        userStore.publicCurrentUserUpdate({ email });
        ToastSuccess('修改成功!');
        goBack(-1);
      },
    });
  }, [userStore, email, goBack]);

  const disabledFunc = useCallback(
    ({ email }) => {
      return (
        email === currentUser.email ||
        !isEmpty(validate({ email: rules.email }, { email }))
      );
    },
    [currentUser.email]
  );

  const canSubmit = useMemo(
    () => !disabledFunc({ email }),
    [disabledFunc, email]
  );

  return (
    <PageWrap>
      <PageHeader title="修改电子邮箱" isBackButton={true}>
        <SubmitText
          canSubmit={canSubmit}
          onClick={canSubmit ? onSubmit : undefined}
        >
          确定
        </SubmitText>
      </PageHeader>
      <ScrollArea>
        <NormalInput
          label="电子邮箱"
          placeholder="请输入电子邮箱"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          validateRule={rules.email}
        ></NormalInput>
      </ScrollArea>
    </PageWrap>
  );
};

export default observer(UpdateCurrentUserEmail);
