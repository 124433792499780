import Dexie, { Table } from "dexie";
import { MessageData } from "../proto/nbchat-proto";

const databaseName = "Nbchat.db";
const databaseVersion = 4;
export const limit = 30;

export class MySubClassedDexie extends Dexie {
  // 'friends' is added by dexie when declaring the stores()
  // We just tell the typing system this is the case
  messages!: Table<MessageData>;

  constructor() {
    super(databaseName);
    this.version(databaseVersion).stores({
      messages: "&message_id, created, chat_cid", // Primary key and indexed props
    });
  }
}

export let db: MySubClassedDexie = undefined;

export const initDB = () => {
  db = new MySubClassedDexie();
};

export const dropMessages = async () => {
  const result = await db.messages.clear();
  return result;
};

export const queryMessageByMessageID = async (message_id) => {
  const result = await db.messages.where({ message_id }).first();
  return result;
};

export const queryMessages = async (chat_cid, offset) => {
  const results = await db.messages
    .orderBy("created")
    .reverse()
    .filter((message) => message.chat_cid === chat_cid)
    .offset(offset)
    .limit(limit)
    .toArray();
  return results;
};

export const queryPrivateMessages = async (user_id, friend_user_id, offset) => {
  friend_user_id = Number(friend_user_id);
  const results = await db.messages
    .orderBy("created")
    .reverse()
    .filter((message) => {
      if (message.chat_id === null) {
        if (message.receiver_id === user_id && message.sender_id === friend_user_id)
          return true;
        if (message.receiver_id === friend_user_id && message.sender_id === user_id)
          return true;
        return false;
      } else {
        return false;
      }
    })
    .offset(offset)
    .limit(limit)
    .toArray();
  return results;
};

export const insertMessage = async ({ cM }) => {
  const result = await db.messages.put(cM, [cM.message_id]);
  return result;
};

export const updateMessage = async ({ cM }) => {
  const result = await db.messages.update(cM.message_id, cM);
  return result;
};

export const deleteMessage = async ({ cM }) => {
  const result = await db.messages.delete(cM.message_id);
  return result;
};
