import React from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { Message } from "../../../../../models";
import { formatBytes } from "../../../../../utils";
import { MessageStatus } from "../../../../../proto/nbchat-proto";
import useGlobalTheme from "../../../../../hooks/useGlobalTheme";
import Icon from "../../../../Icon";

const w = window.innerWidth * 0.06;

const FileMessageTouchableWrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: stretch;
`;

const ProgressBarWrap = styled.div`
  position: absolute;
  top: calc(50% - ${w / 2}px);
  left: calc(50% - ${w / 2}px);
  width: ${w}px;
  height: ${w}px;
`;

const IconSquare = styled.a`
  margin-right: 10px;
  width: 50px;
  height: 50px;
  background-color: ${({ theme }) => theme.colors.blockBackground};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  border: 2px solid ${({ theme }) => theme.colors.border};
`;

const RightContentArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

interface FileMessageProps {
  message: Message;
}

// 优化使用纯组件
const FileMessage: React.FC<FileMessageProps> = ({
  message,
}) => {
  const theme = useGlobalTheme();
  // 选中图片进行预览
  const canDownload = ![
    MessageStatus.SENDING,
  ].includes(message.status);
  const url = message.attachURL;
  const filename = message.content.split('/').reverse()[0];

  return (
    <FileMessageTouchableWrap>
      <IconSquare download={canDownload ? filename : false} href={canDownload ? url : false} target="_blank">
        <Icon
          type="antd"
          name="download"
          color={theme.colors.primary}
          size={1}
        />
      </IconSquare>
      <RightContentArea>
        <span>
          {message.attach.filename}
        </span>
        {message.attach.size && <span>{formatBytes(message.attach.size)}</span>}
      </RightContentArea>
      {message.uploading && (
        <ProgressBarWrap>
          <CircularProgressbar
            styles={buildStyles({
              strokeLinecap: "round",
              textSize: "1rem",
              textColor: "transparent",
              trailColor: theme.colors.blockBackground,
              backgroundColor: theme.colors.primary,
              pathColor: theme.colors.primary,
            })}
            value={message.progress}
            maxValue={1}
            text={(message.progress * 100).toFixed(0)}
          />
        </ProgressBarWrap>
      )}
    </FileMessageTouchableWrap>
  );
};
export default observer(FileMessage);
