/** @format */

import { useCallback, useState, useMemo } from "react";
import styled from "styled-components";
import { useStores } from "../../../models";
import useGlobalTheme from "../../../hooks/useGlobalTheme";
import useNavigation from "../../../hooks/useNavigation";
import { MessageTypes } from "../../../proto/nbchat-proto";
import Overlay from "../../../components/Overlay";
import Accordion from "../../../components/Accordion";
import { Props as IconProps } from "../../../components/Icon";
import { ToastSuccess } from "../../../components/Toast";
import { copyTextToClipboard } from "../../../utils";

const wrapWidth = 400;

const Wrap = styled.div`
  z-index: 1000;
  position: fixed;
  width: ${wrapWidth}px;
  max-width: 80%;
  max-height: 80%;
  overflow: scroll;
  background-color: ${({ theme }) => theme.colors.blockBackground};
  border-radius: ${({ theme }) => theme.radii[3]};
`;

const RightClickMenu = () => {
  const theme = useGlobalTheme();
  const { navigate } = useNavigation();
  const {
    chatStore: {
      setIsReplyBarVisible,
      rightClickMenuMessageId,
      setRightClickMenuVisible,
    },
    messageStore: { messages, senddeleteMessageByMessageID },
    userStore: { currentUser },
  } = useStores();
  const [mode, setMode] = useState("default");
  const selectedMessage = messages.get(rightClickMenuMessageId);

  const handleCloseSelf = useCallback(() => {
    setRightClickMenuVisible(false);
  }, [setRightClickMenuVisible]);

  const defaultModeOptions = useMemo(() => {
    return [
      {
        visible: true,
        title: "回复",
        iconProps: {
          type: "iyes",
          name: "huifu",
          color: theme.colors.text,
          size: 1.2,
        },
        onItemPress: (e) => {
          e.stopPropagation();
          e.cancelBubble = true;
          setIsReplyBarVisible(true);
          handleCloseSelf();
          return false;
        },
      },
      {
        visible: selectedMessage?.type === MessageTypes.TEXT,
        title: "复制",
        iconProps: {
          type: "iyes",
          name: "fuzhi",
          color: theme.colors.text,
          size: 1.2,
        },
        onItemPress: (e) => {
          e.stopPropagation();
          e.cancelBubble = true;
          copyTextToClipboard(selectedMessage.content);
          ToastSuccess("复制成功!");
          handleCloseSelf();
          return false;
        },
      },
      {
        visible: true,
        title: "转发",
        iconProps: {
          type: "iyes",
          name: "zhuanfa",
          color: theme.colors.text,
          size: 1.2,
        },
        onItemPress: (e) => {
          e.stopPropagation();
          e.cancelBubble = true;
          handleCloseSelf();
          navigate("ForwardMessage", {
            chat_id: selectedMessage.messageChatID,
            message_id: selectedMessage?.message_id,
          });
          return false;
        },
      },
      {
        visible: true,
        title: "删除",
        iconProps: {
          type: "iyes",
          name: "shanchu",
          color: theme.colors.text,
          size: 1.2,
        },
        onItemPress: (e) => {
          e.stopPropagation();
          e.cancelBubble = true;
          setMode("delete");
          return false;
        },
      },
    ];
  }, [navigate, selectedMessage, theme, handleCloseSelf, setIsReplyBarVisible]);

  const renderDefaultModeOptions = useMemo(() => {
    return defaultModeOptions.filter((o) => o.visible);
  }, [defaultModeOptions]);

  const deleteModeOptions = useMemo(() => {
    return [
      {
        visible: true,
        title: "为我删除",
        onItemPress: (e) => {
          e.stopPropagation();
          handleCloseSelf();
          senddeleteMessageByMessageID(selectedMessage, true);
        },
      },
      {
        visible: selectedMessage?.sender?.id === currentUser.id,
        title: "为双方删除",
        onItemPress: (e) => {
          e.stopPropagation();
          handleCloseSelf();
          senddeleteMessageByMessageID(selectedMessage);
        },
      },
      {
        visible: true,
        title: "取消",
        onItemPress: (e) => {
          e.stopPropagation();
          handleCloseSelf();
        },
      },
    ];
  }, [
    selectedMessage,
    senddeleteMessageByMessageID,
    currentUser,
    handleCloseSelf,
  ]);

  const renderDeleteModeOptions = useMemo(() => {
    return deleteModeOptions.filter((o) => o.visible);
  }, [deleteModeOptions]);

  return (
    <Overlay onClick={handleCloseSelf}>
      <Wrap>
        {mode === "default" &&
          renderDefaultModeOptions.map((option) => {
            return (
              <Accordion
                key={option.title}
                {...option}
                iconProps={option.iconProps as IconProps}
              ></Accordion>
            );
          })}
        {mode === "delete" &&
          renderDeleteModeOptions.map((option) => {
            return <Accordion key={option.title} {...option}></Accordion>;
          })}
      </Wrap>
    </Overlay>
  );
};

export default RightClickMenu;
