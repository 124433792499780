import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { UserModel } from "../user/user"
import { FriendStatus } from "../../proto/nbchat-proto"
import { numEnumeration } from "../../types/enum"

/**
 * Model description here for TypeScript hints.
 */
export const FriendModel = types
  .model("Friend")
  .props({
    id: types.identifierNumber,
    owner_id: types.number,
    user_id: types.number,
    username: types.string,
    name: types.string,
    user: types.reference(UserModel),
    status: types.optional(
      numEnumeration<FriendStatus>("FriendStatus", Object.values(FriendStatus)),
      FriendStatus.PENDING,
    ),
    created: types.Date,
    updated: types.Date,
    readed: types.optional(types.boolean, false),
  })
  .views((self) => ({
    get displayName() {
      return self.name || self.username;
    },
  })) // eslint-disable-line @typescript-eslint/no-unused-vars
  .actions((self) => ({
    setRead() {
      self.readed = true
    },
    setStatus(status) {
      self.status = status
    },
  })) // eslint-disable-line @typescript-eslint/no-unused-vars

type FriendType = Instance<typeof FriendModel>
export interface Friend extends FriendType {}
type FriendSnapshotType = SnapshotOut<typeof FriendModel>
export interface FriendSnapshot extends FriendSnapshotType {}
export const createFriendDefaultModel = () => types.optional(FriendModel, {})
