import React from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import Gender from "./components/Gender";
import Avatar from "../Avatar";
import { User, Contact } from "../../models";
import AtNickname from "../AtNickname";
import AtUsername from "../AtUsername";


const Wrap = styled.div`
  position: relative;
  display: flex;
  background-color: ${({ theme }) => theme.colors.blockBackground};
  padding: ${({ theme }) => theme.space[2]} ${({ theme }) => theme.space[4]} }
  border: 0px solid ${({ theme }) => theme.colors.border};
  border-bottom-width: 1px;
  cursor: pointer;
`;

const Main = styled.div`
  margin-left: 5px;
  width: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const TitleWrap = styled.div`
  display: flex;
`;

const Title = styled.div`
  font-size: 0.8rem;
`;

interface Props {
  user?: User;
  contact?: Contact;
  onItemPress?: () => void;
}

const UserProfile: React.FC<Props> = ({ user, contact, onItemPress }) => {
  if (!user) return null;
  return (
    <Wrap onClick={onItemPress}>
      <Avatar avatar={user.avatar} name={user.name} />
      <Main>
        <Column>
          <TitleWrap>
            <Title>{contact?.name || user.name}</Title>
            <Gender gender={user.gender}></Gender>
          </TitleWrap>
          {Boolean(contact?.name) && <AtNickname user={user} />}
          <AtUsername user={user} />
        </Column>
      </Main>
    </Wrap>
  );
};

export default observer(UserProfile);
