import { useState } from "react";
import { useStores } from "../models";
import { CDN_URL } from "../config/env";

const useImageUpload = () => {
  const { apiStore } = useStores();
  const [imageUploadLoading, setImageUploadLoading] = useState(false);

  const uploadImage = async (): Promise<{
    image?: string;
    imageUrl?: string;
    errorMsg?: string;
  }> => {
    return new Promise((resolve, reject) => {
      const uploader = document.createElement("input");
      uploader.type = "file";
      uploader.accept = ".png,.jpg,.jpeg";
      uploader.multiple = false;
      uploader.onchange = async (e) => {
        try {
          setImageUploadLoading(true);
          const files = (e.target as HTMLInputElement).files;
          const newFormData = new FormData();
          newFormData.append("file", files[0]);
          const payload = {
            formData: newFormData,
          };
          const { uri } = await apiStore.upload(payload);
          resolve({
            image: CDN_URL + "/" + uri,
            imageUrl: uri,
          });
        } catch (e) {
          reject({ errorMsg: e.message });
        } finally {
          setImageUploadLoading(false);
        }
      };
      document.body.appendChild(uploader);
      uploader.click();
    });
  };

  return {
    imageUploadLoading,
    uploadImage,
  };
};

export default useImageUpload;
