import React from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import useGlobalTheme from "../../hooks/useGlobalTheme";
import PageWrap from "../components/PageWrap";
import PageHeader, { headerHeight } from "../components/PageHeader";
import { useStores } from "../../models";
import QrcodeDisplayer from "../../components/QrcodeDisplayer";
import Text from "../../widgets/Text";

const ScrollArea = styled.div`
  height: calc(100% - ${headerHeight});
  overflow: scroll;
`;

const DownloadArea = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${({ theme }) => theme.space[2]}
`

const DownloadText = styled(Text)`
  cursor: pointer;
`
const QRCODE_ID = "MY_QRCODE"
const CurrentUserQrcode: React.FC = () => {
  const theme = useGlobalTheme();
  const {
    userStore: { currentUser },
  } = useStores();
  const downloadQrcode = () => {
    const qrcodeCanvas = (document.getElementById(QRCODE_ID)) as HTMLCanvasElement;
    const img = new Image();
    img.src = qrcodeCanvas.toDataURL('image/png');
    
    const downLink = (document.createElement('a')) as HTMLAnchorElement;
    downLink.href = img.src;
    downLink.download = '二维码';
    downLink.click();
  }
  return (
    <PageWrap>
      <PageHeader title="我的二维码" isBackButton={true} />
      <ScrollArea>
        <QrcodeDisplayer
          id={QRCODE_ID}
          avatar={currentUser.avatar}
          gender={currentUser.gender}
          qrcode={currentUser.qrcode}
          name={currentUser.name}
          description="扫描二维码 加我为好友"
        />
        <DownloadArea>
          <DownloadText onClick={downloadQrcode} color={theme.colors.primary} fontSize={theme.fontSizes[2]}>保存二维码</DownloadText>
        </DownloadArea>
      </ScrollArea>
    </PageWrap>
  );
};

export default observer(CurrentUserQrcode);
