import { encode } from "js-base64";
import { flow, getRoot, Instance, SnapshotOut, types } from "mobx-state-tree";
import {
  ChatTypes,
  Event,
  EventNames,
  FLAG_ACK,
  MessageStatus,
  MessageTypes,
  ChatPerms,
  ChatMemberARData,
  ChatUpdateData,
  MessageSearchData,
  MessageData,
  FLAG_REPLY,
  ChatData,
  MuteData,
  ChatBurningSwitchData,
} from "../../proto/nbchat-proto";
import { staticUrl, debug } from "../../utils";
import { ChatMemberModel } from "../chat-member";
import { Message, MessageModel } from "../message/message";
import { RootStoreModel } from "..";
import { User, UserModel } from "../user/user";
import { NotifyType } from "../../types/message";

/**
 * 聊天模型
 * 主要类别： 私聊，群聊
 *
 * TODO: 区分 ChatMember 职责，成员可以具备管理权限
 */
export const ChatModel = types
  .model("Chat")
  .props({
    id: types.maybe(types.number), // 如果是用户与用户之间的对话，ID就是联系人用户ID
    chat_id: types.identifier,
    title: types.string,
    image: types.maybeNull(types.string),
    members: types.optional(types.array(ChatMemberModel), []),
    type: types.optional(types.number, ChatTypes.PRIVATE),
    creator: types.maybe(types.reference(UserModel)),
    receiver: types.maybe(types.reference(UserModel)),
    messages: types.optional(types.array(types.reference(MessageModel)), []),
    updated: types.optional(types.Date, () => new Date()),
    created: types.optional(types.Date, () => new Date()),
    deleted: types.optional(types.boolean, false), // delete flag used for soft delete
    draft: types.maybe(types.string),
    pinTime: types.maybeNull(types.Date),
    is_mute: types.maybeNull(types.boolean),
    is_burning: types.maybeNull(types.boolean),
    burning_timer: types.maybeNull(types.number),
  })
  .volatile((self) => ({
    typing: undefined,
    isSearching: false,
    isNoMoreData: false,
  }))
  .views((self: any) => ({
    get sortedMessages(): Message[] {
      return Array.from(self.messages).sort((m1: Message, m2: Message) =>
        m1.created > m2.created ? -1 : 1
      );
    },
    // 获取所有的群成员会员资料
    get users() {
      return self.members.map(({ user }) => user);
    },
    get usersByName() {
      const { userStore } = getRoot<Instance<typeof RootStoreModel>>(self);
      const dst = {};
      self.members
        .map(({ user }) => user)
        .filter((user: User) => user.id !== userStore.currentUser.id)
        .forEach((user: User) => {
          const nameLetter = user.name?.substring(0, 1);
          dst[nameLetter] = dst[nameLetter] || [];
          dst[nameLetter].push(user);
        });
      const lst = Object.keys(dst).map((letter) => ({
        letter,
        data: dst[letter],
      }));
      return lst;
    },
    // 显示标题
    get displayTitle() {
      if (self.type === ChatTypes.PRIVATE) {
        const { contactStore, userStore } =
          getRoot<Instance<typeof RootStoreModel>>(self);
        const contact = contactStore.byUserId(self.privateOtherId);
        if (contact) {
          return contact.displayName;
        }
        const user = userStore.users.get(self.privateOtherId);
        if (user) {
          return user.displayName;
        }
        return "title error";
      } else {
        return self.title;
      }
    },
    // 群成员数量
    get memebersCount() {
      return self.members.length;
    },
    get allMessages() {
      return [...self.messages];
    },
    get lastMessage() {
      return self.sortedMessages.length ? self.sortedMessages[0] : null;
    },
    get isGroup() {
      return self.type !== ChatTypes.PRIVATE;
    },
    get displayImage() {
      if (self.image) {
        return staticUrl(self.image);
      }
      return self.receiver ? self.receiver.img : "";
    },

    get messageCount() {
      return self.messages.length;
    },

    get messageUnread() {
      return self.messages.filter(
        (message) =>
          !message.byMe && !message.isReadedByMe && !message.notify_type
      );
    },

    get messageUnreadCount() {
      return self.messageUnread.length;
    },

    // 所有图片
    get images() {
      const images = [];
      self.messages
        .filter(
          (x) =>
            x.type === MessageTypes.IMAGE ||
            MessageTypes.IMAGES ||
            MessageTypes.VIDEO
        )
        .forEach((x) => {
          if (x.type === MessageTypes.VIDEO) {
            images.unshift({
              type: x.type,
              message_id: x.message_id,
              attach_path: x.attach_path,
              url: x.content,
              key: `${x.message_id}-${x.attach_path || x.content}`,
            });
          } else if (x.type === MessageTypes.IMAGE) {
            images.unshift({
              type: x.type,
              message_id: x.message_id,
              attach_path: x.attach_path,
              url: x.content,
              key: `${x.message_id}-${x.attach_path || x.content}`,
            });
          } else if (x.type === MessageTypes.IMAGES) {
            x.attach.imgs.forEach((img) => {
              images.unshift({
                type: x.type,
                message_id: x.message_id,
                attach_path: img.uri,
                url: img.url,
                key: `${x.message_id}-${img.uri || img.url}`,
              });
            });
          }
        });
      return images;
    },
    get groupQrcode() {
      const { configStore } = getRoot<Instance<typeof RootStoreModel>>(self);
      const path = "group";
      const data = {
        path,
        id: self.id,
        chat_id: self.chat_id,
        title: self.title,
        image: self.image,
      };
      const t = JSON.stringify(data);
      const b = encode(t);
      return `${configStore.redirectURL}?appKey=${configStore.appKey}&path=${path}&data=${b}`;
    },
    get membersExcludeSelf() {
      const {
        userStore: { currentUser },
      } = getRoot<Instance<typeof RootStoreModel>>(self);
      return self.members?.filter(({ user_id }) => user_id !== currentUser.id);
    },
    get isCurrentUserExistInMembers() {
      const {
        userStore: { currentUser },
      } = getRoot<Instance<typeof RootStoreModel>>(self);
      return !!self.members?.find(({ user_id }) => user_id === currentUser.id);
    },
    get isCurrentUserIsAdmin() {
      const {
        userStore: { currentUser },
      } = getRoot<Instance<typeof RootStoreModel>>(self);
      return (
        self.members?.find(({ user_id }) => user_id === currentUser.id)
          ?.perm === ChatPerms.ADMIN
      );
    },
    get privateOtherId() {
      const {
        userStore: { currentUser },
      } = getRoot<Instance<typeof RootStoreModel>>(self);
      return currentUser.id === self.receiver.id
        ? self.sender.id
        : self.receiver.id;
    },
  }))
  .actions((self) => {
    const {
      messageStore,
      userStore: { currentUser: sender },
      eventStore,
    } = getRoot<Instance<typeof RootStoreModel>>(self);

    function setIsBurning(truthy: boolean) {
      self.is_burning = truthy;
    }

    function setBurningTimer(second: number) {
      self.burning_timer = second;
    }

    function setIsSearching(truthy: boolean) {
      self.isSearching = truthy;
    }

    function setIsNoMoreData(truthy: boolean) {
      self.isNoMoreData = truthy;
    }

    function setID(id: number) {
      self.id = id;
    }
    function setCreator(id: number) {
      const {
        userStore: { users },
      } = getRoot<Instance<typeof RootStoreModel>>(self);
      self.creator = users.get(id.toString());
    }
    function setTitle(title: string) {
      self.title = title;
    }
    function setImage(image: string) {
      self.image = image;
    }
    function setDraft(value: string) {
      self.draft = value;
    }
    function clearDraft() {
      self.draft = undefined;
    }
    function setTyping(user_id: number) {
      self.typing = user_id;
    }
    function setPinTime() {
      self.pinTime = new Date();
    }
    function clearPinTime() {
      self.pinTime = null;
    }
    function setIsMute(v: boolean) {
      self.is_mute = v;
    }
    function membersToUsers(data: ChatData) {
      const { userStore } = getRoot<Instance<typeof RootStoreModel>>(self);
      const { members } = data;
      const mdata = members.map((member) => {
        const { user, ...rest } = member;
        const u = userStore.addUser(user);
        return { ...rest, user: u };
      });
      self.members.replace(mdata);
      return self;
    }
    function prependMessages(historyMessages: Partial<Message>[]) {
      historyMessages.forEach((history) => {
        newHistoryMessage(history);
      });
    }
    function newHistoryMessage({
      content,
      type = MessageTypes.TEXT,
      mention_ids: mentionIDs = [],
      ...rest
    }: Partial<MessageData>): Message {
      const m = messageStore.addMessage({
        content,
        type,
        chat_id: self.id,
        chat_cid: self.isGroup ? self.chat_id : null,
        sender_id: sender.id,
        receiver_id: self.receiver ? self.receiver.id : undefined,
        mention_ids: [...mentionIDs],
        burning_timer: self.burning_timer || 0,
        ...rest,
      });
      self.messages.push(m);
      return m;
    }
    function newMessage({
      content,
      type = MessageTypes.TEXT,
      mention_ids: mentionIDs = [],
      ...rest
    }: Partial<MessageData> & { notify_type?: NotifyType }): Message {
      const m = messageStore.addMessage({
        content,
        type,
        chat_id: self.id,
        chat_cid: self.chat_id,
        sender_id: sender.id,
        receiver_id: self.receiver ? self.receiver.id : undefined,
        mention_ids: [...mentionIDs],
        burning_timer: self.burning_timer || 0,
        ...rest,
      });
      self.messages.unshift(m);
      return m;
    }

    function update(values: Partial<Message>) {
      for (const [key, value] of Object.entries(values)) {
        if (key === "message") {
          newMessage({
            content: value,
            type: MessageTypes.TEXT,
            status: MessageStatus.READ,
            notify_type: NotifyType.GROUP_TITLE_UPDATE,
          });
        }
        self[key] = value;
      }
    }
    function markAllRead() {
      const items = self.messageUnread.map((message) => {
        message.markRead();
        const m = {
          sender_id: message.sender.id,
          message_id: message.message_id,
          id: message.id,
          status: MessageStatus.READ,
        };
        return m;
      });
      if (items.length > 0) {
        const { eventStore } = getRoot<Instance<typeof RootStoreModel>>(self);
        const evt = new Event(EventNames.MESSAGE_BATCH_READ, {
          items,
        });
        eventStore.publish(evt, { flags: FLAG_ACK });
        const evt2 = new Event(EventNames.MESSAGE_BATCH_STATUS_UPDATED, {
          items,
        });
        eventStore.publish(evt2, { flags: FLAG_ACK });
      }
    }

    function onMessageCreated(
      { message_id }: Message,
      prepend: boolean,
      skipNotify?: boolean
    ) {
      // reactive chat
      if (self.deleted) {
        self.deleted = false;
      }
      if (!self.messages.find((x) => x.message_id === message_id)) {
        if (prepend) {
          self.messages.push(message_id);
        } else {
          self.messages.unshift(message_id);
        }
      }

      try {
        if (!skipNotify) {
          const text = (message) => {
            if (message.type === MessageTypes.TEXT) {
              return message.content;
            } else if (message.type === MessageTypes.VIDEO) {
              return '[视频]';
            } else if (message.type === MessageTypes.IMAGES) {
              return '[多图片]';
            } else if (message.type === MessageTypes.IMAGE) {
              return '[图片]';
            } else if (message.type === MessageTypes.VOICE) {
              return '[音频]';
            }
          };
          const message = messageStore.messages.get(message_id);
          window.postMessage(
            JSON.stringify({
              type: 'show_notification',
              title: self.title,
              body: text(message),
            })
          );
        }
      } catch (e) {
        debug('window.postMessage', e);
      }
    }

    function onMessageDeleted({ message_id }: Message) {
      const index = self.messages.findIndex((x) => {
        return x.message_id === message_id;
      });
      if (index >= 0) {
        self.messages.splice(index, 1);
      }
    }

    // 清除消息
    function clearMessages() {
      const { messageStore } = getRoot<Instance<typeof RootStoreModel>>(self);
      const ids = self.messages.map((x) => x.message_id);
      self.messages.forEach((message) => {
        messageStore.handleMessageDeleted({
          message_id: message.message_id,
          id: message.id,
        });
      });
      self.messages.clear();
      ids.forEach((x) => messageStore.remove(x));
    }

    function setDeleted(b: boolean) {
      self.deleted = b;
    }

    function publicAddGroupChatMember(newUsers: User["id"][]) {
      const evt = new Event<ChatMemberARData>(EventNames.CHAT_MEMBER_ADD, {
        chat_id: self.id,
        chat_cid: self.chat_id,
        user_ids: newUsers,
      });
      eventStore.publish(evt, { flags: FLAG_ACK });
    }

    function publicRemoveGroupChatMember(deleteUsers: User["id"][]) {
      const evt = new Event<ChatMemberARData>(EventNames.CHAT_MEMBER_REMOVE, {
        chat_id: self.id,
        chat_cid: self.chat_id,
        user_ids: deleteUsers,
      });
      eventStore.publish(evt, { flags: FLAG_ACK });
    }

    function publicUpdateGroupChatTitle(title: string) {
      const evt = new Event<ChatUpdateData>(EventNames.CHAT_UPDATE, {
        chat_id: self.chat_id,
        title,
      });
      eventStore.publish(evt, { flags: FLAG_ACK });
    }

    const publicReadChatHistory = flow(function* (id: Message["id"]) {
      const limit = 30;
      const data: MessageSearchData = {
        id_lt: id,
        limit,
      };
      data.chat_id = self.isGroup ? self.id : null;
      data.target_user_id = self.isGroup ? null : self.receiver.id;

      setIsSearching(true);
      const evt = new Event<MessageSearchData>(EventNames.MESSAGE_SEARCH, data);
      const v = yield new Promise((resolve) => {
        eventStore.publish(evt, {
          flags: FLAG_REPLY,
          onData(data) {
            resolve(data);
          },
          onError() {
            resolve(null);
          },
        });
      });
      if (v) {
        const items = v.items as MessageData[];
        items.forEach((item) => {
          messageStore.onHistoryMessageCreated(item);
        });
        setIsNoMoreData(items.length < limit);
      } else {
        setIsNoMoreData(true);
      }
      setIsSearching(false);
    });

    const publicToggleMuteChat = function (is_mute: boolean) {
      const data: MuteData = {
        is_mute,
      };
      if (self.receiver) {
        data.user_id =
          self.receiver.id === sender.id ? self.creator.id : self.receiver.id;
      } else if (self.id) {
        data.chat_id = self.id;
      }
      const evt = new Event<MuteData>(EventNames.MUTE, data);
      eventStore.publish(evt, {
        flags: FLAG_ACK,
      });
    };

    const publicSwitchBurningChat = function (
      is_burning: boolean,
      burning_timer: number
    ) {
      const {
        userStore: { currentUser },
      } = getRoot<Instance<typeof RootStoreModel>>(self);

      self.is_burning = is_burning;
      self.burning_timer = burning_timer;
      const data: ChatBurningSwitchData = {
        is_burning: is_burning,
        burning_timer: burning_timer,
        creator_id: currentUser.id,
        receiver_id:
          currentUser.id === self.creator.id
            ? self.receiver.id
            : self.creator.id,
        chat_id: self.id,
        chat_cid: self.chat_id,
      };
      const evt = new Event<ChatBurningSwitchData>(
        EventNames.CHAT_BURNING_SWITCH,
        data
      );
      eventStore.publish(evt, {
        flags: FLAG_ACK,
      });
    };

    return {
      setIsBurning,
      setBurningTimer,
      membersToUsers,
      setIsSearching,
      setIsNoMoreData,
      setID,
      setTitle,
      setCreator,
      setImage,
      setDraft,
      clearDraft,
      setTyping,
      setPinTime,
      clearPinTime,
      setIsMute,
      prependMessages,
      newHistoryMessage,
      newMessage,
      update,
      clearMessages,
      markAllRead,
      onMessageCreated,
      onMessageDeleted,
      setDeleted,
      publicReadChatHistory,
      publicToggleMuteChat,
      publicSwitchBurningChat,
      publicAddGroupChatMember,
      publicRemoveGroupChatMember,
      publicUpdateGroupChatTitle,
    };
  });

export type ChatType = Instance<typeof ChatModel>;
export interface Chat extends ChatType {}
type ChatSnapshotType = SnapshotOut<typeof ChatModel>;
export interface ChatSnapshot extends ChatSnapshotType {}
export const createChatDefaultModel = () => types.optional(ChatModel, {});
