import React from "react";
import { observer } from "mobx-react-lite";
import { MessageStatus as Status } from "../../proto/nbchat-proto";
import useGlobalTheme from "../../hooks/useGlobalTheme";
import Icon from "../Icon";
import { Message } from "../../models";

interface Props {
  message: Message;
}

const MessageStatus: React.FC<Props> = ({ message }) => {
  const theme = useGlobalTheme();
  const size = 0.8;
  if (message.status === Status.READ && message.isReadedByOther) {
    return (
      <Icon
        type="ion"
        name="checkmark-done-sharp"
        size={size}
        color={theme.colors.primary}
      />
    );
  }
  switch (message.status) {
    case Status.SENDING:
      return (
        <Icon
          type="mdi"
          name="clockTimeTwoOutline"
          size={0.5}
          color={theme.colors.primary}
        />
      );
    case Status.SENT:
      return (
        <Icon
          type="ion"
          name="checkmark-outline"
          size={size}
          color={theme.colors.secondaryText}
        />
      );
    case Status.DELIVERED:
      return (
        <Icon
          type="ion"
          name="checkmark-done-outline"
          size={size}
          color={theme.colors.primary}
        />
      );
      case Status.READ:
        return (
          <Icon
            type="ion"
            name="checkmark-done-outline"
            size={size}
            color={theme.colors.primary}
          />
        );
    case Status.FAILED:
      return (
        <Icon type="ion" name="close" size={size} color={theme.colors.danger} />
      );
    default:
      return null;
  }
};

export default observer(MessageStatus);
