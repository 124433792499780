export function debounce<T extends Function>(func: T, timeout = 300) {
  let timer
  return (...args: any) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func(...args)
    }, timeout)
  }
}

export function debounceLeading<T extends Function>(func: T, timeout = 300) {
  let timer
  return (...args: any) => {
    if (!timer) {
      func(...args)
    }
    clearTimeout(timer)
    timer = setTimeout(() => {
      timer = undefined
    }, timeout)
  }
}
