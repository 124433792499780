/** @format */

const routeDescriptions = [
  // auth
  {
    key: 'Login',
    routerName: 'auth',
    isLeftPanel: false,
    isPlusButtonPopup: false,
    visibleInFooter: false,
    path: '/',
    title: '登录',
  },
  {
    key: 'ForgetPassword',
    routerName: 'auth',
    isLeftPanel: false,
    isPlusButtonPopup: false,
    visibleInFooter: false,
    path: '/forget-password',
    title: '忘记密码',
  },
  {
    key: 'Register',
    routerName: 'auth',
    isLeftPanel: false,
    isPlusButtonPopup: false,
    visibleInFooter: false,
    path: '/register',
    title: '注册',
  },
  // app - right
  {
    key: 'Welcome',
    routerName: 'app',
    isLeftPanel: false,
    isPlusButtonPopup: false,
    visibleInFooter: false,
    path: '/',
    title: '欢迎',
  },
  {
    key: 'Chat',
    routerName: 'app',
    isLeftPanel: false,
    isPlusButtonPopup: false,
    visibleInFooter: false,
    path: '/chat/:chat_id',
    title: '聊天',
  },
  {
    key: 'User',
    routerName: 'app',
    isLeftPanel: false,
    isPlusButtonPopup: false,
    visibleInFooter: false,
    path: '/user/:user_id',
    title: '好友名片',
  },
  {
    key: 'UpdateContact',
    routerName: 'app',
    isLeftPanel: false,
    isPlusButtonPopup: false,
    visibleInFooter: false,
    path: '/update-contact/:user_id',
    title: '设置备注',
  },
  {
    key: 'GroupChatDetail',
    routerName: 'app',
    isLeftPanel: false,
    isPlusButtonPopup: false,
    visibleInFooter: false,
    path: '/group-chat-detail/:chat_id',
    title: '群聊详情',
  },
  {
    key: 'AddGroupMember',
    routerName: 'app',
    isLeftPanel: false,
    isPlusButtonPopup: false,
    visibleInFooter: false,
    path: '/add-group-member/:chat_id',
    title: '加入联络人',
  },
  {
    key: 'RemoveGroupMember',
    routerName: 'app',
    isLeftPanel: false,
    isPlusButtonPopup: false,
    visibleInFooter: false,
    path: '/remove-group-member/:chat_id',
    title: '移除联络人',
  },
  {
    key: 'AddGroupChat',
    routerName: 'app',
    isLeftPanel: false,
    isPlusButtonPopup: true,
    visibleInFooter: false,
    path: '/add-group-chat',
    title: '发起群聊',
    icon: {
      type: 'mdi',
      name: 'plusBox',
    },
  },
  {
    key: 'AddContact',
    routerName: 'app',
    isLeftPanel: false,
    isPlusButtonPopup: true,
    visibleInFooter: false,
    path: '/add-contact',
    title: '添加好友',
    icon: {
      type: 'mdi',
      name: 'accountPlus',
    },
  },
  {
    key: 'BasicInfo',
    routerName: 'app',
    isLeftPanel: false,
    isPlusButtonPopup: false,
    visibleInFooter: false,
    path: '/basic-info',
    title: '个人信息',
  },
  {
    key: 'Avatar',
    routerName: 'app',
    isLeftPanel: false,
    isPlusButtonPopup: false,
    visibleInFooter: false,
    path: '/avatar',
    title: '修改头像',
  },
  {
    key: 'UpdateCurrentUserDisplayName',
    routerName: 'app',
    isLeftPanel: false,
    isPlusButtonPopup: false,
    visibleInFooter: false,
    path: '/update-current-user-display-name',
    title: '修改昵称',
  },
  {
    key: 'UpdateCurrentUserEmail',
    routerName: 'app',
    isLeftPanel: false,
    isPlusButtonPopup: false,
    visibleInFooter: false,
    path: '/update-current-user-email',
    title: '修改电子邮箱',
  },
  {
    key: 'CurrentUserQrcode',
    routerName: 'app',
    isLeftPanel: false,
    isPlusButtonPopup: false,
    visibleInFooter: false,
    path: '/current-user-qrcode',
    title: '我的二维码',
  },
  {
    key: 'GroupChatQrcode',
    routerName: 'app',
    isLeftPanel: false,
    isPlusButtonPopup: false,
    visibleInFooter: false,
    path: '/group-chat-qrcode/:chat_id',
    title: '群二维码',
  },
  {
    key: 'UpdateGroupTitle',
    routerName: 'app',
    isLeftPanel: false,
    isPlusButtonPopup: false,
    visibleInFooter: false,
    path: '/update-group-title/:chat_id',
    title: '修改群聊名称',
  },
  {
    key: 'UpdateCurrentUserGender',
    routerName: 'app',
    isLeftPanel: false,
    isPlusButtonPopup: false,
    visibleInFooter: false,
    path: '/update-current-user-gender',
    title: '修改性别',
  },
  {
    key: 'Setting',
    routerName: 'app',
    isLeftPanel: false,
    isPlusButtonPopup: false,
    visibleInFooter: false,
    path: '/setting',
    title: '设置',
  },
  {
    key: 'PrivacyAndSafety',
    routerName: 'app',
    isLeftPanel: false,
    isPlusButtonPopup: false,
    visibleInFooter: false,
    path: '/privacy-and-safety',
    title: '隐私安全',
  },
  {
    key: 'BlackList',
    routerName: 'app',
    isLeftPanel: false,
    isPlusButtonPopup: false,
    visibleInFooter: false,
    path: '/black-list',
    title: '黑名单',
  },
  // app - left
  {
    key: 'ChatList',
    routerName: 'app',
    visibleInFooter: true,
    isLeftPanel: true,
    isPlusButtonPopup: false,
    path: '/chat-list',
    title: '聊天',
    icon: {
      type: 'iyes',
      name: 'liaotian',
    },
  },
  {
    key: 'ContactList',
    routerName: 'app',
    visibleInFooter: true,
    isLeftPanel: true,
    isPlusButtonPopup: false,
    path: '/contact-list',
    title: '联系人',
    icon: {
      type: 'iyes',
      name: 'lianxiren',
    },
  },
  {
    key: 'FriendList',
    routerName: 'app',
    visibleInFooter: false,
    isLeftPanel: true,
    isPlusButtonPopup: false,
    path: '/friend-list',
    title: '新朋友',
  },
  {
    key: 'UserSetting',
    routerName: 'app',
    visibleInFooter: false,
    isLeftPanel: false,
    isPlusButtonPopup: false,
    path: '/user-setting/:user_id',
    title: '资料设置',
  },
  {
    key: 'UpdateUserDescription',
    routerName: 'app',
    visibleInFooter: false,
    isLeftPanel: false,
    isPlusButtonPopup: false,
    path: '/update-user-description/:user_id',
    title: '设置备注',
  },
  {
    key: 'Me',
    routerName: 'app',
    visibleInFooter: true,
    isLeftPanel: true,
    isPlusButtonPopup: false,
    path: '/me',
    title: '我',
    icon: {
      type: 'iyes',
      name: 'wode',
    },
  },
  {
    key: 'ForwardMessage',
    routerName: 'app',
    visibleInFooter: false,
    isLeftPanel: false,
    isPlusButtonPopup: false,
    path: '/forward-message/:chat_id/:message_id',
    title: '选择会话',
  },
  {
    key: 'Redirect',
    routerName: 'all',
    isLeftPanel: false,
    isPlusButtonPopup: false,
    visibleInFooter: false,
    path: '*',
    title: '跳转',
  },
];

export default routeDescriptions;
