import React, { Fragment } from "react";
import styled from "styled-components";
import ContactListItem from "./components/ContactListItem";
import { Contact } from "../../models";

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const Seperator = styled.div`
  color: ${({ theme }) => theme.colors.text};
  background-color: ${({ theme }) => theme.colors.inputBackground};
  padding: ${({ theme }) => theme.space[2]} ${({ theme }) => theme.space[3]};
  border: 0px solid ${({ theme }) => theme.colors.border};
  border-bottom-width: 1px;
`;

interface Props {
  selecteds?: Contact["user_id"][];
  data: { letter: string; data: Contact[] }[];
  onItemPress?: (contact: Contact) => void;
}

const ContactList: React.FC<Props> = ({ selecteds, data, onItemPress }) => {
  return (
    <Wrap>
      {data.map(({ letter, data }) => {
        return (
          <Fragment key={letter}>
            <Seperator>{letter}</Seperator>
            {data.map((item) => {
              return (
                <ContactListItem
                  key={item.id}
                  checked={
                    selecteds ? selecteds.includes(item.user_id) : void 0
                  }
                  item={item}
                  onItemPress={onItemPress}
                />
              );
            })}
          </Fragment>
        );
      })}
    </Wrap>
  );
};

export default ContactList;
