/** @format */

import { PageProps } from '../types';
import React, { useRef, useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import useNavigation from '../../hooks/useNavigation';
import PageWrap from '../components/PageWrap';
import PageHeader, { headerHeight } from '../components/PageHeader';
import { footerHeight } from '../components/PageFooter';
import PagePanelFooter from '../components/PagePanelFooter';
import FriendAccordion from './components/FriendAccordion';
import { default as ContactListComponent } from '../../components/ContactList';
import NormalInput from '../../components/NormalInput';
import { useStores } from '../../models';

const ScrollArea = styled.div`
  height: calc(100% - ${footerHeight} - ${headerHeight});
  overflow: scroll;
  scrollbar-width: auto; /* firefox */
  -ms-overflow-style: scrollbar; /* IE 10+ */
  ::-webkit-scrollbar {
    display: block;
  }
  ::-webkit-scrollbar-thumb {
    // /* google */
    background: #808080;
  }
`;

const ChoosePanelWrap = styled.div`
  background-color: ${({ theme }) => theme.colors.blockBackground};
  padding: ${({ theme }) => `${theme.space[2]} ${theme.space[4]}`};
`;

interface Props extends PageProps {}

const ContactList: React.FC<Props> = () => {
  const scrollAreaRef = useRef<HTMLDivElement>();
  const { navigate } = useNavigation();
  const { contactStore, contactListScrollTop, setContactListScrollTop } =
    useStores();
  const [memorizedScrollTop] = useState(contactListScrollTop)
  const [search, setSearch] = useState('');
  const [scrollAreaPaddingTop, setScrollAreaPaddingTop] = useState(0);

  const FriendOnItemPress = useCallback(() => {
    navigate('FriendList');
  }, [navigate]);

  const onItemPress = useCallback(
    (contact) => {
      navigate('User', { user_id: contact.user.id });
    },
    [navigate]
  );

  useEffect(() => {
    contactStore.fetchOnline();
  }, [contactStore]);

  useEffect(() => {
    const fixedOnHeaderBottomDom = document.getElementById(
      'fixed-on-header-bottom'
    );
    if (fixedOnHeaderBottomDom) {
      setScrollAreaPaddingTop(
        fixedOnHeaderBottomDom.getBoundingClientRect().height
      );
    } else {
      setScrollAreaPaddingTop(0);
    }
  }, []);

  useEffect(() => {
    if (scrollAreaRef?.current) {
      scrollAreaRef.current.scrollTop = memorizedScrollTop;
    }
  }, [scrollAreaRef, memorizedScrollTop]);

  return (
    <PageWrap>
      <PageHeader title="联系人" isBackButton={false} isPlusButton={true} />
      <div id="fixed-on-header-bottom">
        <ChoosePanelWrap>
          <NormalInput
            placeholder="请输入用户名"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          ></NormalInput>
        </ChoosePanelWrap>
        <FriendAccordion
          onItemPress={FriendOnItemPress}
          style={{ paddingTop: '0px' }}
        />
      </div>
      <ScrollArea
        ref={scrollAreaRef}
        style={{
          height: `calc(100% - ${footerHeight} - ${headerHeight} - ${scrollAreaPaddingTop}px)`,
        }}
        onScroll={(e) => {
          setContactListScrollTop(
            (e.nativeEvent.target as HTMLDivElement).scrollTop
          );
        }}
      >
        <ContactListComponent
          data={[...contactStore.byNameWithFilter(new RegExp(search, 'i'))]}
          onItemPress={onItemPress}
        />
      </ScrollArea>
      <PagePanelFooter />
    </PageWrap>
  );
};

export default observer(ContactList);
