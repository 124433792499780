import styled from "styled-components";
import { User } from "../../models";

const AtNicknameInner = styled.span`
  font-size: 0.8rem;
  color: ${({ theme }) => theme.colors.secondaryText};
`;

interface Props {
  user: User
}

const AtNickname: React.FC<Props> = ({ user }) => {
  return <AtNicknameInner>{user.atNickname}</AtNicknameInner>
}

export default AtNickname;