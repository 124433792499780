import { ValidationErrors, ValidationRules } from "./types"

const ValidateJS = require("validate.js")

function getEngAndNum(str) {
  if (str) {
    const reg = /[a-zA-Z0-9]/g
    const result = str.match(reg)
    if (result) {
      return str.match(reg).join("")
    } else {
      return ""
    }
  } else return ""
}

// HACK(steve): wierd typescript situation because of strange typings
const Validate: any = ValidateJS.default ? ValidateJS.default : ValidateJS

/**
 * Validates that 1 attribute doesn't appear in another's attributes content.
 */
Validate.validators.excludes = function custom(value, options, key, attributes) {
  const list = attributes[options.attribute] || []
  if (value && list.includes(value)) {
    return options.message || `${value} is in the list`
  }
}

/**
 * Validates that another attribute isn't true.
 */
Validate.validators.tripped = function custom(value, options, key, attributes) {
  if (value && attributes[options.attribute] === true) {
    return options.message || `${options.attribute} is true`
  }
}

Validate.validators.nickname = function custom(value, options) {
  if (value) {
    let l = 0;
    const engAndNumStr = getEngAndNum(value)
    const otherLength = value.length - engAndNumStr.length;
    l = otherLength * 2 + engAndNumStr.length;
    if (l > 18) {
      return options.message || `昵称超过长度`
    }
  }
}

Validate.validators.contact_nickname = function custom(value, options) {
  if (value) {
    let l = 0;
    const engAndNumStr = getEngAndNum(value)
    const otherLength = value.length - engAndNumStr.length;
    l = otherLength * 2 + engAndNumStr.length;
    if (l > 32) {
      return options.message || `备注超过长度`
    }
  }
}

Validate.validators.contact_note = function custom(value, options) {
  if (value) {
    let l = 0;
    const engAndNumStr = getEngAndNum(value)
    const otherLength = value.length - engAndNumStr.length;
    l = otherLength * 2 + engAndNumStr.length;
    if (l > 32) {
      return options.message || `描述超过长度`
    }
  }
}

/**
 * Runs the given rules against the data object.
 *
 * @param rules The rules to apply.
 * @param data The object to validate.
 */
export function validate(rules: ValidationRules, data: Record<string, unknown>): ValidationErrors {
  if (typeof data !== "object") {
    return {} as ValidationErrors
  }
  return Validate(data, rules, { fullMessages: false }) || {}
}

export const validateSingle = (rule, value) => {
  return ValidateJS.single(value, rule)
}

export const isEmpty = (val) => ValidateJS.isEmpty(val)
