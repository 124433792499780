/** @format */

import {
  PictureOutlined,
  VideoCameraOutlined,
  QrcodeOutlined,
  CloseOutlined,
  PlayCircleFilled,
  CameraFilled,
  AudioFilled,
  SmileOutlined,
  DownOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import useGlobalTheme from '../hooks/useGlobalTheme';

const antdIconMap = {
  down: DownOutlined,
  picture: PictureOutlined,
  videoCamera: VideoCameraOutlined,
  qrcode: QrcodeOutlined,
  close: CloseOutlined,
  play: PlayCircleFilled,
  camera: CameraFilled,
  audio: AudioFilled,
  smile: SmileOutlined,
  download: DownloadOutlined,
};

interface Props {
  name: string;
  size?: number;
  color?: string;
}

const AntdIcon: React.FC<Props> = ({ name, size, color, ...rest }) => {
  const theme = useGlobalTheme();
  const Component = antdIconMap[name];
  return (
    <Component
      style={{
        color: color || theme.colors.primary,
        width: `${size}rem`,
        height: `${size}rem`,
        fontSize: `${size}rem`,
      }}
      name={name}
      {...rest}
    ></Component>
  );
};

AntdIcon.defaultProps = {
  size: 1,
};

export default AntdIcon;
