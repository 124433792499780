/** @format */

import React from 'react';
import { observer } from 'mobx-react-lite';
import { Dialog } from 'antd-mobile';
import useGlobalTheme from '../../hooks/useGlobalTheme';
import useNavigation from '../../hooks/useNavigation';
import { useStores } from '../../models';
import PageWrap from '../components/PageWrap';
import PageHeader from '../components/PageHeader';
import Accordion from '../../components/Accordion';
import AccordionBootomGap from '../../components/AccordionBottomGap';
import Button from '../../widgets/Button';
import {
  Event,
  ContactDeleteData,
  EventNames,
  FLAG_ACK,
} from '../../proto/nbchat-proto';

const Setting: React.FC = () => {
  const theme = useGlobalTheme();
  const { navigate, params } = useNavigation();
  const { user_id } = params;
  const { contactStore, eventStore } = useStores();
  const contact = contactStore.byUserId(Number(user_id));

  const handleAlertRemoveContact = () => {
    Dialog.confirm({
      content: '将联系人删除,将同时删除与该联系人的聊天记录',
      onConfirm: handleSubmitRemoveContact,
    });
  };
  const handleSubmitRemoveContact = () => {
    const evt = new Event<ContactDeleteData>(EventNames.CONTACT_DELETE, {
      id: contact.id,
    });
    eventStore.publish(evt, { flags: FLAG_ACK });
    navigate('Welcome', null, { replace: true });
  };
  return (
    <PageWrap>
      <PageHeader title={'资料设置'}></PageHeader>
      {contact !== undefined && (
        <>
          <Accordion
            title="描述"
            onItemPress={() => navigate('UpdateUserDescription', { user_id })}
          />
          <AccordionBootomGap></AccordionBootomGap>
        </>
      )}
      {contact !== undefined && (
        <Button
          onClick={handleAlertRemoveContact}
          style={{
            color: theme.colors.danger,
          }}
        >
          删除
        </Button>
      )}
    </PageWrap>
  );
};

export default observer(Setting);
