import React, { Fragment } from "react";
import styled from "styled-components";
import FriendListItem from "./components/FriendListItem";
import { Friend } from "../../models";

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const Seperator = styled.div`
  color: ${({ theme }) => theme.colors.text};
  background-color: ${({ theme }) => theme.colors.inputBackground};
  padding: ${({ theme }) => theme.space[2]} ${({ theme }) => theme.space[3]};
  border: 0px solid ${({ theme }) => theme.colors.border};
  border-bottom-width: 1px;
`;

interface Props {
  data: { title: string; data: Friend[] }[];
  onItemPress?: (contact: Friend) => void;
}

const FriendList: React.FC<Props> = ({ data, onItemPress }) => {
  return (
    <Wrap>
      {data.map(({ title, data }) => {
        return (
          <Fragment key={title}>
            <Seperator>{title}</Seperator>
            {data.map((item) => {
              return (
                <FriendListItem
                  key={item.id}
                  item={item}
                  onItemPress={onItemPress}
                />
              );
            })}
          </Fragment>
        );
      })}
    </Wrap>
  );
};

export default FriendList;
