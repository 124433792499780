import { MessagePreviewMentionProps } from "../types";
import React from "react";
import useGlobalTheme from "../../../hooks/useGlobalTheme";
import PreviewWrap from "./preview-wrap";
import PreviewText from "./preview-text";

const MessageMention: React.FC<MessagePreviewMentionProps> = ({
  content,
  isUnread,
}) => {
  const theme = useGlobalTheme();
  return (
    <PreviewWrap>
      {isUnread && (
        <PreviewText color={theme.colors.danger}>[有人@我]</PreviewText>
      )}
      <PreviewText> {content} </PreviewText>
    </PreviewWrap>
  );
};

export default MessageMention;
