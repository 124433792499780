import styled from "styled-components";
import Input from "../../widgets/Input";

const StyledInput = styled(Input)``;

const NormalInput = (props) => {
  return (
    <StyledInput {...props} />
  );
};

export default NormalInput;
