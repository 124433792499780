import React from "react";
import useGlobalTheme from "../../../hooks/useGlobalTheme";
import Icon from "../../Icon";
import { UserGender } from "../../../proto/nbchat-proto";

interface Props {
  gender: UserGender;
}

const Gender: React.FC<Props> = ({ gender }) => {
  const theme = useGlobalTheme();
  return (
    <>
      {gender === UserGender.MALE && (
        <Icon
          type="fit"
          name="fi fi-male"
          size={0.8}
          color={theme.colors.primary}
        />
      )}
      {gender === UserGender.FEMALE && (
        <Icon
          type="fit"
          name="fi fi-female"
          size={0.8}
          color={'pink'}
        />
      )}
    </>
  );
};

export default Gender;
