/** @format */

import { Toast as AToast } from 'antd-mobile';

const Toast = AToast;
const ToastError = (content = '错误') => AToast.show({ icon: 'fail', content });
const ToastSuccess = (content = '成功') =>
  AToast.show({ icon: 'success', content });

export { Toast, ToastError, ToastSuccess };
