import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
// import { UploadResult } from "."
import { debug } from "../../utils";
import { APIError } from "./api-error";
import * as Types from "./api.types";

const TIMEOUT = 5 * 1000; // 网络请求超时时间
// const UPLOAD_TIMEOUT = 5 * 60 * 1000 // 上传超时时间
type Method = "GET" | "POST" | "PUT" | "DELETE" | "PATCH";

type RequestParams = {
  method: Method;
  url: string;
  data?: any;
  timeout?: number;
  headers?: {
    [key: string]: string;
  };
};

const codeMessage = {
  200: "服务器成功返回请求的数据。",
  201: "新建或修改数据成功。",
  202: "一个请求已经进入后台排队（异步任务）。",
  204: "删除数据成功。",
  400: "发出的请求有错误，服务器没有进行新建或修改数据的操作。",
  401: "用户没有权限（令牌、用户名、密码错误）。",
  403: "检测到账号异常，请联系客服",
  404: "发出的请求针对的是不存在的记录，服务器没有进行操作。",
  406: "请求的格式不可得。",
  410: "请求的资源被永久删除，且不会再得到的。",
  422: "Unprocessable Entity",
  429: "请求太频繁了",
  500: "服务器发生错误，请检查服务器。",
  502: "网关错误。",
  503: "服务不可用，服务器暂时过载或维护。",
  504: "网关超时。",
};

const platform = /Electron/ig.test(window.navigator.userAgent) ? 'desktop' : 'web';

// HTTP Client implementations
export class Client {
  /**
   * Configurable options.
   */
  headers = {
    Accept: "application/json",
    "RN-Platform-OS": platform,
  };

  // 设置 api 接口参数
  setup() {}

  // check response status
  async checkStatus(response: AxiosResponse) {
    if (response.status >= 200 && response.status <= 299) {
      return;
    }

    // throw error if response failed
    let errText = codeMessage[response.status] || response.statusText;
    let code = response.status;

    if ([400, 429].includes(response.status)) {
      try {
        const data = response.data;
        code = data.code;
        errText = data.message;
      } catch (e) {
        console.error(e);
      }
    }
    debug(`respCode: ${code}, respErr: ${errText}`);
    throw new APIError(errText, code);
  }

  async request<T>({
    method,
    url,
    data,
    timeout,
    headers,
    onUploadProgress,
  }: RequestParams & Partial<AxiosRequestConfig>) {
    const controller = new AbortController();
    const timerid = setTimeout(() => controller.abort(), timeout || TIMEOUT);
    const options: AxiosRequestConfig = {
      url,
      headers: { ...this.headers, ...headers },
      signal: controller.signal,
      method,
      onUploadProgress,
    };
    if (method === "POST") options.data = data;
    debug(`-->[${method}] ${url}`);
    let response;
    try {
      response = await axios(options);
    } catch (e) {
      response = e.response;
      debug("[Axios][Error]", e.message);
    } finally {
      clearTimeout(timerid);
      debug(`<--[${method}] ${url} ${response.status}`);
      await this.checkStatus(response);
    }
    return response.data as T;
  }

  // HTTP POST JSON 方法
  async json<T>(url: string, data: any): Promise<T> {
    return await this.request<T>({
      method: "POST",
      url,
      data: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  // HTTP POST 方法
  async post<T>(url: string, data: any): Promise<T> {
    return await this.request<T>({
      method: "POST",
      url,
      data,
    });
  }

  // HTTP GET 方法
  async get<T>(url: string, headers: RequestParams['headers'] = {}): Promise<T> {
    return await this.request<T>({ method: "GET", url, headers });
  }

  async webUpload<T>(
    url: string,
    formData: Types.UploadToS3StorageParams,
    onProgressUpdate
  ): Promise<T> {
    return await this.request<T>({
      method: "POST",
      headers: {
        "content-type": "multipart/form-data",
      },
      url,
      data: formData,
      timeout: 60000,
      onUploadProgress: onProgressUpdate,
    });
  }

  // 上传文件
  async upload<T>(
    url: string,
    { formData, onProgressUpdate }: Types.UploadParams
  ): Promise<T> {
    return await this.request<T>({
      method: "POST",
      headers: {
        "content-type": "multipart/form-data",
      },
      url,
      data: formData,
      timeout: 60000,
      onUploadProgress: onProgressUpdate,
    });
  }

  async uploadToS3Storage<T>(
    s3url: string,
    formData: Types.UploadToS3StorageParams,
    onProgressUpdate
  ): Promise<T> {
    return await this.request<T>({
      method: "POST",
      headers: {
        "content-type": "multipart/form-data",
      },
      url: s3url,
      data: formData,
      timeout: 60000,
      onUploadProgress: onProgressUpdate,
    });
  }

  async download({ url, onProgressUpdate }: Types.DownloadParams) {
    return await download({
      url,
      onProgress: ({ written, total }) => {
        onProgressUpdate && onProgressUpdate(written / total);
      },
    });
  }
}

function ensureFileScheme(path: string) {
  return path;
}

// 上传文件
export async function upload({
  url,
  path,
  mimeType,
  onProgress,
  method = "POST",
}) {
  let absoluteFilePath = ensureFileScheme(path);
  const req = {
    absoluteFilePath,
    url,
    mimeType,
    method,
    onProgress,
    multipartName: "file",
    returnResponse: true,
  };
  debug("upload request:", req);
  // const result = await BlobCourier.uploadBlob(req)
  // const { data } = result.response
  // return JSON.parse(data) as UploadResult
}

// 下载
export async function download({ url, onProgress, method = "GET" }) {
  debug(`downloading: ${url}`);
  // const { data } = await BlobCourier.fetchBlob({
  //   method,
  //   url,
  //   filename: basename(url),
  //   mimeType: "",
  //   onProgress,
  // })

  // return ensureFileScheme(data.absoluteFilePath)
}
