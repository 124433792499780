import { Routes, Route } from "react-router-dom";
import routes from "../routes";

const AuthRoutes = routes.filter(
  (route) => route.routerName === "auth" || route.routerName === "all"
);

const AuthRouter = () => {
  return (
    <Routes>
      {AuthRoutes.map(({ key, path, Component }) => {
        return <Route key={key} path={path} element={<Component />} />;
      })}
    </Routes>
  );
};

export default AuthRouter;
