import { encode } from "js-base64"
import { Instance, SnapshotOut, types, getRoot } from "mobx-state-tree"
import { UserGender, UserLastSeenData } from "../../proto/nbchat-proto"
import { numEnumeration } from "../../types/enum"
import { staticUrl } from "../../utils"
import { RootStoreModel } from ".."
/**
 * 用户模型，存储用户相关的数据
 */
export const UserModel = types
  .model("User")
  .props({
    id: types.identifierNumber,
    username: types.string,
    name: types.string,
    email: types.maybeNull(types.string),
    avatar: types.maybeNull(types.string),
    last_seen: types.maybe(types.Date),
    online: types.optional(types.boolean, false),
    gender: types.maybeNull(
      types.optional(
        numEnumeration<UserGender>("UserGender", Object.values(UserGender)),
        UserGender.MALE,
      ),
    ),
  })
  .views((self) => ({
    get displayName() {
      return self.name || self.username;
    },
    get img() {
      return self.avatar ? staticUrl(self.avatar) : ""
    },
    get qrcode() {
      const { configStore } = getRoot<Instance<typeof RootStoreModel>>(self)
      const path = "contact";
      const data = {
        path,
        id: self.id,
        username: self.username,
        name: self.name,
        avatar: self.avatar,
        gender: self.gender,
      }
      const t = JSON.stringify(data)
      const b = encode(t)
      return `${configStore.redirectURL}?appKey=${configStore.appKey}&path=${path}&data=${b}`
    },
    get atUsername() {
      return `账号:${self.username}`
    },
    get atNickname() {
      return `昵称:${self.name}`
    },
  }))
  .actions((self) => ({
    onLastSeen({ data }: { data: UserLastSeenData }) {
      self.online = data.online
      self.last_seen = new Date(data.last_seen)
    },
    update(values) {
      for (const key in values) {
        self[key] = values[key]
      }
    },
  }))

export type UserType = Instance<typeof UserModel>
export interface User extends UserType {}
type UserSnapshotType = SnapshotOut<typeof UserModel>
export interface UserSnapshot extends UserSnapshotType {}
export const createUserDefaultModel = () => types.optional(UserModel, {})
