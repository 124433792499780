import React from "react";
import styled from "styled-components";
import { formatMessageTime } from "../../utils";

const Text = styled.span`
  font-size: 0.7rem;
  color: ${({ theme }) => theme.colors.secondaryText};
`;

const Local = {
  Text: Text,
};

interface Props {
  time: Date;
}

const MessageTime: React.FC<Props> = ({ time }) => {
  return <Local.Text>{formatMessageTime(time)}</Local.Text>;
};

export default MessageTime;
