import React from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import useGlobalTheme from "../../hooks/useGlobalTheme";
import useNavigation from "../../hooks/useNavigation";
import PageWrap from "../components/PageWrap";
import PageHeader, { headerHeight } from "../components/PageHeader";
import Accordion from "../../components/Accordion";
import Avatar from "../../components/Avatar";
import Icon from "../../components/Icon";
import { useStores } from "../../models";
import { UserGender } from "../../proto/nbchat-proto";

const genderText = {
  [UserGender.MALE]: "男",
  [UserGender.FEMALE]: "女",
};

const ScrollArea = styled.div`
  height: calc(100% - ${headerHeight});
  overflow: scroll;
`;

const BasicInfo: React.FC = () => {
  const theme = useGlobalTheme();
  const { navigate } = useNavigation();
  const {
    userStore: { currentUser },
  } = useStores();

  return (
    <PageWrap>
      <PageHeader title="个人信息" isBackButton={true} />
      <ScrollArea>
        <Accordion title="头像" onItemPress={() => navigate("Avatar")}>
          <Avatar avatar={currentUser.avatar} name={currentUser.name} />
        </Accordion>
        <Accordion
          title="昵称"
          onItemPress={() => navigate("UpdateCurrentUserDisplayName")}
        >
          {currentUser.name}
        </Accordion>
        <Accordion title="账号">{currentUser.username}</Accordion>
        <Accordion
          title="电子邮箱"
          onItemPress={() => navigate("UpdateCurrentUserEmail")}
        >
          {currentUser.email}
        </Accordion>
        <Accordion
          title="我的二维码"
          onItemPress={() => navigate("CurrentUserQrcode")}
        >
          <Icon
            type="antd"
            name="qrcode"
            color={theme.colors.inputPlaceholderColor}
          ></Icon>
        </Accordion>
        <Accordion
          title="性别"
          onItemPress={() => navigate("UpdateCurrentUserGender")}
        >
          {genderText[currentUser.gender]}
        </Accordion>
      </ScrollArea>
    </PageWrap>
  );
};

export default observer(BasicInfo);
