/** @format */

import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Dialog } from "antd-mobile";
import useGlobalTheme from "../../hooks/useGlobalTheme";
import useNavigation from "../../hooks/useNavigation";
import PageWrap from "../components/PageWrap";
import PageHeader from "../components/PageHeader";
import UserProfile from "../../components/UserProfile";
import Accordion from "../../components/Accordion";
import AccordionBootomGap from "../../components/AccordionBottomGap";
import Icon from "../../components/Icon";
import { ToastSuccess } from "../../components/Toast";
import Button from "../../widgets/Button";
import Switch from "../../widgets/Switch";
import Text from "../../widgets/Text";
import { useStores } from "../../models";
import ReadToDeleteMenu from "../Chat/components/ReadToDeleteMenu";
import {
  Event,
  EventNames,
  FLAG_ACK,
  FriendCommonData,
  FriendStatus,
} from "../../proto/nbchat-proto";

const User: React.FC = () => {
  const theme = useGlobalTheme();
  const { navigate, params } = useNavigation();
  const { user_id } = params;
  const userId = Number(user_id);
  const {
    chatStore,
    contactStore,
    eventStore,
    userStore,
    friendStore,
    blackListStore,
  } = useStores();
  const [isBurningTimerSelectorModalShow, setIsBurningTimerSelectorModalShow] =
    useState(false);
  const chat = chatStore.getPrivateChatByUserID(userId);
  const user = userStore.users.get(`${user_id}`);
  const isBlack = !!blackListStore.black_list.get(`${userId}`);
  if (!user) return null;
  const contact = contactStore.byUserId(userId);
  const friend = friendStore.friendsByUserId(userId);
  friend && friendStore.onRead(friend.id);
  const handleCreatChat = () => {
    const chat = chatStore.newPrivateChat({
      receiver_id: user.id,
      name: user.displayName,
    });
    return chat;
  };
  const handleOpenChat = () => {
    const chat = handleCreatChat();
    chatStore.setCurrent(chat);
    navigate("Chat", { chat_id: chat.chat_id });
  };
  const handleFriendApprove = () => {
    const evt = new Event<FriendCommonData>(EventNames.FRIEND_APPROVE, {
      username: user.username,
    });
    eventStore.publish(evt, { flags: FLAG_ACK });
    navigate("ContactList", {}, { replace: true });
    ToastSuccess("已接受");
  };
  const handleInvite = () => {
    const evt = new Event<FriendCommonData>(EventNames.FRIEND_ADD, {
      username: user.username,
    });
    eventStore.publish(evt, { flags: FLAG_ACK });
    navigate("ContactList", {}, { replace: true });
    ToastSuccess("已发送");
  };
  const handleAddBlack = () => {
    blackListStore.publicAddBlacklist(userId);
  };
  const handleRemoveBlack = () => {
    blackListStore.publicRemoveBlacklist(userId);
  };
  const handlePromptBlack = () => {
    if (!isBlack) {
      Dialog.confirm({
        content: `是否将${user.name}加入黑名单，被加入黑名单的用户将无法向您发送消息`,
        onConfirm: handleAddBlack,
      });
    } else {
      handleRemoveBlack();
    }
  };
  return (
    <>
      <PageWrap>
        <PageHeader title="好友名片">
          <Icon
            type="mdi"
            name="dotsHorizontal"
            size={1.2}
            color="#333"
            onClick={() => navigate("UserSetting", { user_id })}
          ></Icon>
        </PageHeader>
        <UserProfile user={user} contact={contact}></UserProfile>
        {contact && (
          <Accordion
            title={contact.note ? "描述" : "设置备注"}
            onItemPress={() => navigate("UpdateContact", { user_id })}
          >
            {contact.note || ""}
          </Accordion>
        )}
        {chat && (
          <Accordion
            title="阅后即焚"
            onItemPress={() => {
              setIsBurningTimerSelectorModalShow(true);
            }}
          >
            {!!chat.burning_timer && <Text color={theme.colors.primaryText}>{chat.burning_timer}</Text>}
          </Accordion>
        )}
        <Accordion title="加入黑名单">
          <Switch checked={isBlack} onChange={handlePromptBlack} />
        </Accordion>
        <AccordionBootomGap></AccordionBootomGap>
        {contact && (
          <Button
            style={{
              color: theme.colors.primary,
            }}
            onClick={handleOpenChat}
          >
            发消息
          </Button>
        )}
        {friend && friend.status === FriendStatus.PENDING && (
          <Button
            style={{
              color: theme.colors.primary,
            }}
            onClick={handleFriendApprove}
          >
            接受邀请
          </Button>
        )}
        {!contact &&
          (!friend || friend?.status !== FriendStatus.PENDING) &&
          user.id !== userStore.currentUser.id && (
            <Button
              style={{
                color: theme.colors.primary,
              }}
              onClick={handleInvite}
            >
              添加到通讯录
            </Button>
          )}
      </PageWrap>
      {chat && (
        <ReadToDeleteMenu
          chat={chat}
          visible={isBurningTimerSelectorModalShow}
          setVisible={setIsBurningTimerSelectorModalShow}
        ></ReadToDeleteMenu>
      )}
    </>
  );
};

export default observer(User);
