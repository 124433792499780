/** @format */

import { autorun } from "mobx";
import { getRoot, Instance, SnapshotOut, types } from "mobx-state-tree";
import { debug } from "../../utils";
import { RootStoreModel } from "..";
import { WebScoketClient, WebSocketState } from "./client";
import { ToastError } from "../../components/Toast";

// socket 连接状态
export enum SocketStates {
  IDLE = 0,
  CONNECTING,
  CONNECTED,
  DISCONNECTED,
  RECONNECTING,
  CLOSED,
}

// interface WebSocketMessageEvent extends Event {
//   data?: any;
// }
interface WebSocketCloseEvent extends Event {
  code?: number;
  reason?: string;
  message?: string;
}

const socketStatesText: { [key in SocketStates]: string } = {
  [SocketStates.IDLE]: "等待连接",
  [SocketStates.CONNECTING]: "连接中",
  [SocketStates.CONNECTED]: "连接成功",
  [SocketStates.DISCONNECTED]: "连接断开",
  [SocketStates.RECONNECTING]: "重连中",
  [SocketStates.CLOSED]: "关闭",
};

// const RETRY_INTERVAL = 5000 // 重试间隔时间

export const WebsocketStoreModel = types
  .model("WebsocketStore")
  .volatile(() => ({
    error: "",
    state: SocketStates.IDLE,
    socketState: WebSocketState.CLOSED,
    online: true,
    socketID: 1,
  }))
  .views((self) => ({
    get url() {
      const { configStore, authStore } =
        getRoot<Instance<typeof RootStoreModel>>(self);
      return `${configStore.ws}?token=${authStore.token}`;
    },
    get closed() {
      return self.state === SocketStates.CLOSED;
    },
    get stateText() {
      return socketStatesText[self.state];
    },
    get opened() {
      return self.state === SocketStates.CONNECTED;
    },
    get ready() {
      return self.state === SocketStates.CONNECTED;
    },
    get loading() {
      return (
        self.state === SocketStates.CONNECTING ||
        self.state === SocketStates.RECONNECTING
      );
    },
    get hasError() {
      return self.error !== "";
    },
    get hasToken() {
      const { authStore } = getRoot<Instance<typeof RootStoreModel>>(self);
      return authStore.hasToken;
    },
  }))
  .actions((self) => ({
    setSocketID(id: number) {
      self.socketID = id;
    },
    setOnline(online: boolean) {
      self.online = online;
    },
    setError(err: string) {
      self.error = err;
    },
    clearError() {
      self.error = "";
    },
    setState(state: SocketStates) {
      self.state = state;
    },
    setSocketState(state: WebSocketState) {
      self.socketState = state;
    },
  }))
  .actions((self) => {
    const rootStore = getRoot<Instance<typeof RootStoreModel>>(self);
    const { channelStore } = rootStore;

    let disposer;
    let timerId;

    let interval = 2; // 2 seconds
    let tried = 0;

    function kick(message) {
      if (self.hasToken) {
        ToastError(message);
        rootStore.reset();
      }
    }

    function onOpen() {
      self.setSocketState(sock.state);
      self.setState(SocketStates.CONNECTED);
      // reset
      tried = 0;
      interval = 2;
    }

    function onData(blob: Uint8Array) {
      channelStore.ondata(blob);
    }

    function onClose(event: WebSocketCloseEvent) {
      console.log(event);
      self.setSocketState(sock.state);
      switch (event.code) {
        // 前后踢
        case 1000:
          kick("账号已在其他装置登录");
          self.setState(SocketStates.CLOSED);
          return;
        // 遭遇错误
        case 1006:
          self.setError("error");
          return;
        // 客户端主动断线
        case 1005:
          self.setState(SocketStates.CLOSED);
          return;
        default:
          self.setState(SocketStates.DISCONNECTED);
          return;
      }
    }

    function onError(e) {
      console.log(e);
    }

    function createSocketClient(index: number) {
      return new WebScoketClient({
        onOpen: () => {
          if (index === self.socketID) onOpen();
        },
        onData: (e) => {
          if (index === self.socketID) onData(e);
        },
        onClose: (e) => {
          if (index === self.socketID) {
            onClose(e);
          }
        },
        onError: (e) => {
          if (index === self.socketID) onError(e);
        },
      });
    }

    let sock = createSocketClient(self.socketID);

    function send(b: Uint8Array) {
      return sock.send(b);
    }

    function connect() {
      const nextSocketID = self.socketID + 1;
      self.setSocketID(nextSocketID);
      sock = createSocketClient(nextSocketID);
      self.setState(SocketStates.CONNECTING);
    }

    function close() {
      self.setState(SocketStates.CLOSED);
    }

    function reconnect() {
      self.setState(SocketStates.RECONNECTING);
    }

    // 基于状态机实现断线重连
    const onStateChange = (state: SocketStates) => {
      debug(`[SOCK] ST:${SocketStates[self.state]}`);
      // socket 状态机
      switch (state) {
        case SocketStates.CONNECTING:
          self.setError("");
          sock.connect(self.url);
          break;
        case SocketStates.CLOSED:
          clearInterval(timerId);
          sock.close();
          break;
        case SocketStates.DISCONNECTED:
          // 断开后，触发重连机制
          reconnect();
          break;
        case SocketStates.RECONNECTING:
          clearTimeout(timerId);
          const ms = Math.min(interval ** tried, 5) * 1000;
          debug(`trying to connect after ${ms}ms, tried: ${tried}`);
          timerId = setTimeout(connect, ms);
          tried += 1;
          break;
      }
    };

    function onlineCallback() {
      if (!self.online && self.hasToken && self.state === SocketStates.CLOSED) {
        reconnect();
      }
      self.setOnline(true);
    }

    function offlineCallback() {
      ToastError("网络连接不稳定，请检查网路环境");
      close();
      self.setOnline(false);
    }

    function afterCreate() {
      window.addEventListener("online", onlineCallback);
      window.addEventListener("offline", offlineCallback);
      disposer = autorun(() => {
        onStateChange(self.state);
      });
    }

    function beforeDestroy() {
      window.removeEventListener("online", onlineCallback);
      window.removeEventListener("offline", offlineCallback);
      close();
      disposer && disposer();
    }

    return {
      afterCreate,
      beforeDestroy,
      connect,
      reconnect,
      close,
      send,
      kick,
    };
  });

type WebsocketStoreType = Instance<typeof WebsocketStoreModel>;
export interface WebsocketStore extends WebsocketStoreType {}
type WebsocketStoreSnapshotType = SnapshotOut<typeof WebsocketStoreModel>;
export interface WebsocketStoreSnapshot extends WebsocketStoreSnapshotType {}
export const createWebsocketStoreDefaultModel = () =>
  types.optional(WebsocketStoreModel, {});
