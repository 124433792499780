import { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import PageFooter, { footerHeight } from "./PageFooter";
import routeDescriptions from "../../routers/routeDescriptions";
import OvalBadge from "../../components/OvalBadge";
import Icon from "../../components/Icon";
import { useStores } from "../../models";
import useNavigation from "../../hooks/useNavigation";

const LeftPanelFooterComponents = routeDescriptions.filter(
  (route) =>
    route.routerName === "app" && route.isLeftPanel && route.visibleInFooter
);

const FooterButton = styled.div`
  position: relative;
  width: 100%;
  height: ${footerHeight};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  font-weight: bold;
  cursor: pointer;
  border-width: 0px;
  border-right-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.border};
`;

const PagePanelFooter = () => {
  const { navigate } = useNavigation();
  const { messageStore, friendStore } = useStores();

  const [panelData, setPanelData] = useState(
    LeftPanelFooterComponents.reduce((acc, { key }) => {
      acc[key] = {};
      return acc;
    }, {})
  );

  useEffect(() => {
    setPanelData({
      ChatList: {
        badge: messageStore.unread,
      },
      ContactList: {
        badge: friendStore.friendsCount,
      },
    });
  }, [messageStore.unread, friendStore.friendsCount]);

  return (
    <PageFooter>
      {LeftPanelFooterComponents.map((panel) => {
        const d = panelData[panel.key];
        return (
          <FooterButton
            key={panel.key}
            onClick={() => {
              navigate(panel.key);
            }}
          >
            {!!d?.badge && <OvalBadge value={d.badge}></OvalBadge>}
            <Icon {...(panel.icon as any)} size={1.2}></Icon>
            {panel.title}
          </FooterButton>
        );
      })}
    </PageFooter>
  );
};

export default observer(PagePanelFooter);
