import React from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import useGlobalTheme from "../../../../../hooks/useGlobalTheme";
import { getMediaPath } from "../../../../../utils";
import { Message } from "../../../../../models";

const videoWidth = 224;

const AudioWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${videoWidth}px;
  margin: ${({ theme }) => theme.space[2]} ${({ theme }) => theme.space[1]};
`;

const ProgressBarWrap = styled.div`
  top: ${videoWidth / 4}px;
  left: ${videoWidth / 4}px;
  width: ${videoWidth / 4}px;
  height: ${videoWidth / 4}px;
`;

interface Props {
  message: Message;
}

const VideoMessage: React.FC<Props> = ({ message }) => {
  const theme = useGlobalTheme();
  const src = getMediaPath(message);
  return (
    <>
      <AudioWrap key={src}>
        {message.uploading ? (
          <>
            <ProgressBarWrap>
              <CircularProgressbar
                styles={buildStyles({
                  strokeLinecap: "round",
                  textSize: "1rem",
                  textColor: "transparent",
                  trailColor: theme.colors.blockBackground,
                  backgroundColor: theme.colors.primary,
                  pathColor: theme.colors.primary,
                })}
                value={message.progress}
                maxValue={1}
                text={(message.progress * 100).toFixed(0)}
              />
            </ProgressBarWrap>
          </>
        ) : (
          <audio
            controls={true}
            src={src}
            style={{
              width: "100%",
            }}
          />
        )}
      </AudioWrap>
    </>
  );
};
export default observer(VideoMessage);
