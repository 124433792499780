import styled from "styled-components";

const PreviewText = styled.span<{ color?: string }>`
  font-size: 0.8rem;
  color: ${({ color, theme }) => color || theme.colors.secondaryText};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
`;

export default PreviewText;
