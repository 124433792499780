/** @format */

import React, { useRef, useState, useEffect, useImperativeHandle } from 'react';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';
import Button from '../../../widgets/Button';
import Dialog from '../../../widgets/Dialog';
import { MessageTypes } from '../../../proto/nbchat-proto';
import { useStores } from '../../../models';
import { ToastError } from '../../../components/Toast';
import { Photo } from '../../../types/photo';

const Wrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

const ContentArea = styled.div`
  overflow: scroll;
  padding: 15px;
  flex: 1;
  background-color: ${({ theme }) => theme.colors.blockBackground};
  display: flex;
  justify-content: center;
`;

const Textarea = styled.textarea`
  flex: 1;
  height: 100%;
  resize: none;
  border: ${({ theme }) => theme.colors.border};
  background-color: ${({ theme }) => theme.colors.inputBackground};
  padding: ${({ theme }) => theme.space[2]} ${({ theme }) => theme.space[3]};
  :focus {
    outline-color: ${({ theme }) => theme.colors.primary};
  }
  ::placeholder {
    color: ${({ theme }) => theme.colors.inputPlaceholderColor};
  }
`;

const SendButton = styled(Button)`
  border-top: 0px;
  flex: 0.2;
  height: 100%;
`;

const ConfirmUploadImage = ({ src }) => {
  return (
    <ContentArea>
      <img
        alt="ConfirmUploadImage"
        src={src}
        style={{
          zIndex: 1000,
          width: '70%',
          maxWidth: '70%',
          height: 'auto',
          objectFit: 'contain',
        }}
      />
    </ContentArea>
  );
};

const Contents = ({ src, onEnter }) => {
  useEffect(() => {
    const detectEnterPress = (e) => {
      if (e.key === "Enter") {
        onEnter?.()
      }
    }
    document.addEventListener('keypress', detectEnterPress)
    return () => {
      document.removeEventListener('keypress', detectEnterPress)
    }
  }, [onEnter])
  return <ConfirmUploadImage src={src}></ConfirmUploadImage>;
}

const MessageInput = React.forwardRef(({
  chat,
  _send,
  value,
  onChange,
  onKeyPress,
  sendMessage,
}: any, forwardRef: React.Ref<HTMLTextAreaElement>) =>{
  const ref = useRef<HTMLTextAreaElement>(null);
  const {
    chatStore: { isReplyBarVisible, rightClickMenuMessageId },
  } = useStores();
  const [popupData, setPopupData] = useState({
    visible: false,
    src: '',
    photos: [],
  });
  const handleUploadImages = async (photos: Photo[]) => {
    const message = chat.newMessage({
      type: MessageTypes.IMAGES,
      content: '',
      attach: {
        imgs: photos,
      },
      reply_msg_id:
        isReplyBarVisible && rightClickMenuMessageId
          ? rightClickMenuMessageId
          : null,
    });
    _send(message);
  };

  const openPreviewModal = async (file) => {
    const arrayFiles = [file];
    try {
      const promiseArr = arrayFiles.map((file) => {
        const accepts = ['image/jpg', 'image/png', 'image/jpeg'];
        if (!accepts.includes(file.type))
          throw new Error('仅支持上传jpg,png,jpeg等格式');
        return new Promise<any>((resolve) => {
          const reader = new FileReader();
          reader.onload = (readerEvent) => {
            resolve({
              filename: file.name,
              type: file.type,
              path: readerEvent.target.result,
              size: file.size,
            });
          };
          reader.readAsDataURL(file);
        });
      });
      const photos = await Promise.all(promiseArr);
      setPopupData({
        visible: true,
        src: photos[0].path,
        photos,
      });
    } catch (e) {
      ToastError(e.message);
    }
  };

  const onDrop = (files) => {
    openPreviewModal(files[0]);
  };

  useImperativeHandle(forwardRef, () => ref.current);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  useEffect(() => {
    const textarea = ref?.current;
    const handlePaste = (e) => {
      if (e.clipboardData.files[0]) {
        e.preventDefault();
        openPreviewModal(e.clipboardData.files[0]);
        textarea.blur();
      }
    };
    textarea?.addEventListener('paste', handlePaste);
    return () => {
      textarea?.removeEventListener('paste', handlePaste);
    };
  }, []);

  return (
    <>
      <Wrap>
        <input style={{ display: 'none' }} {...getInputProps()} />
        <Textarea
          ref={ref}
          value={value}
          onChange={onChange}
          onDrop={getRootProps().onDrop}
          onKeyPress={onKeyPress}
          placeholder="请输入讯息"
        ></Textarea>
        <SendButton onClick={sendMessage}>发送</SendButton>
      </Wrap>
      <Dialog
        title="确认上传"
        visible={popupData.visible}
        Contents={() => {
          return <Contents src={popupData.src} onEnter={() => {
            handleUploadImages(popupData.photos);
            setPopupData({ visible: false, src: '', photos: [] });
          }}/>
        }}
        onClose={() => {
          setPopupData({ visible: false, src: '', photos: [] });
        }}
        onCancelButtonClick={() => {
          setPopupData({ visible: false, src: '', photos: [] });
        }}
        onConfirmButtonClick={() => {
          handleUploadImages(popupData.photos);
          setPopupData({ visible: false, src: '', photos: [] });
        }}
      />
    </>
  );
});

export default MessageInput;
