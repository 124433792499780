import styled from "styled-components";
import { QRCodeCanvas } from "qrcode.react";
import Avatar from "../Avatar";
import Gender from "../UserProfile/components/Gender";

const Wrap = styled.div`
  background-color: ${({ theme }) => theme.colors.blockBackground};
  padding: ${({ theme }) => theme.space[4]};
`;

const AvatarWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InfoWrap = styled.div`
  margin-left: 10px;
`;

const DisplayName = styled.span`
  font-size: 1.2rem;
  font-weight: bolder;
`;

const QRCodeWrap = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.space[2]} ${({ theme }) => theme.space[3]};
`;

const QRCodeCenterPic = styled.div`
  position: absolute;
  top: calc(50% - 25px);
  left: calc(50% - 25px);
  width: 50px;
  height: 50px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: bolder;
  background-color: ${({ theme }) => theme.colors.primary};
`;

const AddMeToContact = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.secondaryText};
  font-size: 1.2rem;
`;


const QrcodeDisplayer = ({
  id = "qrcode_displayer_id",
  avatar = "",
  gender = undefined,
  qrcode = "",
  size = 260,
  name = "",
  description = "",
}) => {
  const renderName = name ? name.split("")[0] : "";
  return (
    <Wrap>
      <AvatarWrap>
        <Avatar avatar={avatar} name={name} />
        <InfoWrap>
          <DisplayName>
            {name} {gender !== undefined && <Gender gender={gender} />}
          </DisplayName>
        </InfoWrap>
      </AvatarWrap>
      {qrcode && (
        <QRCodeWrap>
          <QRCodeCanvas
            id={id}
            value={qrcode}
            size={size}
            bgColor={"#ffffff"}
            fgColor={"#000000"}
            level={"L"}
            includeMargin={false}
            imageSettings={{
              src: "",
              x: undefined,
              y: undefined,
              height: 50,
              width: 50,
              excavate: true,
            }}
          />
          <QRCodeCenterPic>{renderName}</QRCodeCenterPic>
        </QRCodeWrap>
      )}
      {description && <AddMeToContact>{description}</AddMeToContact>}
    </Wrap>
  );
};

export default QrcodeDisplayer;
