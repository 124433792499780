import { MessagePreviewDraftProps } from "../types";
import React from "react";
import useGlobalTheme from "../../../hooks/useGlobalTheme";
import PreviewWrap from "./preview-wrap";
import PreviewText from "./preview-text";

const MessageDraft: React.FC<MessagePreviewDraftProps> = ({ draft }) => {
  const theme = useGlobalTheme();
  return (
    <PreviewWrap>
      <PreviewText color={theme.colors.danger}>[草稿]</PreviewText>
      <PreviewText> {draft} </PreviewText>
    </PreviewWrap>
  );
};
export default MessageDraft;
