export interface Theme {
  colors: {
    primary: string
    secondary: string
    success: string
    warning: string
    danger: string
    background: string
    blockBackground: string
    inputBackground: string
    inputPlaceholderColor: string
    border: string
    mask: string
    text: string
    primaryText: string
    secondaryText: string
  }
  space: string[]
  fontSizes: string[]
  radii: string[]
}

const theme: Theme = {
  colors: {
    primary: "#1b9cea",
    secondary: "#D6E8FB",
    success: "#97c21c",
    warning: "#f59a23",
    danger: "#DF2C42",
    background: "#F8FAFF",
    blockBackground: "#fff",
    inputBackground: "#F8FAFF",
    inputPlaceholderColor: "#9B9EA2",
    border: "#eee",
    mask: "rgba(0, 0, 0, 0.4)",
    text: "#333333",
    primaryText: "#4F96DF",
    secondaryText: "#9B9EA2",
  },
  fontSizes: ["10px", "12px", "14px", "16px", "18px", "20px", "24px"],
  space: ["0px", "4px", "8px", "16px", "24px", "32px"],
  radii: ["0px", "2px", "4px", "8px"],
}

export default theme;
