import { observer } from "mobx-react-lite";
import styled from "styled-components";
import useGlobalTheme from "../../../hooks/useGlobalTheme";
import OvalBadge from "../../../components/OvalBadge";
import Icon from "../../../components/Icon";
import { useStores } from "../../../models";

const Wrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.blockBackground};
  padding: ${({ theme }) => theme.space[3]} ${({ theme }) => theme.space[4]} }
  border: 0px solid ${({ theme }) => theme.colors.border};
  border-bottom-width: 1px;
  cursor: pointer;
`;

const AvatarWrap = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 10px;
  width: 40px;
  height: 40px;
`;

const Label = styled.span`
  font-size: 1rem;
  margin-left: 10px;
`;

const FriendAccordion = ({ style = {}, onItemPress }) => {
  const theme = useGlobalTheme();
  const { friendStore } = useStores();
  const count = friendStore.friendsCount;
  return (
    <Wrap onClick={() => onItemPress("FriendList")} style={style}>
      <AvatarWrap>
        <OvalBadge value={count} top="-10px" right="-10px" />
        <Icon
          type="mdi"
          name="accountPlus"
          size={1}
          color={theme.colors.blockBackground}
        />
      </AvatarWrap>
      <Label>新的朋友</Label>
    </Wrap>
  );
};

export default observer(FriendAccordion);
