import styled from "styled-components";

const Button = styled.button`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.border};
  background-color: ${({ theme }) => theme.colors.blockBackground};
  padding: ${({ theme }) => theme.space[3]} ${({ theme }) => theme.space[4]};
`;

export default Button;
