import React from "react";
import styled from "styled-components";
import theme from "../../theme";
import Button from "../../widgets/Button";
import Icon, { Props as IconProps } from "../Icon";

const Wrap = styled(Button)`
  justify-content: space-between;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
`;

const LeftWrap = styled.div`
  display: flex;
`;

const RightWrap = styled.div`
  display: flex;
`;

const IconWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TitleWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled.span`
  font-size: 0.8rem;
`;

const DescriptionWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.secondaryText};
`;

const Description = styled.span`
  font-size: 0.8rem;
`;

const RightArrowWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface Props {
  iconProps?: IconProps;
  title?: string;
  description?: string;
  onItemPress?: (e: React.MouseEvent) => void;
  children?: React.ReactNode;
}

const Accordion: React.FC<Props> = ({
  iconProps,
  title,
  onItemPress,
  children,
}) => {
  return (
    <Wrap onClick={(e) => onItemPress(e)}>
      <LeftWrap>
        <IconWrap>{!!iconProps && <Icon {...iconProps}></Icon>}</IconWrap>
        <TitleWrap>{!!title && <Title>{title}</Title>}</TitleWrap>
      </LeftWrap>
      <RightWrap>
        <DescriptionWrap>
          <Description>{children}</Description>
        </DescriptionWrap>
        <RightArrowWrap>
          {!!onItemPress && (
            <Icon
              type="ion"
              name="chevron-forward-outline"
              size={1}
              color={theme.colors.secondaryText}
            />
          )}
        </RightArrowWrap>
      </RightWrap>
    </Wrap>
  );
};

export default Accordion;
