import styled from "styled-components";
import useGlobalTheme from "../hooks/useGlobalTheme";
import Icon from "../components/Icon";
import Button from "./Button";
import Text from "./Text";

const Layout = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 5;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  animation: show 0.3s ease-in-out;
  @keyframes show {
    from {
      background: rgba(0, 0, 0, 0);
    }
    to {
      background: rgba(0, 0, 0, 0.3);
    }
  }
`;
const Box = styled.div`
  display: flex;
  justify-content: space-between;
  width: 90%;
  height: auto;
  flex-direction: column;
  background ${({ theme }) => theme.colors.blockBackground};
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  overflow: hidden;
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  background ${({ theme }) => theme.colors.primary};
  align-items: center;
  height: 40px;
  padding: 0 10px;
`;
const LeftHeader = styled.div`
  height: 40px;
  text-align: left;
  line-height: 40px;
`;
const RightHeader = styled.div`
  width: 20px;
  height: 20px;
`;
const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  overflow: scroll;
  max-height: 400px;
`;
const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-center;
  width: 90%;
  height: 60px;
`;

const Model = ({
  title = "主题",
  Contents,
  headStyle,
  onClose,
  buttonShow = true,
  cancelButtonText = "取消",
  onCancelButtonClick,
  confirmButtonText = "确定",
  onConfirmButtonClick,
}) => {
  const theme = useGlobalTheme();
  return (
    <Box onClick={(event) => event.stopPropagation()}>
      <Header style={headStyle}>
        <span style={{ width: "20px" }}></span>
        <LeftHeader>
          <Text color="white" fontSize="18px">
            {title}
          </Text>
        </LeftHeader>
        <RightHeader onClick={() => onClose && onClose()}>
          <Icon
            type="antd"
            name="close"
            color={theme.colors.blockBackground}
            size={1}
          />
        </RightHeader>
      </Header>
      <Content>
        <Contents />
        {buttonShow && (
          <ButtonBox>
            {onCancelButtonClick && (
              <Button
                style={{ color: theme.colors.danger }}
                onClick={() => {
                  onCancelButtonClick && onCancelButtonClick();
                }}
              >
                {cancelButtonText}
              </Button>
            )}
            {onConfirmButtonClick && (
              <Button
                style={{ color: theme.colors.primary }}
                onClick={() => {
                  onConfirmButtonClick && onConfirmButtonClick();
                }}
              >
                {confirmButtonText}
              </Button>
            )}
          </ButtonBox>
        )}
      </Content>
    </Box>
  );
};

interface DialogProps {
  visible: boolean;
  mask?: boolean;
  [key: string]: any;
}

const Dialog: React.FC<DialogProps> = ({ visible, ...rest }) => {
  if (!visible) return null;
  return (
    <Layout onClick={rest.onClose}>
      <Model {...(rest as any)} />
    </Layout>
  );
};

export default Dialog;
