import { reaction } from "mobx";
import { Instance, SnapshotOut, types, flow, getRoot } from "mobx-state-tree";
import { nanoid } from "nanoid"
import uaParser from "ua-parser-js"
import { RootStoreModel } from "..";
import {
  Event,
  UserProfile,
  FLAG_ACK,
  UpdateDeviceIDData,
  EventNames,
} from "../../proto/nbchat-proto";
import { withEnvironment } from "../extensions/with-environment";
const { browser, os } = uaParser(window.navigator.userAgent);
const device_name = `${os.name} ${os.version} ${browser.name} ${browser.version}`;
/**
 * AuthStore 认证令牌存储
 */
export const AuthStoreModel = types
  .model("AuthStore")
  .props({
    token: types.maybe(types.string), // 认证成功后的登录令牌
    deviceId: types.maybeNull(types.string),
    clientId: types.maybeNull(types.string), // 个推 CID
    haveBeenPassIntro: types.optional(types.boolean, false),
    haveBeenPassEmailCheck: types.optional(types.boolean, false),
    userID: types.maybeNull(types.number),
  })
  .extend(withEnvironment)
  .views((self) => ({
    get authenticated() {
      return self.token !== undefined && self.token !== "";
    },
    get hasToken() {
      return self.token !== undefined;
    },
    get initialRouteName() {
      return self.haveBeenPassIntro ? "Login" : "AppIntro";
    },
  }))
  .actions((self) => {
    const { apiStore, userStore, websocketStore, eventStore, setSyncFinished } =
      getRoot<Instance<typeof RootStoreModel>>(self);

    const setDeviceId = (id) => {
      self.deviceId = id;
    };

    const setClientId = (cid: string) => {
      self.clientId = cid;
    };

    const setHaveBeenPassIntro = (truthy: boolean) => {
      self.haveBeenPassIntro = truthy;
    };
    const setHaveBeenPassEmailCheck = (truthy: boolean) => {
      self.haveBeenPassEmailCheck = truthy;
    };
    const clearUser = () => {
      self.token = undefined;
      self.userID = null;
      setSyncFinished(false);
    };

    const login = ({ token, user }: { token: string; user: UserProfile }) => {
      const curUser = userStore.addUser(user);
      userStore.setCurrentUser(curUser);
      self.token = token;
      self.userID = user.id;
    };

    // 登出
    const logout = () => {
      clearUser();
      websocketStore.close();
      getRoot<Instance<typeof RootStoreModel>>(self).reset();
    };

    const httpLogin = flow(function* httpLogin(username, password) {
      let device_id = localStorage.getItem(`device_id_${username}`);
      if (!device_id) {
        device_id = nanoid();
        localStorage.setItem(`device_id_${username}`, device_id);
      }
      const data = yield apiStore.login({ username, password, device_id, device_name });
      login(data);
    });

    const httpRegister = flow(function* httpLogin(username, password, name, email) {
      let device_id = localStorage.getItem(`device_id_${username}`);
      if (!device_id) {
        device_id = nanoid();
        localStorage.setItem(`device_id_${username}`, device_id);
      }
      const data = yield apiStore.register({ username, password, name, email, device_id, device_name });
      login(data);
    });

    const httpPing = flow(function* httpLogin() {
      return yield apiStore.ping({ token: self.token });
    });

    const publicLogout = flow(function* publicLogout() {
      const evt = new Event(EventNames.USER_LOGOUT, {});
      eventStore.publish(evt, { flags: FLAG_ACK });
      yield new Promise<void>((resolve) => {
        setTimeout((s) => {
          (s as any).logout();
          resolve();
        }, 1000, self);
      });
    });

    function publicUpdateClientID() {
      const evt = new Event<UpdateDeviceIDData>(EventNames.UPDATE_DEVICE_ID, {
        device_id: self.clientId,
      });
      eventStore.publish(evt, { flags: FLAG_ACK });
    }

    function afterCreate() {
      reaction(
        () => self.clientId,
        (value) => {
          if (value === null) return;
          publicUpdateClientID();
        },
        { fireImmediately: true }
      );
    }

    return {
      login,
      logout,
      clearUser,
      setDeviceId,
      setClientId,
      setHaveBeenPassIntro,
      setHaveBeenPassEmailCheck,
      afterCreate,
      httpLogin,
      httpRegister,
      httpPing,
      publicLogout,
      publicUpdateClientID,
    };
  });

type AuthStoreType = Instance<typeof AuthStoreModel>;
export interface AuthStore extends AuthStoreType {}
type AuthStoreSnapshotType = SnapshotOut<typeof AuthStoreModel>;
export interface AuthStoreSnapshot extends AuthStoreSnapshotType {}
export const createAuthStoreDefaultModel = () =>
  types.optional(AuthStoreModel, {});
