/** @format */

import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import useImageUpload from '../../hooks/useImageUpload';
import PageWrap from '../components/PageWrap';
import PageHeader, { headerHeight } from '../components/PageHeader';
import { ToastError, ToastSuccess } from '../../components/Toast';
import Image from '../../widgets/Image';
import { useStores } from '../../models';
import { staticUrl } from '../../utils';

const ScrollArea = styled.div`
  height: calc(100% - ${headerHeight});
  overflow: scroll;
`;

const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
`;

const AvatarImage = styled(Image)`
  max-width: 300px;
  max-height: 300px;
`;

const SubmitButtonArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 10px;
`;

const SubmitText = styled.span`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary};
`;

const Avatar: React.FC = () => {
  const { uploadImage } = useImageUpload();
  const { userStore } = useStores();
  const user = userStore.currentUser;

  const handleUpdateUserAvatar = async () => {
    const { imageUrl, errorMsg } = await uploadImage();
    if (errorMsg) ToastError(errorMsg);
    else {
      userStore.publicCurrentUserUpdate({ avatar: imageUrl });
      ToastSuccess('上传成功!');
    }
  };

  return (
    <PageWrap>
      <PageHeader title="修改头像" isBackButton={true} />
      <ScrollArea>
        <FlexCenter>
          <div>
            <AvatarImage
              src={staticUrl(user.avatar)}
              alt="current-user-avatar"
              onError={(e) => {
                ToastError('头像链接失效，请重新上传!');
              }}
            />
          </div>
        </FlexCenter>
        <SubmitButtonArea>
          <SubmitText onClick={handleUpdateUserAvatar}>修改</SubmitText>
        </SubmitButtonArea>
      </ScrollArea>
    </PageWrap>
  );
};

export default observer(Avatar);
