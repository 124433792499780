import React from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import TextMessage from "./TextMessage";
import ImagesMessage from "./ImagesMessage";
import VideoMessage from "./VideoMessage";
import VoiceMessage from "./VoiceMessage";
import FileMessage from "./FileMessage";
import { Message } from "../../../../../models";
import { MessageTypes } from "../../../../../proto/nbchat-proto";
import { formatMentionId } from "../../../../../utils";
import { useStores } from "../../../../../models";

const Container = styled.div`
  width: 100%;
  word-break: break-all;
`;

const Content: React.FC<{ message: Message }> = ({ message }) => {
  const { userStore } = useStores();
  const content = formatMentionId(message?.content, message.mention_ids, userStore)
  const renderContent = () => {
    switch (message.type) {
      case MessageTypes.TEXT:
        return <TextMessage formatedContent={content} />;
      case MessageTypes.IMAGES:
        return <ImagesMessage message={message} />;
      case MessageTypes.VIDEO:
        return <VideoMessage message={message} />;
      case MessageTypes.VOICE:
          return <VoiceMessage message={message} />;
      case MessageTypes.FILE:
        return <FileMessage message={message} />;
      default:
        return <div>当前版本不支持此消息格式</div>;
    }
  };
  return <Container>{renderContent()}</Container>;
};

export default observer(Content);
