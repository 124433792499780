/** @format */

import { observer } from "mobx-react-lite";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { PageProps } from "../types";
import { useStores } from "../../models";
import useGlobalTheme from "../../hooks/useGlobalTheme";
import useNavigation from "../../hooks/useNavigation";
import { validate, isEmpty, rules } from "../../refactor-utils/validator";
import PageWrap from "../components/PageWrap";
import NormalInput from "../../components/NormalInput";
import NormalButton from "../../components/NormalButton";
import CheckBox from "../../components/CheckBox";
import PrivacyDialog from "../../components/PrivacyDialog";
import { ToastError } from "../../components/Toast";
import ServiceContractDialog from "../../components/ServiceContractDialog";
import { saveString, loadString } from "../../utils/storage";
import { encrypt, decrypt } from "../../utils/crypto";
const loginLogo = require("../../assets/images/login-logo.png");

const Page = styled.div<{ isMobile: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.secondary};
  background-color: ${({ theme, isMobile }) =>
    isMobile ? theme.colors.primary : theme.colors.secondary};
`;

const FormWrap = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  width: ${({ isMobile }) => `${isMobile ? 100 : 60}%`};
  max-width: 500px;
  min-width: 320px;
  min-height: 400px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
`;

const TopArea = styled.div`
  position: relative;
  width: 100%;
  height: 160px;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: ${({ theme }) => theme.radii[3]};
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
`;

const Form = styled.div`
  display: flex;
  width: 100%;
  height: 360px;
  flex-direction: column;
  justify-content: space-around;
  padding: ${({ theme }) => theme.space[4]};
  border: 1px solid ${({ theme }) => theme.colors.border};
  background-color: ${({ theme }) => theme.colors.blockBackground};
  border-radius: ${({ theme }) => theme.radii[3]};
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
`;

const LoginLogo = styled.img`
  position: absolute;
  z-index: 2;
  top: ${({ theme }) => theme.space[2]};
  right: ${({ theme }) => theme.space[2]};
  width: 120px;
  height: 120px;
`;

const MainTitle = styled.h1`
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.blockBackground};
  margin-top: ${({ theme }) => theme.space[4]};
  margin-left: ${({ theme }) => theme.space[4]};
  margin-bottom: ${({ theme }) => theme.space[2]};
`;

const Title = styled.h1`
  font-size: 1.3rem;
  margin: 0px;
  margin-bottom: ${({ theme }) => theme.space[2]};
`;

const ForgetPasswordArea = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ButtonArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
`;

const AgreeArea = styled(ButtonArea)`
  justify-content: flex-start;
`;


const Text = styled.span<{ color?: string; fontSize?: string }>`
  color: ${({ color, theme }) => color || theme.colors.text};
  font-size: ${({ fontSize }) => fontSize || "0.8rem"};
`;

interface Props extends PageProps {}

const Login: React.FC<Props> = observer(() => {
  const theme = useGlobalTheme();
  const { navigate } = useNavigation();
  const { authStore, isMobile, configStore } = useStores();
  const [formErr, setFormErr] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isAgreePrivacy, setIsAgreePrivacy] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isPrivacyModalVisible, setIsPrivacyModalVisible] = useState(false);
  const [isServiceContractModalVisible, setIsServiceContractModalVisible] =
    useState(false);

  async function onLogin() {
    setLoading(true);
    try {
      await authStore.httpLogin(username, password);
      await saveString("u", await encrypt({ value: username }));
      await saveString("p", await encrypt({ value: password }));
    } catch (e) {
      ToastError(e.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    const preload = async () => {
      const u = await loadString("u");
      const p = await loadString("p");
      if (u) setUsername(await decrypt({ encryptValue: u }));
      if (p) setPassword(await decrypt({ encryptValue: p }));
    };
    preload();
  }, []);

  useEffect(() => {
    setFormErr(
      !isEmpty(
        validate(
          { username: rules.username, password: rules.password },
          { username, password }
        )
      )
    );
  }, [username, password]);

  return (
    <PageWrap>
      <Page isMobile={isMobile}>
        <FormWrap isMobile={isMobile}>
          <TopArea>
            <MainTitle>欢迎來到，</MainTitle>
            <MainTitle>{configStore.appName}IM</MainTitle>
            <LoginLogo src={loginLogo} />
          </TopArea>
          <Form>
            <Title>登录</Title>
            <NormalInput
              label="账号"
              placeholder="请输入账号"
              value={username}
              validateRule={rules.username}
              onChange={(e) => {
                setUsername(e.target.value);
              }}
            />
            <NormalInput
              type="password"
              label="密码"
              validateRule={rules.password}
              placeholder="请输入密码"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            <ForgetPasswordArea>
              <Text
                style={{ cursor: "pointer" }}
                color={theme.colors.primary}
                onClick={() => {
                  navigate("ForgetPassword");
                }}
              >
                忘记密码?
              </Text>
            </ForgetPasswordArea>
            <AgreeArea>
              <CheckBox
                checked={isAgreePrivacy}
                value={true}
                onPress={() => setIsAgreePrivacy(!isAgreePrivacy)}
              />
              <Text>我已阅读并同意</Text>
              <Text
                style={{ cursor: "pointer" }}
                color={theme.colors.primary}
                onClick={() => {
                  setIsServiceContractModalVisible(true);
                }}
              >
                《服务协议》
              </Text>
              <Text>和</Text>
              <Text
                style={{ cursor: "pointer" }}
                color={theme.colors.primary}
                onClick={() => {
                  setIsPrivacyModalVisible(true);
                }}
              >
                《隐私政策》
              </Text>
            </AgreeArea>
            <ButtonArea>
              <NormalButton disabled={formErr || !isAgreePrivacy || loading} onClick={onLogin}>
                登录
              </NormalButton>
            </ButtonArea>
            <ButtonArea>
              <Text>没有账号？</Text>
              <Text
                style={{ cursor: "pointer" }}
                color={theme.colors.primary}
                onClick={() => {
                  navigate("Register");
                }}
              >
                去注册
              </Text>
            </ButtonArea>
          </Form>
        </FormWrap>
        <ServiceContractDialog
          visible={isServiceContractModalVisible}
          setVisible={setIsServiceContractModalVisible}
          setAgree={setIsAgreePrivacy}
        ></ServiceContractDialog>
        <PrivacyDialog
          visible={isPrivacyModalVisible}
          setVisible={setIsPrivacyModalVisible}
          setAgree={setIsAgreePrivacy}
        ></PrivacyDialog>
      </Page>
    </PageWrap>
  );
});
export default Login;
