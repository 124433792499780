import IconList from "@reacticons/ionicons/lib/components/iconList.json";
import { default as Icon } from "@reacticons/ionicons";
import useGlobalTheme from "../hooks/useGlobalTheme";

export interface Props {
  name: keyof typeof IconList;
  size?: number;
  color?: string;
}

const IONIcon: React.FC<Props> = ({ name, size, color, ...rest }) => {
  const theme = useGlobalTheme();
  return (
    <Icon
      style={{
        color: color || theme.colors.primary,
        width: `${size}rem`,
        height: `${size}rem`,
      }}
      name={name}
      {...rest}
    />
  );
};

IONIcon.defaultProps = {
  size: 0.8,
};

export default IONIcon;
