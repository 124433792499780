import { useCallback, useMemo } from "react";
import {
  useNavigate as useReactRouterUseNavigate,
  useParams as useReactRouterUseParams,
} from "react-router-dom";
import routeDescriptions from "../routers/routeDescriptions";
import { useStores } from "../models";

const useNavigation = () => {
  const { isMobile, setPanel } = useStores();
  const n = useReactRouterUseNavigate();
  const params = useReactRouterUseParams();

  const isPCLeftPanelRoutesKeys = useMemo(
    () =>
      routeDescriptions
        .filter((route) => route.isLeftPanel)
        .map(({ key }) => key),
    []
  );

  const navigate = useCallback(
    (pageKey, p?, navigateConfig?) => {
      p = p ? p : {};
      const matchRoute = routeDescriptions.find(
        (route) => route.key === pageKey
      );
      if (matchRoute) {
        let path = matchRoute.path;
        Object.keys(p).forEach((k) => {
          const reg = new RegExp(`:${k}`, "ig");
          path = path.replace(reg, p[k]);
        });
        if (!isMobile && isPCLeftPanelRoutesKeys.includes(pageKey)) {
          setPanel(pageKey);
        } else {
          n(path, navigateConfig);
        }
      } else {
        console.error("not match any route with key:", pageKey);
      }
    },
    [isMobile, setPanel, isPCLeftPanelRoutesKeys, n]
  );

  const goBack = useCallback(
    (num) => {
      n(num);
    },
    [n]
  );

  return {
    n,
    navigate,
    params,
    goBack,
  };
};

export default useNavigation;
