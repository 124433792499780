import styled from "styled-components";
import useGlobalTheme from "../../hooks/useGlobalTheme";
import Dialog from "../../widgets/Dialog";
import { useStores } from "../../models";

const Box = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  height: 100%;
`;

const ContentArea = styled.div`
  overflow: scroll;
  padding: 15px;
  flex: 1;
  background-color: ${({ theme }) => theme.colors.blockBackground};
`;

const Description = styled.div<{ color: string }>`
  color: ${({ color }) => color};
  background-color: ${({ theme }) => theme.colors.blockBackground};
  padding-bottom: 20px;
`;

const Contents = () => {
  const theme = useGlobalTheme();
  const { configStore } = useStores();
  return (
    <Box>
        <ContentArea>
          <Description color={theme.colors.secondaryText}>隐私政策</Description>
          <Description color={theme.colors.secondaryText}>
            发布及生效时间：2023年1月14日
          </Description>
          <Description color={theme.colors.secondaryText}>更新时间：2023年1月14日</Description>
          <Description color={theme.colors.secondaryText}>
            公司名称：大连佰祥通信息技术有限公司
          </Description>
          <Description color={theme.colors.secondaryText}>
            注册地址/常用办公联系地址：辽宁省大连市甘井子区黄浦路512号20层1号733
          </Description>
          <Description color={theme.colors.secondaryText}>
            负责人联系电话/邮箱：cdnlyweb@gmail.com
          </Description>
          <Description color={theme.colors.secondaryText}>
            本政策仅适用于大连佰祥通信息技术有限公司提供的产品和服务及其延伸的功能（以下简称“{configStore.appName} IM”）。如我们提供的某款产品有单独的隐私政策或相应的用户服务协议当中存在特殊约定，则该产品的隐私政策将优先适用；该款产品隐私政策和用户服务协议未涵盖的内容，以本政策内容为准。除本隐私权政策另有规定外，在未征得您事先许可的情况下，本系统不会将这些信息对外披露或向第三方提供。本系统会不时更新本隐私权政策。
            您在同意本系统服务使用协议之时，即视为您已经同意本隐私权政策全部内容。本隐私权政策属于本系统服务使用协议不可分割的一部分。
          </Description>
          <Description color={theme.colors.secondaryText}>
            本政策适用于“{configStore.appName} IM”提供的所有产品和服务。在您使用“{configStore.appName} IM”各项产品或者服务前，请您务必仔细阅读并透彻理解本政策，特别是以粗体/粗体下划线标识的条款，您应重点阅读，确保您充分理解和同意后再开始使用。本政策中涉及的专业词汇，我司尽量以简明通俗的表述向您解释，以便于您理解。
          </Description>
          <Description color={theme.colors.secondaryText}>
            本隐私政策将帮助您了解以下内容
          </Description>
          <Description color={theme.colors.secondaryText}>一、定义及适用范围</Description>
          <Description color={theme.colors.secondaryText}>
            二、我司如何收集和使用您的个人信息
          </Description>
          <Description color={theme.colors.secondaryText}>
            三、我司如何使用Cookie和同类技术
          </Description>
          <Description color={theme.colors.secondaryText}>
            四、我司如何共享、转让、公开披露您的信息
          </Description>
          <Description color={theme.colors.secondaryText}>
            五、我司如何保护、存储您的信息
          </Description>
          <Description color={theme.colors.secondaryText}>
            六、如何访问和管理自己的信息、信息安全
          </Description>
          <Description color={theme.colors.secondaryText}>七、其他</Description>
          <Description color={theme.colors.secondaryText}>一、适用范围</Description>
          <Description color={theme.colors.secondaryText}>
            a) 在您注册本系统帐号时，您根据本系统要求提供的个人注册信息（包括手机号码）；{" "}
          </Description>
          <Description color={theme.colors.secondaryText}>
            b)
            在您使用本系统网络服务，或访问本系统平台网页时，本系统自动接收并记录的您的浏览器和计算机上的信息，包括但不限于您的IP地址、浏览器的类型、使用的语言、访问日期和时间、软硬件特征信息及您需求的网页记录等数据；
          </Description>
          <Description color={theme.colors.secondaryText}>
            c) 本系统通过合法途径从商业伙伴处取得的用户个人数据。
          </Description>
          <Description color={theme.colors.secondaryText}>
            d）位置信息：您使用{configStore.appName} IM提供位置服务时，我们需要收集和处理您的位置信息。
          </Description>
          <Description color={theme.colors.secondaryText}>
            e）设备信息：为了保证{configStore.appName} IM服务的正常运行，向您提供问题诊断或设备检测服务，我们会收集您的硬件型号、操作系统版本、设备配置、唯一设备标识符、网络设备硬件地址MAC、设备连接信息以及设备状态信息等。基于不同的系统，设备信息表述文字有所不同。
          </Description>
          <Description color={theme.colors.secondaryText}>f）设备权限收集说明：</Description>
          <Description color={theme.colors.secondaryText}>
            1.电话权限(获取手机识别码
            ):您登录本平台识别您的设备身份，需要调用您的电话权限获取此项信息，除此之外不会基于其他用途进行调用。
          </Description>
          <Description color={theme.colors.secondaryText}>
            2.存储权限(读写存储):为了存储必要的应用配置文件以及您所选择下载、更新的内容、报表，或读取您需要上传、同步的内容，需要调用您的存储权限。
          </Description>
          <Description color={theme.colors.secondaryText}>
            3.相机权限:当您使用拍摄或扫描相关功能时，我们需要调用您的相机权限。
          </Description>
          <Description color={theme.colors.secondaryText}>
            4.相册权限：当您使用更换头像或者意见反馈填写功能时，我们需要调用您的相册获取图片权限填写。
          </Description>
          <Description color={theme.colors.secondaryText}>
            您了解并同意，以下信息不适用本隐私权政策：
          </Description>
          <Description color={theme.colors.secondaryText}>
            a) 您在使用本系统平台提供的搜索服务时输入的关键字信息；
          </Description>
          <Description color={theme.colors.secondaryText}>
            b)
            本系统收集到的您在本系统发布的有关信息数据，包括但不限于参与活动、成交信息及评价详情；{" "}
          </Description>
          <Description color={theme.colors.secondaryText}>
            c) 违反法律规定或违反本系统规则行为及本系统已对您采取的措施。{" "}
          </Description>
          <Description color={theme.colors.secondaryText}>二、信息使用</Description>
          <Description color={theme.colors.secondaryText}>
            a)
            本系统不会向任何无关第三方提供、出售、出租、分享或交易您的个人信息，除非事先得到您的许可，或该第三方和本系统（含本系统关联公司）单独或共同为您提供服务，且在该服务结束后，其将被禁止访问包括其以前能够访问的所有这些资料。
          </Description>
          <Description color={theme.colors.secondaryText}>
            b)
            本系统亦不允许任何第三方以任何手段收集、编辑、出售或者无偿传播您的个人信息。任何本系统平台用户如从事上述活动，一经发现，本系统有权立即终止与该用户的服务协议。
          </Description>
          <Description color={theme.colors.secondaryText}>
            c)
            为服务用户的目的，本系统可能通过使用您的个人信息，向您提供信息，包括但不限于向您发出产品和服务信息，或者与本系统合作伙伴共享信息以便他们向您发送有关其产品和服务的信息（后者需要您的事先同意）。{" "}
          </Description>
          <Description color={theme.colors.secondaryText}>
            d）为了向用户提供更好的服务或产品，本系统可能会在下述情形使用用户的个人信息：
          </Description>
          <Description color={theme.colors.secondaryText}>根据相关法律法规的要求；</Description>
          <Description color={theme.colors.secondaryText}>根据用户的授权；</Description>
          <Description color={theme.colors.secondaryText}>
            根据本系统相关服务条款、应用许可使用协议的约定。
          </Description>
          <Description color={theme.colors.secondaryText}>
            e）为您提供信息或服务，向您推送最新资讯内容。若您拒绝或关闭设备信息后，我们向您推送的信息会降低精准度或相关度，但不影响您正常使用其他不需要该权限的功能。
          </Description>
          <Description color={theme.colors.secondaryText}>三、Cookie的使用 </Description>
          <Description color={theme.colors.secondaryText}>
            a)
            在您未拒绝接受cookies的情况下，本系统会在您的计算机上设定或取用cookies，以便您能登录或使用依赖于cookies的本系统平台服务或功能。本系统使用cookies可为您提供更加周到的服务，包括推广服务。{" "}
          </Description>
          <Description color={theme.colors.secondaryText}>
            b)
            您有权选择接受或拒绝接受cookies。您可以通过修改浏览器设置的方式拒绝接受cookies。但如果您选择拒绝接受cookies，则您可能无法登录或使用依赖于cookies的本系统网络服务或功能。{" "}
          </Description>
          <Description color={theme.colors.secondaryText}>
            c) 通过本系统所设cookies所取得的有关信息，将适用本政策。{" "}
          </Description>
          <Description color={theme.colors.secondaryText}>四、信息披露 </Description>
          <Description color={theme.colors.secondaryText}>
            在如下情况下，本系统将依据您的个人意愿或法律的规定全部或部分的披露您的个人信息：
          </Description>
          <Description color={theme.colors.secondaryText}>
            a) 经您事先同意，向第三方披露；
          </Description>
          <Description color={theme.colors.secondaryText}>
            b) 为提供您所要求的产品和服务，而必须和第三方分享您的个人信息；{" "}
          </Description>
          <Description color={theme.colors.secondaryText}>
            c) 根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；
          </Description>
          <Description color={theme.colors.secondaryText}>
            d) 如您出现违反中国有关法律、法规或者本系统服务协议或相关规则的情况，需要向第三方披露；
          </Description>
          <Description color={theme.colors.secondaryText}>
            e)
            如您是适格的知识产权投诉人并已提起投诉，应被投诉人要求，向被投诉人披露，以便双方处理可能的权利纠纷；
          </Description>
          <Description color={theme.colors.secondaryText}>
            f)
            在本系统平台上创建的某一交易中，如交易任何一方履行或部分履行了交易义务并提出信息披露请求的，本系统有权决定向该用户提供其交易对方的联络方式等必要信息，以促成交易的完成或纠纷的解决。{" "}
          </Description>
          <Description color={theme.colors.secondaryText}>
            g) 其它本系统根据法律、法规或者网站政策认为合适的披露。{" "}
          </Description>
          <Description color={theme.colors.secondaryText}>五、信息存储和交换 </Description>
          <Description color={theme.colors.secondaryText}>
            本系统收集的有关您的信息和资料将保存在本系统及（或）其关联公司的服务器上，这些信息和资料可能传送至您所在国家、地区或本系统收集信息和资料所在地的境外并在境外被访问、存储和展示。
          </Description>
          <Description color={theme.colors.secondaryText}>
            为保证向您提供本隐私政策目的所述的服务，我们可能会向第三方服务提供商与业务合作伙伴等第三方共享必要的个人信息。
          </Description>
          <Description color={theme.colors.secondaryText}>
            为了使您能够接收信息、在第三方平台分享信息、使用地图服务、播放视频等必要的功能用途，我们的应用中会嵌入授权合作伙伴的SDK或其他类似的应用程序。如您使用华为/小米/魅族手机时，华为/小米/魅族SDK需要读取您的设备识别号、联网相关信息，用于下发通知栏消息；如您使用高德提供的地图服务，高德SDK需要您打开设备位置权限并收集设备所在位置相关信息信息、设备识别号、联网相关信息。
          </Description>
          <Description color={theme.colors.secondaryText}>
            我们会对授权合作伙伴获取有关信息的应用程序接口（API）、软件工具开发包（SDK）进行严格的安全检测，并与授权合作伙伴约定严格的数据保护措施，令其按照我们的委托目的、服务说明、本隐私权政策以及其他任何相关的保密和安全措施来处理个人信息。
          </Description>
          <Description color={theme.colors.secondaryText}>
            我们还会收集您的IMSI、IMEI、设备MAC地址、软件列表、设备序列号、Android
            ID用于判断您上次登录的方式，目的是方便您使用登录功能。
          </Description>
          <Description color={theme.colors.secondaryText}>
            本政策涉及的个人信息或权限包括：
          </Description>
          <Description color={theme.colors.secondaryText}>
            （1）基本信息（包括个人姓名、生日、性别、住址、个人电话号码、电子邮箱）；
          </Description>
          <Description color={theme.colors.secondaryText}>
            （2）个人身份信息（包括身份证相关身份证明等）；
          </Description>
          <Description color={theme.colors.secondaryText}>
            （3）财产信息（包括交易记录以及金币、钻石等虚拟财产信息）；
          </Description>
          <Description color={theme.colors.secondaryText}>（4）个人位置信息；</Description>
          <Description color={theme.colors.secondaryText}>
            （5）网络身份标识信息（包括系统账号、IP地址、邮箱地址及与前述有关的密码、密保）；
          </Description>
          <Description color={theme.colors.secondaryText}>通讯录；</Description>
          <Description color={theme.colors.secondaryText}>
            （6）个人上网记录（包括搜索记录、软件使用记录、点击记录）；
          </Description>
          <Description color={theme.colors.secondaryText}>
            （7）个人常用设备信息（包括硬件型号、设备MAC地址、操作系统类型）；
          </Description>
          <Description color={theme.colors.secondaryText}>
            （8）软件列表唯一设备识别码（如IMEI/android
            ID/IDFA/OPENUDID/GUID、SIM卡IMSI信息等在内的描述个人常用设备基本情况的信息，存在收集ICCID、Android
            ID、IP、IMSI、SD卡数据、IMEI、安装列表、设备序列号、传感器、MAC、本机号码、剪切板的行为）。
          </Description>
          <Description color={theme.colors.secondaryText}>自启动和关联启动说明</Description>
          <Description color={theme.colors.secondaryText}>
            为确保本应用处于关闭或后台运行状态下可正常接收到发送的广播信息，本应用须使用（自启动）能力，将存在一定频率通过系统发送广播唤醒本应用自启动或关联启动行为，是因实现功能及服务所必要的。
          </Description>
          <Description color={theme.colors.secondaryText}>第三方SDK列表：</Description>
          <Description color={theme.colors.secondaryText}>
            （1）SDK名称：移动智能终端补充设备标识体系统一调用SDK
          </Description>
          <Description color={theme.colors.secondaryText}>包名：com.bun</Description>
          <Description color={theme.colors.secondaryText}>SDK厂家：中国信息通信研究院</Description>
          <Description color={theme.colors.secondaryText}>
            SDK描述：获取华为、小米、OPPO、vivo、中兴、努比亚、魅族、联想、三星等设备厂商的补充设备标识（OAID、AAID等）
          </Description>
          <Description color={theme.colors.secondaryText}>个人信息：获取OAID</Description>
          <Description color={theme.colors.secondaryText}>（2）SDK名称：高德SDK</Description>
          <Description color={theme.colors.secondaryText}>
            作用：定位以及地图展示及 poi 查询
          </Description>
          <Description color={theme.colors.secondaryText}>
            收集个人信息类型：需要获取外部存储状态，最近一次地理位置信息，持续定位，网络类型，网络服务运营商，IMEI，MAC
            地址，WiFi 信息。
          </Description>
          <Description color={theme.colors.secondaryText}>
            隐私政策链接：https://lbs.amap.com/pages/privacy/
          </Description>
          <Description color={theme.colors.secondaryText}>
            （3）SDK名称：支付宝-阿里支付
          </Description>
          <Description color={theme.colors.secondaryText}>作用：用于支付宝支付。</Description>
          <Description color={theme.colors.secondaryText}>
            收集个人信息类型：系统语言，系统唯一标识符，外部存储状态，网络类型，设备型号，设备制造商，SIM
            卡序列号，IMEI，MAC 地址，ANDROID_ID，IP 地址，WiFi 信息，应用安装列表，OpenUDID。
          </Description>
          <Description color={theme.colors.secondaryText}>
            隐私政策链接：https://www.alipay.com/
          </Description>
          <Description color={theme.colors.secondaryText}>（4）SDK名称：微信</Description>
          <Description color={theme.colors.secondaryText}>
            作用：使用微信开放平台SDK，支持微信授权登录、微信分享和微信支付。
          </Description>
          <Description color={theme.colors.secondaryText}>
            收集个人信息类型：第三方账号、设备信息（包括开放性独立设备标识符(Open
            UDID)、唯一设备识别码IMEI、IOS
            广告标识符(IDFA)、MAC地址、设备型号、终端设备操作系统版本、终端制造厂商）、设备语言、时区和网络/语言状态相关信息，用于统计分析您使用我们的服务数据；Bugly
            SDK 会读取您的 CRASH 环境（包括 CRASH 信息及线程堆线、ROM/RAM/SD
            卡容量）、应用程序包名、应用程序版本、应用程序所属进程名、设备信息（包括开放性独立设备标识符(Open
            UDID)、IOS 广告标识符(IDFA)、MAC
            地址、国际移动设备识别码(IMEI)、设备型号、终端设备操作系统版本、终端制造厂商）、设备语言、时区和网络/语言状态相关信息，用于判断并收集、统计
            CRASH 信息。）
          </Description>
          <Description color={theme.colors.secondaryText}>
            隐私政策链接：https://open.weixin.qq.com/
          </Description>
          <Description color={theme.colors.secondaryText}>SDK名称：QQSDK </Description>
          <Description color={theme.colors.secondaryText}>
            收集个人信息类型：设备型号、操作系统版本、唯一设备标识符、电池、信号强度、软件的版本号、浏览器类型、IP地址、地理位置、服务日志信息、通讯日志信息{" "}
          </Description>
          <Description color={theme.colors.secondaryText}>目的：支持QQ授权登录及分享 </Description>
          <Description color={theme.colors.secondaryText}>
            腾讯隐私政策： https://www.tencent.com/zh-cn/privacy-policy.html
          </Description>
          <Description color={theme.colors.secondaryText}>SDK名称：腾讯Bugly SDK</Description>
          <Description color={theme.colors.secondaryText}>
            收集个人信息类型：在收集运行进程信息时，腾讯bugly
            SDK将会收集、使用日志信息（包括：第三方开发者自定义日志、Logcat日志以及APP崩溃堆栈信息）、联网信息、系统名称、系统版本、IMEI、IMSI、DEVICEID设备MAC地址以及国家码{" "}
          </Description>
          <Description color={theme.colors.secondaryText}>
            目的：提供app稳定性监控，程序崩溃上报，帮助开发者快速发现问题{" "}
          </Description>
          <Description color={theme.colors.secondaryText}>
            用户协议：https://bugly.qq.com/v2/contract
          </Description>
          <Description color={theme.colors.secondaryText}>
            （7）SDK名称：FaceBookReact、com.facebook.react、ReactNative
          </Description>
          <Description color={theme.colors.secondaryText}>使用目的：数据分析</Description>
          <Description color={theme.colors.secondaryText}>
            收集的个人信息类型：读取Android ID、获取设备序列号、获取传感器、获取剪切板、IP地址
          </Description>
          <Description color={theme.colors.secondaryText}>
            官方链接：https://github.com/facebook/react-native
          </Description>
          <Description color={theme.colors.secondaryText}>
            （8）SDK名称：openinstall
            SDK；io.openinstall.sdk；com.fm.openinstall；com.openinstall.openinstallLibrary
          </Description>
          <Description color={theme.colors.secondaryText}>
            使用目的：渠道统计；应用统计分析；作弊防护
          </Description>
          <Description color={theme.colors.secondaryText}>
            使用方式：在您授权后，调用系统相关接口自动采集
          </Description>
          <Description color={theme.colors.secondaryText}>
            收集个人数据类型：获取SD卡数据、获取Android
            ID、获取设备序列号、获取剪切板、获取传感器、设备信息：设备品牌，设备型号，操作系统版本；网络信息：IP地址
          </Description>
          <Description color={theme.colors.secondaryText}>
            第三方SDK隐私政策：https://www.openinstall.io/privacy.html
          </Description>
          <Description color={theme.colors.secondaryText}>（9）SDK名称：个推</Description>
          <Description color={theme.colors.secondaryText}>
            SDK包名：Gtc、com.igexin.push、com.getui.gtc
            、com.igexin.sdk、io.dcloud、com.bun、cn.com.chinatelecom、com.xiaomi.mipush、com.heytap.mcs、com.heytap.msp、com.xiaomi.push
          </Description>
          <Description color={theme.colors.secondaryText}>SDK用途：UniPush推送</Description>
          <Description color={theme.colors.secondaryText}>
            可能获取的个人信息类型：网络信息、IMEI、openid、获取设备序列号、获取SD卡数据、获取Android
            ID、OAID、MAC地址、设备传感器数据、获取安装列表
          </Description>
          <Description color={theme.colors.secondaryText}>
            调用的设备权限：获取网络状态、访问Wi-Fi状态、读取手机状态和身份、获取传感器信息。
          </Description>
          <Description color={theme.colors.secondaryText}>
            SDK隐私政策链接：https://docs.getui.com/privacy/
          </Description>
          <Description color={theme.colors.secondaryText}>（10）SDK名称：Gtc(个推)</Description>
          <Description color={theme.colors.secondaryText}>
            使用目的：为保障您能及时收到实习问答消息、日志、周志提交和回复提醒，以及自主实习申报审核的重要消息提醒
          </Description>
          <Description color={theme.colors.secondaryText}>使用方式：Gtc收集</Description>
          <Description color={theme.colors.secondaryText}>收集数据类型：</Description>
          <Description color={theme.colors.secondaryText}>
            ①设备信息：设备识别码(包含IMEI、IDFA、Android ID、MAC、OAID、Advertising ID、Serial
            Number、IMSI、UAID、ICCID)、设备平台、
            设备厂商用于生成唯一的推送目标ID（CID）和设备ID（GID）、获取安装列表，以此保证消息推送的准确下发和消息设备的准确识别。
            设备品牌、设备型号及系统版本用于优化通道资源的有效性，提升消息推送的到达率和稳定性。此外，为确保CID的唯一性、提升消息推送稳定性和准确性，
            还需您授权存储权限、和设备权限。
          </Description>
          <Description color={theme.colors.secondaryText}>
            ②应用列表信息：采用合并链路技术进行技术推送，当一个设备有多个APP的推送链路同时活跃时，会随机合并成一条链路，以达到为您省电省流量的目的，
            因此需获得应用列表信息；
          </Description>
          <Description color={theme.colors.secondaryText}>
            ③网络信息以及位置相关信息：为了最大程度保持网络连接的稳定性，建立长链接，需要了解设备的网络状态和变化，从而实现稳定连续的推送服务。
          </Description>
          <Description color={theme.colors.secondaryText}>
            Gtc(个推)SDK隐私政策：https://docs.getui.com/privacy/
          </Description>
          <Description color={theme.colors.secondaryText}>
            （11）SDK名称：VIVO push；VIVOPUSHSDK
          </Description>
          <Description color={theme.colors.secondaryText}>
            作用：用于向vivo手机用户推送消息；
          </Description>
          <Description color={theme.colors.secondaryText}>
            收集个人信息类型：设备标识符、获取SD卡数据；
          </Description>
          <Description color={theme.colors.secondaryText}>
            隐私政策链接：http://www.mi.com/about/new-privacy
          </Description>
          <Description color={theme.colors.secondaryText}>（12）SDK名称 ：GpushOPPOSDK</Description>
          <Description color={theme.colors.secondaryText}>
            共享的信息 ：设备标识（OAID、VAID、AAID、UDID）、设备相关信息（IMEI，Serial
            Number，IMSI，User ID，Android ID，Google Advertising
            ID,手机Region设置，设备型号）、获取安装包列表、使用推送服务的应用信息、网络相关信息（如IP或域名连接结果，当前网络类型）、通知栏状态（如通知栏权限、用户点击行为）
          </Description>
          <Description color={theme.colors.secondaryText}>
            使用目的 ：向OPPO设备推送信息
          </Description>
          <Description color={theme.colors.secondaryText}>
            使用场景 ：新消息到达设备时提醒用户查看
          </Description>
          <Description color={theme.colors.secondaryText}>共享方式 ：SDK本机采集</Description>
          <Description color={theme.colors.secondaryText}>
            （13）SDK名称：魅族SDK；MzPush{" "}
          </Description>
          <Description color={theme.colors.secondaryText}>
            作用：用于向魅族手机用户推送消息；
          </Description>
          <Description color={theme.colors.secondaryText}>
            收集个人信息类型：获取MAC、设备标识符、获取本机号码、读取DEVICEID、获取安装列表、获取SD卡数据；
          </Description>
          <Description color={theme.colors.secondaryText}>
            隐私政策链接：http://www.mi.com/about/new-privacy
          </Description>
          <Description color={theme.colors.secondaryText}>
            （14）SDK名称：Sentry SDK（io.sentry、sentry-android、io.sentry.react）
          </Description>
          <Description color={theme.colors.secondaryText}>所属公司：Sentry</Description>
          <Description color={theme.colors.secondaryText}>信息获取：获取传感器信息</Description>
          <Description color={theme.colors.secondaryText}>权限获取：无</Description>
          <Description color={theme.colors.secondaryText}>
            使用目的：捕捉APP发生异常或崩溃时的相关数据
          </Description>
          <Description color={theme.colors.secondaryText}>
            官网链接：https://sentry.io/welcome
          </Description>
          <Description color={theme.colors.secondaryText}>
            （15）SDK名称：expo.modules.application,expo.modules.adapters
          </Description>
          <Description color={theme.colors.secondaryText}>
            收集个人信息类型：获取Android ID
          </Description>
          <Description color={theme.colors.secondaryText}>（16）SDK名称：微软SDK</Description>
          <Description color={theme.colors.secondaryText}>
            收集个人信息类型：获取Android ID的行为。
          </Description>
          <Description color={theme.colors.secondaryText}>
            六、如何访问和管理自己的信息、信息安全{" "}
          </Description>
          <Description color={theme.colors.secondaryText}>（一）信息安全</Description>
          <Description color={theme.colors.secondaryText}>
            a)
            帐号均有安全保护功能，请妥善保管您的用户名及密码信息。将通过对用户密码进行加密等安全措施确保您的信息不丢失，不被滥用和变造。尽管有前述安全措施，但同时也请您注意在信息网络上不存在“完善的安全措施”。{" "}
          </Description>
          <Description color={theme.colors.secondaryText}>
            b)
            在使用本系统网络服务进行网上交易时，您不可避免的要向交易对方或潜在的交易对方披露自己的个人信息，如联络方式或者邮政地址。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息泄密，尤其是本系统用户名及密码发生泄露，请您立即联络本系统客服，以便本系统采取相应措施。
          </Description>
          <Description color={theme.colors.secondaryText}>
            （二）如何访问和管理自己的信息
          </Description>
          <Description color={theme.colors.secondaryText}>
            a）我们将采取适当的技术手段，保证您可以查询您的注册信息。
          </Description>
          <Description color={theme.colors.secondaryText}>
            您可以通过“我的”，查看您的头像、昵称等信息，点击“设置”可以修改昵称。
          </Description>
          <Description color={theme.colors.secondaryText}>b)账号注销</Description>
          <Description color={theme.colors.secondaryText}>
            您可以通过点击“我的”——“设置”——“我的账号”——“删除账号”，输入登录密码，进行注销账号操作。账号注销后，我们将对已经收集的个人信息进行删除或匿名化处理。
          </Description>
          <Description color={theme.colors.secondaryText}>其他</Description>
          <Description color={theme.colors.secondaryText}>
            本隐私政策的签订、生效、履行、争议的解决均适用中华人民共和国法律。
          </Description>
          <Description color={theme.colors.secondaryText}>
            有关本协议的争议应通过友好协商解决，如果协商不成，该争议将提交公司所在地有管辖权的法院诉讼解决。
          </Description>
        </ContentArea>
    </Box>
  );
};

const PrivacyDialog = ({ visible, setVisible, setAgree }) => {
  const { configStore } = useStores();
  const onClose = () => {
    setVisible(false);
  };
  const onCancelButtonClick = () => {
    setAgree(false);
    onClose();
  };
  const onConfirmButtonClick = () => {
    setAgree(true);
    onClose();
  };
  return (
    <Dialog
      title={`${configStore.appName} IM -隐私政策`}
      visible={visible}
      Contents={Contents}
      onClose={() => setVisible(false)}
      onCancelButtonClick={onCancelButtonClick}
      onConfirmButtonClick={onConfirmButtonClick}
      buttonShow={false}
    />
  );
};

export default PrivacyDialog;
