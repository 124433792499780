import { decode, encode } from "@msgpack/msgpack"

export class Packer {
  static pack(data: any): Uint8Array {
    return encode(data)
  }

  static unpack(blob: Uint8Array) {
    return decode(blob)
  }
}

export default Packer
