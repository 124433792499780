import {
  flow,
  applySnapshot,
  Instance,
  SnapshotOut,
  types,
  onSnapshot,
} from "mobx-state-tree";

import { WebsocketStoreModel } from "../websocket-store";
import { AuthStoreModel } from "../auth-store";
import { EventStoreModel } from "../event-store";
import { MessageStoreModel } from "../message-store";
import { ChatStoreModel } from "../chat-store";
import { UserStoreModel } from "../user-store";
import { ContactStoreModel } from "../contact-store";
import { ChannelStoreModel } from "../channel-store";
import { ConfigStoreModel } from "../config-store";
import { FriendStoreModel } from "../friend-store";
import { APIStoreModel } from "../api-store";
import { BlackListStoreModel } from "../black-list-store";
import * as storage from "../../utils/storage";
import { rootStoreInstance } from "./setup-root-store";
import { dropMessages } from "../../services/dexie";
import { Event, EventBatchPackedData } from "../../proto/nbchat-proto"

export const USER_STATE_STORAGE_KEY = `nbchat-user-state-storage-key-V8`

/**
 * A RootStore model.
 */

function checkWindowWidthIsLowerThan700() {
  return window.innerWidth < 700;
}

export const RootStoreModel = types
  .model("RootStore")
  .props({
    configStore: types.optional(ConfigStoreModel, {} as any),
    authStore: types.optional(AuthStoreModel, {} as any),
    websocketStore: types.optional(WebsocketStoreModel, {} as any),
    channelStore: types.optional(ChannelStoreModel, {} as any),
    userStore: types.optional(UserStoreModel, {} as any),
    eventStore: types.optional(EventStoreModel, {} as any),
    contactStore: types.optional(ContactStoreModel, {} as any),
    chatStore: types.optional(ChatStoreModel, {} as any),
    messageStore: types.optional(MessageStoreModel, {} as any),
    friendStore: types.optional(FriendStoreModel, {} as any),
    apiStore: types.optional(APIStoreModel, {} as any),
    blackListStore: types.optional(BlackListStoreModel, {} as any),
  })
  .volatile(() => ({
    panel: "ChatList",
    isMobile: checkWindowWidthIsLowerThan700(),
    isUserStateLoaded: false,
    onSnapshotDisposer: undefined,
    syncFinished: false,
    contactListScrollTop: 0,
  }))
  .actions((self) => {
    const setContactListScrollTop = (v: number) => {
      self.contactListScrollTop = v;
    }
    const setSyncFinished = (v: boolean) => {
      self.syncFinished = v;
    }
    const onSyncFinished = () => {
      setSyncFinished(true);
    }
    const onEventPacked = ({ data }: Event<EventBatchPackedData>) => {
      const { items } = data;
      items.forEach((packedData) => {
        const eventName = packedData.event_name
        const eventData = packedData.data;
        self.eventStore.onEvent(new Event(eventName, eventData))
      })
    }
    function setPanel(v) {
      self.panel = v;
    }
    function detectMobile() {
      self.isMobile = checkWindowWidthIsLowerThan700();
    }
    function setIsUserSateLoaded(loaded) {
      self.isUserStateLoaded = loaded;
    }
    function setOnSnapshotDisposer(disposer) {
      self.onSnapshotDisposer = disposer;
    }
    const clearCache = flow(function* () {
      yield storage.remove(USER_STATE_STORAGE_KEY);
      yield dropMessages();
    })
    // 重置状态
    const reset = flow(function* () {
      const initialState = {
        authStore: {
          deviceId: self.authStore.deviceId,
          clientId: self.authStore.clientId,
          haveBeenPassIntro: self.authStore.haveBeenPassIntro,
        },
      };
      self.onSnapshotDisposer();
      applySnapshot(self, initialState);
      setIsUserSateLoaded(false);
      yield clearCache();
    })
    const load = flow(function* () {
      const data = (yield storage.load(USER_STATE_STORAGE_KEY)) || {};
      data.authStore = self.authStore;
      if (data.chatStore?.chats) {
        const allMessages = {};
        data.chatStore.chats = Object.keys(data.chatStore.chats).reduce(
          (acc, chat_id) => {
            const chat = data.chatStore.chats[chat_id];
            chat.messages = chat.messages.slice(0, 30);
            chat.messages.forEach((message_id) => {
              allMessages[message_id] = data.messageStore.messages[message_id];
            });
            acc[chat_id] = chat;
            return acc;
          },
          {}
        );
        data.messageStore.messages = allMessages;
      }
      applySnapshot(self, data);
      setIsUserSateLoaded(true);

      const disposer = onSnapshot(rootStoreInstance, (snapshot) => {
        storage.save(USER_STATE_STORAGE_KEY, snapshot);
      });
      setOnSnapshotDisposer(disposer);
    });
    return { setContactListScrollTop, setSyncFinished, onSyncFinished, onEventPacked, setPanel, detectMobile, reset, load };
  });

/**
 * The RootStore instance.
 */
export interface RootStore extends Instance<typeof RootStoreModel> {}

/**
 * The data of a RootStore.
 */
export interface RootStoreSnapshot extends SnapshotOut<typeof RootStoreModel> {}
