import { PageProps } from "../types";
import React, { useCallback, useMemo } from "react";
import { observer } from "mobx-react-lite";
import useNavigation from "../../hooks/useNavigation";
import PageWrap from "../components/PageWrap";
import PageHeader from "../components/PageHeader";
import { default as FriendListComponent } from "../../components/FriendList";
import { useStores } from "../../models";

interface Props extends PageProps {}

const FriendList: React.FC<Props> = () => {
  const { navigate } = useNavigation();
  const { friendStore } = useStores();

  const onItemPress = useCallback(
    (friend) => {
      navigate("User", { user_id: friend.user_id });
    },
    [navigate]
  );

  const isBackButton = useMemo(() => {
    return true;
  }, []);

  const backFunc = useCallback(() => {
    navigate("ContactList");
  }, [navigate]);

  return (
    <PageWrap>
      <PageHeader
        title="新朋友"
        isBackButton={isBackButton}
        backFunc={backFunc}
        isPlusButton={false}
      />
      <FriendListComponent
        data={[...friendStore.friendsByStatus]}
        onItemPress={onItemPress}
      />
    </PageWrap>
  );
};

export default observer(FriendList);
