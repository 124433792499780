import { debug } from "../../utils"
import * as Types from "./api.types"
import { Client } from "./client"

/**
 * Manages all requests to the API.
 */

export class APIService {
  client = new Client()
  baseUrl: string

  constructor(baseUrl: string) {
    debug(`new APIService ${baseUrl}`)
    this.baseUrl = baseUrl
  }

  url(path: string) {
    return `${this.baseUrl}${path}`
  }

  // 登录
  async login(data: Types.LoginParams): Promise<Types.AuthResult> {
    return await this.client.json<Types.AuthResult>(this.url(`/auth/login`), data)
  }

  // 注册
  async register(data: Types.RegisterParams): Promise<Types.AuthResult> {
    return await this.client.json<Types.AuthResult>(this.url(`/auth/register`), data)
  }

  // 登出
  async logout(data: Types.LogoutParams): Promise<Types.LogoutResult> {
    return await this.client.post(this.url(`/auth/logout`), data)
  }

  // 忘记密码
  async forgetPassword(data: Types.ForgetPasswordParams): Promise<any> {
    return await this.client.post<any>(this.url(`/auth/pwd_code`), data)
  }

  // 重置密码
  async resetPassword(data: Types.ResetPasswordParams): Promise<any> {
    return await this.client.post<any>(this.url(`/auth/reset/pwd`), data)
  }

  // 上传
  async upload(data: Types.UploadParams): Promise<Types.UploadResult> {
    return await this.client.upload(this.url("/upload/file"), data)
  }

  // 上传
  async uploadClient(data: Types.UploadClientParams): Promise<Types.UploadClientResult> {
    return await this.client.get<Types.UploadClientResult>(
      this.url(`/upload/client/payload?filename=${data.filename}`),
    )
  }

  // 確認token是否有效
  async ping(data: Types.PingParams): Promise<Types.PingResult> {
    return await this.client.get<Types.PingResult>(this.url(`/auth/ping`), {
      token: data.token
    })
  }
  

  async uploadToS3Storage(
    s3url: Types.UploadClientResult["url"],
    formData: Types.UploadToS3StorageParams,
    onProgressUpdate: (progress: number) => void,
  ): Promise<Types.UploadToS3StorageResult> {
    return await this.client.uploadToS3Storage<Types.UploadToS3StorageResult>(s3url, formData, onProgressUpdate)
  }

  // 下载
  async download(data: Types.DownloadParams) {
    return await this.client.download(data)
  }

  // 确认是否有新版本
  async checkVersion(data: Types.CheckVersionParams): Promise<Types.CheckVersionResult> {
    return await this.client.get<Types.CheckVersionResult>(
      this.url(`/app_versions/checkingVersion?version=${data.version}&device=${data.device}`),
    )
  }
}
