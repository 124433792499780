/** @format */

import styled from "styled-components";
import Overlay from "../../../components/Overlay";
import Accordion from "../../../components/Accordion";

const wrapWidth = 400;

const Wrap = styled.div`
  z-index: 1000;
  position: fixed;
  width: ${wrapWidth}px;
  max-width: 80%;
  max-height: 80%;
  overflow: scroll;
  background-color: ${({ theme }) => theme.colors.blockBackground};
  border-radius: ${({ theme }) => theme.radii[3]};
`;

const ReadToDeleteMenu = ({ chat, visible, setVisible }) => {
  const options = [
    {
      id: 0,
      title: "关闭",
      onItemPress: () => {
        console.log('he');
        chat.publicSwitchBurningChat(false, 0);
        setVisible(false);
      },
    },
    {
      id: 1,
      title: "30秒",
      onItemPress: () => {
        chat.publicSwitchBurningChat(true, 30);
        setVisible(false);
      },
    },
    {
      id: 2,
      title: "1分钟",
      onItemPress: () => {
        chat.publicSwitchBurningChat(true, 60);
        setVisible(false);
      },
    },
    {
      id: 3,
      title: "5分钟",
      onItemPress: () => {
        chat.publicSwitchBurningChat(true, 300);
        setVisible(false);
      },
    },
  ];
  if (!visible) return null;
  return (
    <Overlay
      onClick={() => {
        setVisible(false);
      }}
    >
      <Wrap>
        {options.map((option) => {
          return (
            <Accordion
              key={option.title}
              {...option}
            ></Accordion>
          );
        })}
      </Wrap>
    </Overlay>
  );
};

export default ReadToDeleteMenu;
