import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { UserModel } from "../user/user"

/**
 * Model description here for TypeScript hints.
 */

export const BlackListModel = types
  .model("BlackList")
  .props({
    id: types.identifierNumber,
    owner_id: types.number,
    user_id: types.number,
    user: types.reference(UserModel),
    cerated: types.maybe(types.number),
  })

type BlackListType = Instance<typeof BlackListModel>
export interface BlackList extends BlackListType {}
type BlackListSnapshotType = SnapshotOut<typeof BlackListModel>
export interface BlackListSnapshot extends BlackListSnapshotType {}
export const createBlackListDefaultModel = () => types.optional(BlackListModel, {} as any)
