import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import useGlobalTheme from "../../hooks/useGlobalTheme";
import PageWrap from "../components/PageWrap";
import PageHeader from "../components/PageHeader";
import Accordion from "../../components/Accordion";
import AccordionBootomGap from "../../components/AccordionBottomGap";
import Button from "../../widgets/Button";
import { useStores } from "../../models";
import { version } from "../../version";
import PrivacyDialog from "../../components/PrivacyDialog";
import ServiceContractDialog from "../../components/ServiceContractDialog";

const Setting: React.FC = () => {
  const theme = useGlobalTheme();
  const { authStore } = useStores();
  const [, setIsAgreePrivacy] = useState(false);
  const [isServiceContractModalVisible, setIsServiceContractModalVisible] = useState(false)
  const [isPrivacyModalVisible, setIsPrivacyModalVisible] = useState(false)
  const handleLogout = () => {
    authStore.publicLogout();
  };
  return (
    <>
      <PageWrap>
        <PageHeader title={"设置"}></PageHeader>
        <Accordion title="关于喜鹊">版本 {version}</Accordion>
        <Accordion
          title="服务协议"
          onItemPress={() => setIsServiceContractModalVisible(true)}
        />
        <Accordion
          title="隐私政策"
          onItemPress={() => setIsPrivacyModalVisible(true)}
        />
        <AccordionBootomGap></AccordionBootomGap>
        <Button
          onClick={handleLogout}
          style={{
            color: theme.colors.danger,
          }}
        >
          退出登录
        </Button>
      </PageWrap>
      <ServiceContractDialog
        visible={isServiceContractModalVisible}
        setVisible={setIsServiceContractModalVisible}
        setAgree={setIsAgreePrivacy}
      ></ServiceContractDialog>
      <PrivacyDialog
        visible={isPrivacyModalVisible}
        setVisible={setIsPrivacyModalVisible}
        setAgree={setIsAgreePrivacy}
      ></PrivacyDialog>
    </>
  );
};

export default observer(Setting);
