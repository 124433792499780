import styled from "styled-components";

const StyledSpan = styled.span<{ visible: boolean }>`
  visibility: ${({ visible }) => visible ? 'visible' : 'hidden'};
  color: ${({ theme }) => theme.colors.danger};
  margin-top: 5px;
  height: 15px;
`

interface Props {
  visible: boolean
  children?: React.ReactNode
}

const ErrorText: React.FC<Props> = ({ visible, children }) => {
  return <StyledSpan visible={visible}>{children}</StyledSpan>
}

export default ErrorText;