import React from "react";
import styled from "styled-components";
import { formatOnlineDate } from "../../refactor-utils/date";

const LastSeenText = styled.span`
  font-size: 0.8rem;
  color: ${({ theme }) => theme.colors.text};
`;

const OnlineText = styled.span`
  font-size: 0.8rem;
  color: ${({ theme }) => theme.colors.success};
`;

const Online = () => {
  return <OnlineText>在线</OnlineText>;
};

const LastSeen = ({ last_seen }) => (
  <LastSeenText>{formatOnlineDate(last_seen)}</LastSeenText>
);

interface Props {
  last_seen: Date;
  online: boolean;
}

const OnlineStatus: React.FC<Props> = ({ online, last_seen }) => {
  return online ? <Online /> : <LastSeen last_seen={last_seen} />;
};

export default OnlineStatus;
