import styled from "styled-components";
import { User } from "../../models";

const AtUsernameInner = styled.span`
  font-size: 0.8rem;
  color: ${({ theme }) => theme.colors.secondaryText};
`;

interface Props {
  user: User
}

const AtUsername: React.FC<Props> = ({ user }) => {
  return <AtUsernameInner>{user.atUsername}</AtUsernameInner>
}

export default AtUsername;