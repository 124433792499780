import React from "react";
import styled from "styled-components";
import Avatar from "../../Avatar";
import AtUsername from "../../AtUsername";
import { Friend } from "../../../models";
import { FriendStatus } from "../../../proto/nbchat-proto";

const FriendListItemWrap = styled.div`
  position: relative;
  display: flex;
  background-color: ${({ theme }) => theme.colors.blockBackground};
  padding: ${({ theme }) => theme.space[2]} ${({ theme }) => theme.space[4]} }
  border: 0px solid ${({ theme }) => theme.colors.border};
  border-bottom-width: 1px;
  cursor: pointer;
`;

const Main = styled.div`
  margin-left: 5px;
  width: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const Title = styled.div`
  font-size: 0.8rem;
`;

const PrimaryText = styled.div`
  font-size: 0.8rem;
  color: ${({ theme }) => theme.colors.primary};
`;

const SmallText = styled.div`
  font-size: 0.8rem;
  color: ${({ theme }) => theme.colors.secondaryText};
`;

interface Props {
  item: Friend;
  onItemPress?: (contact: Friend) => void;
}

const FriendListItem: React.FC<Props> = ({ item, onItemPress }) => {
  return (
    <FriendListItemWrap onClick={() => onItemPress(item)}>
      <Avatar avatar={item.user.avatar} name={item.name} />
      <Main>
        <Column>
          <Title>
            {item.name}
            <AtUsername user={item.user} />
          </Title>
          {item.status === FriendStatus.PENDING && (
            <PrimaryText>查看</PrimaryText>
          )}
          {item.status === FriendStatus.APPROVED && (
            <SmallText>已接受</SmallText>
          )}
        </Column>
      </Main>
    </FriendListItemWrap>
  );
};

export default FriendListItem;
