/** @format */

import React from 'react';
import styled from 'styled-components';
import appleEmojiDatasource from 'emoji-datasource-apple/emoji.json';
import googleEmojiDatasource from 'emoji-datasource-google/emoji.json';
import useConstants from '../../../hooks/useConstants';
import Text from '../../../widgets/Text';

const Wrap = styled.div`
  height: 350px;
`;
const ListStyle = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;
const EmojisStyle = styled.div`
  display: flex;
  justify-content: center;
  padding: 15px 0 0;
  width: 20%;
`;

const List = ({ data, renderItem }) => {
  return <ListStyle>{data.map((item) => renderItem({ item }))}</ListStyle>;
};

const EmojiItem = ({ children, onPress }) => {
  const textStyle = { fontSize: 30 };
  return (
    <EmojisStyle onClick={() => onPress(children)}>
      <Text style={textStyle}>{children}</Text>
    </EmojisStyle>
  );
};

interface Props {
  onSelect: (t: string) => void;
}

const Emoji: React.FC<Props> = ({ onSelect }) => {
  const { isIos } = useConstants();
  const emojiDatasource = (isIos ? appleEmojiDatasource : googleEmojiDatasource)
    .filter((i) => i.category === 'Smileys & Emotion')
    .reverse()
    .filter((i) => Number(i.added_in) <= 9);

  const charFromUtf16 = (utf16) =>
    String.fromCodePoint(...utf16.split('-').map((u) => '0x' + u));
  const charFromEmojiObject = (obj) => charFromUtf16(obj);
  const handleSelect = (emoji) => onSelect(emoji);
  const renderItem = ({ item }) => {
    return (
      <EmojiItem onPress={handleSelect}>
        {charFromEmojiObject(item.unified)}
      </EmojiItem>
    );
  };

  return (
    <Wrap>
      <List data={emojiDatasource} renderItem={renderItem} />
    </Wrap>
  );
};

export default Emoji;
