import { MessagePreviewImagesProps } from "../types"
import React from "react"
import PreviewWrap from "./preview-wrap"
import PreviewText from "./preview-text"

const MessageDraft: React.FC<MessagePreviewImagesProps> = ({ forwardPrependMsg, length }) => {
  return (
    <PreviewWrap>
      <PreviewText>{`${forwardPrependMsg}[${length > 1 ? "图组" : "图片"}]`}</PreviewText>
    </PreviewWrap>
  )
}
export default MessageDraft
