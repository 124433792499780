import React from "react";
import styled from "styled-components";
import UserListItem from "./components/UserListItem";
import { User } from "../../models";

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

interface Props {
  selecteds?: User["id"][];
  data: User[];
  onItemPress?: (contact: User) => void;
}

const UserList: React.FC<Props> = ({ selecteds, data, onItemPress }) => {
  return (
    <Wrap>
      {data.map((user) => {
        return (
          <UserListItem
            key={user.id}
            checked={selecteds ? selecteds.includes(user.id) : void 0}
            item={user}
            onItemPress={onItemPress}
          />
        );
      })}
    </Wrap>
  );
};

export default UserList;
