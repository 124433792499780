import { getRoot, Instance, SnapshotOut, types } from "mobx-state-tree";
import {
  Event,
  FriendList,
  FriendData,
  FriendApprovedData,
  FriendStatus,
  FriendDeleteData,
  MessageTypes,
  MessageStatus,
} from "../../proto/nbchat-proto";
import { FriendModel } from "../friend/friend";
import { compareDayAgo } from "../../utils";
import { RootStoreModel } from "../root-store/root-store";
import { NotifyType } from "../../types/message";
/**
 * Model description here for TypeScript hints.
 */
export const FriendStoreModel = types
  .model("FriendStore")
  .props({ friends: types.optional(types.map(FriendModel), {}) })
  .views((self) => ({
    friendsByUserId(id: number) {
      return Array.from(self.friends.values()).find((x) => x.user.id === id);
    },
    get friendsCount() {
      return Array.from(self.friends.values()).filter(
        (i) => !i.readed && i.status === FriendStatus.PENDING
      ).length;
    },
    get friendsByStatus() {
      const { contactStore } = getRoot<Instance<typeof RootStoreModel>>(self);
      const list = [
        { title: "最近三天", data: [] },
        { title: "三天前", data: [] },
      ];
      Array.from(self.friends.values())
        .sort((a, b) => (a.created > b.created ? -1 : 1))
        .forEach((i) => {
          const contact = contactStore.byUserId(i.user_id);
          if (
            (i.status !== FriendStatus.PENDING &&
              i.status !== FriendStatus.APPROVED) ||
            (i.status === FriendStatus.APPROVED && !contact)
          )
            return;
          if (compareDayAgo(i.updated) > 3) {
            list[1].data.push(i);
          } else {
            list[0].data.push(i);
          }
        });
      return list;
    },
  }))
  .actions((self) => {
    function friendCreateHandle({ user, ...rest }: FriendData) {
      const { userStore } = getRoot<Instance<typeof RootStoreModel>>(self);
      const u = userStore.addUser(user);
      return self.friends.put({ ...rest, user: u.id, readed: false });
    }
    // event-store
    function onFriendCreated({ data }: Event<FriendData>) {
      return friendCreateHandle(data);
    }
    // event-store
    function onFriendList({ data: { items } }: Event<FriendList>) {
      items.forEach((data) => friendCreateHandle(data));
    }
    function onRead(id: number) {
      const friend = self.friends.get(id.toString());
      friend.setRead();
    }
    // event-store
    function onFriendApproved({ data }: Event<FriendApprovedData>) {
      const friend = self.friendsByUserId(data.id);
      friend?.setStatus(FriendStatus.APPROVED);
      const { contactStore, chatStore } =
        getRoot<Instance<typeof RootStoreModel>>(self);
      const contact = contactStore.byUserId(data.id);
      if (contact) {
        const chat = chatStore.newPrivateChat({
          receiver_id: data.id,
          name: data.username,
        });
        contact.setStatus(1);
        chat.setDeleted(false);
        chat.newMessage({
          content: "现在我们是朋友了，可以开始聊天了",
          type: MessageTypes.TEXT,
          status: MessageStatus.READ,
          notify_type: NotifyType.CONTACT_CREATED,
        });
      }
    }
    // event-store
    function onFriendDeleted({ data: { id } }: Event<FriendDeleteData>) {
      self.friends.delete(id.toString());
    }
    return {
      onFriendCreated,
      onFriendList,
      onRead,
      onFriendApproved,
      onFriendDeleted,
    };
  }); // eslint-disable-line @typescript-eslint/no-unused-vars

type FriendStoreType = Instance<typeof FriendStoreModel>;
export interface FriendStore extends FriendStoreType {}
type FriendStoreSnapshotType = SnapshotOut<typeof FriendStoreModel>;
export interface FriendStoreSnapshot extends FriendStoreSnapshotType {}
export const createFriendStoreDefaultModel = () =>
  types.optional(FriendStoreModel, {});
