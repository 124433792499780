import { Message, UserStore } from "../models"

export const compareDayAgo = (timestamp) => {
  const time = timestamp
  const today = new Date().getTime()
  const msPerDay = 60 * 1000 * 60 * 24
  const compareTime = today - time
  return Math.round(compareTime / msPerDay)
}

// 是否是当日时间
const isToday = (date: Date) => {
  const today = new Date()
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  )
}

// 是否是昨日时间
const isYesterday = (date: Date) => {
  const yesterday = new Date()
  yesterday.setDate(yesterday.getDate() - 1)

  return (
    date.getDate() === yesterday.getDate() &&
    date.getMonth() === yesterday.getMonth() &&
    date.getFullYear() === yesterday.getFullYear()
  )
}

const isThisYear = (date: Date) => {
  const today = new Date()
  return date.getFullYear() === today.getFullYear()
}
// 格式化消息时间, 更友好的阅读体验
// 当日时间，则显示 小时:分钟
// 昨日时间，则显示 昨天 小时:分钟
// 历史数据，则显示 月-日期 小时:分钟
export const formatMessageTime = (time: Date) => {
  if (!time) return undefined
  const dd = String(time.getDate()).padStart(2, "0")
  const mm = String(time.getMonth() + 1).padStart(2, "0")
  const yyyy = time.getFullYear()

  const HH = String(time.getHours()).padStart(2, "0")
  const MM = String(time.getMinutes()).padStart(2, "0")
  const t = `${HH}:${MM}`

  if (isToday(time)) {
    return t
  } else if (isYesterday(time)) {
    return `昨天 ${t}`
  } else if (isThisYear(time)) {
    return `${mm}-${dd} ${t}`
  } else {
    return `${yyyy}-${mm}-${dd} ${t}`
  }
}

export const formatMessageOnlyTime = (time: Date) => {
  if (!time) return undefined

  const HH = String(time.getHours()).padStart(2, "0")
  const MM = String(time.getMinutes()).padStart(2, "0")
  return `${HH}:${MM}`
}

export const formatMentionId = (
  content: Message["content"],
  mention_ids: Message["mention_ids"],
  userStore: UserStore
) => {
  if (!content) return "";
  const handleGetDisplayName = (match) => {
    const username = match.replace("@", "")
    const user = userStore.getUserByUsername(username)
    if (user) {
      const displayName = userStore.getDisplayNameByUser(user);
      return displayName ? `@${displayName}` : match;
    } else {
      return match
    }
  };
  return content.replace(/@\w+/g, handleGetDisplayName);
};

const padStartZero = (s) => String(s).padStart(2, "0")

export const msToTime =(s) => {
  let ms = s % 1000;
  s = (s - ms) / 1000;
  let secs = s % 60;
  s = (s - secs) / 60;
  let mins = s % 60;
  let hrs = (s - mins) / 60;

  return padStartZero(hrs) + ':' + padStartZero(mins) + ':' + padStartZero(secs)
}


export const formatBytes = (bytes, decimals = 2, isBinary = false) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']; // or ['B', 'KB', 'MB', 'GB', 'TB']

  if (!+bytes) {
    return `0 ${sizes[0]}`;
  }

  const inByte = isBinary ? 1024 : 1000;
  const dm = decimals < 0 ? 0 : decimals;

  const pow = Math.floor(Math.log(bytes) / Math.log(inByte));
  const maxPow = Math.min(pow, sizes.length - 1);

  return `${parseFloat((bytes / Math.pow(inByte, maxPow)).toFixed(dm))} ${
    sizes[maxPow]
  }`;
}