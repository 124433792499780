import styled from "styled-components";
import FieldWrap from "../../widgets/FieldWrap";
import FieldFloatLabel from "../../widgets/FieldFloatLabel";
import CheckBox from "../CheckBox";

const CheckBoxesWrap = styled.div`
  display: flex;
`;

const CheckBoxGroup = ({ label, options, value, setValue }) => {
  return (
    <FieldWrap>
      {label && <FieldFloatLabel>{label}</FieldFloatLabel>}
      <CheckBoxesWrap>
        {options.map((option) => {
          return (
            <CheckBox
              {...option}
              checked={value === option.value}
              onPress={setValue}
            />
          );
        })}
      </CheckBoxesWrap>
    </FieldWrap>
  );
};

export default CheckBoxGroup;
