
/*
 * 协议文件是由程序自动生成的，请不要手动编辑修改保存
 */ 
 
export const __PROTO_HASH__ = "3c27fc35"
export const FLAG_ACK = 1
export const FLAG_REPLY = 2
export const FLAG_ACKED = 4
export type EventHandler<T> = (event: Event<T>) => void
 
export type dict = {
    [key: string]: any
}

export class Event<T> {
  name: EventNames
  data: T
  publish = true
  
  constructor(name: EventNames, data: T, publish = true) {
    this.name = name
    this.data = data
    this.publish = publish
  }
}


// 协议事件列表
export enum EventNames {
    NOT_SET = 0,
    SYSTEM_1 = 1,
    SYSTEM_2 = 2,
    SYSTEM_3 = 3,
    SYSTEM_4 = 4,
    SYSTEM_5 = 5,
    SYSTEM_6 = 6,
    SYSTEM_7 = 7,
    SYSTEM_8 = 8,
    SYSTEM_9 = 9,
    SYSTEM_10 = 10,
    ERROR = 11,
    ECHO = 12,
    SESSION_STARTED = 13,
    USER_PROFILE = 14,
    USER_UPDATE = 15,
    USER_UPDATED = 16,
    USER_LIST = 17,
    CONTACT_LIST = 18,
    CONTACT_CREATED = 19,
    MESSAGE_NEW = 20,
    MESSAGE_CREATED = 21,
    MESSAGE_UPDATED = 22,
    MESSAGE_STATUS_UPDATED = 23,
    MESSAGE_BATCH_STATUS_UPDATED = 24,
    MESSAGE_READ = 25,
    USER_ACTION = 26,
    CHAT_LIST = 27,
    CHAT_NEW = 28,
    CHAT_CREATED = 29,
    FRIEND_LIST = 30,
    FRIEND_APPROVE = 31,
    FRIEND_APPROVED = 32,
    FRIEND_ADD = 33,
    FRIEND_CREATED = 34,
    FRIEND_SCAN_QR = 35,
    CONTACT_UPDATE = 36,
    CONTACT_UPDATED = 37,
    CHAT_UPDATE = 38,
    CHAT_UPDATED = 39,
    REQUEST_USER_SEARCH = 40,
    USER_LAST_SEEN = 41,
    USER_LAST_SEEN_LIST = 42,
    QUERY_ONLINE = 43,
    FRIEND_DELETE = 44,
    FRIEND_DELETED = 45,
    CONTACT_DELETE = 46,
    CONTACT_DELETED = 47,
    CHAT_MEMBER_ADD = 48,
    CHAT_MEMBER_ADDED = 49,
    CHAT_MEMBER_REMOVE = 50,
    CHAT_MEMBER_REMOVED = 51,
    CHAT_DELETE = 52,
    CHAT_DELETED = 53,
    CHAT_MEMBER_LEAVE = 54,
    CHAT_MEMBER_LEFT = 55,
    CHAT_MEMBER_JOIN = 56,
    CHAT_MEMBER_JOINED = 57,
    UPDATE_DEVICE_ID = 58,
    PUSH_NOTIFICATION = 59,
    UPDATE_APP_STATE = 60,
    USER_LOGOUT = 61,
    MESSAGE_BATCH_NEW = 62,
    MESSAGE_DELETE = 63,
    MESSAGE_DELETED = 64,
    MESSAGE_BATCH_DELETE = 65,
    MESSAGE_BATCH_DELETED = 66,
    ACCUSATION_CREATE = 67,
    ACCUSATION_CREATED = 68,
    BLACKLIST_ADD = 69,
    BLACKLIST_ADDED = 70,
    BLACKLIST_REMOVE = 71,
    BLACKLIST_REMOVED = 72,
    QUERY_BLACKLIST = 73,
    BLACKLIST_LIST = 74,
    USER_INACTIVE = 75,
    USER_INACTIVED = 76,
    CHATS_LAST_MESSAGE_LIST = 77,
    MESSAGE_SEARCH = 78,
    MESSAGE_BATCH_READ = 79,
    MESSAGE_BATCH_READED = 80,
    SYNC_FINISHED = 81,
    EVENT_PACKED = 82,
    MUTE = 83,
    MUTED = 84,
    CHAT_BURNING_SWITCH = 85,
    CHAT_BURNING_SWITCHED = 86,
}

// 事件类型，目前主要分为来自客户端的事件，系统事件
export enum EventTypes {
    CLIENT = 0,
    SYSTEM = 1,
}

// 聊天成员权限
export enum ChatPerms {
    DEFAULT = 0,
    ADMIN = 1,
}

// 聊天室类别
export enum ChatTypes {
    PRIVATE = 0,
    GROUP = 1,
}

// 用户事件类型
export enum UserActions {
    TYPING_STARTED = 0,
    TYPING_STOPPED = 1,
    VOICE_RECORDING_STARTED = 2,
    VOICE_RECORDING_CANCELLED = 3,
    VOICE_RECORDING_STOPPED = 4,
}


export type EchoData = {
    data: string
}

// 好友申请方式
export enum FriendAddWays {
    SEARCH = 0,
    QRCODE = 1,
}

// 群友添加方式
export enum ChatMemberAddWays {
    SEARCH = 0,
    QRCODE = 1,
    INVITE = 2,
}


export enum ErrorCodes {
    INVALID_REQUEST = 40000,

}


export type ErrorData = {
    code: ErrorCodes
    message?: string
}


export type Error = {
    error: ErrorData
}

// 性别
export enum UserGender {
    MALE = 0,
    FEMALE = 1,
}

// 用户个人资料
export type UserProfile = {
    id: number
    name: string
    username: string
    avatar?: string
    gender?: UserGender
    email?: string
}


// 用户数据列表
export type UserList = {
    items: UserProfile[]
    page: number
}

// 联络人两边关系
export enum ContactStatus {
    ONE_SIDE = 0,
    CONFIRM = 1,
}

// 联系人数据
export type ContactData = {
    id: number
    owner_id: number
    user_id: number
    username: string
    name?: string
    name_letter: string
    note?: string
    user: UserProfile
    origin: FriendAddWays
    status?: ContactStatus
    is_mute?: boolean
}

// 联系人列表数据
export type ContactList = {
    items: ContactData[]
    page: number
}


// 会话数据
export type SessionData = {
    sid: string
}

// 消息类型
export enum MessageTypes {
    TEXT = 0,
    VOICE = 1,
    IMAGE = 2,
    VIDEO = 3,
    EMOJI = 4,
    FILE = 5,
    VIDEO_CALL = 6,
    VOICE_CALL = 7,
    IMAGES = 8,
}

// 消息状态
export enum MessageStatus {
    SENDING = 0,
    SENT = 1,
    DELIVERED = 2,
    READ = 3,
    FAILED = 4,
    UPLOADING = 5,
    DOWNLOADING = 6,
}

// 图片附件
export type ImgAttach = {
    url: string
    filename: string
    type: string
    length?: number
    size?: number
}

// 消息附件
export type Attach = {
    filename?: string
    type?: string
    length?: number
    size?: number
    imgs?: ImgAttach[]
}

// 消息
export type MessageData = {
    message_id: string
    sender_id: number
    content: string
    created: number
    updated: number
    type: MessageTypes
    status: MessageStatus
    receiver_id?: number
    chat_id?: number  // 聊天室ID, 服务器生成
    chat_cid?: string  // 聊天室主键, 客户端生成
    id?: number
    attach?: Attach
    mention_ids?: number[]
    reply_msg_id?: string
    forward_msg_id?: string
    forward_user_id?: number
    forward_name?: string
    is_deleted?: boolean
    deleted_by?: number
    read_ids?: number[]
    burning_timer?: number
}



// 消息状态变更数据
export type MessageStatusUpdatedData = {
    sender_id: number
    message_id: string
    status: MessageStatus
    id: number
}



export type MessageBatchStatusUpdatedData = {
    items: MessageStatusUpdatedData[]
    page: number
}

// 纪录读取消息数据
export type MessageReadData = {
    message_id: string
    id: number
}


export type MessageBatchReadData = {
    items: MessageReadData[]
}

// 消息已读取数据
export type MessageReadedData = {
    message_id: string
    id: number
    read_ids?: number[]
    sender_id?: number
    receiver_id?: number
    chat_id?: number
}


export type MessageBatchReadedData = {
    items: MessageReadedData[]
}

// 聊天室成员数据
export type ChatMemberData = {
    id: number
    chat_id: number
    user_id: number
    user: UserProfile
    perm: ChatPerms
    origin: ChatMemberAddWays
    options?: dict
    is_mute?: boolean
}

// 聊天室数据
export type ChatData = {
    id: number
    chat_id: string
    title: string
    creator_id: number
    type: ChatTypes
    members: ChatMemberData[]
    created: number
    updated: number
    image: string
    is_burning?: boolean
    burning_timer?: number
}

// 聊天室数据
export type ChatDisplayData = {
    id: number
    chat_id: string
    title: string
    creator_id: number
    type: ChatTypes
    members: ChatMemberData[]
    created: number
    updated: number
    image: string
    is_mute?: boolean
    is_burning?: boolean
    burning_timer?: number
}

// 新建聊天室
export type ChatNewData = {
    chat_id: string
    title: string
    members: number[]
    image: string
}

// 聊天室列表数据
export type ChatListData = {
    items: ChatDisplayData[]
    page: number
}





export type UserActionData = {
    user_id: number
    action: UserActions
    chat_id: string
    chat_cid: number
    receiver_id: number
}

// 添加好友状态
export enum FriendStatus {
    PENDING = 0,
    APPROVED = 1,
    REJECTED = 2,
    EXPIRED = 3,
}

// 好友资料
export type FriendData = {
    id: number
    owner_id: number
    user_id: number
    username: string
    name: string
    user: UserProfile
    status: FriendStatus
    origin: FriendAddWays
    created: number
    updated: number
}

// 好友列表数据
export type FriendList = {
    items: FriendData[]
    page: number
}

// 好友通用资讯
export type FriendCommonData = {
    user_id?: number
    username?: string
    origin?: FriendAddWays
}




// 好友通过资料
export type FriendApprovedData = {
    id: number
    username: string
    status: FriendStatus
    updated: number
}



// 扫码资料
export type QrData = {
    user: UserProfile
    is_contact: boolean
}

// 更新个人资料
export type UserUpdateData = {
    name?: string
    avatar?: string
    gender?: UserGender
    email?: string
}


// 更新个人资料
export type UserUpdatedData = {
    id: number
    name: string
    username: string
    email?: string
    avatar: string
    gender: UserGender
}


// 更新联系人资料
export type ContactUpdateData = {
    username: string
    name?: string
    note?: string
}


// 更新联系人资料
export type ContactUpdatedData = {
    id: number
    username: string
    note?: string
    name?: string
}


// 更新群资料
export type ChatUpdateData = {
    chat_id: string
    title: string
}


// 更新群资料
export type ChatUpdatedData = {
    chat_id: string
    title: string
    message: string
}


// 查询用户
export type RequestUserSearchData = {
    username: string
}


// 用户最后在线时间
export type UserLastSeenData = {
    user_id: number
    online: boolean
    last_seen: number
}


// 用户数据列表
export type UserLastSeenList = {
    items: UserLastSeenData[]
}

// 查询最后在线时间
export type QueryOnlineData = {
    user_id?: number
    chat_id?: number
}


// 删除好友申请
export type FriendDeleteData = {
    id: number
}



// 删除联系人
export type ContactDeleteData = {
    id: number
}


// 已删除联系人
export type ContactDeletedData = {
    id: number
    owner_id: number
    user_id: number
}


// 添加/移除聊天室成员数据
export type ChatMemberARData = {
    chat_id: number
    chat_cid: string
    user_ids: number[]
}


// 群友数据列表
export type ChatMemberAddedData = {
    items: ChatMemberData[]
    chat_cid: string
}



// 解散群
export type ChatDeleteData = {
    chat_id: number
    chat_cid: string
}



// 主动加入/退出聊天室成员数据
export type ChatMemberLJData = {
    chat_id: number
    chat_cid: string
    user_id?: number
    origin?: ChatMemberAddWays
}




// 加群群群员资料
export type ChatMemberjoinedData = {
    item: ChatMemberData
    chat_cid: string
}

// 更新设备ID
export type UpdateDeviceIDData = {
    device_id: string
}


// 推送消息
export type PushNotificationData = {
    message: string
    title: string
    alias: number[]
    extras?: dict
}


// app状态
export enum AppState {
    ACTIVE = 0,
    BACKGROUND = 1,
    INACTIVE = 2,
}

// app状态数据
export type AppStateData = {
    state: AppState
}



// app每日統計資料
export type AppDailyData = {
    id: number
    channel_code: string
    date: number
    ios_install_count: number
    android_install_count: number
    register_count: number
    active_count: number
    active_device_count: number
    created: number
    updated: number
}

// 批量消息创建数据
export type MessageBatchNewData = {
    items: MessageData[]
}

// 删除讯息数据
export type MessageDeleteData = {
    id: number
    message_id?: string
    is_unilateral?: boolean
}



// 批量删除讯息数据
export type MessageBatchDeleteData = {
    items: MessageDeleteData[]
}



// 检举理由类型
export enum AccusationType {
    OTHER = 0,
    SCAM = 1,
    ILLEGAL = 2,
}

// 用户检举资料
export type AccusationData = {
    id: number
    user_id: number
    accused_id: number
    type: AccusationType
    description: string
    created: number
}

// 创建用户检举资料
export type AccusationNewData = {
    accused_id: number
    type: AccusationType
    description: string
}



// 黑名单资料
export type BlacklistData = {
    id: number
    owner_id: number
    user_id: number
    user: UserProfile
    created: number
}

// 黑名单更新资料
export type BlacklistUpdateData = {
    user_id: number
}






// 黑名單列表数据
export type BlacklistList = {
    items: BlacklistData[]
}



// 批量消息创建数据
export type ChatsLastMessageData = {
    items: MessageData[]
}

// 查询小于id的历史聊天纪录
export type MessageSearchData = {
    id_lt?: number
    id_gt?: number
    limit?: number
    target_user_id?: number
    chat_id?: number
}



// 事件包装数据
export type EventPackedData = {
    event_name: number
    data?: dict
}

// 多笔事件包装数据
export type EventBatchPackedData = {
    items: EventPackedData[]
}

// 静音数据
export type MuteData = {
    is_mute: boolean
    user_id?: number
    chat_id?: number
}



// 阅后即焚数据
export type ChatBurningSwitchData = {
    is_burning: boolean
    burning_timer: number
    creator_id?: number
    receiver_id?: number
    chat_id?: number
    chat_cid?: string
}

