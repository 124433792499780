/** @format */

import React, { useState, useMemo, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { Dialog } from 'antd-mobile';
import useNavigation from '../../hooks/useNavigation';
import useGlobalTheme from '../../hooks/useGlobalTheme';
import useImageUpload from '../../hooks/useImageUpload';
import PageWrap from '../components/PageWrap';
import PageHeader, { headerHeight } from '../components/PageHeader';
import NormalInput from '../../components/NormalInput';
import Avatar from '../../components/Avatar';
import Icon from '../../components/Icon';
import { ToastSuccess } from '../../components/Toast';
import { default as ContactListComponent } from '../../components/ContactList';
import { useStores } from '../../models';

const ChoosePanelWrap = styled.div`
  height: 120px;
  background-color: ${({ theme }) => theme.colors.blockBackground};
  padding: ${({ theme }) => theme.space[2]};
`;

const SelectedListWrap = styled.div`
  display: flex;
  overflow: scroll;
  margin: ${({ theme }) => theme.space[2]};
`;

const SelectedListItemWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${({ theme }) => theme.space[2]};
  cursor: pointer;
`;

const SubmitText = styled.span<{ canSubmit: boolean }>`
  cursor: pointer;
  color: ${({ canSubmit, theme }) =>
    canSubmit ? theme.colors.primary : theme.colors.inputPlaceholderColor};
`;

const SearchBar = styled.div`
  height: 100px;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.blockBackground};
  padding: ${({ theme }) => theme.space[2]} ${({ theme }) => theme.space[3]};
`;

const SearchInputWrap = styled.div`
  padding-right: ${({ theme }) => theme.space[2]};
  flex-grow: 1;
`;

const SearchInput = styled(NormalInput)``;

const SearchButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px ${({ theme }) => theme.space[3]};
  cursor: pointer;
`;

const AvatarName = styled.span`
  font-size: 0.8rem;
  color: ${({ theme }) => theme.colors.secondaryText};
  margin-top: ${({ theme }) => theme.space[2]};
`;

const CameraIconWrap = styled.div`
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.inputPlaceholderColor};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
`;

const ScrollArea = styled.div`
  height: calc(100% - ${headerHeight} - 165px);
  overflow: scroll;
`;

const AddContact: React.FC = () => {
  const { goBack } = useNavigation();
  const theme = useGlobalTheme();
  const { uploadImage, imageUploadLoading } = useImageUpload();
  const {
    contactStore,
    userStore,
    chatStore,
    chatStore: { publicNewGroupChat },
  } = useStores();
  const [search, setSearch] = useState('');
  const [title, setTitle] = useState('');
  const [image, setImage] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [selecteds, setSelecteds] = useState([]);
  const canSubmit = useMemo(
    () => selecteds.length > 0 && !imageUploadLoading,
    [selecteds, imageUploadLoading]
  );

  const sections = [...contactStore.byNameWithFilter(new RegExp(search, 'i'))];

  const selectedUsers = selecteds.map((s) => {
    const user = userStore.users.get(s);
    return user;
  });

  const onSubmit = useCallback(() => {
    Dialog.confirm({
      content: '确定要创建?',
      onConfirm: () => {
        const users = selecteds.map((user_id) => {
          return { user_id, user: userStore.users.get(user_id.toString()) }
        })
        users.push({ user_id: userStore.currentUser.id, user: userStore.currentUser })

        const chat = chatStore.newGroupChat({
          creator_id: userStore.currentUser.id,
          image: imageUrl,
          title: title || '群聊',
          members: users,
        });

        publicNewGroupChat(chat);
        ToastSuccess('创建成功!');
        goBack(-1);
      },
    });
  }, [
    userStore.users,
    userStore.currentUser,
    selecteds,
    imageUrl,
    title,
    chatStore,
    publicNewGroupChat,
    goBack,
  ]);

  const handleUploadImage = async () => {
    const { image, imageUrl } = await uploadImage();
    setImage(image);
    setImageUrl(imageUrl);
  };

  const onItemPress = useCallback(
    ({ user }) => {
      const { id } = user;
      if (selecteds.find((x) => x === id)) {
        setSelecteds(selecteds.filter((x) => x !== id));
      } else {
        setSelecteds([...selecteds, id]);
      }
    },
    [selecteds]
  );

  return (
    <PageWrap>
      <PageHeader title="发起群聊" isBackButton={true} isPlusButton={false}>
        <SubmitText
          canSubmit={canSubmit}
          onClick={canSubmit ? onSubmit : undefined}
        >
          确定({selecteds.length})
        </SubmitText>
      </PageHeader>
      <SearchBar>
        <SearchButtonWrap onClick={handleUploadImage}>
          {image ? (
            <Avatar avatar={image} name={title} isRound={true} />
          ) : (
            <CameraIconWrap>
              <Icon
                type="antd"
                name="camera"
                color={theme.colors.blockBackground}
                size={1.2}
              />
            </CameraIconWrap>
          )}
        </SearchButtonWrap>
        <SearchInputWrap>
          <SearchInput
            placeholder="请输入群名称"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
          />
        </SearchInputWrap>
      </SearchBar>
      <ChoosePanelWrap>
        <NormalInput
          placeholder="请输入用户名"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        ></NormalInput>
        <div>
          <SelectedListWrap>
            {selectedUsers.map((user, index) => {
              return (
                <SelectedListItemWrap
                  key={index}
                  onClick={() => onItemPress({ user })}
                >
                  <Avatar avatar={user.avatar} name={user.name} />
                  <AvatarName>{user.name}</AvatarName>
                </SelectedListItemWrap>
              );
            })}
          </SelectedListWrap>
        </div>
      </ChoosePanelWrap>
      <ScrollArea>
        <ContactListComponent
          selecteds={selecteds}
          data={sections}
          onItemPress={onItemPress}
        />
      </ScrollArea>
    </PageWrap>
  );
};

export default observer(AddContact);
