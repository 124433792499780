import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import useGlobalTheme from "../../../../../hooks/useGlobalTheme";
import Overlay from "../../../../Overlay";
import Icon from "../../../../Icon";
import { getMediaPath } from "../../../../../utils";

import { Message, useStores } from "../../../../../models";
const w = window.innerWidth * 0.1;

const Popup = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1000;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  overflow: auto;
`;

const FloatToolBar = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 1001;
  width: 50px;
  background-color: white;
  border-bottom-left-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: bolder;
  padding-bottom: 10px;
`;

const FloatToolBarButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const ImagesWrap = styled.div<{ isMobile: boolean; isMultiple: boolean }>`
  display: flex;
  flex-wrap: wrap;
  flex-flow: wrap-reverse;
  ${({ isMobile, isMultiple }) =>
    isMultiple
      ? `max-width: 300px`
      : isMobile
      ? `max-width: 44vw`
      : `max-width: 220px`};
  width: ${({ isMobile }) => (isMobile ? "60vw" : "30vw")};
`;

const ImageWrap = styled.div<{
  isTail: boolean;
  isMobile: boolean;
  isMultiple: boolean;
}>`
  position: relative;
  display: flex;
  width: ${({ isTail }) => (isTail ? "100%" : "50%")};
  max-height: ${({ isTail, isMultiple }) =>
    !isMultiple ? "unset" : isTail ? "300px" : "150px"};
  ${({ isMobile, isTail, isMultiple }) =>
    !isMultiple
      ? `height: unset`
      : isMobile
      ? `height: ${isTail ? "60vw" : "30vw"}`
      : `height: ${isTail ? "30vw" : "15vw"}`}
`;

const ProgressBarWrap = styled.div`
  position: absolute;
  top: calc(50% - ${w/2}px);
  left: calc(50% - ${w/2}px);
  width: ${w}px;
  height: ${w}px;
`;

const BackgroundImage = styled.div<{ isTail: boolean }>`
  max-width: ${({ isTail }) => (isTail ? "300px" : "150px")};
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
`;

interface Props {
  message: Message;
}

const ImagesMessage: React.FC<Props> = ({ message }) => {
  const theme = useGlobalTheme();
  const { isMobile } = useStores();
  const [popupData, setPopupData] = useState({
    src: "",
    visible: false,
  });
  const [scale, setScale] = useState(1);
  const imgs = message.attach.imgs;

  const handleImageClick = (src) => {
    setPopupData({ src, visible: true });
  };
  const isMultiple = imgs.length !== 1;
  useEffect(() => {
    function onMouseWheel(e) {
      if (e.ctrlKey) {
        console.log('hehe');
        let tmpScale = scale + e.deltaY * -0.01;
        tmpScale = Math.min(Math.max(0.5, tmpScale), 4);
        setScale(tmpScale);
      }
    }
    if (popupData.visible) {
      window.addEventListener("mousewheel", onMouseWheel, { passive: true });
    }
    return () => {
      if (popupData.visible) {
        window.removeEventListener("mousewheel", onMouseWheel);
      }
    };
  }, [popupData.visible, scale]);
  return (
    <ImagesWrap isMobile={isMobile} isMultiple={isMultiple}>
      {imgs.map((img, i) => {
        const m = {
          ...message,
          attach_path: img.path,
          url: img.url,
          progress: img.progress,
          isUploading: img.isUploading,
        };
        const isTail = imgs.length - 1 === i && imgs.length % 2 === 1;
        const src = getMediaPath(m);
        return (
          <ImageWrap
            key={src}
            isTail={isTail}
            isMobile={isMobile}
            isMultiple={isMultiple}
          >
            {imgs.length !== 1 ? (
              <BackgroundImage
                isTail={isTail}
                onClick={() => handleImageClick(src)}
                style={{
                  backgroundImage: `url(${src})`,
                }}
              ></BackgroundImage>
            ) : (
              <Image
                src={src}
                onClick={() => handleImageClick(src)}
              ></Image>
            )}
            {!!m.isUploading && (
              <ProgressBarWrap>
                <CircularProgressbar
                  styles={buildStyles({
                    strokeLinecap: "round",
                    textSize: "1rem",
                    textColor: "transparent",
                    trailColor: theme.colors.blockBackground,
                    backgroundColor: theme.colors.primary,
                    pathColor: theme.colors.primary,
                  })}
                  value={m.progress}
                  maxValue={1}
                  text={(m.progress * 100).toFixed(0)}
                />
              </ProgressBarWrap>
            )}
          </ImageWrap>
        );
      })}
      {popupData.visible && (
        <Popup>
          <img
            alt={popupData.src}
            src={popupData.src}
            style={{
              marginLeft: 'auto',
              marginRight: 'auto',
              zIndex: 1000,
              width: `${70 * scale}%`,
              maxWidth: "280%",
              height: "auto",
              objectFit: "cover",
            }}
          ></img>
          <Overlay
            onClick={() => {
              setPopupData({ src: "", visible: false });
            }}
          >
            <FloatToolBar>
              <div
                onClick={async () => {
                  const outsideRes = await fetch(popupData.src);
                  const blob = await outsideRes.blob();
                  const url = window.URL.createObjectURL(blob);
                  const link = document.createElement("a");
                  link.href = url;
                  link.download = `${Date.now()}.png`;
                  link.click();
                }}
                style={{ textDecoration: "none" }}
              >
                <FloatToolBarButton>
                  <Icon type="mdi" name="download" size={2} />
                  下载
                </FloatToolBarButton>
              </div>
            </FloatToolBar>
          </Overlay>
        </Popup>
      )}
    </ImagesWrap>
  );
};
export default observer(ImagesMessage);
