const rules = {
  username: {
    presence: { allowEmpty: false, message: "账号不能为空白" },
    format: {
      pattern: /[a-zA-Z0-9]+/,
      message: "账号只能为英数组合",
    },
    length: {
      maximum: 18,
      minimum: 6,
      message: "账号长度必须是6至18位之间",
    },
  },
  password: {
    presence: { allowEmpty: false, message: "密码不能为空白" },
    format: {
      pattern: /[a-zA-Z0-9]+/,
      message: "密码只能为英数组合",
    },
    length: {
      minimum: 6,
      message: "密码长度必须至少6位以上",
    },
  },
  code: {
    presence: { allowEmpty: false, message: "验证码不能为空白" },
  },
  email: {
    presence: { allowEmpty: false, message: "电子邮箱不能为空白" },
    format: {
      pattern: /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
      message: "需符合电子邮箱格式",
    },
  },
  displayname: {
    presence: { allowEmpty: false, message: "昵称不能为空白" },
    nickname: {
      message: "昵称超过长度",
    },
  },
  contact_displayname: {
    contact_nickname: {
      message: "备注超过长度",
    },
  },
  contact_note: {
    contact_note: {
      message: "描述超过长度",
    },
  },
  accusationType: {
    presence: { allowEmpty: false, message: "请选择举报理由" },
  },
}

export default rules
