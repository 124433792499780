import "../assets/css/iyesIcon.css";
import styled from "styled-components";
import useGlobalTheme from "../hooks/useGlobalTheme";

const Icon = styled.i`
  line-height: 13px;
`;

export interface Props {
  name: string;
  size?: number;
  color?: string;
}

const IyesIcon: React.FC<Props> = ({ name, size, color, ...rest }) => {
  const theme = useGlobalTheme();
  return (
    <Icon
      style={{
        color: color || theme.colors.primary,
        fontSize: `${size}rem`,
      }}
      className={`iyes iyes-${name}`}
      {...rest}
    />
  );
};

IyesIcon.defaultProps = {
  size: 0.8,
};

export default IyesIcon;
