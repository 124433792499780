/** @format */

import { PageProps } from "../types";
import React, { useRef, useState, useCallback, useEffect } from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { Modal, SpinLoading } from "antd-mobile";
import useGlobalTheme from "../../hooks/useGlobalTheme";
import useNavigation from "../../hooks/useNavigation";
import useConstant from "../../hooks/useConstant";
import PageWrap from "../components/PageWrap";
import PageHeader from "../components/PageHeader";
import PageFooter from "../components/PageFooter";
import { useStores } from "../../models";
import Icon from "../../components/Icon";
import Message from "../../components/Message";
import { ToastError } from "../../components/Toast";
import ToolsBar from "./components/ToolsBar";
import MessageInput from "./components/MessageInput";
import RightClickMenu from "./components/RightClickMenu";
import UserSeperateList from "../../components/UserSeperateList";
import { headerHeight } from "../../pages/components/PageHeader";
import { footerHeight } from "../../pages/components/PageFooter";
import { ChatTypes, MessageTypes } from "../../proto/nbchat-proto";
import { Message as MessageType } from "../../models";
import Text from "../../widgets/Text";
import {
  limit,
  queryMessages,
} from "../../services/dexie";
import ReplyBar from "./components/ReplyBar";
import { debounceLeading } from "../../utils";

const Area = styled.div`
  z-index: 3333;
  position: absolute;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
`;

const MessageListInner = styled.div`
  overflow-y: scroll;
  width: 100%;
  height: calc(100% - ${footerHeight} - ${headerHeight});
  display: flex;
  flex-direction: column-reverse;
  background-color: #f5f5f5;
`;

const MessageList = React.forwardRef(
  (
    {
      paddingBottom = 0,
      children,
      ...rest
    }: { paddingBottom: number; children: React.ReactNode | undefined },
    ref?: React.Ref<HTMLDivElement>
  ) => (
    <MessageListInner
      style={{ paddingBottom: `${40 + paddingBottom}px` }}
      {...rest}
      ref={ref}
    >
      {children}
    </MessageListInner>
  )
);

const scrollToEnd = (dom) => {
  if (dom) {
    dom.scrollTo({
      top: dom.scrollHeight + 40,
      behavior: "smooth",
    });
  }
};

const checkDomIsTop = (dom) => {
  if (dom) {
    const { scrollHeight, scrollTop, clientHeight } = dom;
    return scrollHeight - (Math.abs(scrollTop) + clientHeight) <= 1;
  } else {
    return false;
  }
};

const checkDomIsAlmostBottom = (dom) => {
  if (dom) {
    const { scrollTop, clientHeight } = dom;
    return Math.abs(scrollTop) < clientHeight / 2;
  } else {
    return false;
  }
};

const FooterInner = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const IsBlackBarWrap = styled.div`
  position: fixed;
  top: ${headerHeight}px;
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.background};
`;

const IsBlackBarText = styled.span`
  color: ${({ theme }) => theme.colors.danger};
`;

const IsBlackBarCloseWrap = styled.div`
  position: absolute;
  top: 1px;
  padding: 8px;
  right: 10px;
`;

const GoBottomButtonWrap = styled.div`
  z-index: 800;
  position: absolute;
  right: 12px;
  bottom: 15%;
  border-radius: 20px;
  border: 3px solid ${({ theme }) => theme.colors.secondary};
`;

const GoBottomButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  width: 40px;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.primary};
`;

interface Props extends PageProps {}

const Chat: React.FC<Props> = () => {
  const theme = useGlobalTheme();
  const { navigate, params } = useNavigation();
  const { chat_id } = params;
  const {
    websocketStore,
    chatStore: {
      chats,
      isReplyBarVisible,
      setIsReplyBarVisible,
      rightClickMenuVisible,
      setRightClickMenuVisible,
      getRightClickMenuSelectedMessage,
      setRightClickMenuMessageId,
    },
    messageStore,
    userStore,
    contactStore,
    blackListStore,
    chatStore,
  } = useStores();
  const ref = useRef<HTMLDivElement>();
  const inputRef = useRef<HTMLTextAreaElement>();
  const currentChat = chats.get(chat_id);
  const rightClickMenuSelectedMessage =
    getRightClickMenuSelectedMessage(currentChat);
  const isGroup = currentChat.isGroup;
  const [content, setContent] = useState(currentChat?.draft || "");
  const [paddingBottom, setPaddingBottom] = useState(0);
  const [isBlackBarVisible, setIsBlackBarVisible] = useState(false);
  const [isScrollToBottom, setIsScrollToBottom] = useState(true);
  const [isMentionListVisible, setIsMentionListVisible] = useState(false);
  const data = currentChat?.sortedMessages;
  const isBlack =
    !currentChat.isGroup &&
    !!blackListStore.black_list.get(`${currentChat.chat_id}`);

  const focusInput = useCallback(() => {
    const input = inputRef?.current;
    if (input) {
      input.focus();
    }
  }, []);

  const onTypingStarted = useConstant(() =>
    debounceLeading(() => {
      chatStore.onTypingStarted(currentChat);
    })
  );

  const addNewContentToInput = (value: string) => {
    onChange(
      content.slice(0, inputRef.current.selectionEnd) +
        value +
        content.slice(inputRef.current.selectionEnd, content.length)
    );
    focusInput();
  };

  const onChange = (value: string) => {
    setContent(value);
    currentChat?.setDraft(value);
    onTypingStarted();
  };

  function _send(message: MessageType) {
    messageStore.sendMessageByMessageType(message);
    setTimeout(() => scrollToEnd(ref.current));
    setIsReplyBarVisible(false);
  }

  function sendMessage() {
    const text = content.trim();
    if (text === "") {
      ToastError("消息不能为空");
      return;
    }

    if (currentChat.type !== ChatTypes.GROUP) {
      // const receiverContact = contactStore.byUserId(currentChat.receiver.id);
      // receiverContact?.deleted &&
      //   currentChat.newMessage({
      //     content: "",
      //     type: MessageTypes.TEXT,
      //     status: MessageStatus.READ,
      //     notify_type: NotifyType.CONTACT_DELETED,
      //   });
    }
    const re = /@[\u4e00-\u9fa5\w]+/g;
    const mentionIds: Array<number> = text
      .match(re)
      ?.reduce((acc: number[], i: string) => {
        const username = i.replace("@", "");
        const mentionId = userStore.getUserByUsername(username);
        if (mentionId) acc.push(mentionId);
        return acc;
      }, []);

    // 組成訊息
    const message = currentChat.newMessage({
      content: text,
      type: MessageTypes.TEXT,
      mention_ids: mentionIds || [],
      reply_msg_id:
        isReplyBarVisible && rightClickMenuSelectedMessage
          ? rightClickMenuSelectedMessage.message_id
          : null,
    });

    // 送出訊息
    _send(message);

    // 送出訊息後清除輸入框資料以及清除回覆Bar
    if (message.type === MessageTypes.TEXT) {
      // hack way to prevent linebreak when hit enter key
      setTimeout(() => {
        setContent("");
        currentChat.clearDraft();
      }, 3);
    }
  }

  const fetchData = useCallback(async () => {
    if (currentChat.isSearching || currentChat.isNoMoreData) return null;
    currentChat.setIsSearching(true);
    const timeout = setTimeout(async () => {
      const result = await queryMessages(currentChat.chat_id, data.length)
      if (result.length !== limit) {
        const firstMessageID = data.length ? data[data.length - 1].id : null;
        currentChat.publicReadChatHistory(firstMessageID);
      } else {
        currentChat.prependMessages(result);
        currentChat.setIsSearching(false);
      }
    }, 300);
    return () => {
      clearTimeout(timeout);
    };
  }, [currentChat, data]);

  useEffect(() => {
    chatStore.setCurrent(currentChat);
  }, [chatStore, currentChat]);

  useEffect(() => {
    if (data.length < 30 && !currentChat.isNoMoreData) {
      fetchData();
    }
  }, [data.length, fetchData, currentChat]);

  // 更換聊天室時, 已讀所有訊息, 並滾至最底
  useEffect(() => {
    setContent(currentChat.draft || "");
    scrollToEnd(ref.current);
  }, [currentChat]);

  // 當收發訊息後, 將所有消息標記已讀
  useEffect(() => {
    currentChat?.markAllRead();
  }, [currentChat, data.length]);

  // 當收發訊息後, 將所有消息標記已讀, 偵測當前滾動軸位置, 判斷是否需滾至最底,
  useEffect(() => {
    if (checkDomIsAlmostBottom(ref.current)) {
      scrollToEnd(ref.current);
    }
  }, [currentChat, data.length]);

  // 偵測滾動軸位置, 判斷是否滾至最頂, 如置頂, 則拉取歷史資料
  useEffect(() => {
    const dom = ref.current;
    function handleScroll() {
      if (checkDomIsTop(dom)) {
        fetchData();
      }
      setIsScrollToBottom(checkDomIsAlmostBottom(dom));
    }
    dom.addEventListener("scroll", handleScroll);
    return () => {
      dom.removeEventListener("scroll", handleScroll);
    };
  }, [fetchData]);

  useEffect(() => {
    setIsBlackBarVisible(isBlack);
  }, [isBlack]);

  useEffect(() => {
    const replayBarDom = document.getElementById("reply-bar");
    if (replayBarDom) {
      setPaddingBottom(replayBarDom.getBoundingClientRect().height);
    } else {
      setPaddingBottom(0);
    }
  }, [isReplyBarVisible]);

  // 进入聊天室时 清除选择之讯息
  useEffect(() => {
    setRightClickMenuVisible(false);
    setIsReplyBarVisible(false);
    setRightClickMenuMessageId("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 当被选择之讯息遗失 清除相关状态
  useEffect(() => {
    if (!rightClickMenuSelectedMessage) {
      setRightClickMenuVisible(false);
      setIsReplyBarVisible(false);
    }
  }, [
    rightClickMenuSelectedMessage,
    setIsReplyBarVisible,
    setRightClickMenuVisible,
  ]);

  let title = currentChat.displayTitle;
  if (currentChat.typing) {
    const name = contactStore.getDisplayName(currentChat.typing);
    title = currentChat.isGroup ? `${name} 输入中...` : "正在输入中...";
  }
  if (websocketStore.loading) {
    title += websocketStore.stateText + (websocketStore.loading ? "..." : "");
  }

  return (
    <PageWrap>
      <PageHeader title={title}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          {!!currentChat.burning_timer && (
            <Text color={theme.colors.primaryText} style={{ marginRight: 10}}>
              {currentChat.burning_timer}
            </Text>
          )}
          <Icon
            onClick={() => {
              if (isGroup) {
                navigate("GroupChatDetail", { chat_id });
              } else {
                navigate("User", { user_id: currentChat.privateOtherId });
              }
            }}
            type="mdi"
            name="dotsHorizontal"
            size={1.2}
            color="#333"
          ></Icon>
        </div>
      </PageHeader>
      {rightClickMenuVisible && <RightClickMenu />}
      {isBlackBarVisible && (
        <IsBlackBarWrap>
          <IsBlackBarText>该用户在您的黑名单中</IsBlackBarText>
          <IsBlackBarCloseWrap
            onClick={() => {
              blackListStore.publicRemoveBlacklist(currentChat.chat_id);
              setIsBlackBarVisible(false);
            }}
          >
            <Icon
              type="antd"
              name="close"
              color={theme.colors.secondaryText}
              size={1}
            />
          </IsBlackBarCloseWrap>
        </IsBlackBarWrap>
      )}
      {currentChat.isSearching && !currentChat.isNoMoreData && (
        <Area>
          <SpinLoading color={theme.colors.primary} />
        </Area>
      )}
      {!isScrollToBottom && (
        <GoBottomButtonWrap onClick={() => scrollToEnd(ref.current)}>
          <GoBottomButton>
            <Icon
              type="antd"
              name="down"
              size={1}
              color={theme.colors.blockBackground}
            ></Icon>
          </GoBottomButton>
        </GoBottomButtonWrap>
      )}
      <MessageList
        key={currentChat.chat_id || currentChat.id}
        paddingBottom={paddingBottom}
        ref={ref}
      >
        {data.map((message) => {
          return <Message key={message.message_id} message={message}></Message>;
        })}
      </MessageList>
      <PageFooter>
        {isReplyBarVisible && <ReplyBar />}
        <ToolsBar
          chat={currentChat}
          _send={_send}
          addNewContentToInput={addNewContentToInput}
        />
        <FooterInner>
          <MessageInput
            ref={inputRef}
            chat={currentChat}
            _send={_send}
            sendMessage={sendMessage}
            value={content}
            onChange={(e) => onChange(e.target.value)}
            onKeyPress={(e) => {
              if (
                e.key === "Enter" &&
                e.shiftKey === false &&
                e.altKey === false &&
                e.ctrlKey === false
              ) {
                sendMessage();
                ref.current.blur();
              } else if (e.key === "@") {
                setIsMentionListVisible(true);
              }
            }}
          ></MessageInput>
        </FooterInner>
      </PageFooter>
      <Modal
        bodyClassName="jjtest1"
        visible={isMentionListVisible && !!currentChat.usersByName.length}
        bodyStyle={{
          padding: "0px",
        }}
        content={
          <div>
            <UserSeperateList
              data={[...currentChat.usersByName]}
              onItemPress={(item) => {
                addNewContentToInput(`${item.username} `);
                setIsMentionListVisible(false);
              }}
            />
          </div>
        }
        closeOnMaskClick
        onClose={() => {
          setIsMentionListVisible(false);
        }}
      />
    </PageWrap>
  );
};

export default observer(Chat);
