import { getRoot, Instance, SnapshotOut, types } from "mobx-state-tree";
import {
  Event,
  EventNames,
  UserLastSeenData,
  UserLastSeenList,
  UserList,
  UserProfile,
  RequestUserSearchData,
  UserUpdateData,
  FLAG_ACK,
  FLAG_REPLY,
} from "../../proto/nbchat-proto";
import { withEnvironment } from "../extensions/with-environment";
import { User, UserModel } from "../user/user";
import { RootStoreModel } from "..";

/**
 * Model description here for TypeScript hints.
 */
export const UserStoreModel = types
  .model("UserStore")
  .props({
    users: types.optional(types.map(UserModel), {}),
    currentUser: types.maybe(types.reference(UserModel)),
  })
  .extend(withEnvironment)
  .views((self) => ({
    getDisplayNameByUser(user: User) {
      const { contactStore } = getRoot<Instance<typeof RootStoreModel>>(self);
      const contact = contactStore.byUserId(user.id);
      return contact ? contact.displayName : user.displayName;
    },
    getUserByUsername(searchUsername: string) {
      return Object.values(self.users).find(
        ({ username }) => username === searchUsername
      );
    },
  }))
  .actions((self) => {
    function updateUser({id, ...values}: UserProfile) {
      const user = self.users.get(id.toString()); // 根据ID获取需要更新资料的用户
      user.update(values);
      return user;
    }
    // event-store
    function onUserUpdated({ data: user }: Event<UserProfile>) {
      return updateUser(user);
    }
    function addUser(user: UserProfile) {
      return self.users.has(user.id.toString())
        ? updateUser(user)
        : self.users.put(user);
    }
    function setCurrentUser(user: User) {
      self.currentUser = user;
      return self.currentUser;
    }
    // event-store
    function onUserProfile({ data }: Event<UserProfile>) {
      setCurrentUser(addUser(data));
    }
    // event-store
    function onUserList({ data: { items } }: Event<UserList>) {
      items.forEach(addUser);
    }
    function updateUserLastSeen(data: UserLastSeenData) {
      const user = self.users.get(data.user_id.toString());
      if (user) user.onLastSeen({ data });
    }
    // event-store
    function onUserLastSeen({ data }: Event<UserLastSeenData>) {
      updateUserLastSeen(data);
    }
    // event-store
    function onUserLastSeenList({ data: { items } }: Event<UserLastSeenList>) {
      items.forEach(updateUserLastSeen);
    }
    function publicSearchUser(username, onData, onError) {
      const { eventStore } = getRoot<Instance<typeof RootStoreModel>>(self);
      const evt = new Event<RequestUserSearchData>(
        EventNames.REQUEST_USER_SEARCH,
        {
          username,
        }
      );
      eventStore.publish(evt, {
        flags: FLAG_REPLY,
        onData(data) {
          onData && onData(data);
        },
        onError(error) {
          onError(error);
        },
      });
    }
    function publicCurrentUserUpdate(data: UserUpdateData) {
      const { eventStore } = getRoot<Instance<typeof RootStoreModel>>(self);
      const evt = new Event<Partial<UserUpdateData>>(
        EventNames.USER_UPDATE,
        data
      );
      eventStore.publish(evt, { flags: FLAG_ACK });
    }
    return {
      setCurrentUser,
      addUser,
      onUserProfile,
      onUserList,
      onUserUpdated,
      onUserLastSeen,
      onUserLastSeenList,
      publicSearchUser,
      publicCurrentUserUpdate,
    };
  });

type UserStoreType = Instance<typeof UserStoreModel>;
export interface UserStore extends UserStoreType {}
type UserStoreSnapshotType = SnapshotOut<typeof UserStoreModel>;
export interface UserStoreSnapshot extends UserStoreSnapshotType {}
export const createUserStoreDefaultModel = () =>
  types.optional(UserStoreModel, {});
