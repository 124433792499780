/** @format */

import { PageProps } from '../types';
import React, { useState, useMemo, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { Dialog } from 'antd-mobile';
import useNavigation from '../../hooks/useNavigation';
import PageWrap from '../components/PageWrap';
import PageHeader, { headerHeight } from '../components/PageHeader';
import { footerHeight } from '../components/PageFooter';
import PagePanelFooter from '../components/PagePanelFooter';
import Avatar from '../../components/Avatar';
import NormalInput from '../../components/NormalInput';
import { default as ChatListComponent } from '../../components/ChatList';
import { useStores } from '../../models';
import { newID } from '../../utils';

const ChoosePanelWrap = styled.div`
  background-color: ${({ theme }) => theme.colors.blockBackground};
  padding: ${({ theme }) => theme.space[2]};
`;

const SelectedListWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow: scroll;
  margin: ${({ theme }) => theme.space[2]};
`;

const SelectedListItemWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${({ theme }) => theme.space[2]};
  cursor: pointer;
`;

const AvatarName = styled.span`
  font-size: 0.8rem;
  color: ${({ theme }) => theme.colors.secondaryText};
  margin-top: ${({ theme }) => theme.space[2]};
`;

const ScrollArea = styled.div`
  height: calc(100% - ${footerHeight} - ${headerHeight});
  overflow: scroll;
`;

const SubmitText = styled.span<{ canSubmit: boolean }>`
  color: ${({ canSubmit, theme }) =>
    canSubmit ? theme.colors.primary : theme.colors.inputPlaceholderColor};
`;

interface Props extends PageProps {}

const ForwardMessage: React.FC<Props> = () => {
  const { goBack, params } = useNavigation();
  const { chat_id, message_id } = params;
  console.log('paramsparamsparams', params);
  const { chatStore, userStore, messageStore } = useStores();

  const { chats } = chatStore;
  const { users } = userStore;
  const { messages } = messageStore;

  const currentChat = chats.get(chat_id);
  const message = messages.get(message_id);

  const [search, setSearch] = useState('');

  const [selecteds, setSelecteds] = useState([]);

  const canSubmit = useMemo(() => selecteds.length > 0, [selecteds]);

  const sections = chatStore
    .search(search)
    .filter(({ chat_id }) => chat_id !== currentChat.chat_id);

  const selectedChats = selecteds.map((s) => {
    const chat = chats.get(s);
    return chat;
  });

  const onSubmit = () => {
    Dialog.confirm({
      content: '确定要转发?',
      onConfirm: () => {
        const messages = selecteds.map((id) => {
          const chat = chats.get(id);
          const m = {
            ...message,
            message_id: newID(),
            sender_id: userStore.currentUser.id,
            receiver: chat.isGroup ? null : users.get(id),
            chat_id: chat.isGroup ? chat.id : null,
            chat_cid: chat.isGroup ? chat.chat_id : null,
            forward_msg_id: message.message_id,
            forward_user_id: message.sender.id,
            forward_name: message.sender.name,
          };
          return m;
        });
        messageStore.sendBatchMessage(messages);
        goBack(-1);
      },
    });
  };

  const onItemPress = useCallback(
    (chat) => {
      const { chat_id } = chat;
      if (selecteds.find((x) => x === chat_id)) {
        setSelecteds(selecteds.filter((x) => x !== chat_id));
      } else {
        setSelecteds([...selecteds, chat_id]);
      }
    },
    [selecteds]
  );

  return (
    <PageWrap>
      <PageHeader title="选择会话" isBackButton={true} isPlusButton={false}>
        <SubmitText
          canSubmit={canSubmit}
          onClick={canSubmit ? onSubmit : undefined}
        >
          确定({selecteds.length})
        </SubmitText>
      </PageHeader>
      <ChoosePanelWrap>
        <NormalInput
          placeholder="请输入会话名称"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        ></NormalInput>
        <div>
          <SelectedListWrap>
            {selectedChats.map((chat, index) => {
              return (
                <SelectedListItemWrap key={index}>
                  <Avatar avatar={chat.image} name={chat.title} />
                  <AvatarName>{chat.title}</AvatarName>
                </SelectedListItemWrap>
              );
            })}
          </SelectedListWrap>
        </div>
      </ChoosePanelWrap>
      <ScrollArea>
        <ChatListComponent
          selecteds={selecteds}
          data={sections}
          onItemPress={onItemPress}
        />
      </ScrollArea>
      <PagePanelFooter />
    </PageWrap>
  );
};

export default observer(ForwardMessage);
