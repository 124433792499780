// websocekt client

var sock: WebSocket = undefined;

export enum WebSocketState {
  CONNECTING = 0,
  OPEN,
  CLOSING,
  CLOSED,
  NOT_INIT,
}

interface WebSocketMessageEvent extends Event {
  data?: any;
}
interface WebSocketErrorEvent extends Event {}
interface WebSocketCloseEvent extends Event {
  code?: number;
  reason?: string;
  message?: string;
}

export class WebScoketClient {
  onOpen: () => void;
  onClose: (event: WebSocketCloseEvent) => void;
  onData: (blob: Uint8Array) => void;
  onError: (e: WebSocketErrorEvent) => void;

  constructor({ onOpen, onData, onError, onClose }) {
    this.onOpen = onOpen;
    this.onClose = onClose;
    this.onData = onData;
    this.onError = onError;
  }

  connect(url: string) {
    sock = new WebSocket(url);
    sock.binaryType = "arraybuffer";
    sock.onopen = this.onOpen;
    sock.onclose = this.onClose;
    sock.onerror = this.onError;
    sock.onmessage = (event: WebSocketMessageEvent) => {
      const blob = new Uint8Array(event.data);
      this.onData(blob);
    };
  }

  send(b: Uint8Array) {
    if (this.ready) {
      return sock.send(b);
    }
    console.warn("socket did not initialized");
  }

  close() {
    sock && sock.close();
  }

  get ready() {
    return sock && sock.readyState === WebSocketState.OPEN;
  }

  get state() {
    return sock ? sock.readyState : WebSocketState.NOT_INIT;
  }
}
