import { getRoot, Instance, SnapshotOut, types } from "mobx-state-tree";
import { BlackListModel } from "../black-list/black-list";
import {
  BlacklistList,
  BlacklistData,
  Event,
  BlacklistUpdateData,
  EventNames,
  FLAG_ACK,
} from "../../proto/nbchat-proto";
import { RootStoreModel } from "..";
/**
 * Model description here for TypeScript hints.
 */

export const BlackListStoreModel = types
  .model("BlackListStore")
  .props({
    black_list: types.optional(types.map(BlackListModel), {}),
  })
  .views((self) => ({
    get blackListUsers() {
      const { userStore } = getRoot<Instance<typeof RootStoreModel>>(self);
      const data = [];
      self.black_list.forEach((black) => {
        const user = userStore.users.get(black.id.toString());
        if (user) data.push(user);
      }, []);
      return data;
    },
  }))
  .actions((self) => {
    const { eventStore, userStore, authStore } =
      getRoot<Instance<typeof RootStoreModel>>(self);
    const currentUserId = authStore.userID;

    function publicQueryBlacklistList(user_id) {
      const evt = new Event<BlacklistUpdateData>(EventNames.QUERY_BLACKLIST, {
        user_id,
      });
      eventStore.publish(evt, { flags: FLAG_ACK });
    }

    function publicAddBlacklist(user_id) {
      const evt = new Event<BlacklistUpdateData>(EventNames.BLACKLIST_ADD, {
        user_id,
      });
      eventStore.publish(evt, { flags: FLAG_ACK });
    }

    function publicRemoveBlacklist(user_id) {
      const evt = new Event<BlacklistUpdateData>(EventNames.BLACKLIST_REMOVE, {
        user_id,
      });
      eventStore.publish(evt, { flags: FLAG_ACK });
    }

    function queryBlacklistList(userID) {
      const evt = new Event<BlacklistUpdateData>(EventNames.QUERY_BLACKLIST, {
        user_id: userID,
      });
      eventStore.publish(evt, { flags: FLAG_ACK });
    }

    function addBlacklist(user_id) {
      const evt = new Event<BlacklistUpdateData>(EventNames.BLACKLIST_ADD, {
        user_id,
      });
      eventStore.publish(evt, { flags: FLAG_ACK });
    }

    function removeBlacklist(user_id) {
      const evt = new Event<BlacklistUpdateData>(EventNames.BLACKLIST_REMOVE, {
        user_id,
      });
      eventStore.publish(evt, { flags: FLAG_ACK });
    }

    function onBlacklistList({ data: { items } }: Event<BlacklistList>) {
      self.black_list.clear();
      items.forEach((item: BlacklistData) => {
        const { user_id } = item;
        const user = userStore.users.get(user_id);
        self.black_list.put({
          id: user_id,
          owner_id: currentUserId,
          user_id,
          user,
        });
      });
    }

    function onBlacklistAdded({
      data: { user_id },
    }: Event<BlacklistUpdateData>) {
      const user = userStore.users.get(user_id);
      self.black_list.put({
        id: user_id,
        owner_id: currentUserId,
        user_id,
        user,
      });
    }

    function onBlacklistRemoved({
      data: { user_id },
    }: Event<BlacklistUpdateData>) {
      self.black_list.delete(user_id.toString());
    }

    return {
      publicQueryBlacklistList,
      publicAddBlacklist,
      publicRemoveBlacklist,
      queryBlacklistList,
      addBlacklist,
      removeBlacklist,
      onBlacklistList,
      onBlacklistAdded,
      onBlacklistRemoved,
    };
  });

type BlackListStoreType = Instance<typeof BlackListStoreModel>;
export interface BlackListStore extends BlackListStoreType {}
type BlackListStoreSnapshotType = SnapshotOut<typeof BlackListStoreModel>;
export interface BlackListStoreSnapshot extends BlackListStoreSnapshotType {}
export const createBlackListStoreDefaultModel = () =>
  types.optional(BlackListStoreModel, {});
