/** @format */

import React, { useState, useMemo, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { Dialog } from 'antd-mobile';
import useNavigation from '../../hooks/useNavigation';
import PageWrap from '../components/PageWrap';
import PageHeader, { headerHeight } from '../components/PageHeader';
import NormalInput from '../../components/NormalInput';
import { ToastSuccess } from '../../components/Toast';
import { useStores } from '../../models';

const ScrollArea = styled.div`
  height: calc(100% - ${headerHeight});
  overflow: scroll;
  padding: ${({ theme }) => theme.space[4]};
  background-color: ${({ theme }) => theme.colors.blockBackground};
`;

const SubmitText = styled.span<{ canSubmit: boolean }>`
  cursor: pointer;
  color: ${({ canSubmit, theme }) =>
    canSubmit ? theme.colors.primary : theme.colors.inputPlaceholderColor};
`;

const SubTitle = styled.div`
  font-size: 0.8rem;
  color: ${({ theme }) => theme.colors.inputPlaceholderColor};
  margin-bottom: ${({ theme }) => theme.space[4]};
  text-align: center;
`;

const UpdateGroupTitle: React.FC = () => {
  const { goBack, params } = useNavigation();
  const { chat_id } = params;
  const { chatStore } = useStores();
  const chat = chatStore.chats.get(chat_id);
  const [title, setTitle] = useState(chat.title);

  const onSubmit = useCallback(() => {
    Dialog.confirm({
      content: '确定要修改?',
      onConfirm: () => {
        chat.publicUpdateGroupChatTitle(title);
        ToastSuccess('修改成功!');
        goBack(-1);
      },
    });
  }, [chat, title, goBack]);

  const disabledFunc = useCallback(
    ({ title }) => {
      return title === chat.title || !title;
    },
    [chat.title]
  );

  const canSubmit = useMemo(
    () => !disabledFunc({ title }),
    [disabledFunc, title]
  );

  return (
    <PageWrap>
      <PageHeader title="修改群聊名称" isBackButton={true}>
        <SubmitText
          canSubmit={canSubmit}
          onClick={canSubmit ? onSubmit : undefined}
        >
          确定
        </SubmitText>
      </PageHeader>
      <ScrollArea>
        <SubTitle>修改群聊名称后，将在群内通知其他成员。</SubTitle>
        <NormalInput
          label="群聊名称"
          placeholder="请输入群聊名称"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        ></NormalInput>
      </ScrollArea>
    </PageWrap>
  );
};

export default observer(UpdateGroupTitle);
