import React from "react";
import styled from "styled-components";
import OnlineStatus from "../../OnlineStatus";
import Avatar from "../../Avatar";
import CheckBox from "../../CheckBox";
import AtUsername from "../../AtUsername";
import { User } from "../../../models";

const ContactListItemWrap = styled.div`
  position: relative;
  display: flex;
  background-color: ${({ theme }) => theme.colors.blockBackground};
  padding: ${({ theme }) => theme.space[2]} ${({ theme }) => theme.space[4]} }
  border: 0px solid ${({ theme }) => theme.colors.border};
  border-bottom-width: 1px;
  cursor: pointer;
`;

const CheckWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: ${({ theme }) => theme.space[3]};
`;

const Main = styled.div`
  margin-left: 5px;
  width: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const Title = styled.div`
  font-size: 0.8rem;
`;

interface Props {
  checked?: boolean;
  item: User;
  onItemPress?: (contact: User) => void;
}

const ContactListItem: React.FC<Props> = ({ checked, item, onItemPress }) => {
  return (
    <ContactListItemWrap onClick={() => onItemPress(item)}>
      {checked !== void 0 && (
        <CheckWrap>
          <CheckBox checked={checked} value={item.id} />
        </CheckWrap>
      )}
      <Avatar avatar={item.avatar} name={item.name} />
      <Main>
        <Column>
          <Title>
            {item.displayName}
            <AtUsername user={item} />
          </Title>
          <OnlineStatus
            last_seen={item.last_seen}
            online={item.online}
          />
        </Column>
      </Main>
    </ContactListItemWrap>
  );
};

export default ContactListItem;
