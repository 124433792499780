import React from "react";
import MessageNotfiy from "./components/MessageNotify";
import MessageCommon from "./components/MessageCommon";
import { Message as MessageModel } from "../../models";

interface Props {
  message: MessageModel;
}

const Message: React.FC<Props> = ({ message }) => {
  return message.isNotify ? (
    <MessageNotfiy message={message} />
  ) : (
    <MessageCommon message={message} />
  );
};

export default Message;
