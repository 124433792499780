import { Event, EventNames } from "../proto/nbchat-proto";
import Packer from "../utils/packer";
import { debug } from "../utils/print";

export const FLAG_ACK = 1;
export const FLAG_REPLY = 2;
export const FLAG_ACKED = 4;

// 协议数据， 用于发送
export type ProtoData = [
  [v: number, flags: number, id: number],
  EventNames,
  any
];

// 协议结构体
export class Proto {
  id = 0;
  flags = 0;
  v = 1;
  name: EventNames;
  data: any;

  constructor(name: EventNames, data: any, flags = 0, id?: number, v?: number) {
    this.name = name;
    this.data = data;
    this.flags = flags;
    this.id = id || this.newId();
    this.v = v || 1;
  }

  unstructure(): ProtoData {
    return [[this.v, this.flags, this.id], this.name, this.data];
  }

  serialize() {
    return serialize(this.unstructure());
  }

  newId() {
    return (Math.random() * 10000000) | 0;
  }

  asEvent(publish: boolean = false) {
    const [, name, value] = this.unstructure();
    return new Event(name, value, publish);
  }
}

export function deserialize(blob) {
  const msg = Packer.unpack(blob);
  const [[v, flags, id], name, data]: any = msg;
  return new Proto(name, data, flags, id, v);
}

export function serialize(data: ProtoData) {
  return Packer.pack(data);
}

export const debugProto = <T extends Partial<Proto>>(proto: T, dir = ">>>") => {
  const name = EventNames[proto.name];
  debug(
    dir + `E:${name} EID:${proto.name} F:${proto.flags} ID:${proto.id}`,
    JSON.stringify(proto.data, null, 2)
  );
};
