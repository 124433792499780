/** @format */

import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { Dialog } from 'antd-mobile';
import useNavigation from '../../hooks/useNavigation';
import useGlobalTheme from '../../hooks/useGlobalTheme';
import PageWrap from '../components/PageWrap';
import PageHeader from '../components/PageHeader';
import Accordion from '../../components/Accordion';
import AccordionBootomGap from '../../components/AccordionBottomGap';
import Avatar from '../../components/Avatar';
import Icon from '../../components/Icon';
import { ToastSuccess } from '../../components/Toast';
import Button from '../../widgets/Button';
import { useStores } from '../../models';

const MemberListWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow: scroll;
  margin: ${({ theme }) => theme.space[2]};
`;

const MemberListItemWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${({ theme }) => theme.space[2]};
  cursor: pointer;
`;

const AvatarName = styled.span`
  font-size: 0.8rem;
  color: ${({ theme }) => theme.colors.secondaryText};
  margin-top: ${({ theme }) => theme.space[2]};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 40px;
`;

const MemberActionBorder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid ${({ theme }) => theme.colors.inputPlaceholderColor};
  border-radius: 6px;
  height: 40px;
  width: 40px;
`;

const GroupChatDetail: React.FC = () => {
  const theme = useGlobalTheme();
  const { navigate, params } = useNavigation();
  const { chat_id } = params;
  const { chatStore } = useStores();
  const { chats } = chatStore;
  const currentChat = chats.get(chat_id);
  const {
    membersExcludeSelf,
    isCurrentUserExistInMembers,
    isCurrentUserIsAdmin,
  } = currentChat;

  const goToAddGroupMember = () => {
    navigate('AddGroupMember', { chat_id: chat_id });
  };

  const goToRemoveGroupMember = () => {
    navigate('RemoveGroupMember', { chat_id: chat_id });
  };

  const buttonGroup = [];
  if (isCurrentUserExistInMembers) {
    buttonGroup.push({
      type: 'mdi',
      icon: 'plus',
      onPress: goToAddGroupMember,
    });
    if (isCurrentUserIsAdmin)
      buttonGroup.push({
        type: 'mdi',
        icon: 'minus',
        onPress: goToRemoveGroupMember,
      });
  }

  const handleAdminDissolveGroup = () => {
    chatStore.publicDismissGroupChat(currentChat);
    ToastSuccess('解散成功');
    navigate('Welcome');
  };

  const handleMemberLeftGroup = () => {
    chatStore.publicLeaveGroupChat(currentChat);
    ToastSuccess('退出成功');
    navigate('Welcome');
  };

  const handleDestructiveButtonClick = () => {
    Dialog.confirm({
      content: isCurrentUserIsAdmin
        ? '解散群聊后，所有成员将强制离开该群聊'
        : '删除并退出后，将不再接收此群聊信息',
      onConfirm: () => {
        isCurrentUserIsAdmin
          ? handleAdminDissolveGroup()
          : handleMemberLeftGroup();
      },
    });
  };

  return (
    <PageWrap>
      <PageHeader
        title={`群聊详情(${currentChat.members.length})`}
      ></PageHeader>
      <MemberListWrap>
        {[...membersExcludeSelf, ...buttonGroup].map((item, index) => {
          const { type, icon, onPress, user } = item;
          const goToUser = () => {
            navigate('User', { user_id: user.id });
          };
          return (
            <MemberListItemWrap key={index} onClick={onPress || goToUser}>
              {icon ? (
                <MemberActionBorder>
                  <Icon
                    type={type}
                    name={icon}
                    color={theme.colors.inputPlaceholderColor}
                    size={1}
                  />
                </MemberActionBorder>
              ) : (
                <>
                  <Avatar avatar={user.avatar} name={user.name} />
                  <AvatarName>{user.name}</AvatarName>
                </>
              )}
            </MemberListItemWrap>
          );
        })}
      </MemberListWrap>
      <Accordion
        title="群聊名称"
        onItemPress={() => navigate('UpdateGroupTitle', { chat_id: chat_id })}
      >
        {currentChat.title}
      </Accordion>
      <Accordion
        title="群二维码"
        onItemPress={() => navigate('GroupChatQrcode', { chat_id: chat_id })}
      >
        <Icon
          type="antd"
          name="qrcode"
          color={theme.colors.inputPlaceholderColor}
          size={1}
        />
      </Accordion>
      <AccordionBootomGap></AccordionBootomGap>
      <Button
        onClick={handleDestructiveButtonClick}
        style={{
          color: theme.colors.danger,
        }}
      >
        {isCurrentUserIsAdmin ? '解散群聊' : '离开群聊'}
      </Button>
    </PageWrap>
  );
};

export default observer(GroupChatDetail);
