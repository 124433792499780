import { MessagePreviewProps } from "./types";
import React from "react";
import styled from "styled-components";
import MessagePreviewDraft from "./components/message-preview-draft";
import MessagePreviewVoice from "./components/message-preview-voice";
import MessagePreviewImage from "./components/message-preview-image";
import MessagePreviewVideo from "./components/message-preview-video";
import MessagePreviewImages from "./components/message-preview-images";
import MessagePreviewMention from "./components/message-preview-mention";
import MessagePreviewText from "./components/message-preview-text";
import { MessageTypes } from "../../proto/nbchat-proto";
import { useStores } from "../../models";
import { formatMentionId } from "../../utils/format";
import MessagePreviewFile from "./components/message-preview-file";

const Container = styled.div`
  width: 80%;
`;

const MessagePreview: React.FC<MessagePreviewProps> = ({
  message,
  draft,
  isGroup = false,
  isUnread,
}) => {
  const { userStore } = useStores();
  const forwardPrependMsg = message?.forward_msg_id
    ? `转发来自${message.forward_name}: `
    : "";
  const senderName = message?.sender?.name;
  const isMe = userStore.currentUser.id === message?.sender?.id;
  const renderMessage = () => {
    switch (message.type) {
      case MessageTypes.TEXT:
        return (
          <MessagePreviewText
            senderName={isGroup && senderName && !isMe ? `${senderName}:` : ""}
            forwardPrependMsg={forwardPrependMsg}
            content={formatMentionId(
              message?.content,
              message?.mention_ids,
              userStore
            )}
          ></MessagePreviewText>
        );
      case MessageTypes.IMAGE:
        return (
          <MessagePreviewImage
            forwardPrependMsg={forwardPrependMsg}
          ></MessagePreviewImage>
        );
      case MessageTypes.VIDEO:
        return (
          <MessagePreviewVideo
            forwardPrependMsg={forwardPrependMsg}
          ></MessagePreviewVideo>
        );
      case MessageTypes.IMAGES:
        return (
          <MessagePreviewImages
            forwardPrependMsg={forwardPrependMsg}
            length={message?.attach?.imgs?.length || 0}
          ></MessagePreviewImages>
        );
      case MessageTypes.VOICE:
        return (
          <MessagePreviewVoice
            forwardPrependMsg={forwardPrependMsg}
          ></MessagePreviewVoice>
        );
      case MessageTypes.FILE:
        return (
          <MessagePreviewFile
            forwardPrependMsg={forwardPrependMsg}
          ></MessagePreviewFile>
        );
      default:
        return null;
    }
  };
  const hasMentioned = message?.mention_ids.some(
    (i) => i === userStore.currentUser.id
  );
  const mentionedContent = formatMentionId(
    message?.content,
    message?.mention_ids,
    userStore
  );
  return (
    <Container>
      {draft ? (
        <MessagePreviewDraft draft={draft} />
      ) : hasMentioned ? (
        <MessagePreviewMention content={mentionedContent} isUnread={isUnread} />
      ) : message ? (
        renderMessage()
      ) : null}
    </Container>
  );
};

export default MessagePreview;
