import { MessagePreviewTextProps } from "../types"
import React from "react"
import PreviewWrap from "./preview-wrap"
import PreviewText from "./preview-text"

const MessagePreviewText: React.FC<MessagePreviewTextProps> = ({
  senderName,
  forwardPrependMsg,
  content,
}) => {
  return (
    <PreviewWrap>
      <PreviewText>
        {senderName}
        {forwardPrependMsg}
        {content}
      </PreviewText>
    </PreviewWrap>
  )
}
export default MessagePreviewText
