import {
  flow,
  getRoot,
  Instance,
  SnapshotOut,
  types,
  resolveIdentifier,
} from "mobx-state-tree";
import * as qiniu from "qiniu-js";
import { updateMessage } from "../../services/dexie";
import { Attach, MessageStatus, MessageTypes } from "../../proto/nbchat-proto";
import { numEnumeration } from "../../types/enum";
import { debug, staticUrl } from "../../utils";
import { withEnvironment } from "../extensions/with-environment";
import { UserModel } from "../user";
import { RootStoreModel } from "..";

export function imageDataUrlToBlob(dataurl) {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement("canvas");
    const img = new Image();
    img.src = dataurl;
    img.crossOrigin = "Anonymous";
    img.onload = function () {
      canvas.width = img.naturalWidth * window.devicePixelRatio;
      canvas.height = img.naturalHeight * window.devicePixelRatio;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      canvas.toBlob((blob) => resolve(blob));
    };
    img.onerror = reject;
  });
}

export const MessageModel = types
  .model("Message")
  .props({
    message_id: types.identifier,
    id: types.maybeNull(types.number), // 服务db的消息primary_key
    sid: types.maybeNull(types.number), // 本地db的消息primary_key
    chat_id: types.maybeNull(types.number), // 服务db的聊天室primary_key
    chat_cid: types.maybeNull(types.string), // 本地mobx的聊天室primary_keyuploadMultipleImages
    sender: types.reference(UserModel),
    receiver: types.maybeNull(types.reference(UserModel)),
    content: types.optional(types.string, ""),
    created: types.Date,
    updated: types.Date,
    attach: types.maybeNull(
      types.frozen<Attach & { localVideoPreviewImageUri?: string; path?: string }>()
    ), // 本地附件
    attach_path: types.maybeNull(types.string), // 本地附件保存路径
    type: types.optional(
      numEnumeration<MessageTypes>("MessageType", Object.values(MessageTypes)),
      MessageTypes.TEXT
    ),
    status: types.optional(
      numEnumeration<MessageStatus>(
        "MessageStatus",
        Object.values(MessageStatus)
      ),
      MessageStatus.SENDING
    ),
    read_ids: types.optional(types.array(types.number), []),
    notify_type: types.maybeNull(types.number),
    mention_ids: types.optional(types.array(types.number), []),
    reply_msg_id: types.maybeNull(types.string),
    forward_msg_id: types.maybeNull(types.string),
    forward_user_id: types.maybeNull(types.number),
    forward_name: types.maybeNull(types.string),
    burning_timer: types.maybeNull(types.number),
    is_deleted: types.maybeNull(types.boolean)
  })
  .volatile(() => ({
    progress: 0,
  }))
  .extend(withEnvironment)
  .views((self) => {
    function messageChatID() {
      return self.chat_cid;
    }
    function replyMsg() {
      const root = getRoot<Instance<typeof RootStoreModel>>(self);
      return self.reply_msg_id
        ? resolveIdentifier(MessageModel, root, self.reply_msg_id)
        : null;
    }
    function attachURL() {
      return staticUrl(self.content);
    }
    // 获取附件地址
    function getAttachFile() {
      return self.attach_path || staticUrl(self.content);
    }

    // 消息附件是否已经成功下载
    function hasAttachFile() {
      return hasAttach() && self.attach_path !== null;
    }

    // 消息是否包含附件
    function hasAttach() {
      return self.attach !== null;
    }
    // 图片文件
    function image() {
      return getAttachFile();
    }
    // 音频文件
    function voice() {
      return getAttachFile();
    }
    function loading() {
      return self.id !== undefined;
    }
    function uploading() {
      return self.status === MessageStatus.UPLOADING;
    }
    function inProgress() {
      return (
        self.status === MessageStatus.UPLOADING ||
        self.status === MessageStatus.DOWNLOADING
      );
    }
    // 本人发送
    function byMe() {
      const {
        userStore: { currentUser },
      } = getRoot<Instance<typeof RootStoreModel>>(self);
      return currentUser.id === self.sender.id;
    }
    function isReadedByOther() {
      return self.read_ids ? self.read_ids.length > 0 : false;
    }
    function isReadedByMe() {
      const {
        userStore: { currentUser },
      } = getRoot<Instance<typeof RootStoreModel>>(self);
      return self.read_ids ? self.read_ids.includes(currentUser.id) : false;
    }
    function isNotify() {
      return self.notify_type !== null;
    }
    return {
      get messageChatID() {
        return messageChatID();
      },
      get replyMsg() {
        return replyMsg();
      },
      get attachURL() {
        return attachURL();
      },
      get getAttachFile() {
        return getAttachFile();
      },
      get hasAttachFile() {
        return hasAttachFile();
      },
      get hasAttach() {
        return hasAttach();
      },
      get image() {
        return image();
      },
      get voice() {
        return voice();
      },
      get loading() {
        return loading();
      },
      get uploading() {
        return uploading();
      },
      get inProgress() {
        return inProgress();
      },
      get byMe() {
        return byMe();
      },
      get isReadedByOther() {
        return isReadedByOther();
      },
      get isReadedByMe() {
        return isReadedByMe();
      },
      get isNotify() {
        return isNotify();
      },
    };
  })
  .actions((self) => ({
    update(values: { [key: string]: any }) {
      for (const key in values) {
        self[key] = values[key];
      }
    },
    setStatus(status: MessageStatus) {
      self.status = status;
    },
    markRead() {
      const {
        userStore: { currentUser },
      } = getRoot<Instance<typeof RootStoreModel>>(self);
      self.status = MessageStatus.READ;
      if (!self.read_ids) self.read_ids.replace([currentUser.id]);
      else self.read_ids.push(currentUser.id);
      updateMessage({
        cM: { message_id: self.message_id, status: self.status },
      });
    },
    // 设置上传/下载进度
    setProgress(progress: number) {
      debug(self.getAttachFile, progress);
      self.progress = progress;
    },
    setAttachPath(path: string) {
      self.attach_path = path;
    },
    setLocalVideoPreviewImageUri(uri: string) {
      self.attach = {
        ...self.attach,
        localVideoPreviewImageUri: uri,
      };
    },
  }))
  .actions((self) => {
    // 下载附件
    const download = flow(function* ({ onProgressUpdate }) {
      const { apiStore } = getRoot<Instance<typeof RootStoreModel>>(self);
      const payload = {
        url: staticUrl(self.content),
        onProgressUpdate: onProgressUpdate,
      };
      const prevStatus = self.status;
      try {
        self.status = MessageStatus.DOWNLOADING;
        const path = yield apiStore.download(payload);
        self.attach_path = path;
        self.status = prevStatus;
        return self.attach_path;
      } catch (e) {
        self.status = MessageStatus.FAILED;
        throw new Error(e.message);
      }
    });

    // 上传附件
    const upload = flow(function* () {
      const { apiStore } = getRoot<Instance<typeof RootStoreModel>>(self);
      const st = self.status;
      try {
        self.status = MessageStatus.UPLOADING;
        const response = yield fetch(self.attach_path);
        const blob = yield response.blob();
        const newFormData = new FormData();
        newFormData.append("file", blob, self.attach.filename);
        const payload = {
          formData: newFormData,
          onProgressUpdate: ({ loaded, total }) => {
            self.setProgress(loaded / total);
          },
        };
        const data = yield apiStore.upload(payload);
        debug("upload response:", data);
        self.content = data.uri;
        self.status = st;
        return data;
      } catch (e) {
        self.status = MessageStatus.FAILED;
        throw new Error(e.message);
      }
    });

    const uploadClient = flow(function* () {
      const { apiStore } = getRoot<Instance<typeof RootStoreModel>>(self);
      const payload: any = {
        ...self.attach,
        path: self.attach_path,
        onProgressUpdate: self.setProgress,
      };
      const st = self.status;
      try {
        self.status = MessageStatus.UPLOADING;
        const data = yield apiStore.uploadClient(payload);
        const file = yield fetch(payload.path).then(r => r.blob())
        debug("uploadClient response:", data);
        if (data?.storage === "S3Storage") {
          const newFormData = new FormData();
          Object.keys(data.payload.fields).forEach((key) => {
            newFormData.append(key, data.payload.fields[key]);
          });
          newFormData.append("file", file, payload.filename);
          yield apiStore.uploadToS3Storage(
            data.payload.url,
            newFormData,
            (e) => {
              payload.onProgressUpdate(e.loaded / e.total);
            }
          );
        } else if (data?.storage === "QiNiuStorage") {
          if (!data.payload.url) {
            data.payload.url = "https://prenbqn.bj-huishou.com";
          }
          yield new Promise<void>(async (resolve) => {
            const observable = qiniu.upload(
              file,
              data.url,
              data.payload.token,
              {
                mimeType: payload.type,
              },
              {
                region: qiniu.region.z2,
              }
            );
            observable.subscribe({
              next: (res) => {
                payload.onProgressUpdate(res.total.loaded / res.total.size);
              },
              complete: () => {
                resolve();
              },
            });
          });
        }
        self.content = data.payload.url + "/" + data.url;
        self.attach_path = self.content;
        debug("uploaded video url:", self.content);
        self.status = st;
        return data;
      } catch (e) {
        self.status = MessageStatus.FAILED;
        throw new Error(e.message);
      }
    });

    const uploadMultipleImages = flow(function* () {
      const { apiStore } = getRoot<Instance<typeof RootStoreModel>>(self);
      const st = self.status;
      try {
        self.status = MessageStatus.UPLOADING;
        const setProgress = self.setProgress;
        const results = yield Promise.all(
          self.attach.imgs.map(async (img: any) => {
            img.progress = 0;
            const blob = await imageDataUrlToBlob(img.path);
            const newFormData = new FormData();
            newFormData.append("file", blob as Blob, img.filename);
            const payload = {
              formData: newFormData,
              onProgressUpdate: ({ loaded, total }) => {
                setProgress(loaded / total);
                img.progress = loaded / total;
              },
            };
            img.isUploading = true;
            const data = await apiStore.upload(payload);
            img.isUploading = false;
            img.url = data.uri;
            return data;
          })
        );
        self.status = st;
        return results;
      } catch (e: any) {
        self.status = MessageStatus.FAILED;
        throw new Error(e.message);
      }
    });
    return { upload, uploadMultipleImages, download, uploadClient };
  });

type MessageType = Instance<typeof MessageModel>;
export interface Message extends MessageType {}
type MessageSnapshotType = SnapshotOut<typeof MessageModel>;
export interface MessageSnapshot extends MessageSnapshotType {}
export const createMessageDefaultModel = () => types.optional(MessageModel, {});

export const lateMessage = () => {
  return MessageModel;
};
