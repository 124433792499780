import React from "react";
import styled from "styled-components";
import ChatListItem from "./components/ChatListItem";
import { Chat } from "../../models";

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

interface Props {
  selecteds?: Chat["chat_id"][];
  data: Chat[];
  onItemPress?: (item: Chat) => void;
}

const ChatList: React.FC<Props> = ({ selecteds, data, onItemPress }) => {
  return (
    <Wrap>
      {data.map((item) => {
        return (
          <ChatListItem
            key={item.chat_id}
            checked={
              selecteds ? selecteds.includes(item.chat_id) : void 0
            }
            item={item}
            onItemPress={onItemPress}
          />
        );
      })}
    </Wrap>
  );
};

export default ChatList;
