import "fontisto/css/fontisto/fontisto.min.css";
import styled from "styled-components";
import useGlobalTheme from "../hooks/useGlobalTheme";

const Icon = styled.i`
  line-height: 13px;
`;

export interface Props {
  name: string;
  size?: number;
  color?: string;
}

const FitIcon: React.FC<Props> = ({ name, size, color, ...rest }) => {
  const theme = useGlobalTheme();
  return (
    <Icon
      style={{
        color: color || theme.colors.primary,
        fontSize: `${size}rem`,
      }}
      className={name}
      {...rest}
    />
  );
};

FitIcon.defaultProps = {
  size: 0.8,
};

export default FitIcon;
