/** @format */

import routeDescriptions from './routeDescriptions';
import Login from '../pages/Login';
import ForgetPassword from '../pages/ForgetPassword';
import Register from '../pages/Register';
import Welcome from '../pages/Welcome';
import Chat from '../pages/Chat';
import Setting from '../pages/Setting';
import User from '../pages/User';
import GroupChatDetail from '../pages/GroupChatDetail';
import AddGroupMember from '../pages/AddGroupMember';
import RemoveGroupMember from '../pages/RemoveGroupMember';
import AddGroupChat from '../pages/AddGroupChat';
import AddContact from '../pages/AddContact';
import ChatList from '../pages/ChatList';
import ContactList from '../pages/ContactList';
import FriendList from '../pages/FriendList';
import BasicInfo from '../pages/BasicInfo';
import Me from '../pages/Me';
import Avatar from '../pages/Avatar';
import UpdateCurrentUserDisplayName from '../pages/UpdateCurrentUserDisplayName';
import UpdateCurrentUserEmail from '../pages/UpdateCurrentUserEmail';
import CurrentUserQrcode from '../pages/CurrentUserQrcode';
import GroupChatQrcode from '../pages/GroupChatQrcode';
import UpdateGroupTitle from '../pages/UpdateGroupTitle';
import UpdateCurrentUserGender from '../pages/UpdateCurrentUserGender';
import PrivacyAndSafety from '../pages/PrivacyAndSafety';
import UserSetting from '../pages/UserSetting';
import UpdateContact from '../pages/UpdateContact';
import UpdateUserDescription from '../pages/UpdateUserDescription';
import BlackList from '../pages/BlackList';
import ForwardMessage from '../pages/ForwardMessage';

import ORedirect from '../pages/Redirect';

const Redirect = () => {
  return <ORedirect redirectUrl="/"></ORedirect>;
};

const components = {
  Login,
  ForgetPassword,
  Register,
  Welcome,
  Chat,
  Setting,
  User,
  GroupChatDetail,
  AddGroupMember,
  RemoveGroupMember,
  AddGroupChat,
  AddContact,
  ChatList,
  ContactList,
  FriendList,
  BasicInfo,
  Me,
  Avatar,
  UpdateCurrentUserDisplayName,
  UpdateCurrentUserEmail,
  CurrentUserQrcode,
  GroupChatQrcode,
  UpdateGroupTitle,
  UpdateCurrentUserGender,
  UpdateContact,
  PrivacyAndSafety,
  UserSetting,
  UpdateUserDescription,
  BlackList,
  ForwardMessage,
  Redirect,
};

const routes = routeDescriptions.map(({ key, ...rest }) => {
  return {
    key,
    ...rest,
    Component: components[key],
  };
});

export default routes;
