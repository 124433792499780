import { InputHTMLAttributes, useCallback } from "react";
import styled from "styled-components";
import useValidateInput from "../hooks/useValidateInput";
import { ValidationRule } from "../utils";
import FieldWrap from "./FieldWrap";
import FieldFloatLabel from "./FieldFloatLabel";
import ErrorText from "./ErrorText";

const StyledInput = styled.input`
  font-size: 0.8rem;
  color: ${({ theme }) => theme.colors.text};
  border-width: 0px;
  padding: ${({ theme }) => `${theme.space[1]} ${theme.space[3]}`};
  border-radius: ${({ theme }) => theme.radii[3]};
  background-color: ${({ theme }) => theme.colors.inputBackground};
  :focus {
    outline-color: ${({ theme }) => theme.colors.primary};
  }
`;

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  validateRule?: ValidationRule
  label?: string;
}

const Input: React.FC<Props> = ({ validateRule, label, value, onChange, ...rest }) => {
  const { error, validate } = useValidateInput(validateRule);

  const overridedOnChange = useCallback((event) => {
    onChange(event)
    validate(event.target.value);
  }, [onChange, validate])

  return (
    <FieldWrap>
      {label && <FieldFloatLabel>{label}</FieldFloatLabel>}
      <StyledInput value={value} onChange={overridedOnChange} {...rest}></StyledInput>
      <ErrorText visible={true}>{!!error === true ? error[0] : ''}</ErrorText>
    </FieldWrap>
  );
};
export default Input;
