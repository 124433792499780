/** @format */

import { observer } from 'mobx-react-lite';
import React from 'react';
import styled from 'styled-components';
import {
  Event,
  EventNames,
  FLAG_ACK,
  FriendCommonData,
  FriendStatus,
} from '../../../../proto/nbchat-proto';
import { useStores, Message } from '../../../../models';
import { ToastSuccess } from '../../../Toast';

export enum NotifyType {
  CONTACT_CREATED = 1,
  CONTACT_DELETED = 2,
  GROUP_TITLE_UPDATE = 3,
  CHAT_CREATED = 4,
  CHAT_MEMBER_ADDED = 5,
  CHAT_MEMBER_REMOVED = 6,
  CHAT_MEMBER_LEFT = 7,
  CONTACT_CREATED_BUT_NOT_APPROVED = 8,
  MESSAGE_CREATED_BUT_NOT_APPROVED = 9,
  FRIEND_ADDED_BUT_NOT_APPROVED = 10,
}

const Wrap = styled.div`
  padding: 0px 20px;
  margin: 10px auto;
  display: flex;
`;

const Content = styled.span`
  color: ${({ theme }) => theme.colors.secondaryText};
`;

const Link = styled.span`
  color: ${({ theme }) => theme.colors.primary};
`;

const ErrorLink = styled.span`
  color: ${({ theme }) => theme.colors.danger};
`;

const MessageContactDeletedNotify: React.FC<{ message: Message }> = observer(
  ({ message }) => {
    const { eventStore, userStore } = useStores();
    const handleInvited = () => {
      const user = userStore.users.get(message.receiver.id.toString());
      const evt = new Event<FriendCommonData>(EventNames.FRIEND_ADD, {
        username: user.username,
      });
      eventStore.publish(evt, { flags: FLAG_ACK });
      ToastSuccess('发送成功');
    };
    return (
      <Wrap>
        <Content>
          你还不是他(她)的朋友。请先发送朋友邀请，对方通过验证后，才能聊天。
          <Link onClick={handleInvited}>发送邀请</Link>
        </Content>
      </Wrap>
    );
  }
);

const MessageContactCreateNotify: React.FC = () => {
  return (
    <Wrap>
      <Content>现在我们是朋友了，可以开始聊天了</Content>
    </Wrap>
  );
};

const MessageContactCreateButNotApprovedNotify: React.FC = () => {
  return (
    <Wrap>
      <Content>
        你还不是他(她)的朋友。请通知对方加你为好友，来保持最优良的聊天品质。
      </Content>
    </Wrap>
  );
};

export const MessageMessageCreateButNotApprovedNotify: React.FC<{
  message: Message;
}> = observer(({ message }) => {
  const { eventStore, userStore, friendStore, contactStore, blackListStore } =
    useStores();
  const { currentUser } = userStore;
  const id = currentUser.id;
  const user_id =
    id === message.receiver.id ? message.sender.id : message.receiver.id;
  const user = userStore.users.get(user_id.toString());
  const friend = friendStore.friendsByUserId(user_id);
  const contact = contactStore.byUserId(user_id);
  const isBlack = !!blackListStore.black_list.get(`${user_id}`);

  const handleApprove = () => {
    const evt = new Event<FriendCommonData>(EventNames.FRIEND_APPROVE, {
      username: user.username,
    });
    eventStore.publish(evt, { flags: FLAG_ACK });
    ToastSuccess('发送成功');
  };

  const handleAddFriend = () => {
    const evt = new Event<FriendCommonData>(EventNames.FRIEND_ADD, {
      username: user.username,
    });
    eventStore.publish(evt, { flags: FLAG_ACK });
    ToastSuccess('发送成功');
  };

  const handleAddBlack = () => {
    blackListStore.publicAddBlacklist(user_id);
  };
  return (
    <Wrap>
      <Content>
        他(她)还不是你的朋友。你可以新增他为好友，来保持最优良的聊天品质。
        {!isBlack && (
          <>
            {friend && friend.status === FriendStatus.PENDING ? (
              <Link onClick={handleApprove}>同意邀请</Link>
            ) : !contact ? (
              <Link onClick={handleAddFriend}>加入好友</Link>
            ) : null}
            {!contact && !isBlack && (
              <>
                /<ErrorLink onClick={handleAddBlack}>加入黑名单</ErrorLink>
              </>
            )}
          </>
        )}
      </Content>
    </Wrap>
  );
});

const MessageCommonNotify: React.FC<{ message: Message }> = ({ message }) => {
  return (
    <Wrap>
      <Content>{message.content}</Content>
    </Wrap>
  );
};

const MessageFriendAddedButNotApprovedNotify: React.FC = () => {
  return (
    <Wrap>
      <Content>已加为好友。</Content>
    </Wrap>
  );
};

const MessageNotfiy: React.FC<{ message: Message }> = ({ message }) => {
  switch (message.notify_type) {
    case NotifyType.CONTACT_DELETED:
      return <MessageContactDeletedNotify message={message} />;
    case NotifyType.CONTACT_CREATED:
      return <MessageContactCreateNotify />;
    case NotifyType.CONTACT_CREATED_BUT_NOT_APPROVED:
      return <MessageContactCreateButNotApprovedNotify />;
    case NotifyType.MESSAGE_CREATED_BUT_NOT_APPROVED:
      return <MessageMessageCreateButNotApprovedNotify message={message} />;
    case NotifyType.FRIEND_ADDED_BUT_NOT_APPROVED:
      return <MessageFriendAddedButNotApprovedNotify />;
    case NotifyType.GROUP_TITLE_UPDATE:
      return <MessageCommonNotify message={message} />;
    case NotifyType.CHAT_CREATED:
      return <MessageCommonNotify message={message} />;
    default:
      return <MessageCommonNotify message={message} />;
  }
};

export default MessageNotfiy;
