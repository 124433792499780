import { useState } from "react";
import styled from "styled-components";
import Image from "../../widgets/Image";

const AvatarWrap = styled.div<{ isRound: boolean }>`
  border-radius: ${({ isRound }) => (isRound ? "50%" : "0%")};
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary};
  overflow: hidden;
`;

const AvatarImage = styled(Image)`
  width: 100%;
  height: 100%;
`;

const AvatarText = styled.span`
  font-size: 0.8rem;
  color: ${({ theme }) => theme.colors.blockBackground};
`;

const getName = (text) => {
  return text ? text.split("")[0].toUpperCase() : "";
};

const Avatar = ({ avatar, name, isRound = true }) => {
  const [imageError, setImageError] = useState(false);
  return (
    <AvatarWrap isRound={isRound}>
      {avatar && !imageError ? (
        <AvatarImage
          src={avatar}
          onError={() => {
            setImageError(true);
          }}
        />
      ) : (
        <AvatarText>{getName(name)}</AvatarText>
      )}
    </AvatarWrap>
  );
};

export default Avatar;
