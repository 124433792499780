import { observer } from "mobx-react-lite";
import React, { useState, useCallback } from "react";
import styled from "styled-components";
import routeDescriptions from "../../routers/routeDescriptions";
import useNavigation from "../../hooks/useNavigation";
import useGlobalTheme from "../../hooks/useGlobalTheme";
import Icon from "../../components/Icon";
import Overlay from "../../components/Overlay";
import { useStores } from "../../models";

const PopupRoutes = routeDescriptions.filter(
  (routeDescription) => routeDescription.isPlusButtonPopup
);

export const headerHeight = "50px";

const Wrap = styled.div`
  height: ${headerHeight};
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.blockBackground};
  border-width: 0px;
  border-bottom-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.border};
  padding: 0px ${({ theme }) => theme.space[3]};
`;

const PlusIconWrap = styled.div`
  position: relative;
  cursor: pointer;
`;

const PlusPopup = styled.div<{ isMobile: boolean }>`
  position: absolute;
  z-index: 3;
  top: 24px;
  left: ${({ isMobile }) => `${isMobile ? 'unset' : '120px'}` };
  right: ${({ isMobile }) => `${isMobile ? '30px' : 'unset'}` };
  width: 150px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: ${({ theme }) => theme.space[2]};
  border-top-right-radius: 0px;
  background-color: ${({ theme }) => theme.colors.blockBackground};
  display: flex;
  flex-direction column;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
`;

const PlusPopupItem = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) =>
    `${theme.space[2]} ${theme.space[4]} ${theme.space[2]} ${theme.space[4]}`};
  border: 0px solid ${({ theme }) => theme.colors.border};
  gap: ${({ theme }) => theme.space[2]};
  cursor: pointer;
`;

const Title = styled.span`
  font-size: 1.2rem;
  font-weight: bold;
`;

interface Props {
  title: string;
  isBackButton?: boolean;
  backFunc?: () => void;
  isPlusButton?: boolean;
  children?: React.ReactNode;
}

const PageHeader: React.FC<Props> = observer(({
  title,
  isBackButton,
  backFunc,
  isPlusButton,
  children,
}) => {
  const { navigate, goBack } = useNavigation();
  const theme = useGlobalTheme();
  const { isMobile } = useStores();
  const [plusPopupVisible, setPlusPopupVisible] = useState(false);

  const onBackButttonPress = useCallback(() => {
    if (backFunc) {
      backFunc();
    } else {
      goBack(-1);
    }
  }, [backFunc, goBack]);

  const onPlusButtonPress = useCallback(() => {
    setPlusPopupVisible(!plusPopupVisible);
  }, [plusPopupVisible]);

  return (
    <Wrap>
      {isBackButton && (
        <Icon
          type="mdi"
          name="arrowLeft"
          size={0.7}
          color={theme.colors.text}
          onClick={onBackButttonPress}
        ></Icon>
      )}
      <Title>{title}</Title>
      {children ? (
        children
      ) : isPlusButton ? (
        <PlusIconWrap onClick={onPlusButtonPress}>
          <Icon type="mdi" name="plus" size={1}></Icon>
          {plusPopupVisible && (
            <Overlay>
              <PlusPopup isMobile={isMobile}>
                {PopupRoutes.map((route) => {
                  return (
                    <PlusPopupItem
                      key={route.key}
                      onClick={() => navigate(route.key)}
                    >
                      <Icon {...(route.icon as any)} size={0.8}></Icon>
                      {route.title}
                    </PlusPopupItem>
                  );
                })}
              </PlusPopup>
            </Overlay>
          )}
        </PlusIconWrap>
      ) : (
        <div></div>
      )}
    </Wrap>
  );
});

PageHeader.defaultProps = {
  isBackButton: true,
  isPlusButton: false,
};

export default PageHeader;
