import styled from "styled-components";

const LabelWrap = styled.div`
  margin-bottom: ${({ theme }) => theme.space[2]};
`;
const Label = styled.span`
  font-size: 0.8rem;
  color: ${({ theme }) => theme.colors.inputPlaceholderColor};
`;

const FieldFloatLabel = ({ children }) => {
  return (
    <LabelWrap>
      <Label>{children}</Label>
    </LabelWrap>
  );
};

export default FieldFloatLabel;
