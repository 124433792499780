export const copyTextToClipboard = message => {
  const input = document.createElement('input')
  input.value = message
  input.readOnly = true
  input.ontouchstart = function() {
    (this as HTMLElement).removeAttribute('readonly')
  }
  input.onfocus = function() {
    (this as HTMLElement).removeAttribute('readonly')
  }
  document.body.appendChild(input)
  input.select()
  input.setSelectionRange(0, input.value.length)
  document.execCommand('Copy')
  return document.body.removeChild(input)
}