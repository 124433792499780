import React from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import useNavigation from "../../hooks/useNavigation";
import PageWrap from "../components/PageWrap";
import PageHeader, { headerHeight } from "../components/PageHeader";
import { useStores } from "../../models";
import QrcodeDisplayer from "../../components/QrcodeDisplayer";

const ScrollArea = styled.div`
  height: calc(100% - ${headerHeight});
  overflow: scroll;
`;

const GroupChatQrcode: React.FC = () => {
  const { params } = useNavigation();
  const { chat_id } = params;
  const { chatStore } = useStores();
  const chat = chatStore.chats.get(chat_id);
  return (
    <PageWrap>
      <PageHeader title="群二维码" isBackButton={true} />
      <ScrollArea>
        <QrcodeDisplayer
          avatar={chat.image}
          qrcode={chat.groupQrcode}
          name={chat.title}
          description="扫描二维码 加入群组"
        />
      </ScrollArea>
    </PageWrap>
  );
};

export default observer(GroupChatQrcode);
