import { getRoot, Instance, SnapshotOut, types } from "mobx-state-tree";
import { Chat, ChatModel } from "../chat/chat";
import { Contact } from "../contact/contact";
import { withEnvironment } from "../extensions/with-environment";
import { newID, genPrivacyChatID } from "../../utils";
import {
  ChatData,
  ChatListData,
  ChatTypes,
  Event,
  EventNames,
  UserActionData,
  UserActions,
  ChatMemberAddedData,
  ChatMemberARData,
  ChatMemberLJData,
  MessageTypes,
  MessageStatus,
  ChatNewData,
  MessageData,
  ChatDeleteData,
  ChatsLastMessageData,
  FLAG_ACK,
  MuteData,
  ChatDisplayData,
  ChatBurningSwitchData,
} from "../../proto/nbchat-proto";
import { NotifyType } from "../../types/message";
import { Message, RootStoreModel } from "..";

/**
 * Model description here for TypeScript hints.
 */
export const ChatStoreModel = types
  .model("ChatStore")
  .props({
    chats: types.map(ChatModel),
    current: types.maybe(types.reference(ChatModel)),
  })
  .volatile(() => ({
    rightClickMenuVisible: false,
    rightClickMenuMessageId: "",
    isReplyBarVisible: false,
  }))
  .extend(withEnvironment)
  .views((self) => ({
    getRightClickMenuSelectedMessage(chat: Chat): Message {
      return chat.messages.find(
        ({ message_id }) => message_id === self.rightClickMenuMessageId
      );
    },
    search(key: string) {
      const data = Array.from(self.chats.values()).filter((x) => !x.deleted);
      data.sort((a, b) => {
        if (!a.pinTime && !b.pinTime) {
          if (a.lastMessage && b.lastMessage) {
            return (
              b.lastMessage.created.getTime() - a.lastMessage.created.getTime()
            );
          } else if (a.lastMessage && !b.lastMessage) {
            return -1;
          } else {
            return 1;
          }
        } else if (a.pinTime && b.pinTime) {
          return b.pinTime.getTime() - a.pinTime.getTime();
        } else if (a.pinTime && !b.pinTime) {
          return -1;
        } else {
          return 1;
        }
      });
      return key ? data.filter((x) => x.title.includes(key)) : data;
    },
    byId(id: number) {
      return Array.from(self.chats.values()).find((x) => x.id === id);
    },
    byChatId(chat_id: string) {
      return Array.from(self.chats.values()).find((x) => x.chat_id === chat_id);
    },
  }))
  .actions((self) => {
    function setRightClickMenuVisible(visible: boolean) {
      self.rightClickMenuVisible = visible;
    }
    function setRightClickMenuMessageId(message_id) {
      self.rightClickMenuMessageId = message_id;
    }
    function setIsReplyBarVisible(visible: boolean) {
      self.isReplyBarVisible = visible;
    }
    function getChat(chat_id: string | number) {
      return self.chats.get(chat_id.toString());
    }
    function hasChat(chat_id: string | number) {
      return self.chats.has(chat_id.toString());
    }
    function getPrivateChatByUserID(user_id: number) {
      const {
        userStore: { currentUser },
      } = getRoot<Instance<typeof RootStoreModel>>(self);
      const { chat_id } = genPrivacyChatID(currentUser.id, user_id);
      return getChat(chat_id);
    }
    function _putChat(
      data: Partial<ChatDisplayData> & { receiver_id?: number }
    ) {
      const { creator_id, receiver_id, members = [], ...item } = data;
      const now = Date.now();
      data.created = data.created || now;
      data.updated = data.updated || now;
      let chat;
      if (hasChat(data.chat_id)) {
        chat = getChat(data.chat_id);
        chat.setID(data.id);
        if (data.creator_id) chat.setCreator(data.creator_id);
        chat.setTitle(data.title);
        chat.setImage(data.image);
        chat.setIsMute(data.is_mute);
        chat.setIsBurning(data.is_burning);
        chat.setBurningTimer(data.burning_timer);
      } else {
        chat = self.chats.put({
          ...item,
          creator: creator_id,
          receiver: receiver_id,
        });
      }
      chat.membersToUsers({
        members,
      });
      return chat;
    }
    // 新建私聊
    function newPrivateChat(
      data: Partial<ChatDisplayData> & { receiver_id: number }
    ) {
      data.type = ChatTypes.PRIVATE;

      const {
        userStore: { currentUser },
      } = getRoot<Instance<typeof RootStoreModel>>(self);

      const { title, chat_id } = genPrivacyChatID(
        currentUser.id,
        data.receiver_id
      );

      return _putChat({ ...data, chat_id, title });
    }
    // 新建群聊
    function newGroupChat(data: ChatDisplayData) {
      data.type = ChatTypes.GROUP;
      const chat_id = data.chat_id || newID();
      const title = data.title || "群聊";

      return _putChat({ ...data, chat_id, title });
    }
    function createChatByMessageData(m: MessageData) {
      const { userStore, contactStore } =
        getRoot<Instance<typeof RootStoreModel>>(self);
      const { currentUser } = userStore;
      const isGroup = m.chat_cid;

      if (isGroup) {
        const chat_id = m.chat_cid;
        return getChat(chat_id);
      } else {
        const other_id =
          currentUser.id === m.sender_id ? m.receiver_id : m.sender_id;

        const user = userStore.users.get(other_id.toString());
        if (!user) return null;

        const contact = contactStore.byUserId(other_id);
        return newPrivateChat({
          creator_id: currentUser.id,
          receiver_id: other_id,
          is_mute: contact?.is_mute,
        });
      }
    }
    function createChatByChatData(data: ChatDisplayData) {
      const {
        userStore: { currentUser },
      } = getRoot<Instance<typeof RootStoreModel>>(self);
      const isGroup = data.type === ChatTypes.GROUP;

      if (isGroup) {
        return newGroupChat({ ...data });
      } else {
        const member = data.members.find((m) => m.user_id !== currentUser.id);
        return newPrivateChat({ ...data, receiver_id: member.user_id });
      }
    }
    // event-store
    function onChatList({ data: { items } }: Event<ChatListData>) {
      items.forEach((item: ChatDisplayData) => {
        createChatByChatData(item);
      });
    }
    // event-store
    function onChatLastMessageList({
      data: { items },
    }: Event<ChatsLastMessageData>) {
      const { messageStore } = getRoot<Instance<typeof RootStoreModel>>(self);
      items.forEach((item) => {
        const chat = createChatByMessageData(item);
        if (
          chat &&
          chat.messages.length === 0 &&
          !messageStore.messages.get(item.message_id)
        ) {
          messageStore.onLastMessageCreated({
            ...item,
            chat_cid: chat.chat_id,
          });
        }
      });
    }
    // event-store
    function onChatCreated({ data }: Event<ChatDisplayData>) {
      const chat = createChatByChatData(data);

      const { userStore } = getRoot<Instance<typeof RootStoreModel>>(self);
      const { currentUser } = userStore;
      const { creator_id } = data;

      const name =
        currentUser.id === creator_id
          ? "你"
          : userStore.getDisplayNameByUser(userStore.users.get(creator_id));

      if (data.type === ChatTypes.GROUP) {
        chat.newMessage({
          content: `聊天已被${name}创建，可以开始聊天了`,
          type: MessageTypes.TEXT,
          status: MessageStatus.READ,
          notify_type: NotifyType.CHAT_CREATED,
        });
      }
    }
    function onChatMemberAdded({
      data: { chat_cid, items },
    }: Event<ChatMemberAddedData>) {
      const { userStore } = getRoot<Instance<typeof RootStoreModel>>(self);
      const { currentUser } = userStore;
      const chat = self.chats.get(chat_cid);
      // 掃碼加入群組時會先多收到一個memberAdd事件
      if (chat) {
        items.forEach(({ user_id, user }) => {
          const name =
            currentUser.id === user_id
              ? "你"
              : userStore.getDisplayNameByUser(user);
          chat.newMessage({
            content: `${name}加入群聊`,
            type: MessageTypes.TEXT,
            status: MessageStatus.READ,
            notify_type: NotifyType.CHAT_MEMBER_ADDED,
          });
        });
        const newMembers = [
          ...chat?.members,
          ...items.map((m) => ({ ...m, user: userStore.users.get(m.user_id) })),
        ];
        chat.members.replace(newMembers);
      }
    }
    function onChatMemberRemoved({
      data: { chat_cid, user_ids },
    }: Event<ChatMemberARData>) {
      const { userStore } = getRoot<Instance<typeof RootStoreModel>>(self);
      const { currentUser } = userStore;
      const chat = self.chats.get(chat_cid);
      if (chat) {
        user_ids.forEach((user_id) => {
          const { user } = chat.members.find(
            (user) => user.user_id === user_id
          );
          const name =
            currentUser.id === user_id
              ? "你"
              : userStore.getDisplayNameByUser(user);
          chat.newMessage({
            content: `${name}已从群聊移除`,
            type: MessageTypes.TEXT,
            status: MessageStatus.READ,
            notify_type: NotifyType.CHAT_MEMBER_REMOVED,
          });
        });
        const newMembers = chat?.members?.filter(
          (i) => user_ids.indexOf(i.user_id) === -1
        );
        chat.members.replace(newMembers);
      }
    }
    function onChatDeleted({ data: { chat_cid } }) {
      const chat = self.chats.get(chat_cid);
      remove(chat);
    }
    function onChatUpdated({ data: { chat_id, ...values } }: Event<ChatData>) {
      const chat = self.chats.get(chat_id.toString()); // 根据ID获取需要更新资料的用户
      chat.update(values);
      return chat;
    }
    function onChatMemberLeft({
      data: { chat_cid, user_id },
    }: Event<ChatMemberLJData>) {
      const { userStore } = getRoot<Instance<typeof RootStoreModel>>(self);
      const { currentUser } = userStore;
      const chat = self.chats.get(chat_cid);
      if (chat) {
        const { user } = chat.members.find((user) => user.user_id === user_id);
        const name =
          currentUser.id === user_id
            ? "你"
            : userStore.getDisplayNameByUser(user);
        chat.newMessage({
          content: `${name}已退出群聊`,
          type: MessageTypes.TEXT,
          status: MessageStatus.READ,
          notify_type: NotifyType.CHAT_MEMBER_LEFT,
        });
        const newMembers = chat?.members?.filter((i) => i.user_id !== user_id);
        chat.members.replace(newMembers);
      }
    }
    function onChatBurningSwitched({
      data: { is_burning, burning_timer, chat_id, creator_id },
    }: Event<ChatBurningSwitchData>) {
      const chat = self.byId(Number(chat_id));
      if (chat) {
        const { userStore } = getRoot<Instance<typeof RootStoreModel>>(self);
        chat.setIsBurning(is_burning);
        chat.setBurningTimer(burning_timer);

        const name =
          userStore.currentUser.id === creator_id
            ? "你"
            : userStore.getDisplayNameByUser(
                userStore.users.get(creator_id.toString())
              );
        chat.newMessage({
          content:
            burning_timer === 0
              ? `${name}已关闭阅后即焚`
              : `${name}修改阅后即焚为:${burning_timer}秒`,
          type: MessageTypes.TEXT,
          status: MessageStatus.READ,
          notify_type: NotifyType.CHAT_BURNING_TIMER_SWITCHED,
        });
      }
    }
    function setCurrent(chat: Chat) {
      self.current = chat;
    }
    function save(chat: Chat) {
      self.chats.put(chat);
    }
    function clear() {
      self.chats.replace([]);
    }
    function remove(chat: Chat) {
      chat.clearMessages();
      chat.setDeleted(true);
    }

    // 正在输入事件处理
    function onTypingStarted(chat) {
      if (!chat) {
        return;
      }

      const {
        userStore: { currentUser },
        eventStore,
      } = getRoot<Instance<typeof RootStoreModel>>(self);
      // send event
      const evt = new Event<UserActionData>(EventNames.USER_ACTION, {
        action: UserActions.TYPING_STARTED,
        user_id: currentUser.id,
        chat_id: chat.chat_id,
        chat_cid: chat.id ? chat.id : 0,
        receiver_id: chat.receiver ? chat.receiver.id : 0,
      });
      eventStore.publish(evt);
    }
    function deletePrivateChat(user_id: number) {
      self.chats.delete(user_id.toString());
    }
    function onUserAction({
      data: { user_id, chat_id, receiver_id },
    }: Event<UserActionData>) {
      let chat;
      if (receiver_id) {
        chat = self.chats.get(user_id.toString());
      } else {
        chat = self.chats.get(chat_id);
      }
      if (chat) {
        chat.setTyping(user_id);
        setTimeout(() => {
          chat.setTyping(undefined);
        }, 2000);
      }
    }
    function onChatMuted({
      data: { user_id, chat_id, is_mute },
    }: Event<MuteData>) {
      const { chatStore, contactStore } =
        getRoot<Instance<typeof RootStoreModel>>(self);
      const chat: Chat = chatStore.byId(chat_id);
      if (chat) {
        chat.setIsMute(is_mute);
      } else {
        // is private
        const chats = Array.from(self.chats.values());
        const privateChat: Chat = chats.find((chat) => {
          const { chat_id } = chat;
          return Number(chat_id) === user_id;
        });
        console.log("privateChat", privateChat);
        if (privateChat) {
          privateChat.setIsMute(is_mute);
        }
        const contact: Contact = contactStore.byUserId(user_id);
        console.log("contact", contact);
        if (contact) {
          contact.setIsMute(is_mute);
        }
      }
    }
    function publicNewGroupChat(chat: Chat) {
      const { eventStore } = getRoot<Instance<typeof RootStoreModel>>(self);
      const evt = new Event<ChatNewData>(EventNames.CHAT_NEW, {
        chat_id: chat.chat_id,
        title: chat.title,
        image: chat.image,
        members: chat.users.map(({ id }) => id),
      });
      eventStore.publish(evt, { flags: FLAG_ACK });
    }
    function publicLeaveGroupChat(chat: Chat) {
      const { eventStore } = getRoot<Instance<typeof RootStoreModel>>(self);
      const evt = new Event<ChatDeleteData>(EventNames.CHAT_MEMBER_LEAVE, {
        chat_id: chat.id,
        chat_cid: chat.chat_id,
      });
      eventStore.publish(evt, { flags: FLAG_ACK });
      remove(chat);
    }
    function publicDismissGroupChat(chat: Chat) {
      const { eventStore } = getRoot<Instance<typeof RootStoreModel>>(self);
      const evt = new Event<ChatDeleteData>(EventNames.CHAT_DELETE, {
        chat_id: chat.id,
        chat_cid: chat.chat_id,
      });
      eventStore.publish(evt, { flags: FLAG_ACK });
      remove(chat);
    }

    return {
      setRightClickMenuVisible,
      setRightClickMenuMessageId,
      setIsReplyBarVisible,
      getChat,
      hasChat,
      getPrivateChatByUserID,
      _putChat,
      newPrivateChat,
      newGroupChat,
      createChatByMessageData,
      createChatByChatData,
      onChatList,
      onChatLastMessageList,
      onChatCreated,
      onChatMemberAdded,
      onChatMemberRemoved,
      onChatDeleted,
      onChatUpdated,
      onChatMemberLeft,
      onChatBurningSwitched,
      setCurrent,
      save,
      clear,
      remove,
      onTypingStarted,
      deletePrivateChat,
      onUserAction,
      onChatMuted,
      publicNewGroupChat,
      publicLeaveGroupChat,
      publicDismissGroupChat,
    };
  });

type ChatStoreType = Instance<typeof ChatStoreModel>;
export interface ChatStore extends ChatStoreType {}
type ChatStoreSnapshotType = SnapshotOut<typeof ChatStoreModel>;
export interface ChatStoreSnapshot extends ChatStoreSnapshotType {}
export const createChatStoreDefaultModel = () =>
  types.optional(ChatStoreModel, {});
