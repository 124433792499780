import { PageProps } from "../types";
import React, { useCallback } from "react";
import { observer } from "mobx-react-lite";
import useNavigation from "../../hooks/useNavigation";
import PageWrap from "../components/PageWrap";
import PageHeader from "../components/PageHeader";
import { default as UserListComponent } from "../../components/UserList";
import { useStores } from "../../models";

interface Props extends PageProps {}

const BlackList: React.FC<Props> = () => {
  const { navigate } = useNavigation();
  const { blackListStore } = useStores();

  const onItemPress = useCallback(
    (user) => {
      navigate("User", { user_id: user.id });
    },
    [navigate]
  );

  return (
    <PageWrap>
      <PageHeader
        title="黑名单"
        isBackButton={true}
        isPlusButton={false}
      />
      <UserListComponent
        data={blackListStore.blackListUsers}
        onItemPress={onItemPress}
      />
    </PageWrap>
  );
};

export default observer(BlackList);
