import { default as Icon } from "@mdi/react";
import {
  mdiAccountGroup,
  mdiPin,
  mdiPinOff,
  mdiTrashCan,
  mdiClockTimeTwoOutline,
  mdiArrowLeft,
  mdiLock,
  mdiCog,
  mdiAccountSearch,
  mdiPlus,
  mdiMinus,
  mdiAccountPlus,
  mdiPlusBox,
  mdiDotsHorizontal,
  mdiCheckboxMarkedCircle,
  mdiCheckboxBlankCircleOutline,
  mdiDownloadBoxOutline,
  mdiBellOutline,
  mdiBellOffOutline,
  mdiFilePlusOutline,
} from "@mdi/js";
import useGlobalTheme from "../hooks/useGlobalTheme";

const mdiIconMap = {
  group: mdiAccountGroup,
  pin: mdiPin,
  pinOff: mdiPinOff,
  trashCan: mdiTrashCan,
  clockTimeTwoOutline: mdiClockTimeTwoOutline,
  arrowLeft: mdiArrowLeft,
  lock: mdiLock,
  cog: mdiCog,
  accountSearch: mdiAccountSearch,
  plus: mdiPlus,
  minus: mdiMinus,
  accountPlus: mdiAccountPlus,
  plusBox: mdiPlusBox,
  dotsHorizontal: mdiDotsHorizontal,
  check: mdiCheckboxMarkedCircle,
  checko: mdiCheckboxBlankCircleOutline,
  download: mdiDownloadBoxOutline,
  bell: mdiBellOutline,
  bellOff: mdiBellOffOutline,
  file: mdiFilePlusOutline,
};

interface Props {
  name: string;
  size?: number;
}

const MDIIcon: React.FC<Props> = ({ name, size, ...rest }) => {
  const theme = useGlobalTheme();
  return (
    <Icon
      color={theme.colors.primary}
      {...rest}
      path={mdiIconMap[name]}
      size={size}
    ></Icon>
  );
};

MDIIcon.defaultProps = {
  size: 0.5,
};

export default MDIIcon;
