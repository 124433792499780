import { PageProps } from "../types";
import { useEffect } from "react";
import useNavigation from "../../hooks/useNavigation";

interface Props extends PageProps {
  redirectUrl: string;
}

const RedirectPage: React.FC<Props> = ({ redirectUrl }) => {
  const { n } = useNavigation();
  useEffect(() => {
    n(redirectUrl, { replace: true });
  }, [n, redirectUrl]);
  return null;
};

export default RedirectPage;
