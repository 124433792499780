import styled from "styled-components";

export const footerHeight = "60px";

const PageFooter = styled.div`
  position: relative;
  height: ${footerHeight};
  display: flex;
  justify-content: space-around;
  background-color: ${({ theme }) => theme.colors.blockBackground};
  border-width: 0px;
  border-top-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.border};
`;

export default PageFooter;
