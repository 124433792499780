import styled from "styled-components";
import useGlobalTheme from "../../../hooks/useGlobalTheme";
import useNavigation from "../../../hooks/useNavigation";
import Icon from "../../../components/Icon";
import Content from "../../../components/Message/components/MessageCommon/contents";
import { useStores } from "../../../models";

const ReplyBarWrap = styled.div`
  position: absolute;
  bottom: 97px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.blockBackground};
  padding: 10px 20px;
`;
const ReplyBarBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row
  justify-content: space-between;
  border-width: 0px;
  border-left-width: 2px;
  border-style: solid;
  padding-left: 8px;
  border-color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 6px;
`;
const ReplyBarBoxTitle = styled.span`
  color: ${({ theme }) => theme.colors.primary};
`;
const ReplayBarBoxLeftWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const ReplayBarBoxRightWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
const ReplayBarBoxCloseIconWrap = styled.div``;

const ReplyBar = () => {
  const theme = useGlobalTheme();
  const { params } = useNavigation();
  const { chat_id } = params;
  const {
    chatStore: { setRightClickMenuMessageId, setIsReplyBarVisible, chats, getRightClickMenuSelectedMessage },
  } = useStores();
  const currentChat = chats.get(chat_id);
  const rightClickMenuSelectedMessage = getRightClickMenuSelectedMessage(currentChat) 
  const handleOnClose = () => {
    setIsReplyBarVisible(false);
    setRightClickMenuMessageId('');
  };
  if (!rightClickMenuSelectedMessage) return null;
  return (
    <ReplyBarWrap id="reply-bar">
      <ReplyBarBox>
        <ReplayBarBoxLeftWrap>
          <ReplyBarBoxTitle>{rightClickMenuSelectedMessage.sender.name}</ReplyBarBoxTitle>
          <Content message={rightClickMenuSelectedMessage} />
        </ReplayBarBoxLeftWrap>
        <ReplayBarBoxRightWrap>
          <ReplayBarBoxCloseIconWrap onClick={handleOnClose}>
            <Icon
              type="antd"
              name="close"
              size={1}
              color={theme.colors.secondaryText}
            ></Icon>
          </ReplayBarBoxCloseIconWrap>
        </ReplayBarBoxRightWrap>
      </ReplyBarBox>
    </ReplyBarWrap>
  );
};

export default ReplyBar;
