import styled from "styled-components";

const BottomGap = styled.div`
  margin-top: ${({ theme }) => theme.space[5]};
`

const AccordionBootomGap = () => {
  return <BottomGap />;
}

export default AccordionBootomGap;