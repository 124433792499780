/** @format */

import React, { useState, useMemo, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { Dialog } from 'antd-mobile';
import useNavigation from '../../hooks/useNavigation';
import PageWrap from '../components/PageWrap';
import PageHeader, { headerHeight } from '../components/PageHeader';
import NormalInput from '../../components/NormalInput';
import { ToastSuccess } from '../../components/Toast';
import { rules, validate, isEmpty } from '../../refactor-utils/validator';
import { useStores } from '../../models';

const ScrollArea = styled.div`
  height: calc(100% - ${headerHeight});
  overflow: scroll;
  padding: ${({ theme }) => theme.space[4]};
  background-color: ${({ theme }) => theme.colors.blockBackground};
`;

const SubmitText = styled.span<{ canSubmit: boolean }>`
  cursor: pointer;
  color: ${({ canSubmit, theme }) =>
    canSubmit ? theme.colors.primary : theme.colors.inputPlaceholderColor};
`;

const UpdateCurrentUserDisplayName: React.FC = () => {
  const { goBack } = useNavigation();
  const {
    userStore,
    userStore: { currentUser },
  } = useStores();
  const [name, setName] = useState(currentUser.name);

  const onSubmit = useCallback(() => {
    Dialog.confirm({
      content: '确定要修改?',
      onConfirm: () => {
        userStore.publicCurrentUserUpdate({ name });
        ToastSuccess('修改成功!');
        goBack(-1);
      },
    });
  }, [userStore, name, goBack]);

  const disabledFunc = useCallback(
    ({ name }) => {
      return (
        name === currentUser.name ||
        !isEmpty(validate({ name: rules.displayname }, { name }))
      );
    },
    [currentUser.name]
  );

  const canSubmit = useMemo(
    () => !disabledFunc({ name }),
    [disabledFunc, name]
  );

  return (
    <PageWrap>
      <PageHeader title="修改昵称" isBackButton={true}>
        <SubmitText
          canSubmit={canSubmit}
          onClick={canSubmit ? onSubmit : undefined}
        >
          确定
        </SubmitText>
      </PageHeader>
      <ScrollArea>
        <NormalInput
          label="昵称"
          placeholder="请输入昵称"
          value={name}
          onChange={(e) => setName(e.target.value)}
          validateRule={rules.displayname}
        ></NormalInput>
      </ScrollArea>
    </PageWrap>
  );
};

export default observer(UpdateCurrentUserDisplayName);
