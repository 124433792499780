import { PageProps } from "../types";
import React, { useMemo, useCallback, useState } from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import List from "react-virtualized/dist/commonjs/List";
import AutoSizer from "react-virtualized/dist/commonjs/AutoSizer";
import useNavigation from "../../hooks/useNavigation";
import PageWrap from "../components/PageWrap";
import PageHeader, { headerHeight } from "../components/PageHeader";
import { footerHeight } from "../components/PageFooter";
import ChatListItem from "../../components/ChatList/components/ChatListItem";
import Icon, { Props as IconProps } from "../../components/Icon";
import PagePanelFooter from "../components/PagePanelFooter";
import { Chat, useStores } from "../../models";
import useGlobalTheme from "../../hooks/useGlobalTheme";
import { PanelWidth } from "../../routers/AppRouter/components/LeftPanel";
import Overlay from "../../components/Overlay";

const ScrollArea = styled.div`
  height: calc(100% - ${footerHeight} - ${headerHeight});
`;
const RightClickMenuWidth = 100;
const RightClickMenu = styled.div`
  position: absolute;
  width: ${RightClickMenuWidth}px;
  height: auto;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.blockBackground};
  box-shadow: 0px 1px 30px 0px ${({ theme }) => theme.colors.border};
`;
const RightClickButton = styled.div`
  padding: 0px 10px;
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  border-color: ${({ theme }) => theme.colors.border};
  border-bottom-style: solid;
  border-bottom-width: 0.6px;
  cursor: pointer;
`;
const RightClickButtonText = styled.div`
  margin-left: ${({ theme }) => theme.space[2]};
`;
interface Props extends PageProps {}

const ChatList: React.FC<Props> = () => {
  const theme = useGlobalTheme();
  const { navigate } = useNavigation();
  const { chatStore, websocketStore, messageStore, isMobile, contactStore } = useStores();
  const [item, setItem] = useState<Chat | undefined>(undefined);
  const [rightClickMenuStyle, setRightClickMenuStyle] = useState({
    top: 0,
    left: 0,
  });
  const onItemPress = useCallback(
    (chat) => {
      navigate("Chat", { chat_id: chat.chat_id });
    },
    [navigate]
  );

  const onRightClickMenu = (
    e: React.MouseEvent<HTMLDivElement>,
    item: Chat | undefined
  ) => {
    const { clientX, clientY } = e;
    setItem(item);
    const mostRight = PanelWidth - RightClickMenuWidth;
    setRightClickMenuStyle({
      ...rightClickMenuStyle,
      top: clientY,
      left: clientX >= mostRight ? mostRight : clientX,
    });
  };

  const onCancelRightClickMenu = () => {
    setItem(undefined);
  };

  const actionDelete = useCallback(() => {
    chatStore.remove(item);
  }, [chatStore, item]);

  const actionPinOff = useCallback(() => {
    item.clearPinTime();
  }, [item]);

  const actionPinToTop = useCallback(() => {
    item.setPinTime();
  }, [item]);

  const actionMute = useCallback(() => {
    item.publicToggleMuteChat(true)
  }, [item])

  const actionUnmute = useCallback(() => {
    item.publicToggleMuteChat(false)
  }, [item])

  const swipeActionButtonConfigs = useMemo(
    () => [
      {
        visible: () => true,
        icon: {
          type: "mdi",
          name: "trashCan",
        },
        color: theme.colors.danger,
        text: "删除会话",
        onPress: actionDelete,
      },
      {
        visible: () => {
          return !!item?.pinTime;
        },
        icon: {
          type: "mdi",
          name: "pinOff",
        },
        color: theme.colors.success,
        text: "取消置顶",
        onPress: actionPinOff,
      },
      {
        visible: () => !item?.pinTime,
        icon: {
          type: "mdi",
          name: "pin",
        },
        color: theme.colors.success,
        text: "置顶",
        onPress: actionPinToTop,
      },
      {
        visible: () => {
          if (item?.isGroup) {
            return !item.is_mute
          }
          const contact = contactStore.byUserId(item?.receiver.id)
          if (contact) {
            return !contact?.is_mute;
          } else {
            return false;
          }
        },
        icon: {
        type: "mdi",
          name: "bellOff",
        },
        color: theme.colors.warning,
        text: "静音",
        onPress: actionMute,
      },
      {
        visible: () => {
          if (item?.isGroup) {
            return item.is_mute
          }
          const contact = contactStore.byUserId(item?.receiver.id)
          if (contact) {
            return contact?.is_mute;
          } else {
            return false;
          }
        },
        icon: {
          type: "mdi",
          name: "bell",
        },
        color: theme.colors.warning,
        text: "取消静音",
        onPress: actionUnmute,
      },
    ],
    [theme, item, actionDelete, actionPinOff, actionPinToTop, actionMute, actionUnmute, contactStore]
  );

  const visibleSwipeActionButtonConfigs = useMemo(() => {
    return swipeActionButtonConfigs.filter((config) => config.visible())
  }, [swipeActionButtonConfigs])

  let title;
  if (websocketStore.ready) {
    title =
      `聊天` + (messageStore.unread > 0 ? `(${messageStore.unread})` : "");
  } else {
    title = websocketStore.stateText + (websocketStore.loading ? "..." : "");
  }

  const data = chatStore.search("");

  return (
    <PageWrap onClick={item ? onCancelRightClickMenu : undefined}>
      <PageHeader title={title} isBackButton={false} isPlusButton={true} />
      <ScrollArea>
        <AutoSizer>
          {({ width, height }) => {
            return (
              <List
                ref="List"
                width={width}
                height={height}
                rowCount={data.length}
                overscanRowCount={15}
                rowHeight={57}
                rowRenderer={({ index, key, style }) => {
                  return (
                    <div id={index} key={key} style={style}>
                      <ChatListItem
                        item={data[index]}
                        onItemPress={onItemPress}
                        onRightPress={onRightClickMenu}
                      />
                    </div>
                  );
                }}
              ></List>
            );
          }}
        </AutoSizer>
      </ScrollArea>
      <PagePanelFooter />
      {!isMobile && item && (
        <RightClickMenu style={rightClickMenuStyle}>
          {swipeActionButtonConfigs.map((config, index) => {
            const style: React.CSSProperties = {};
            if (!config.visible()) style.display = "none";
            if (index === swipeActionButtonConfigs.length - 1)
              style.borderBottomWidth = "0px";
            return (
              <RightClickButton
                key={config.icon.name}
                onClick={config.onPress}
                style={style}
              >
                <Icon
                  type={config.icon.type as IconProps["type"]}
                  name={config.icon.name as IconProps["name"]}
                  size={0.7}
                  color={config.color}
                ></Icon>
                <RightClickButtonText>{config.text}</RightClickButtonText>
              </RightClickButton>
            );
          })}
        </RightClickMenu>
      )}
      {isMobile && item && (
        <Overlay onClick={item ? onCancelRightClickMenu : undefined}>
          <RightClickMenu style={rightClickMenuStyle}>
            {visibleSwipeActionButtonConfigs.filter((config) => config.visible()).map((config, index) => {
              const style: React.CSSProperties = {};
              if (index === visibleSwipeActionButtonConfigs.length - 1) {
                style.borderBottomWidth = "0px";
              }
              return (
                <RightClickButton
                  key={config.icon.name}
                  onClick={config.onPress}
                  style={style}
                >
                  <Icon
                    type={config.icon.type as IconProps["type"]}
                    name={config.icon.name as IconProps["name"]}
                    size={0.7}
                    color={config.color}
                  ></Icon>
                  <RightClickButtonText>{config.text}</RightClickButtonText>
                </RightClickButton>
              );
            })}
          </RightClickMenu>
        </Overlay>
          
      )}
    </PageWrap>
  );
};

export default observer(ChatList);
