import { getRoot, Instance, SnapshotOut, types } from "mobx-state-tree";
import {
  Event,
  EventHandler,
  EventNames,
  FLAG_ACK,
} from "../../proto/nbchat-proto";
import { observable } from "mobx";
import { RootStoreModel } from "..";
import { Proto } from "../../types/proto";
import { __DEV__ } from "../../config/env";

export const EventStoreModel = types
  .model("EventStore")
  .props({})
  .volatile(() => ({
    events: observable.array(),
    handlers: {},
  }))
  .views((self) => ({
    get items() {
      return self.events.slice().reverse();
    },
  }))
  .actions((self) => {
    function clearEvents() {
      self.events.clear();
    }

    function onEvent<T>(event: Event<T>) {
      dispatch<T>(event);
    }

    function on<T>(event: EventNames, handler: EventHandler<T>) {
      self.handlers[event] = self.handlers[event] || [];
      self.handlers[event].push(handler);
    }

    // apply event
    function apply<T>(event: Event<T>) {
      if (event.publish) {
        return _publish(event);
      }
      return dispatch(event);
    }

    function dispatch<T>(event: Event<T>) {
      if (__DEV__) {
        self.events.push(event);
      }
      (self.handlers[event.name] || []).forEach((f) => {
        try {
          f(event);
        } catch (e) {
          console.error(e);
        }
      });
    }

    function _publish<T>(event: Event<T>) {
      if (__DEV__) self.events.push(event);
    }

    function publish<T>(
      evt: Event<T>,
      options?: {
        flags?: number;
        onData?: (data) => void;
        onError?: (err: Error) => void;
        immediately?: boolean;
      }
    ) {
      const { channelStore } = getRoot<Instance<typeof RootStoreModel>>(self);
      const { flags = 0, onData, onError, immediately } = options || {};
      const proto = new Proto(evt.name, evt.data, flags);
      channelStore.send(proto, { onData, onError, immediately });
      if (__DEV__) self.events.push(evt);
    }

    function publicLogout() {
      const { authStore } = getRoot<Instance<typeof RootStoreModel>>(self);
      const evt = new Event(EventNames.USER_LOGOUT, {});
      publish(evt, { flags: FLAG_ACK });
      setTimeout(() => {
        authStore.logout();
      }, 1000);
    }

    function registerHandlers() {
      const {
        authStore,
        userStore,
        contactStore,
        messageStore,
        chatStore,
        friendStore,
        blackListStore,
        onSyncFinished,
        onEventPacked,
      } = getRoot<Instance<typeof RootStoreModel>>(self);

      on(EventNames.USER_PROFILE, userStore.onUserProfile);
      on(EventNames.USER_LIST, userStore.onUserList);
      on(EventNames.CONTACT_LIST, contactStore.onContactList);
      on(EventNames.FRIEND_LIST, friendStore.onFriendList);
      on(EventNames.CHAT_LIST, chatStore.onChatList);
      on(EventNames.CHATS_LAST_MESSAGE_LIST, chatStore.onChatLastMessageList);
      on(EventNames.EVENT_PACKED, onEventPacked);
      on(EventNames.SYNC_FINISHED, onSyncFinished);

      on(EventNames.USER_UPDATED, userStore.onUserUpdated);
      on(EventNames.FRIEND_CREATED, friendStore.onFriendCreated);
      on(EventNames.FRIEND_APPROVED, friendStore.onFriendApproved);
      on(EventNames.FRIEND_DELETED, friendStore.onFriendDeleted);
      on(EventNames.CONTACT_CREATED, contactStore.onContactCreated);
      on(EventNames.CONTACT_UPDATED, contactStore.onContactUpdated);
      on(EventNames.CONTACT_DELETED, contactStore.onContactDeleted);

      on(EventNames.MESSAGE_CREATED, messageStore.onMessageCreated);
      on(EventNames.MESSAGE_DELETED, messageStore.onMessageDeleted);
      on(
        EventNames.MESSAGE_STATUS_UPDATED,
        messageStore.onMessageStatusUpdated
      );
      on(
        EventNames.MESSAGE_BATCH_STATUS_UPDATED,
        messageStore.onMessageBatchStatusUpdated
      );
      on(EventNames.MESSAGE_BATCH_READED, messageStore.onMessageBatchReaded);
      on(EventNames.MESSAGE_BATCH_DELETED, messageStore.onMessageBatchDeleted);

      on(EventNames.CHAT_UPDATED, chatStore.onChatUpdated);
      on(EventNames.USER_ACTION, chatStore.onUserAction);
      on(EventNames.CHAT_CREATED, chatStore.onChatCreated);
      on(EventNames.CHAT_MEMBER_ADDED, chatStore.onChatMemberAdded);
      on(EventNames.CHAT_MEMBER_REMOVED, chatStore.onChatMemberRemoved);
      on(EventNames.CHAT_DELETED, chatStore.onChatDeleted);
      on(EventNames.CHAT_MEMBER_LEFT, chatStore.onChatMemberLeft);
      on(EventNames.MUTED, chatStore.onChatMuted);
      on(EventNames.USER_LAST_SEEN_LIST, userStore.onUserLastSeenList);
      on(EventNames.USER_LAST_SEEN, userStore.onUserLastSeen);
      on(EventNames.CHAT_BURNING_SWITCHED, chatStore.onChatBurningSwitched);

      on(EventNames.BLACKLIST_LIST, blackListStore.onBlacklistList);
      on(EventNames.BLACKLIST_ADDED, blackListStore.onBlacklistAdded);
      on(EventNames.BLACKLIST_REMOVED, blackListStore.onBlacklistRemoved);

      on(EventNames.USER_INACTIVED, authStore.publicLogout)
    }

    function afterCreate() {
      registerHandlers();
    }

    return {
      on,
      dispatch,
      afterCreate,
      publish,
      apply,
      onEvent,
      clearEvents,
      publicLogout,
    };
  });

type EventStoreType = Instance<typeof EventStoreModel>;
export interface EventStore extends EventStoreType {}
type EventStoreSnapshotType = SnapshotOut<typeof EventStoreModel>;
export interface EventStoreSnapshot extends EventStoreSnapshotType {}
export const createEventStoreDefaultModel = () =>
  types.optional(EventStoreModel, {});
